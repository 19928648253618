import bootstrap from "../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-table/dist/bootstrap-table";
import template from "./BookOutQueueSelectChargeStep.html";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { createColumn, dollarFieldFormatter } from "../BootstrapTableExtensions";
import "../ActionButton";
import "../AlertMessage";
import "../Roster/SyncFailureDialog";
import "../AdditionalChargesDialog";
import { isAttributeTrue, openSyncFailureDialog } from "../JavaScriptFunctions";

class BookOutQueueSelectChargeStep extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ["container"]);
        this._pageTools = new PageTools();
        this._table = this.shadowRoot.getElementById("table");
        this._returnButton = this.shadowRoot.getElementById("return-button");
        this._continueButton = this.shadowRoot.getElementById("continue-button");
        this._completeButton = this.shadowRoot.getElementById("complete-button");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._syncFailureDialog = this.shadowRoot.getElementById("sync-failure-dialog");
        this._loadingMessage = this.shadowRoot.getElementById("loading-message");
        this._additionalTotalBondChargesDialog = this.shadowRoot.getElementById("additional-charges-dialog");
        this._syncFailureDialog.isBookoutPage = true;
        this._returnButtonOnClick = this._returnButtonOnClick.bind(this);
        this._continueButtonOnClick = this._continueButtonOnClick.bind(this);
        this._completeButtonOnClick = this._completeButtonOnClick.bind(this);
        this._retryCompleteBookout = this._retryCompleteBookout.bind(this);
        this._continueCompleteBookout = this._continueCompleteBookout.bind(this);
        this._continueOnSyncFailures = this._continueOnSyncFailures.bind(this);
        this._additionalTotalBondOffenses = this._additionalTotalBondOffenses.bind(this);
        this._dialogCancelButtonOnClick = this._dialogCancelButtonOnClick.bind(this);
        this._dialogContinueButtonOnClick = this._dialogContinueButtonOnClick.bind(this);
        this._pdfData = {};
        this._bondApplicationId = null;
        this._continueOnSyncFailure = null;
        this._hasSkipPermission = null;
    }

    get _showCourtName() {
        return isAttributeTrue(this, "court-name-shows-for-bond-application-feature-flag");
    }

    set continueOnSyncFailure(value) {
        this._continueOnSyncFailure = value;
    }

    set hasSkipPermission(value) {
        this._hasSkipPermission = value;
    }

    set model(value) {
        this._model = value;
        const combinedModel = value.BondDetails.map(bondDetails => ({
            BondApplicationDetailID: bondDetails.BondApplicationDetailID,
            BondDetails: bondDetails
        }));

        combinedModel.forEach(x => x.BondDetails.BondType = value.BondApp.BondTypeDisplayName);

        this._loadTableData(combinedModel);

        this._bondApplicationId = value.BondDetails[0].BondApplicationID;
        this._additionalTotalBondChargesDialog.showCourtName = this._showCourtName;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "GET",
            `/BookOutQueue/GetApplicationDocumentStatus/${this._bondApplicationId}`,
            null,
            this._updateModelCallback.bind(this)
        );
    }

    set shortcutBookoutQueueProcess(value) {
        this._shortcutBookoutQueueProcess = value;
    }

    connectedCallback() {
        this._returnButton.addEventListener("click", this._returnButtonOnClick);
        this._continueButton.addEventListener("click", this._continueButtonOnClick);
        this._completeButton.addEventListener("click", this._completeButtonOnClick);
        this._syncFailureDialog.addEventListener("retry", this._retryCompleteBookout);
        this._syncFailureDialog.addEventListener("continue", this._continueOnSyncFailures);
        this._additionalTotalBondChargesDialog.addEventListener("continue", this._dialogContinueButtonOnClick);
        this._additionalTotalBondChargesDialog.addEventListener("cancel", this._dialogCancelButtonOnClick);
    }

    disconnectedCallback() {
        this._returnButton.removeEventListener("click", this._returnButtonOnClick);
        this._continueButton.removeEventListener("click", this._continueButtonOnClick);
        this._completeButton.removeEventListener("click", this._completeButtonOnClick);
        this._syncFailureDialog.removeEventListener("retry", this._retryCompleteBookout);
        this._syncFailureDialog.removeEventListener("continue", this._continueOnSyncFailures);
        this._additionalTotalBondChargesDialog.removeEventListener("continue", this._dialogContinueButtonOnClick);
        this._additionalTotalBondChargesDialog.removeEventListener("cancel", this._dialogCancelButtonOnClick);
    }

    showErrorMessage(errorMessage) {
        this._alertMessage.makeMessageAnError();
        if (!errorMessage.endsWith("."))
            errorMessage += ".";
        this._alertMessage.innerHTML = `${errorMessage} Please try again. If the problem persists <contact-link>contact eBONDS™ Support</contact-link>`;
    }

    enableStep(data) {
        if (data !== this._pdfData) {
            this._updateCompletedCharges(data);
            this._pdfData = {};
        }
        this.toggleAttribute("hidden", false);

        const dataArray = Array.from(data);
        if (this._shortcutBookoutQueueProcess && dataArray.length !== 0 && dataArray.every(x => x.BondApplicationDetailPdf)) {
            this._completeButton.click();
        }
    }

    disableStep() {
        this.toggleAttribute("hidden", true);
        this._pageTools.toggleTriggers(this.shadowRoot, false);
    }

    _loadTableData(model) {
        const table = $(this._table);
        const columns = [];
        columns.push({ radio: true, formatter: this._radioFieldFormatter });
        columns.push(createColumn("Bond Number", "BondDetails.BondApplicationDetailID"));
        columns.push(createColumn("Degree", "BondDetails.OffenseDegree"));
        columns.push(createColumn("Offense", "BondDetails.OffenseDesc"));
        columns.push(createColumn("Bond Type", "BondDetails.BondType"));
        columns.push(createColumn("Bond Amount", "BondDetails.BondAmt", undefined, dollarFieldFormatter));

        if (model.some(item => parseFloat(item.BondDetails.CountyBondFee) >= 0 || item.BondDetails.CountyBondFee)) {
            columns.push(createColumn("Bond Fee", "BondDetails.CountyBondFee", undefined, dollarFieldFormatter));
        }

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered table-hover",
            uniqueId: "BondApplicationDetailID",
            clickToSelect: true
        });
    }

    _updateModelCallback(data, success) {
        if (!success) {
            this._alertMessage.makeMessageAnError();
            this._alertMessage.textContent = "Unable to load bond application data. Please refresh the page to try again.";
            this._pageTools.toggleTriggers(this.shadowRoot, true);
            return;
        }

        this._updateCompletedCharges(JSON.parse(data));
        this._continueButton.toggleAttribute("disabled", false);
        this._table.toggleAttribute("hidden", false);
    }

    _updateCompletedCharges(data) {
        const $table = $(this._table);
        const idsToUncheck = data.filter(x => x.BondApplicationDetailPdf).map(x => x.BondApplicationDetailId);
        $table.bootstrapTable("uncheckBy", { field: "BondApplicationDetailID", values: idsToUncheck });
        data.forEach(bookOutApplicationModel => {
            const id = bookOutApplicationModel.BondApplicationDetailId;
            const hasPdf = bookOutApplicationModel.BondApplicationDetailPdf;
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            if (row.hasPdf !== hasPdf) {
                row.hasPdf = hasPdf;
                $table.bootstrapTable("updateByUniqueId",
                    {
                        id: id,
                        row: row
                    });
            }
        });
        const idToCheck = data.filter(x => !x.BondApplicationDetailPdf).map(x => x.BondApplicationDetailId).slice(0, 1);
        $table.bootstrapTable("checkBy", { field: "BondApplicationDetailID", values: idToCheck });
        // Must remove the radios after updating the rows because the table regenerate after every row update
        data.forEach(bookOutApplicationModel => {
            const id = bookOutApplicationModel.BondApplicationDetailId;
            const hasPdf = bookOutApplicationModel.BondApplicationDetailPdf;
            if (hasPdf) {
                const rowElement = this._table.querySelector(`tr[data-uniqueid="${id}"]`);
                const radio = rowElement.querySelector(".bs-checkbox > label");
                radio.remove();
            }
        });

        const tableData = $table.bootstrapTable("getData");
        if (tableData.every(x => x.hasPdf)) {
            this._continueButton.toggleAttribute("hidden", true);
            this._completeButton.toggleAttribute("hidden", false);
        }
    }

    _radioFieldFormatter(value, row, index, field) {
        if (row.hasPdf) {
            return '<span class="fa-stack"><span class="fas fa-circle fa-stack-1x text-white"></span><span class="fa-check-circle fas text-success fa-stack-1x"></span></span>';
        }
        return false;
    }

    _returnButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl("/BookOutQueue");
    }

    _continueButtonOnClick() {
        const $table = $(this._table);
        const selectedCharges = $table.bootstrapTable("getSelections").filter(x => !x.hasPdf);
        const newDetailId = selectedCharges[0].BondApplicationDetailID;
        if (this._pdfData.BondApplicationDetailId !== newDetailId)
            this._pdfData = { BondApplicationDetailId: newDetailId };
        this._pageTools.toggleTriggers(this.shadowRoot, true);

        if (this._shortcutBookoutQueueProcess && $table.bootstrapTable("getData").filter(x => !x.hasPdf).length === 1) {
            this._pdfData.isLastCharge = true;
        };

        const event = new CustomEvent("continue", { detail: this._pdfData });
        this.dispatchEvent(event);
    }

    _completeButtonOnClick(event) {
        if (this._shortcutBookoutQueueProcess) {
            this._alertMessage.makeMessageASuccess();
            this._alertMessage.textContent = "Please wait while we process the bond application and check for any remaining unbonded charges.";
        }
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        
        const currentTarget = event.currentTarget;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST",
            "/Roster/RefreshInmate/BondApplicationId",
            { bondApplicationId: this._bondApplicationId },
            this._completeBookoutSyncCallback.bind(this, currentTarget));
    }

    _retryCompleteBookout(event) {
        event.detail.buttonElement.click();
    }

    _continueCompleteBookout() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/BookoutQueue/BookoutApplication",
            { bondApplicationId: this._bondApplicationId },
            this._continueCompleteBookoutCallback.bind(this)
        );
    }

    _continueCompleteBookoutCallback(response, isSuccess) {
        if (!isSuccess) {
            this._alertMessage.innerHTML = response;
            this._alertMessage.makeMessageAnError();
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            return;
        }

        this._pageTools.redirectToUrl(`/BookOutQueue/CompletedBookout/${this._bondApplicationId}`);
    }

    _additionalTotalBondOffenses() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "GET",
            `/Application/GetAdditionalTotalBondOffenses/${this._bondApplicationId}`,
            null,
            this._additionalTotalBondOffensesCallback.bind(this)
        );
    }

    _additionalTotalBondOffensesCallback(response, isSuccess) {
        if (!isSuccess) {
            this._alertMessage.innerHTML = "Please try again. If the problem persists <contact-link>contact eBONDS™ Support</contact-link>";
            return;
        }

        const offenses = this._pageTools.tryParseJson(response);
        if (offenses && offenses.length > 0) {
            const data = {
                InmateFullNameRev: this._model.Inmate.InmateFullNameRev,
                Offenses: offenses
            }
            this._additionalTotalBondChargesDialog.openDialog(data);
            return;
        }
        this._continueCompleteBookout();
    }

    _openSyncFailureDialog(buttonElement, response) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const responseObj = this._pageTools.tryParseJson(response);
        openSyncFailureDialog(responseObj,
            buttonElement,
            this._syncFailureDialog,
            this._hasSkipPermission,
            this._continueOnSyncFailure,
            "There was a problem completing bookout.");
    }

    _completeBookoutSyncCallback(buttonElement, response, isSuccess) {
        if (!isSuccess) {
            this._alertMessage.textContent = "";
            this._openSyncFailureDialog(buttonElement, response);
            return;
        }
        this._displayAdditionalTotalBondChargesOrContinue();
    }

    _displayAdditionalTotalBondChargesOrContinue() {
        if (this._model.BondDetails.some(x => x.IsTotalBond)) {
            this._additionalTotalBondOffenses();
            return;
        }
        this._continueCompleteBookout();
    }

    _continueOnSyncFailures() {
        this._syncFailureDialog.closeDialog();
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._displayAdditionalTotalBondChargesOrContinue();
    }

    _dialogCancelButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._alertMessage.textContent = "";
    }

    _dialogContinueButtonOnClick() {
        this._continueCompleteBookout();
    }
}

customElements.define("book-out-queue-select-charge-step", BookOutQueueSelectChargeStep);