import { createGrid, LicenseManager } from "ag-grid-enterprise";
import type { GridApi, GridOptions, Params } from "ag-grid-enterprise"

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-058386}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Gencore_Candeo,_Ltd.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_May_2025}____[v3]_[01]_MTc0ODY0NjAwMDAwMA==a7a2e3b1e76c7a18dc6e2dcbcecb5ca4");

import template from './BasicGrid.html';
import agStyles from 'ag-grid-enterprise/styles/ag-grid.css';
import agBalhamTheme from 'ag-grid-enterprise/styles/ag-theme-balham.css';
import customStyles from './BasicGrid.scss';
import { installStyles } from "../HTMLElementExtensions";

// Add themes outside of shadow dom, this allows the popupparent to be set to the body to fix menu issues
const styleId = "basic-grid-styles";
if (!document.getElementById(styleId)) {
    const style = document.createElement("style");
    Array.from(agStyles.cssRules).forEach(cssRule => {
        style.appendChild(document.createTextNode(cssRule.cssText));
    });
    Array.from(agBalhamTheme.cssRules).forEach(cssRule => {
        style.appendChild(document.createTextNode(cssRule.cssText));
    });
    document.getElementsByTagName('head')[0].appendChild(style);
}

export class BasicGrid extends HTMLElement {
    public createGrid<T>(gridOptions: GridOptions<T>, params?: Params): GridApi<T> {
        this.innerHTML = template;
        installStyles(<DocumentOrShadowRoot><unknown>this.getRootNode(), [agStyles, agBalhamTheme, customStyles], []);
        return createGrid(<HTMLElement>this.querySelector("#grid-container"), gridOptions, params);
    }
}

customElements.define('basic-grid', BasicGrid);