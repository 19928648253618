import bootstrap from './../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './../HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert, ensureListHasSameValuesAndDisplay } from './../EditDialogExtensions';
import template from './EditReviewAuthorizationDialog.html';
import './../ActionButton';

class EditReviewAuthorizationDialog extends HTMLElement
{
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._closeButton = this.shadowRoot.getElementById('close-button');
        this._saveButton = this.shadowRoot.getElementById('save-button');
        this._authorizedByInput = this.shadowRoot.getElementById('authorized-by-input');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._form = this.shadowRoot.querySelector('form');

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(selectedCharges, controllerPath) {
        this._controllerPath = controllerPath;
        this._selectedCharges = selectedCharges;
        this._setInitialValues();
        this._modalComponent.openModal('Authorization', false);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _setInitialValues() {
        const differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, [
            { property: 'ReviewAuthorizedBy', element: this._authorizedByInput }
        ]);

        if (differences.length > 0) {
            showAlert(this._alert, 'There are conflicting changes with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _gatherData() {
        return {
            selected: this._selectedCharges.map(x => x.BondApplicationDetailID),
            reviewAuthorizedBy: this._authorizedByInput.value
        }
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: `/${this._controllerPath}/UpdateReviewAuthorizedBy`,
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }
}
customElements.define('edit-review-authorization-dialog', EditReviewAuthorizationDialog)