import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './MoneyInput.html';

// How to make a custom element form associated
// https://web.dev/more-capable-form-controls/
// https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-face-example

class MoneyInput extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], [], true);

        this._inputElement = this.shadowRoot.querySelector('#money-input');
        this._pageTools = new PageTools();
        this._internals = this.attachInternals();
        this._inputElementOnChange = this._inputElementOnChange.bind(this);
    }

    static get observedAttributes() {
        return ['required', 'value', 'min', 'max', 'disabled', 'placeholder', 'not-allowed-cursor'];
    }

    static get formAssociated() {
        return true;
    }

    reportValidity() {
        return this._inputElement.reportValidity();
    }

    connectedCallback() {
        this._inputElement.addEventListener('change', this._inputElementOnChange);
    }

    disconnectedCallback() {
        this._inputElement.removeEventListener('change', this._inputElementOnChange);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;

        switch (name) {
            case 'required':
                this._inputElement.toggleAttribute('required', newValue !== null);
                this._setValidity();
                break;
            case 'value':
                this.value = newValue;
                break;
            case 'min':
                this.min = newValue;
                break;
            case 'max':
                this.max = newValue;
                break;
            case 'disabled':
                this._inputElement.disabled = newValue != null;
                break;
            case 'placeholder':
                this._inputElement.placeholder = newValue;
                break;
            case 'not-allowed-cursor':
                this._inputElement.style.cursor = "not-allowed";
                break;
        }
    }

    set value(value) {
        if (value !== null && value.toString() !== '')
            this._inputElement.value = this._pageTools.formatNumberToDollarAmount(this._parseValue(value));
        else
            this._inputElement.value = null;
        this._setValidity();
    }

    set min(value) {
        this._min = value;
        this._setValidity();
}

    set max(value) {
        this._max = value;
        this._setValidity();
    }

    get value() {
        return this._parseValue(this._inputElement.value);
    }

    get min() {
        return this._min;
    }

    get max() {
        return this._max;
    }

    get options() {
        return this._inputElement.options;
    }

    get _isRequired() {
        return this._inputElement.hasAttribute('required');
    }

    _inputElementOnChange() {
        if (this.min != null && this.value < this.min) {
            this.value = this.min;
        }

        if (this.max != null && this.value > this.max) {
            this.value = this.max;
        }

        // enforce formatting
        this.value = this._inputElement.value;

        this._setValidity();
        this.dispatchEvent(new Event('change'));
    }

    _setValidity() {
        this._internals.setValidity(this._inputElement.validity, this._inputElement.validationMessage, this._inputElement);
    }

    _parseValue(value) {
        const result = parseFloat(`${value}`.replace(/[^\d\.-]/g, ""));
        if (Number.isNaN(result))
            return null;
        return result;
    }
}

customElements.define('money-input', MoneyInput);