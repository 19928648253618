import bootstrap from "../scss/bootstrap-custom.scss";
import "./ActionButton";
import "./ConfirmationModal";
import { clearAlert, ensureListHasSameValuesAndDisplay, handleDropdownDifferencesCallback, saveDialog, showAlert } from "./EditDialogExtensions";
import template from "./EditPropertyInfoDialog.html";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import "./StateDropdown";

class EditPropertyInfoDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._contactMessage = " If the problem persists, <contact-link>contact eBONDS&#8482; Support</contact-link>.";
        this._closeButton = this.shadowRoot.getElementById("property-info-dialog-close-button");
        this._saveButton = this.shadowRoot.getElementById("property-info-dialog-save-button");
        this._modalComponent = this.shadowRoot.querySelector("modal-component");
        this._conflictingParcelDialog = this.shadowRoot.getElementById("conflicting-parcel-dialog");
        this._alert = this.shadowRoot.getElementById("modal-alert-view");
        this._form = this.shadowRoot.querySelector("form");

        this._propertyInformationContainer = this.shadowRoot.getElementById("property-information-container");
        this._ownerNamesContainer = this.shadowRoot.getElementById("owner-names-container");
        this._parcelNumberContainer = this.shadowRoot.getElementById("parcel-number-container");
        this._propertyAddressContainer = this.shadowRoot.getElementById("property-address-container");
        this._propertyCityContainer = this.shadowRoot.getElementById("property-city-container");
        this._propertyZipContainer = this.shadowRoot.getElementById("property-zip-container");
        this._availableCollateralContainer = this.shadowRoot.getElementById("available-collateral-container");

        this._ownerNamesInput = this.shadowRoot.getElementById("owner-names");
        this._parcelNumberInput = this.shadowRoot.getElementById("parcel-number");
        this._propertyAddressInput = this.shadowRoot.getElementById("property-address");
        this._propertyCityInput = this.shadowRoot.getElementById("property-city");
        this._propertyStateDropDown = this.shadowRoot.getElementById("property-state-dropdown");
        this._propertyZipInput = this.shadowRoot.getElementById("property-zip");
        this._availableCollateralInput = this.shadowRoot.getElementById("available-collateral");

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._validateChargesWithSameParcel = this._validateChargesWithSameParcel.bind(this);
        this._confirmationSetsSpinner = this._confirmationSetsSpinner.bind(this);
    }

    set optionalParcelNumber(value) {
        this._optionalParcelNumber = value;
    }

    connectedCallback() {
        this._closeButton.addEventListener("click", this._closeDialog);
        this._saveButton.addEventListener("click", this._validateChargesWithSameParcel);
        this._conflictingParcelDialog.addEventListener("ok-confirmation", this._confirmationSetsSpinner);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener("click", this._closeDialog);
        this._saveButton.removeEventListener("click", this._validateChargesWithSameParcel);
        this._conflictingParcelDialog.addEventListener("ok-confirmation", this._confirmationSetsSpinner);
    }

    openModal(selectedCharges, unselectedCharges) {
        this._selectedCharges = selectedCharges;
        this._unselectedCharges = unselectedCharges;
        this._setInitialValues();
        if (this._optionalParcelNumber) {
            this._parcelNumberContainer.classList.remove("required");
            this._parcelNumberInput.toggleAttribute("required", false);
        }
        this._modalComponent.openModal("Property Information", false);
    }

    _setInitialValues() {
        const dif = ensureListHasSameValuesAndDisplay(this._selectedCharges, [
            { property: "PropertyOwnerNames", element: this._ownerNamesInput },
            { property: "ParcelNumber", element: this._parcelNumberInput },
            { property: "Address", element: this._propertyAddressInput },
            { property: "City", element: this._propertyCityInput },
            { property: "State", element: this._propertyStateDropDown, callback: handleDropdownDifferencesCallback },
            { property: "Zip", element: this._propertyZipInput },
            { property: "AvailableCollateral", element: this._availableCollateralInput }
        ]);

        if (dif.length > 0)
            showAlert(this._alert,
                "There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.");
        else
            clearAlert(this._alert);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        const data = this._gatherData();
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: "/Application/UpdateProperty",
            data: data,
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: this._saveCallBack.bind(this, data)
        });
    }

    _saveCallBack(data, response, status) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!status) {
            const errorMessage = response.startsWith("<!DOCTYPE html>")
                ? "Failed to save. Review the information you have entered and try again." + this._contactMessage
                : response;
            this._showAlert(this._alert, errorMessage);
            return;
        }
        const event = new CustomEvent('save', { detail: data });
        this.dispatchEvent(event);
        this._modalComponent.closeModal();
    }

    _confirmationSetsSpinner() {
        this._saveButton.toggleAttribute("disabled", true);
        this._saveButton.setSpinner(true);
        this._saveDialog();
    }

    _validateChargesWithSameParcel() {
        // Find all the matching parcels and then see if the new information differs. If it does we need to display a confirmation dialog.
        const parcelNumber = this._parcelNumberInput.value;
        const existingItems = this._unselectedCharges.filter(x => x.ParcelNumber === parcelNumber);
        const newItem = { // Database stores the values as null, but input.value is empty string, so we need to convert to detect changes correctly
            PropertyOwnerNames: this._nullIfEmpty(this._ownerNamesInput.value),
            ParcelNumber: this._nullIfEmpty(this._parcelNumberInput.value),
            Address: this._nullIfEmpty(this._propertyAddressInput.value),
            City: this._nullIfEmpty(this._propertyCityInput.value),
            State: this._nullIfEmpty(this._propertyStateDropDown.value),
            Zip: this._nullIfEmpty(this._propertyZipInput.value),
            AvailableCollateral: this._nullIfEmpty(this._availableCollateralInput.value)
        }
        const allItems = [...existingItems, newItem];
        const differencesList = ensureListHasSameValuesAndDisplay(allItems, [
            { property: "PropertyOwnerNames" },
            { property: "ParcelNumber" },
            { property: "Address" },
            { property: "City" },
            { property: "State" },
            { property: "Zip" },
            { property: "AvailableCollateral" }
        ]);

        if (differencesList.length > 0) {
            const message = `Conflicting information was entered for Parcel Number ${parcelNumber}. Press 'Confirm and Update' to use the parcel information shown below for parcel number ${parcelNumber} on this offense and other offenses in this application associated with parcel number ${parcelNumber}. Otherwise, press 'Cancel'.`;
            const formattedValues = this._formatConfirmationModal();
            this._conflictingParcelDialog.open({
                message: message,
                response: { "action": "cancel" },
                modalBodyText: formattedValues,
                actionButtonText: "Confirm and Update"
            });
        }
        else {
            this._saveDialog();
        }
    }

    _formatConfirmationModal() {
        const isNull = "-";

        let formattedValues = [
            `Owner Names: ${this._ownerNamesInput.value ? this._ownerNamesInput.value : isNull}`,
            `Parcel Number: ${this._parcelNumberInput.value ? this._parcelNumberInput.value : isNull}`,
            `Address: ${this._propertyAddressInput.value ? this._propertyAddressInput.value : isNull}`,
            `City: ${this._propertyCityInput.value ? this._propertyCityInput.value : isNull}`,
            `State: ${this._propertyStateDropDown.value ? this._propertyStateDropDown.value : isNull}`,
            `Zip: ${this._propertyZipInput.value ? this._propertyZipInput.value : isNull}`,
            `Available Collateral: ${this._pageTools.formatNumberToDollarAmount(this._availableCollateralInput.value)}`
        ]
        formattedValues = Array.prototype.join.call(formattedValues, "\n");
        return formattedValues;
    }

    _showAlert(alertElement, message) {
        alertElement.innerHTML = message;
        alertElement.classList.remove('d-none');
        alertElement.scrollIntoView();
    }

    _gatherData() {
        const unselectedSharedParcels = this._unselectedCharges.filter(x => x.ParcelNumber === this._parcelNumberInput.value);
        const sharedParcelCharges = this._selectedCharges.concat(unselectedSharedParcels);

        // Database stores the values as null, but input.value is empty string, so we need to convert to detect changes correctly for the confirmation modal
        const model = { //BondApplicationPropertyInformationViewModel
            ownerNames: this._nullIfEmpty(this._ownerNamesInput.value),
            parcelNumber: this._nullIfEmpty(this._parcelNumberInput.value),
            propertyAddress: this._nullIfEmpty(this._propertyAddressInput.value),
            propertyCity: this._nullIfEmpty(this._propertyCityInput.value),
            propertyState: this._nullIfEmpty(this._propertyStateDropDown.value),
            propertyZip: this._nullIfEmpty(this._propertyZipInput.value),
            availableCollateral: this._nullIfEmpty(this._availableCollateralInput.value)
        };

        return {
            detailIds: sharedParcelCharges.map(elt => elt.BondApplicationDetailID),
            data: model
        }
    }

    _nullIfEmpty(value) {
        if (value === "")
            return null;
        return value;
    }
}

customElements.define("edit-property-info-dialog", EditPropertyInfoDialog);