import bootstrap from "./../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import "bootstrap-table/dist/bootstrap-table";
import { initializeHtmlElement } from "./../HTMLElementExtensions";
import { createColumn } from "../BootstrapTableExtensions";
import template from "./BondDocumentTemplateAssociations.html";
import "./../ActionButton";

class BondDocumentTemplateAssociations extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ["container"]);
        this._associationsTable = this.shadowRoot.getElementById("associations-table");
        this._cancelAssociationsButton = this.shadowRoot.getElementById("cancel-associations-button");
        this._saveAssociationsButton = this.shadowRoot.getElementById("save-associations-button");
        this._templateAssociations = document.querySelector("bond-document-template-associations");
        this._alert = this.shadowRoot.getElementById("alert");

        this._cancelAssociationsButtonOnClick = this._cancelAssociationsButtonOnClick.bind(this);
        this._saveAssociationsButtonOnClick = this._saveAssociationsButtonOnClick.bind(this);
    }

    _isOutOfCounty() {
        return this.hasAttribute("out-of-county");
    }

    _isTotalBond() {
        return this.hasAttribute("total-bonds");
    }

    get bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag() {
        const name = "bond-document-templates-are-assignable-per-offense";
        return this.hasAttribute(name) && this.getAttribute(name).toLowerCase() === "true";
    }

    set model(value) {
        const isReload = this._originalModel === value;
        this._originalModel = value;
        const $table = $(this._associationsTable);
        const columns = [
            createColumn("Bond Type", "bondTypeDisplayName", true)
        ];

        if (this.bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag) {
            columns.push(createColumn("Offense", "codeOffenseDisplayName", true));
            // "Null" row is for the all checkbox
            if (!isReload)
                value.codeOffenses.push({ codeOffenseId: null, code: "All" });
            value.codeOffenses.sort((a, b) => {
                if (a.code == null) {
                    return -1;
                }
                if (b.code == null) {
                    return 1;
                }

                return a.code.localeCompare(b.code);
            });
        }
        value.templates.sort((a, b) => a.name.localeCompare(b.name));
        value.templates.forEach(x => {
            columns.push(createColumn(x.name, x.name, false, this._checkboxFormatter.bind(this)));
        });

        // Need to build the table up from the data sent from server to display it correctly
        const data = [];
        value.bondTypes.sort((a, b) => a.displayName.localeCompare(b.displayName));
        value.bondTypes.forEach(bondType => {
            if (this.bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag) {
                value.codeOffenses.forEach(offense => {
                    const row = this._createDataRow(bondType, offense, value.templates, value.associations);
                    data.push(row);
                });
            } else {
                const row = this._createDataRow(bondType, {codeOffenseId: null}, value.templates, value.associations);
                data.push(row);
            }
        });
        $table.bootstrapTable("destroy");
        $table.bootstrapTable({
            columns: columns,
            classes: "table-sm table-striped table-bordered table-hover",
            uniqueId: "bondDocumentTemplateId",
            data: data
        });
        $table.bootstrapTable("hideLoading");

        this._associationsTable.querySelectorAll("input[type=checkbox]")
            .forEach(x => x.addEventListener("change", this._checkboxOnChange.bind(this)));
        this._toggleButtonState(true);
    }

    connectedCallback() {
        this._initialize();
        this._cancelAssociationsButton.addEventListener("click", this._cancelAssociationsButtonOnClick);
        this._saveAssociationsButton.addEventListener("click", this._saveAssociationsButtonOnClick);
    }

    disconnectedCallback() {
        this._cancelAssociationsButton.removeEventListener("click", this._cancelAssociationsButtonOnClick);
        this._saveAssociationsButton.removeEventListener("click", this._saveAssociationsButtonOnClick);
    }

    onTemplatesChanged() {
        this._initialize();
    }

    _checkboxData() {
        const checkboxes = this._associationsTable.querySelectorAll(`input[type="checkbox"]:checked`);
        return Array.from(checkboxes).map(x => ({
            bondDocumentTemplateId: this._originalModel.templates.find(t => t.name === x.getAttribute("data-field")).bondDocumentTemplateId,
            bondTypeId: parseInt(x.getAttribute("data-bond-type-id")),
            codeOffenseId: this.bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag ?
                this._originalModel.codeOffenses.find(offense => offense.code === x.getAttribute("data-code-offense")).codeOffenseId : null
        }));
    }

    _doesEveryBondTypeHaveAssociations() {
        const data = this._checkboxData();
        let hasBondTypeAssociation = true;
        const allCodeOffenseIds = this._originalModel.codeOffenses
            .filter(x => x.codeOffenseId !== null)
            .map(x => x.codeOffenseId.toString());
        for (const bondType of this._originalModel.bondTypes) {
            const dataForBondType = data.filter(x => x.bondTypeId === bondType.bondTypeId);
            if (dataForBondType.length === 0) {
                this._colorUncheckedRow(bondType.bondTypeId, true);
                hasBondTypeAssociation = false;
                continue;
            }
            if (dataForBondType.length === 1) {
                if (dataForBondType[0].codeOffenseId === null) {
                    this._colorUncheckedRow(bondType.bondTypeId, false);
                    continue;
                }
                this._colorUncheckedRow(bondType.bondTypeId, true);
                hasBondTypeAssociation = false;
                continue;
            }
            if (this.bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag) {
                const codeOffensesForBondType = dataForBondType.map(x => x.codeOffenseId.toString());
                const filteredArray = allCodeOffenseIds.filter(value => codeOffensesForBondType.includes(value));
                if (filteredArray.length !== allCodeOffenseIds.length) {
                    this._colorUncheckedRow(bondType.bondTypeId, true);
                    hasBondTypeAssociation = false;
                    continue;
                }
            }
            this._colorUncheckedRow(bondType.bondTypeId, false);
        }
        return hasBondTypeAssociation;
    }

    _colorUncheckedRow(bondTypeId, isInvalid) {
        this.shadowRoot.querySelectorAll(`[data-bond-type-id='${bondTypeId}']`).forEach(x => x.parentNode.parentNode.classList.toggle("charge-status-hold-without-bond", isInvalid));
    }

    _cancelAssociationsButtonOnClick() {
        const x = window.scrollX;
        const y = window.scrollY;
        let tbody = this._associationsTable.querySelector("tbody");
        const tbodyX = tbody.scrollLeft;
        const tbodyY = tbody.scrollTop;
        this.model = this._originalModel;
        this._cancelAssociationsButton.toggleAttribute("disabled", true);
        this._saveAssociationsButton.toggleAttribute("disabled", true);

        // Reset window position after the table got destroyed and remade.
        window.scrollTo(x, y);
        tbody = this._associationsTable.querySelector("tbody");
        tbody.scrollTo(tbodyX, tbodyY);
        this._checkAssociationsCheckbox();
    }

    _saveAssociationsButtonOnClick() {
        const data = this._checkboxData();
        const xhrWrapper = new XhrWrapper();
        this._toggleButtonState(true);
        this._checkAssociationsCheckbox();
        xhrWrapper.makeJsonRequest(
            "POST",
            `/SysAdmin/BondDocumentTemplateAssociations?outOfCounty=${this._isOutOfCounty()}&totalBond=${this._isTotalBond()}`,
            JSON.stringify(data),
            this._saveAssociationsButtonOnClickCallback.bind(this, data));
    }

    _saveAssociationsButtonOnClickCallback(data, response, isSuccess) {
        this._toggleButtonState(false);
        this._alert.classList = "";
        if (isSuccess) {
            this._toggleButtonState(true);
            this._originalModel.associations = data;
            this._alert.classList.add("text-success");
            this._alert.textContent = "Successfully saved this association.";
        } else {
            this._alert.classList.add("text-danger");
            this._alert.textContent = "There was an error saving this association.";
        }
        this._alert.style.visibility = "visible";
    }

    _toggleButtonState(state) {
        this._cancelAssociationsButton.toggleAttribute("disabled", state);
        this._saveAssociationsButton.toggleAttribute("disabled", state);
    }

    _associationsError() {
        this._alert.classList.add("text-danger");
        this._alert.textContent = "There are not enough associations checked, please choose a template for each bond type.";
        this._alert.style.visibility = "visible";
    }

    _clearAssociationsError() {
        this._alert.style.visibility = "hidden";
    }

    _checkAssociationsCheckbox() {
        if (this._doesEveryBondTypeHaveAssociations())
            this._clearAssociationsError();
        else
            this._associationsError();
    }

    _checkboxOnChange(e) {
        this._toggleButtonState(false);
        if (!e.target.checked) {
            // No special logic
            this._checkAssociationsCheckbox();
            return;
        }
        this._clearAssociationsError();
        const bondTypeSelector = (element) => `[data-bond-type-id="${element.getAttribute("data-bond-type-id")}"]`;
        const fieldSelector = (element) => `[data-field="${element.getAttribute("data-field")}"]`;
        const offenseSelector = (element) => `[data-code-offense="${element.getAttribute("data-code-offense")}"]`;
        
        // Below logic is always scoped within the BondType
        if (e.target.getAttribute("data-is-all") === "true") {
            // Uncheck everything in our bond type section (row and columns) except us
            const allBondTypeCheckboxes = this._associationsTable.querySelectorAll(`input[type="checkbox"][data-bond-type-id="${e.target.getAttribute("data-bond-type-id")}"]`);
            allBondTypeCheckboxes.forEach(x => {
                if (x === e.target) {
                    return;
                }
                x.checked = false;
            });
        } else {
            const everyCheckboxInColumnExceptAll = Array.from(this._associationsTable.querySelectorAll(`input[type="checkbox"][data-is-all="false"]${bondTypeSelector(e.target)}${fieldSelector(e.target)}`));
            const allCheckbox = this._associationsTable.querySelector(`input[type="checkbox"][data-is-all="true"]${bondTypeSelector(e.target)}${fieldSelector(e.target)}`);

            // If every notAllColumn is checked, then uncheck all columns and check the All cell
            if (everyCheckboxInColumnExceptAll.every(x => x.checked)) {
                everyCheckboxInColumnExceptAll.forEach(x => x.checked = false);
                allCheckbox.checked = true;
            }

            // If all cell was already checked, this cell needs to be reset to false
            if (allCheckbox.checked === true) {
                e.target.checked = false;
            }

            // If some other column's all cell was checked, then fix that other column
            const otherAllCheckboxes = this._associationsTable.querySelectorAll(`input[type="checkbox"][data-is-all="true"]${bondTypeSelector(e.target)}:not(${fieldSelector(e.target)})`);
            otherAllCheckboxes.forEach(x => {
                if (x.checked) {
                    x.checked = false;

                    const otherColumnCheckboxes = this._associationsTable.querySelectorAll(`input[type="checkbox"][data-is-all="false"]${bondTypeSelector(x)}${fieldSelector(x)}:not(${offenseSelector(e.target)})`);
                    otherColumnCheckboxes.forEach(y => y.checked = true);
                }
            });

            // Handle unchecking everything in our row
            const otherCheckboxesInRow = this._associationsTable.querySelectorAll(`input[type="checkbox"]${bondTypeSelector(e.target)}${offenseSelector(e.target)}:not(${fieldSelector(e.target)})`);
            otherCheckboxesInRow.forEach(x => x.checked = false);
        }
        this._checkAssociationsCheckbox();
    }

    _createDataRow(bondType, offense, templates, associations) {
        const row = {
            bondTypeId: bondType.bondTypeId,
            bondTypeDisplayName: offense.codeOffenseId ? "" : bondType.displayName,
            codeOffenseId: offense.codeOffenseId,
            codeOffenseDisplayName: offense.code
        };

        templates.forEach(documentTemplate => {
            row[documentTemplate.name] = associations.some(x => x.bondTypeId === bondType.bondTypeId && x.codeOffenseId === offense.codeOffenseId && x.bondDocumentTemplateId === documentTemplate.bondDocumentTemplateId);
        });

        return row;
    }

    _initialize() {
        const $table = $(this._associationsTable);
        $table.bootstrapTable({});
        $table.bootstrapTable("showLoading");

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/SysAdmin/BondDocumentTemplateAssociations?outOfCounty=${this._isOutOfCounty()}&totalBond=${this._isTotalBond()}`,
            null,
            this._initializeCallback.bind(this));
    }

    _initializeCallback(response, isSuccess) {
        if (!isSuccess) {
            this._alert.style.visibility = "visible";
            this._alert.textContent = "There was a problem loading the associations table.";
            return;
        }

        this.model = JSON.parse(response);
        this._checkAssociationsCheckbox();
    }

    _checkboxFormatter(value, row, index, field) {
        return `<input type="checkbox"${value ? " checked" : ""} data-field="${field}" data-is-all="${row.codeOffenseDisplayName === "All" || !this.bondDocumentTemplatesAreAssignablePerOffenseFeatureFlag}" data-bond-type-id="${row.bondTypeId}" data-code-offense="${row.codeOffenseDisplayName}"/>`;
    }
}

customElements.define("bond-document-template-associations", BondDocumentTemplateAssociations);