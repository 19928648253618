import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class BondDocumentListGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="bond-document-list"></bootstrap-report-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
    }

    _compileColumns() {
        this._tableColumns = [
            {
                title: 'Date Submitted',
                field: 'TimeChanged',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'BondApplicationID',
                field: 'BondApplicationID',
                type: 'Number',
                escape: true,
                class: 'd-none',
                printIgnore: true
            },
            {
                title: 'Created By',
                field: 'BondCompanyName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Inmate',
                field: 'InmateFullName',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking',
                field: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Offense Degree',
                field: 'OffenseDegree',
                type: 'Text',
                escape: true
            },
            {
                title: 'Offense Code',
                field: 'OffenseCode',
                type: 'Text',
                escape: true
            },
            {
                title: 'Offense',
                field: 'OffenseDesc',
                type: 'Text',
                escape: false
            },
            {
                title: 'Bond Number',
                field: 'BondApplicationDetailID',
                type: 'Number',
                escape: true
            },
            {
                title: 'Bond Type',
                field: 'BondTypeDisplayName',
                type: 'Number',
                escape: true
            },
            {
                title: 'Bond Amt',
                field: 'BondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Bond Fee',
                field: 'CountyBondFee',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            }
        ];
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: 'BondApplicationID',
            tableColumns: this._tableColumns,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: 'Bond-Document-List',
            reportUrl: '/Reports/BondDoc_Read',
            restoreFilters: true,
            tableName: 'BondDocumentList',
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }
}

customElements.define('bond-document-list-grid', BondDocumentListGrid);