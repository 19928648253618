import bootstrap from './../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import { initializeHtmlElement } from './../HTMLElementExtensions';
import { createColumn, dollarFieldFormatter } from '../BootstrapTableExtensions';
import template from './CancelBondApplication.html';
import applicationStatus from '../Enumerations/ApplicationStatus';
import { showAlert } from './../EditDialogExtensions';
import './../InmateInformation';
import './../ActionButton';
import './CancelReasonModal';
import { isAttributeTrue } from './../JavaScriptFunctions';

class CancelBondApplication extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ['container']);
        this._pageTools = new PageTools();
        this._cancelledBondDocumentUploadFailureDialogModel = null;
        this._model = null;
        this._inmateInformation = this.shadowRoot.getElementById('inmate-information');
        this._bondCompany = this.shadowRoot.getElementById('bond-company');
        this._bondType = this.shadowRoot.getElementById('bond-type');
        this._currentStatus = this.shadowRoot.getElementById('current-status');
        this._statusDate = this.shadowRoot.getElementById('status-date');
        this._totalCountyBondFees = this.shadowRoot.getElementById('total-county-bond-fees');
        this._table = this.shadowRoot.getElementById('table');
        this._infoMessage = this.shadowRoot.getElementById('info-message');
        this._backButton = this.shadowRoot.getElementById('back-button');
        this._addButton = this.shadowRoot.getElementById('add-button');
        this._cancelReasonModal = this.shadowRoot.getElementById('cancel-reason-modal');
        this._allPaidTransactionContainer = this.shadowRoot.getElementById('all-paid-transactions');
        this._listOfallPaidTransactions = this.shadowRoot.getElementById('list-of-all-paid-transactions');
        this._associateTransactionErrorMessage = this.shadowRoot.getElementById('associated-transaction-error-message');
        this._printPreviewAllButton = this.shadowRoot.getElementById('print-preview-all-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');

        this._backButtonOnClick = this._backButtonOnClick.bind(this);
        this._addButtonOnClick = this._addButtonOnClick.bind(this);
        this._printPreviewAllClick = this._printPreviewAllClick.bind(this);
    }

    set cancelledBondDocumentUploadFailureDialogModel(value) {
        this._cancelledBondDocumentUploadFailureDialogModel = value;
    }

    set model(value) {
        this._model = value;
        this._isAllPaidApplication = value.IsAllPaidApplication;
        this._inmateInformation.loadInmateInformationByBondApplicationId(value.BondApplicationId);
        this._bondCompany.value = value.BondCompany;
        this._bondType.value = value.BondTypeDisplayName;
        this._currentStatus.value = value.Status;
        this._statusDate.value = window.DateFormatter.utcToLocal(value.StatusDate);
        this._totalCountyBondFees.value = `${this._pageTools.formatNumberToDollarAmount(value.TotalCountyBondFees)}`;
        this._loadTableData(value.Bonds);
        this._cancelReasonModal.bondApplicationId = value.BondApplicationId;
        if (value.Status !== applicationStatus.cancelled.string) {
            this._infoMessage.classList.add('text-danger');
            const voidOrRelease = value.Status === applicationStatus.posted.string ? 'void' : 'release';
            if (value.Bonds.some(x => x.Poa) && value.TotalCountyBondFees > 0) {
                this._infoMessage.textContent =
                    `Cancelling this application will ${voidOrRelease} all associated powers of attorney and void/credit any refundable fees.`;
            } else if (value.Bonds.some(x => x.Poa) && value.TotalCountyBondFees === 0) {
                this._infoMessage.textContent =
                    `Cancelling this application will ${voidOrRelease} all associated powers of attorney.`;
            } else if (value.Bonds.every(x => !x.Poa) && value.TotalCountyBondFees > 0) {
                this._infoMessage.textContent = 'Cancelling this application will void/credit any refundable fees.';
            }
        } else {
            this._infoMessage.classList.remove('text-danger');
            this._infoMessage.textContent = 'The bond application has been successfully cancelled.';
            if (this._isAllPaidApplication)
                this._getAssociatedAllPaidTransactions(this._model.BondApplicationId);
        }
    }

    connectedCallback() {
        this._backButton.addEventListener('click', this._backButtonOnClick);
        this._addButton.addEventListener('click', this._addButtonOnClick);
        this._printPreviewAllButton.addEventListener('click', this._printPreviewAllClick);
        this._printPreviewAllButton.toggleAttribute('hidden', !isAttributeTrue(this, "preview-all-feature-flag-temp"));
        this._inmateInformation.userIsCounty = isAttributeTrue(this, "user-is-county");
   }

    disconnectedCallback() {
        this._backButton.removeEventListener('click', this._backButtonOnClick);
        this._addButton.removeEventListener('click', this._addButtonOnClick);
    }

    _addButtonOnClick() {
        const data = {
            endPoint: '/Cancel/CompleteCancel',
            redirectUrl: `/Cancel/FinishCancel/${this._model.BondApplicationId}`,
            requestObject: { bondApplicationId: this._model.BondApplicationId },
            title: 'Cancel Bond Application Reason'
        }
        this._cancelReasonModal.openModal(data);
    }

    _backButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl('/Cancel');
    }

    _getAssociatedAllPaidTransactions(bondAppId) {
        if (isAttributeTrue(this, "allPaidFeatureFlag")) {
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest('GET', `/Cancel/ListOfAllPaidTransactionsWithApplication/${bondAppId}`,
                null, this._getAssociatedAllPaidTransactionsCallBack.bind(this));
        }
    }

    _getAssociatedAllPaidTransactionsCallBack(response, isSuccess) {
        const referenceNumbers = this._pageTools.tryParseJson(response);
        if (!isSuccess || !referenceNumbers) {
            this._associateTransactionErrorMessage.removeAttribute('hidden');
            return;
        }
        if (referenceNumbers.length > 0) {
            this._allPaidTransactionContainer.removeAttribute('hidden');
            referenceNumbers
                .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
                .forEach((refItem) => {
                    const linkItem = document.createElement('a');
                    linkItem.setAttribute('href', `/Admin/CashTransaction?transactionId=${refItem}`);
                    linkItem.setAttribute('target', '_blank');
                    linkItem.textContent = refItem;
                    const listItem = document.createElement('span');
                    listItem.textContent = 'Transaction ID ';
                    listItem.appendChild(linkItem);
                    this._listOfallPaidTransactions.appendChild(listItem);
            });
        }
    }

    _loadTableData(model) {
        this._printPreviewAllButton.toggleAttribute('hidden', this._model.Status !== applicationStatus.cancelled.string);
        const $table = $(this._table);
        const columns = [];
        columns.push(createColumn('Bond Number', 'BondNumber'));
        columns.push(createColumn('Offense Degree', 'OffenseDegree'));
        columns.push(createColumn('Offense Description', 'OffenseDescription'));
        if (model.some(x => x.Poa)) {
            columns.push(createColumn('POA Number', 'Poa'));
            columns.push(createColumn('POA Status', 'PoaStatus'));
        }
        columns.push(createColumn('Bond Amount', 'BondAmount', undefined, dollarFieldFormatter));
        if (this._currentStatus.value === applicationStatus.cancelled.string) {
            columns.push(createColumn('Cancelled Bond Document', 'BondDocumentId', undefined, this._printPreviewFormatter));
            this._addButton.toggleAttribute('hidden', true);
            this._backButton.toggleAttribute('hidden', true);
        }

        $table.bootstrapTable({
            columns: columns,
            data: model,
            classes: 'table table-sm table-striped table-bordered',
            uniqueId: 'BondNumber'
        });
        this._checkForDocuments(model);
    }

    _checkForDocuments(model) {
        const noneHaveBondDocument = model.every(obj => obj.BondDocumentId === null);
        if (noneHaveBondDocument) {
            this._printPreviewAllButton.disabled = true;
        }
    }

    _printPreviewFormatter(value, row, index) {
        if (value === null){
            return '<a class="btn btn-primary disabled">No File</a>';
        } else {
            return `<a class="btn btn-primary" target="_blank" rel="noopener noreferrer" href="/Reports/Download/${value}">Print Preview</a>`;
        }
    }

    _printPreviewAllClick() {
        const bondDocumentIds = this._model.Bonds.map(x => x.BondDocumentId);
        this._pageTools.toggleTriggers(this.shadowRoot, true);

        const data = {
            ids: bondDocumentIds,
            inmateName: this._model.Inmate.Name
        };
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "/Cancel/PrintAllBonds/", data, this._pageTools.openPdfXhrCallback.bind(this._pageTools, this.shadowRoot, showAlert.bind(this, this._alert)));
    }
}
customElements.define('cancel-bond-application', CancelBondApplication);
   