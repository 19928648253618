import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './AvailablePowersItem.html';

import './BannerItemCard';

class AvailablePowersItem extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._limit = this.shadowRoot.getElementById('limit');
        this._amount = this.shadowRoot.getElementById('amount');
        this._pageTools = new PageTools();
    }

    set limit(value) {
        this._limit.textContent = value === 0 ? "Various" : '$' + this._pageTools.formatNumberWithCommas(value) ;
    }

    set amount(value) {
        this._amount.textContent = this._pageTools.formatNumberWithCommas(value);
    }
}
customElements.define('available-powers-item', AvailablePowersItem);