import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert, ensureListHasSameValuesAndDisplay, handleDropdownDifferencesCallback } from './EditDialogExtensions';
import template from './EditCosignerInfoDialog.html';
import './StateDropdown';
import './ActionButton';

class EditCosignerInfoDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('cosigner-info-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('cosigner-info-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');

        this._cosignerFirstNameInput = this.shadowRoot.getElementById("cosigner-first-name");
        this._cosignerMiddleInitialInput = this.shadowRoot.getElementById("cosigner-middle-initial");
        this._cosignerLastNameInput = this.shadowRoot.getElementById("cosigner-last-name");
        this._cosignerAddressInput = this.shadowRoot.getElementById("cosigner-address");
        this._cosignerCityInput = this.shadowRoot.getElementById("cosigner-city");
        this._cosignerStateInput = this.shadowRoot.getElementById("cosigner-state");
        this._cosignerZipInput = this.shadowRoot.getElementById("cosigner-zip");
        this._cosignerPhoneInput = this.shadowRoot.getElementById("cosigner-phone");
        this._cosignerAmountInput = this.shadowRoot.getElementById("cosigner-amount");

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(selectedCharges) {
        this._selectedCharges = selectedCharges;
        this._setInitialValues();

        this._modalComponent.openModal('Cosigner Information', false);
    }

    _setInitialValues() {
        const differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, [
            { property: 'CosignerFn', element: this._cosignerFirstNameInput },
            { property: 'CosignerMi', element: this._cosignerMiddleInitialInput },
            { property: 'CosignerLn', element: this._cosignerLastNameInput },
            { property: 'CosignerAddress', element: this._cosignerAddressInput },
            { property: 'CosignerCity', element: this._cosignerCityInput },
            { property: 'CosignerState', element: this._cosignerStateInput, callback: handleDropdownDifferencesCallback },
            { property: 'CosignerZip', element: this._cosignerZipInput },
            { property: 'CosignerPhone', element: this._cosignerPhoneInput },
            { property: 'CosignerAmount', element: this._cosignerAmountInput },
        ]);

        if (differences.length > 0) {
            showAlert(this._alert, 'There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: '/Application/UpdateCosigner',
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }

    _gatherData() {
        const cosignerFn = this._cosignerFirstNameInput.value;
        const cosignerMi = this._cosignerMiddleInitialInput.value;
        const cosignerLn = this._cosignerLastNameInput.value;
        const cosignerAddress = this._cosignerAddressInput.value;
        const cosignerCity = this._cosignerCityInput.value;
        const cosignerState = this._cosignerStateInput.value;
        const cosignerZip = this._cosignerZipInput.value;
        const cosignerPhone = this._cosignerPhoneInput.value;
        const cosignerAmount = this._cosignerAmountInput.value;

        return {
            selected: this._selectedCharges.map(element => element.BondApplicationDetailID),
            cosignerModel: {
                FirstName: cosignerFn,
                MiddleInitial: cosignerMi,
                LastName: cosignerLn,
                Address: cosignerAddress,
                City: cosignerCity,
                State: cosignerState,
                Zip: cosignerZip,
                Phone: cosignerPhone,
                Amount: cosignerAmount,
            }
        };
    }    
}
customElements.define('edit-cosigner-info-dialog', EditCosignerInfoDialog);