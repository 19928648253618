import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import * as hourglass_png_url from "../images/hourglass.png";
import * as hourglass_svg_url from "../images/hourglass.svg";
import bootstrap from '../scss/bootstrap-custom.scss';
import template from './GenericSessionModal.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import './ModalComponent';

class GenericSessionModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap, fontawesome]);

        this._pageTools = new PageTools();
        this._modalComponent = this.shadowRoot.getElementById("modal-component");
        this._modalContentContainer = this.shadowRoot.getElementById("modal-container");
        this._buttonGroupDiv = this.shadowRoot.getElementById("btn-group");

        this._modalTitle = "";

        this._logoutBtnClickHandler = this._logoutBtnClickHandler.bind(this);
        this._terminateSessionCallback = this._terminateSessionCallback.bind(this);
        this._terminateSessionBtnClickHandler = this._terminateSessionBtnClickHandler.bind(this);
    }

    get _modalType() {
        return this.getAttribute('modal-type');
    }

    connectedCallback() {
        this._constructModal();
        this._modalComponent.openModal(this._modalTitle, true);
    }

    _logoutBtnClickHandler() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        document.getElementById('logoutForm').submit();
    }

    _terminateSessionBtnClickHandler() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "/Account/EndActiveSessions", null, this._terminateSessionCallback);
    }

    _terminateSessionCallback(response, isSuccess) {
        if (!isSuccess) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            console.error("Failed to terminate active sessions");
            return;
        }
        this._pageTools.redirectToUrl("/Roster");
    }

    _constructModal() {
        const modalType = this._modalType;
        const modalMsg = document.createElement("p");
        const loginBtn = document.createElement("a");

        loginBtn.id = "login-btn";
        loginBtn.classList = "btn btn-primary";
        loginBtn.href = "/Account/Login";
        loginBtn.text = "Log in";

        switch(modalType) {
            case "inactivity-timeout":
                {
                    this._modalTitle = "Session Timeout";
                    modalMsg.textContent = "You have been logged out due to inactivity.";

                    const pic = document.createElement("picture");
                    const src = document.createElement("source");
                    const img = document.createElement("img");

                    src.type = "image/svg+xml";
                    src.setAttribute("srcSet", hourglass_svg_url);
                    img.src = hourglass_png_url;
                    img.alt = "Inactivity Timeout Occurred";

                    pic.appendChild(src);
                    pic.appendChild(img);
                    this._modalContentContainer.appendChild(pic);
                    this._modalContentContainer.appendChild(modalMsg);
                    this._buttonGroupDiv.appendChild(loginBtn);
                    break;
                }
            case "session-terminated":
                {
                    this._modalTitle = "Session Terminated";
                    modalMsg.textContent = "You have been logged out of this device because your credentials were used to log in on a different device.";

                    const modalIcon = document.createElement("span");

                    modalIcon.classList = "fas fa-4x fa-exclamation-triangle text-warning";

                    this._modalContentContainer.appendChild(modalIcon);
                    this._modalContentContainer.appendChild(modalMsg);
                    this._buttonGroupDiv.appendChild(loginBtn);
                    break;
                }
            case "end-active-session":
                {
                    this._modalTitle = "Multiple Sessions Detected";
                    modalMsg.textContent = "You are currently logged in on another device, and eBONDS has a single device policy. Please log out on this device, or end your active session on the other device.";

                    const modalIcon = document.createElement("span");
                    const logoutBtn = document.createElement('action-button');
                    const terminateSessionBtn = document.createElement("action-button");

                    logoutBtn.id = "logout-btn";
                    logoutBtn.classList = "btn btn-danger col mr-4";
                    logoutBtn.innerText = "Log out";
                    logoutBtn.toggleAttribute("trigger", true);
                    logoutBtn.addEventListener("click", this._logoutBtnClickHandler);
                    terminateSessionBtn.id = "end-active-sessions-btn";
                    terminateSessionBtn.classList = "btn btn-success col ml-4";
                    terminateSessionBtn.innerText = "End Active Session";
                    terminateSessionBtn.toggleAttribute("trigger", true);
                    terminateSessionBtn.addEventListener("click", this._terminateSessionBtnClickHandler);
                    this._buttonGroupDiv.classList = "d-flex";
                    modalIcon.classList = "fas fa-4x fa-solid fa-lock";

                    this._modalContentContainer.appendChild(modalIcon);
                    this._modalContentContainer.appendChild(modalMsg);
                    this._buttonGroupDiv.appendChild(logoutBtn);
                    this._buttonGroupDiv.appendChild(terminateSessionBtn);
                    break;
                }
        }
    }
}

customElements.define("generic-session-modal", GenericSessionModal);