import template from './AuthPaymentCardFooter.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';

class AuthPaymentCardFooter extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();

        this.editButton = this.shadowRoot.querySelector('#cmi-button-edit');
        this.removeButton = this.shadowRoot.querySelector('#cmi-button-remove');
        this.useButton = this.shadowRoot.getElementById('cmi-button-use');

        this._editButtonClick = this._editButtonClick.bind(this);
        this._removeButtonClick = this._removeButtonClick.bind(this);
        this._useButtonClick = this._useButtonClick.bind(this);
    }

    connectedCallback() {
        this.editButton.addEventListener('click', this._editButtonClick);
        this.removeButton.addEventListener('click', this._removeButtonClick);
        this.useButton.addEventListener('click', this._useButtonClick);
        this.model = (this.parentElement || this.parentNode).model;
        this._validateUseButton();
    }

    disconnectedCallback() {
        this.editButton.removeEventListener('click', this._editButtonClick);
        this.removeButton.removeEventListener('click', this._removeButtonClick);
        this.useButton.removeEventListener('click', this._useButtonClick);
    }

    set model(value) {
        this._model = value;
        this._validateUseButton();
    }

    get model() {
        return this._model;
    }

    _editButtonClick() {
        this.dispatchEvent(new CustomEvent("edit-card-onclick", { detail: { model: this.model } }));
    }

    _removeButtonClick() {
        this.dispatchEvent(new CustomEvent("remove-card-onclick", { detail: { model: this.model } }));
    }

    _useButtonClick() {
        this.dispatchEvent(new CustomEvent("use-card-onclick", { bubbles: true, composed: true, detail: { model: this.model, footerShadowRoot: this.shadowRoot } }));
    }

    _validateUseButton() {
        if (this.model) {
            const useButtonState = this._pageTools.checkExpired(this.model.ExpirationDate);
            this.useButton.classList.toggle('hidden',  useButtonState);
            this.useButton.toggleAttribute('disabled',  useButtonState);
        }
    }
}

customElements.define('auth-payment-card-footer', AuthPaymentCardFooter);