import bootstrap from "../../scss/bootstrap-custom.scss";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import template from "./AllPaidPartialPaymentForm.html";

class AllPaidPartialPaymentForm extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);
        this._pageTools = new PageTools();
        const location = this._pageTools.getLocation();
        const optionalPortString = location.port !== "" ? `:${location.port}` : "";

        this._plc = this.shadowRoot.getElementById("plc");
        this._firstName = this.shadowRoot.getElementById("inmate-fn");
        this._lastName = this.shadowRoot.getElementById("inmate-ln");
        this._facilityId = this.shadowRoot.getElementById("facility-id");
        this._inmateId = this.shadowRoot.getElementById("inmate-id");
        this._returnUrl = this.shadowRoot.getElementById("return-url");
        this._postbackUrl = this.shadowRoot.getElementById("postback-url");
        this._payAmount1 = this.shadowRoot.getElementById("pay-amount-1");
        this._payAmount2 = this.shadowRoot.getElementById("pay-amount-2");
        this._payAmount3 = this.shadowRoot.getElementById("pay-amount-3");
        this._payAmount4 = this.shadowRoot.getElementById("pay-amount-4");
        this._form = this.shadowRoot.getElementById("form");
        this._website = `${location.protocol}//${location.hostname}${optionalPortString}`;
    }

    set url(value) {
        this._form.setAttribute("action", value);
    }

    set plcCode(value) {
        this._plcCode = value;
    }

    submit(data) {
        this._plc.value = data.plc;
        this._firstName.value = data.inmateFn;
        this._lastName.value = data.inmateLn;
        this._facilityId.value = data.facilityId;
        this._inmateId.value = data.soNumber;
        this._returnUrl.value = `${this._website}/PaymentPortal/ConfirmPayment?plcCode=${this._plcCode}&validationCode=${data.validationCode}`;
        this._postbackUrl.value = `${this._website}/PaymentPortal/Callback?code=${data.validationCode}`;
        this._payAmount1.value = data.payment1;
        this._payAmount2.value = data.payment2;
        this._payAmount3.value = data.payment3;
        this._payAmount4.value = data.payment4;

        data.charges.forEach((charge, i) => {
            this._createChargeInputs(i, charge.JmsChargeId, charge.OffenseDesc, charge.ChargeAmount);
        });

        this._form.submit();
    }

    _createChargeInputs(index, chargeId, offenseDesc, amount) {
        const offset = index + 5;
        //this offset is set to 5 because of the field numbers for the charges start at "field5"

        const chargeIdInput = document.createElement("input");
        chargeIdInput.name = `field${offset}.itemNumber`;
        chargeIdInput.type = "text";
        chargeIdInput.id = `charge-id${offset}`;
        chargeIdInput.value = `${chargeId}`;

        const offenseDescInput = document.createElement("input");
        offenseDescInput.name = `field${offset}.description`;
        offenseDescInput.type = "text";
        offenseDescInput.id = `offense-desc${offset}`;
        offenseDescInput.value = `${offenseDesc}`;

        const amountInput = document.createElement("input");
        amountInput.name = `field${offset}.amount`;
        amountInput.type = "text";
        amountInput.id = `offense-amount${offset}`;
        amountInput.value = `${amount}`;

        this._form.appendChild(chargeIdInput);
        this._form.appendChild(offenseDescInput);
        this._form.appendChild(amountInput);
    }
}

customElements.define("all-paid-partial-payment-form", AllPaidPartialPaymentForm);