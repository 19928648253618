import chargeStatus from './Enumerations/ChargeStatus';
import offenseStatus from "./Enumerations/OffenseStatus";

export function createColumn(title, field, escape, formatter) {
    const column = {
        title: title,
        field: field,
        escape: escape
    };

    if (formatter !== null) {
        column.formatter = formatter;
    }

    return column;
}

export function dateTimeFormatter(value, row, index) {
    const dateTime = (value && moment(value).isValid()) ? moment.utc(value).local().format('M/D/YYYY h:mm:ss A') : '-';
    return `<div>${dateTime}</div>`;
}

export function dateTimeFormatterWithTz(value, row, index) {
    let dateTime = '-';
    if (value && moment(value).isValid()) {
        const momentDate = moment.utc(value);
        dateTime = `${momentDate.local().format('M/D/YYYY h:mm:ss A')} ${window.DateFormatter.getUserTimeZone(momentDate.toDate())}`;
    }
    return `<div>${dateTime}</div>`;
}

export function dateFormatter(value, row, index) {
    const date = (value && moment(value).isValid()) ? moment(value).format('M/D/YYYY') : '-';
    return `<div>${date}</div>`;
}

export function boldNotApplicableDollarFieldFormatter(value, row, index) {
    return `<strong>${notApplicableDollarFieldFormatter(value, row, index)}</strong>`;
}

export function noWrapNotApplicableDollarFieldFormatter(value, row, index) {
    let noWrap = true;
    if (value === null)
        return '<div>N/A</div>';
    return dollarFieldFormatter(value, row, index, noWrap);
}

export function notApplicableDollarFieldFormatter(value, row, index) {
    if (value === null)
        return '<div>N/A</div>';
    return dollarFieldFormatter(value, row, index);
}

export function dollarFieldFormatter(value, row, index, noWrap) {
    // https://stackoverflow.com/a/2901298/7412948
    if (value === null || value === undefined)
        return null;
    value = value.toString()
    const splitResults = value.split('.');
    const formattedWholeDollarAmount = splitResults[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (splitResults.length === 1) {
        value = `${formattedWholeDollarAmount}.00`;
    } else {
        const decimal = splitResults[1].slice(0, 2);
        value = `${formattedWholeDollarAmount}.${decimal.length < 2 ? decimal + '0' : decimal}`;
    }
    if (noWrap === true)
        return `<div class="text-nowrap">$${value}</div>`

    return `<div>$${value}</div>`;
}

export function bondTypeFormatter(value, row, index) {
    if (value === null) {
        return '<div>Not Bondable</div>';
    }
    return `<div>${value}</div>`;
}

export function chargeStatusFieldFormatter(value, row, index) {
    switch (Number(value)) {
        case chargeStatus.available.value:
            return '<div>Available</div>';
        case chargeStatus.completed.value:
            return '<div>Completed</div>';
        case chargeStatus.holdWithoutBond.value:
            return '<div>Hold Without Bond</div>';
        case chargeStatus.inBookout.value:
            return '<div>In Bookout</div>';
        case chargeStatus.inReview.value:
            return '<div>In Review</div>';
        case chargeStatus.notBondable.value:
            return '<div>Not Bondable</div>';
        default:
            console.error(`The value passed: ${value} is not properly formatted`);
            return '<div></div>';
    }
}

export function getRowChargeStatusCssClass(status) {
    let customClass = '';
    switch (status) {
        case chargeStatus.available.value:
            customClass = 'charge-status-available';
            break;
        case chargeStatus.completed.value:
            break;
        case chargeStatus.holdWithoutBond.value:
            customClass = 'charge-status-hold-without-bond';
            break;
        case chargeStatus.inBookout.value:
        case chargeStatus.inReview.value:
            customClass = 'charge-status-in-progress';
            break;
        case chargeStatus.notBondable.value:
            customClass = 'charge-status-not-bondable';
            break;
    }
    return { classes: customClass };
}

export function generateAndShowDetailTable(columns, requestUrl, element) {
    const $table = element.html('<table></table>').find('table');
    $table.bootstrapTable({
        columns: columns,
        classes: 'table table-sm table-striped table-bordered'
    });

    $table.bootstrapTable('showLoading');
    const xhrWrapper = new XhrWrapper();
    xhrWrapper.makeRequest("GET",
        requestUrl,
        null,
        detailDataRequestCallback);

    function detailDataRequestCallback(response, success) {
        if (success) {
            $table.bootstrapTable('append', JSON.parse(response));
        } else {
            console.error(response);
        }
        $table.bootstrapTable('hideLoading');
    }
}

export function uploadStatusFormatter(value, row, index) {
    switch (value) {
        case true:
            return `<div class="font-weight-bold text-success">Success</div>`;
        case false:
            return `<div class="font-weight-bold text-danger">Failure</div>`;
        default:
            return `<div>N/A</div>`;
    }
}

export function fieldFormatter(value, row, index) {
    if (value === offenseStatus.required.string)
        return `<div class="font-weight-bold text-danger">${value}</div>`;
    else if ((value === offenseStatus.optional.string) || (value === offenseStatus.notApplicable.string))
        return `<div class="font-weight-bold text-info">${value}</div>`;
    else
        return `<div class="font-weight-bold text-success">${value}</div>`;
}

export function printPreviewPowerButtonFormatter(value, row, index) {
    if (value === null)
        return '<button class="btn" disabled>N/A</button>';
    return `<div><a class="btn btn-primary" href="/Reports/DownloadPower/${value}" target="_blank">Print Preview</a></div>`;
}

export function printPreviewButtonFormatter(value, row, index) {
    return `<div><a class="btn btn-primary" href="/Reports/Download/${value}" target="_blank">Print Preview</a></div>`;
}

/**
 * Sort the provided data
 * @param {Object} data
 * @param {String} _sortField
 * @param {String} _sortDataType
 * @param {String} _sortOrder
 * @returns
 */
export function sortData(data, _sortField, _sortDataType, _sortOrder) {
    const order = _sortOrder === 'desc' ? -1 : 1;
    if (_sortField) {
        const field = _sortField;
        const dataType = _sortDataType;
        data.sort(function (a, b) {
            const firstField = _getLowerCaseValue(a[field], dataType);
            const secondField = _getLowerCaseValue(b[field], dataType);

            if (firstField === null || firstField === undefined)
                return 1;
            else if (secondField === null || secondField === undefined)
                return -1;
            else if (firstField === secondField)
                return 0;
            else if (firstField < secondField)
                return order * -1;
            else if (firstField > secondField)
                return order;
        });
    }
    return data;
}

function _getLowerCaseValue(value, dataType) {
    return value !== undefined && value !== null && dataType === 'Text' ? value.toLowerCase() : value;
}