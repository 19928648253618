import bootstrap from './../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './PaymentConfirmationDialog.html';
import './../CardLogo';
import '../ActionButton';

class PaymentConfirmationDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._model = null;
        this._form = this.shadowRoot.getElementById('authorize-form');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._cardLogoElem = this.shadowRoot.querySelector('card-logo');
        this._bondApplicationIdInput = this.shadowRoot.getElementById('bond-application-id');
        this._cardIdInput = this.shadowRoot.getElementById('card-id');
        this._cardNumberSpan = this.shadowRoot.getElementById('card-number');
        this._convenienceFeeLabel = this.shadowRoot.getElementById('convenience-fee-label');
        this._convenienceFeeSpan = this.shadowRoot.getElementById('convenience-fee');
        this._subtotalFeeSpan = this.shadowRoot.getElementById('amount');
        this._totalFeeSpan = this.shadowRoot.getElementById('total');
        this._closeButton = this.shadowRoot.getElementById('closeModal-button');
        this._authorizePaymentButton = this.shadowRoot.getElementById('authorize-payment-button');

        this.openModal = this.openModal.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._authorizePayment = this._authorizePayment.bind(this);
    }

    get bondApplicationId() {
        return this.getAttribute('bondapplicationid');
    }

    get totalFee() {
        return this.getAttribute('totalfee');
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeModal);
        this._authorizePaymentButton.addEventListener('click', this._authorizePayment);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeModal);
        this._authorizePaymentButton.removeEventListener('click', this._authorizePayment);
    }

    openModal(event) {
        this._model = event.detail.model;
        this._cardNumberSpan.innerText = this._model.CardNumber;
        this._bondApplicationIdInput.value = this.bondApplicationId;
        this._cardIdInput.value = this._model.CardId;
        this._cardLogoElem.setAttribute('type', this._model.CardType);
        if (event.detail.convenienceFeeAndName && event.detail.convenienceFeeAndName.ConvenienceFee > 0) {
            const convenienceFee = event.detail.convenienceFeeAndName.ConvenienceFee;
            const subTotalFee = Number(this.totalFee);
            const totalFee = subTotalFee + convenienceFee;
            this._subtotalFeeSpan.innerText = this._pageTools.formatNumberToDollarAmount(subTotalFee).substring(1);
            this._convenienceFeeLabel.innerText = `${event.detail.tenantName} Payment Processor Convenience Fee`;
            this._convenienceFeeSpan.innerText = this._pageTools.formatNumberToDollarAmount(convenienceFee).substring(1);
            this._totalFeeSpan.innerText = this._pageTools.formatNumberToDollarAmount(totalFee).substring(1);
            this.shadowRoot.querySelectorAll('.fee-item').forEach(x => x.toggleAttribute('hidden', false));
            this._modalComponent.openModal('Confirm Payment', false);
            return;
        }

        this._totalFeeSpan.innerText = this._pageTools.formatNumberToDollarAmount(this.totalFee).substring(1);
        this._modalComponent.openModal('Confirm Payment', false);
    }

    _closeModal() {
        this._bondApplicationIdInput.value = null;
        this._cardIdInput.value = null;
        this._modalComponent.closeModal();
    }

    _authorizePayment() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._form.submit();
    }
}

customElements.define('payment-confirmation-dialog', PaymentConfirmationDialog);