import bootstrap from '../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { saveDialog } from '../EditDialogExtensions';
import template from './AllPaidPayorInformationDialog.html';
import '../StateDropdown';
import '../ActionButton';

class AllPaidPayorInformationDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('all-paid-payor-information-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('all-paid-payor-information-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');

        this._payorNameInput = this.shadowRoot.getElementById('payor-name');
        this._payorAddressInput = this.shadowRoot.getElementById('payor-address');
        this._payorCityInput = this.shadowRoot.getElementById('payor-city');
        this._payorStateInput = this.shadowRoot.getElementById('payor-state');
        this._payorZipInput = this.shadowRoot.getElementById('payor-zip');
        this._payorPhoneInput = this.shadowRoot.getElementById('payor-phone');

        this._payorEmployerInput = this.shadowRoot.getElementById('payor-employer');
        this._payorEmployerAddressInput = this.shadowRoot.getElementById('payor-employer-address');
        this._payorEmployerPhoneInput = this.shadowRoot.getElementById('payor-employer-phone');

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
    }

    set isAdditionalPayorInformationRequired(value) {
        this._isAdditionalPayorInformationRequired = value;
    }

    set isPayorInformationRequired(value) {
        this._isPayorInformationRequired = value;
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(data) {
        this._allPaidPayData = data;

        const payorInfoRequired = this._isPayorInformationRequired;
        this._payorNameInput.toggleAttribute('required', payorInfoRequired);
        this._payorAddressInput.toggleAttribute('required', payorInfoRequired);
        this._payorCityInput.toggleAttribute('required', payorInfoRequired);
        this._payorStateInput.toggleAttribute('required', payorInfoRequired);
        this._payorZipInput.toggleAttribute('required', payorInfoRequired);
        this._payorPhoneInput.toggleAttribute('required', payorInfoRequired);
        this._payorNameInput.parentNode.classList.toggle('required', payorInfoRequired);
        this._payorAddressInput.parentNode.classList.toggle('required', payorInfoRequired);
        this._payorStateInput.parentNode.classList.toggle('required', payorInfoRequired);
        this._payorCityInput.parentNode.classList.toggle('required', payorInfoRequired);
        this._payorZipInput.parentNode.classList.toggle('required', payorInfoRequired);
        this._payorPhoneInput.parentNode.classList.toggle('required', payorInfoRequired);
        
        const additionalInforequired = this._isAdditionalPayorInformationRequired;
        this._payorEmployerInput.toggleAttribute('required', additionalInforequired);
        this._payorEmployerAddressInput.toggleAttribute('required', additionalInforequired);
        this._payorEmployerPhoneInput.toggleAttribute('required', additionalInforequired);
        this._payorEmployerInput.parentNode.classList.toggle('required', additionalInforequired);
        this._payorEmployerAddressInput.parentNode.classList.toggle('required', additionalInforequired);
        this._payorEmployerPhoneInput.parentNode.classList.toggle('required', additionalInforequired);

        this._modalComponent.openModal('Payor Information', false);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: '/PaymentPortal/SubmitPayment',
            data: this._gatherData(),
            pageTools: this._pageTools,
            customSaveCallback: this._saveCallBack.bind(this)
        });
    }

    _saveCallBack(response, status) {
        if (!status) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            this._closeDialog();
        }
        const event = new CustomEvent('payorDialogResponse', { detail: { 'response': response, 'status': status } });
        this.dispatchEvent(event);
    }

    _gatherData() {
        this._allPaidPayData.AllPaidPayorInformation = {
                AllPaidPayorName: this._payorNameInput.value,
                AllPaidPayorAddress: this._payorAddressInput.value,
                AllPaidPayorCity: this._payorCityInput.value,
                AllPaidPayorState: this._payorStateInput.value,
                AllPaidPayorZip: this._payorZipInput.value,
                AllPaidPayorPhone: this._payorPhoneInput.value,
                AllPaidPayorEmployer: this._payorEmployerInput.value,
                AllPaidPayorEmployerAddress: this._payorEmployerAddressInput.value,
                AllPaidPayorEmployerPhone: this._payorEmployerPhoneInput.value
        }
        return this._allPaidPayData;
    }
}
customElements.define('all-paid-payor-information-dialog', AllPaidPayorInformationDialog);