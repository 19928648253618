import bootstrap from "../scss/bootstrap-custom.scss";
import template from "./CreditCardFeeComponent.html";
import { initializeHtmlElement } from "./HTMLElementExtensions";

class CreditCardFeeComponent extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], ["credit-card-fees"]);

        this._pageTools = new PageTools();
        this._countyFee = this.shadowRoot.getElementById("county-fee");
        this._ebcaFee = this.shadowRoot.getElementById("ebca-fee");
        this._convenienceFee = this.shadowRoot.getElementById("convenience-fee");
        this._totalFees = this.shadowRoot.getElementById("total-fees");
        this._stateSubdivisionFeeName = this.shadowRoot.getElementById("state-subdivision-fee-name");

        this._countyFee.value = 0;
        this._ebcaFee.value = 0;
        this._convenienceFee.value = 0;
        this._totalFees.value = 0;
    }

    set countyFee(value) {
        const dollarAmount = this._pageTools.formatNumberToDollarAmount(value).substring(1);
        this._countyFee.value = parseFloat(value);
        this._countyFee.innerHTML = dollarAmount;
        this._calculateFees();
    }

    set ebcaFee(value) {
        const dollarAmount = this._pageTools.formatNumberToDollarAmount(value).substring(1);
        this._ebcaFee.value = parseFloat(value);
        this._ebcaFee.innerHTML = dollarAmount;
        this._calculateFees();
    }

    set convenienceFee(value) {
        const dollarAmount = this._pageTools.formatNumberToDollarAmount(value).substring(1);
        this._convenienceFee.value = parseFloat(value);
        this._convenienceFee.innerHTML = dollarAmount;
        this._calculateFees();
    }

    set stateSubdivision(value) {
        this._stateSubdivision = value;
        this._stateSubdivisionFeeName.innerHTML = `${this._stateSubdivision} Fee:`;
    }

    getTotalFees() {
        return this._pageTools.formatNumberToDollarAmount(this._totalFees.value).substring(1);
    }

    _calculateFees() {
        const totalFees = this._countyFee.value + this._ebcaFee.value + this._convenienceFee.value;
        this._totalFees.value = totalFees;
        this._totalFees.innerHTML = this._pageTools.formatNumberToDollarAmount(totalFees).substring(1);
    }
}
customElements.define("credit-card-fee-component", CreditCardFeeComponent);