import template from './CountyCompanyModal.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import './ModalComponent';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CountyCompanyModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();

        this._selectedBondCompanyId = null;
        this._closeModalButton = this.shadowRoot.getElementById("closeModal-button");
        this._actionModalButton = this.shadowRoot.getElementById("actionModal-button");
        this._companySelect = this.shadowRoot.getElementById("company-select");
        this._modal = this.shadowRoot.querySelector("modal-component");
        this._warningLabel = this.shadowRoot.getElementById("warning-label");

        this.closeModal = this.closeModal.bind(this);
        this.setCompany = this.setCompany.bind(this);
        this.clearErrorMessages = this.clearErrorMessages.bind(this);
    }

    static get observedAttributes() {
        return ["current-bond-company-id"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;
        if (name === "current-bond-company-id") {
            this._selectedBondCompanyId = parseInt(newValue);
        }
    }
    
    connectedCallback() {
        this._closeModalButton.addEventListener("click", this.closeModal);
        this._actionModalButton.addEventListener("click", this.setCompany);
        this._companySelect.addEventListener("change", this.clearErrorMessages)
    }

    disconnectedCallback() {
        this._closeModalButton.removeEventListener("click", this.closeModal);
        this._actionModalButton.removeEventListener("click", this.setCompany);
        this._companySelect.removeEventListener("click", this.clearErrorMessages);
    }

    openModal(selectionRequired) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET", "/Account/GetCountyCompaniesForModal", null, this.updateCompanyListInModal.bind(this));

        this._modal.openModal("Choosing a Tenant is required", selectionRequired);
        
        if (selectionRequired) {
            this._closeModalButton.classList.add("disabled");
            this._closeModalButton.setAttribute("disabled",true);
        }
    }

    closeModal() {
        this._modal.closeModal();
    }

    setErrorMessage(message) {
        this._warningLabel.textContent = message;
    }

    clearErrorMessages() {
        this.setErrorMessage("");
    }

    updateCompanyListInModal(message, isSuccess) {
        if (!isSuccess) {
            this.setErrorMessage('Failed to get jail companies.');
            return;
        }
        const companies = JSON.parse(message);
        companies.forEach((company, i) => {
            const option = new Option(`${company.CompanyName}, ${company.TenantStateCode}`, `${company.BondCompanyID}`, false, this._selectedBondCompanyId === company.BondCompanyID)
            this._companySelect[i + 1] = option;
        });
    }

    setCompany() {
        const selectedCompany = this._companySelect.value;

        if (!selectedCompany) {
            this.setErrorMessage('Choosing a jail is required');
            return;
        }

        this._pageTools.toggleTriggers(this.shadowRoot, true);

        this.clearErrorMessages();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", `/Account/SetCountyCompany/${selectedCompany}`, null, this.successUpdateState.bind(this));
    }

    successUpdateState(message, isSuccess) {
        if (isSuccess) {
            location.reload();
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this.setErrorMessage(message);
    }
}
customElements.define("county-company-modal", CountyCompanyModal);
