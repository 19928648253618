import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert, ensureListHasSameValuesAndDisplay } from './EditDialogExtensions';
import template from './EditArrestInfoDialog.html';
import './ActionButton';
import './StateDropdown';
import bootstrap from '../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { isAttributeTrue } from "./JavaScriptFunctions";


class EditArrestInfoDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome]);
        
        this._hasAgencyCaseNumber = null;
        this._totalBondUiDelimiter = null;
        this._courtInformationReadOnly = null;
        this._readOnlyCourtInformationUnknownCourtOpenDialogErrorMessage = "The selected offenses include one or more courts that are not configured in eBONDS.";
        this._readOnlyCourtInformationUnknownCourtSaveDialogErrorMessage = "The selected offenses include one or more courts that are not configured in eBONDS. Please check the court name on each selected offense. To update an offense's court in eBONDS, update the offense's court in the JMS.";
        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('arrest-info-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('arrest-info-dialog-save-button');
        this._courtInfoContainer = this.shadowRoot.getElementById('court-information-container');
        this._courtDropDownContainer = this.shadowRoot.getElementById('court-dropdown-container');
        this._causeWarrantContainer = this.shadowRoot.getElementById('cause-warrant-container');
        this._agencyCaseNumberContainer = this.shadowRoot.getElementById('agency-case-number-container');
        this._causeNumberContainer = this.shadowRoot.getElementById('cause-number-container');
        this._warrantNumberContainer = this.shadowRoot.getElementById('warrant-number-container');
        this._causeNumberInput = this.shadowRoot.getElementById('cause-number');
        this._warrantNumberInput = this.shadowRoot.getElementById('warrant-number');
        this._warrantNumberLabel = this.shadowRoot.getElementById('warrant-number-label');
        this._extraditionStateDropDown = this.shadowRoot.getElementById('extradition-state-dropdown');
        this._agencyCaseNumberInput = this.shadowRoot.getElementById('agency-case-number');
        this._courtDateInput = this.shadowRoot.getElementById('court-date');
        this._courtTimeInput = this.shadowRoot.getElementById('court-time');
        this._courtDropdown = this.shadowRoot.getElementById('court-dropdown');
        this._altCourtAppearanceInput = this.shadowRoot.getElementById('alternate-court-appearance');
        this._oocCourtContainer = this.shadowRoot.getElementById('ooc-container');
        this._oocCourtNameInput = this.shadowRoot.getElementById('out-of-county-court-name');
        this._oocCourtAddressInput = this.shadowRoot.getElementById('out-of-county-court-address');
        this._oocCourtCityInput = this.shadowRoot.getElementById('out-of-county-court-city');
        this._oocCourtCountyInput = this.shadowRoot.getElementById('out-of-county-court-county');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');
        this._time = this.shadowRoot.getElementById('time');
        this.hideCourtInformation = false;
        this._totalBondsTooltip = this.shadowRoot.getElementById("tb-tooltip");
        $(this._totalBondsTooltip).tooltip();
        this._aggregateBondCourtInfoTooltip = this.shadowRoot.getElementById("agb-court-info-tooltip");
        $(this._aggregateBondCourtInfoTooltip).tooltip();

        this._closeDialog = this._closeDialog.bind(this);
        this._courtDropdownChange = this._courtDropdownChange.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._setCourtDateRequired = this._setCourtDateRequired.bind(this);
    }

    set courts(value) {
        // Leave the first option (Select one) and last option (OOC Court)
        const optionCount = this._courtDropdown.options.length - 2;
        for (let i = optionCount; i > 1; i--) {
            this._courtDropdown.remove(i);
        }

        value.sort((a, b) => a.CourtName.localeCompare(b.CourtName)).reverse().forEach(item => {
            this._courtDropdown.options.add(new Option(item.CourtName, item.CourtID), this._courtDropdown.options[2]);
        });
    }

    set totalBondUiDelimiter (value) {
        this._totalBondUiDelimiter = value;
    }

    set hasAgencyCaseNumber(value) {
        this._hasAgencyCaseNumber = value;
        this._updateAgencyCaseNumberState();
    }

    set courtInformationReadOnly(value) {
        this._courtInformationReadOnly = value;
    }

    set hasCourtRequired(value) {
        this._courtDropdown.toggleAttribute('required', value);
        this._courtDropDownContainer.classList.toggle('required', value);
    }

    set hideCourtInformation(value) {
        this._hideCourtInformation = value;
        this._courtInfoContainer.toggleAttribute('hidden', this._hideCourtInformation);
        if (value) {
            this._courtDropdown.toggleAttribute('required', false);
        }
    }

    set hideCauseAndWarrantInformation(value) {
        this._hideCauseAndWarrantInformation = value;
        this._causeWarrantContainer.toggleAttribute('hidden', this._hideCauseAndWarrantInformation);
        this._updateAgencyCaseNumberState();
    }

    set hasCauseNumberRequiredFeatureFlag(value) {
        this._causeNumberInput.toggleAttribute('required', value && !this.hideCauseAndWarrantInformation);
        this._causeNumberContainer.classList.toggle('required', value && !this.hideCauseAndWarrantInformation);
    }

    set hasWarrantNumberRequiredFeatureFlag(value) {
        this._warrantNumberInput.toggleAttribute('required', value && !this.hideCauseAndWarrantInformation);
        this._warrantNumberContainer.classList.toggle('required', value && !this.hideCauseAndWarrantInformation);
    }

    set hasTotalBondsFeatureFlag(value) {
        this._hasTotalBondFeatureFlag = value;
    }

    get hideCauseAndWarrantInformation() {
        return this._hideCauseAndWarrantInformation;
    }

    get _extraditionInformationTempFeatureFlag() {
        return isAttributeTrue(this, 'data-extradition-information-temp');
    }

    get hideAgencyCaseNumberInformation() {
        return this._agencyCaseNumberContainer.hasAttribute('hidden');
    }
    get hideCourtInformation() {
        return this._hideCourtInformation;
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._courtDropdown.addEventListener('change', this._courtDropdownChange);
        this._saveButton.addEventListener('click', this._saveDialog);
        this._courtTimeInput.addEventListener('change', this._setCourtDateRequired);
    }

    disconnectedCallback(){
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._courtDropdown.removeEventListener('change', this._courtDropdownChange);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(selectedCharges) {
        this._selectedCharges = selectedCharges;
        const totalBonds = this._hasTotalBonds(selectedCharges);

        if (selectedCharges.length > 1)
            this._warrantNumberLabel.innerText = "Warrant Numbers";
        else
            this._warrantNumberLabel.innerText = "Warrant Number";

        this._setInitialValues();

        let dialogTitle;
        const courtInfoIsHidden = this._hideCourtInformation;
        const causeAndWarrantContainerIsHidden = this._hideCauseAndWarrantInformation;
        if (!courtInfoIsHidden && !causeAndWarrantContainerIsHidden)
            dialogTitle = 'Arrest / Court Information';
        else if (!courtInfoIsHidden && causeAndWarrantContainerIsHidden)
            dialogTitle = 'Court Information';
        else
            dialogTitle = 'Arrest Information';

        this._totalBondsTooltip.toggleAttribute('hidden', !totalBonds);
        this._warrantNumberInput.toggleAttribute('disabled', totalBonds);
        if (totalBonds) {
            this._warrantNumberContainer.classList.remove('required');
        } else {
            this._warrantNumberContainer.classList.toggle('required', this._warrantNumberInput.hasAttribute('required'));
        }

        // Court Name Cannot Be Changed During Review
        this._courtDropdown.toggleAttribute('disabled', this._courtInformationReadOnly);
        this._aggregateBondCourtInfoTooltip.toggleAttribute('hidden', !this._courtInformationReadOnly);

        const extraditionFeatureOnAndInformationIsRequired =
            this._extraditionInformationTempFeatureFlag &&
            this._selectedCharges.some(x => x.ExtraditionInformationRequired) &&
            !this._hideCauseAndWarrantInformation;
        this._extraditionStateDropDown.toggleAttribute('required', extraditionFeatureOnAndInformationIsRequired);
        this._extraditionStateDropDown.toggleAttribute('hidden', !extraditionFeatureOnAndInformationIsRequired);
        
        this._saveButton.textContent = `Save ${dialogTitle}`;
        this._modalComponent.openModal(dialogTitle, false);
    }

    _setCourtDateRequired() {
        this._courtDateInput.toggleAttribute('required', this._courtTimeInput.value);
    }

    _updateAgencyCaseNumberState() {
        const agencyCaseNumberRequired = this._hasAgencyCaseNumber && !this._hideCauseAndWarrantInformation;
        this._agencyCaseNumberContainer.toggleAttribute('hidden', !agencyCaseNumberRequired);
        this._agencyCaseNumberInput.toggleAttribute('required', agencyCaseNumberRequired);
    }

    _courtDifferenceCallback(item, different) {
        if (different) {
            this._courtDropdown.selectedIndex = 0;
        } else {
            if (item.Court) {
                this._courtDropdown.value = item.Court;
            } else if (item.CourtName) {
                this._courtDropdown.selectedIndex = this._courtDropdown.options.length - 1;
                if (this._courtInformationReadOnly) 
                    this._courtDropdown.selectedIndex = 0;
            } else {
                this._courtDropdown.selectedIndex = 0;
            }
        }
        this._courtDropdown.dispatchEvent(new Event('change')); // Show/hide OOC section
    }

    _courtDateDifferenceCallback(item, different) {
        if (different) {
            this._courtDateInput.value = null;
        } else {
            if (item.CourtTime) {
                this._courtDateInput.value = window.DateFormatter.convertToDateCourtTimeCourtFormat(item.CourtTime);
            } else {
                this._courtDateInput.value = window.DateFormatter.convertToDateCourtDateTime(item.CourtDate);
            }

        }
    }

    _courtTimeDifferenceCallback(item, different) {
        this._time.textContent = `Time (${window.DateFormatter.getUserTimeZone()})`;
        if (different || !item.CourtTime) {
            this._courtTimeInput.value = null;
        } else {
            const dateObj = window.DateFormatter.convertToTime(item.CourtTime);
            if (dateObj) {
                this._courtTimeInput.value = dateObj;
            } else {
                this._courtTimeInput.value = item.CourtTime;
            }
        }
    }

    //we are storing the full state for the Extradition state hence we can not use the handleDropdownDifferencesCallback
    _extraditionStateDifferenceCallback(item, different) {
        if (this._extraditionInformationTempFeatureFlag) {
            if (different) {
                this._extraditionStateDropDown.selectedIndex = 0;
            } else {
                if (item.ExtraditionState)
                    this._extraditionStateDropDown.value = Array.from(this._extraditionStateDropDown.options).find(x => x.text === item.ExtraditionState).value;
                else
                    this._extraditionStateDropDown.selectedIndex = 0;
            }
        }
    }

    _hasTotalBonds(value) {
        const hasTotalBond = value.some(x => x.IsTotalBond);
        const totalBond = this._hasTotalBondFeatureFlag && hasTotalBond;
        return totalBond;
    }

    _setInitialValues() {
        const elements = [];

        if (!this.hideCauseAndWarrantInformation) {
            elements.push(...[
                { property: "CauseNum", element: this._causeNumberInput },
                { property: "WarrantNum", element: this._warrantNumberInput }
            ]);
        }
        if (!this.hideCauseAndWarrantInformation && !this._agencyCaseNumberContainer.hasAttribute('hidden')) {
            elements.push(...[
                { property: "AgencyCaseNumber", element: this._agencyCaseNumberInput }
            ]);
        }
        if (!this._hideCourtInformation) {
            elements.push(...[
                { property: "Court", callback: this._courtDifferenceCallback.bind(this) }, // requires special handling
                { property: "CourtDate", callback: this._courtDateDifferenceCallback.bind(this) }, // requires special handling
                { property: "CourtTime", callback: this._courtTimeDifferenceCallback.bind(this) }, // requires special handling
                { property: "AlternateCourt", element: this._altCourtAppearanceInput },
                // Out of County Court Fields
                { property: "CourtName", element: this._oocCourtNameInput },
                { property: "CourtAddress", element: this._oocCourtAddressInput },
                { property: "CourtCity", element: this._oocCourtCityInput },
                { property: "CourtCounty", element: this._oocCourtCountyInput }
            ]);
        }
        if (!this.hideCauseAndWarrantInformation && this._extraditionInformationTempFeatureFlag && !this._extraditionStateDropDown.hasAttribute('hidden')) {
            elements.push(...[
                { property: "ExtraditionState", callback: this._extraditionStateDifferenceCallback.bind(this) }
            ]);
        }
       
        let differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, elements);

        if (this._selectedCharges[0].Court) { // We're not OOC
            this._oocCourtNameInput.value = null;
            this._oocCourtAddressInput.value = null;
            this._oocCourtCityInput.value = null;
            this._oocCourtCountyInput.value = null;
        }

        const isTotalBond = this._selectedCharges.some(x => x.IsTotalBond);
        if (isTotalBond && this._selectedCharges.length > 1) {
            this._warrantNumberInput.value =
                this._selectedCharges
                    .map(element => element.WarrantNum)
                    .filter(x => x !== null && x !== undefined && x.trim() !== "")
                    .sort()
                    .join(this._totalBondUiDelimiter);
        }

        differences = this._getFilteredDifferences(differences, isTotalBond);

        if (this._courtInformationReadOnly && this._selectedCharges.some(x => x.Court == null)) {
            // When FF Enabled and JMS Supplied Court is Unknown to Bonds, Alert User
            showAlert(this._alert, this._readOnlyCourtInformationUnknownCourtOpenDialogErrorMessage);
        } else if (differences.length > 0) {
            showAlert(this._alert,
                'There is conflicting arrest information with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _getFilteredDifferences(differences, isTotalBond) {
        if (isTotalBond)
            differences = differences.filter(item => item !== 'WarrantNum');
        if (this._courtInformationReadOnly)
            differences = differences.filter(item => item !== "Court");
        return differences;
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _courtDropdownChange(e) {
        const selectedText = e.target.options[e.target.selectedIndex].text;
        const hide = selectedText !== "Out of County";
        const require = !hide;
        if (!this._hideCourtInformation) {
            this._oocCourtContainer.querySelectorAll('input').forEach(element => { element.toggleAttribute('required', require) });
        }
        this._oocCourtContainer.toggleAttribute('hidden', hide);

        // Court Info Cannot Be Changed, Concat All CourtNames and Display
        if (this._courtInformationReadOnly) {
            const courtNames = this._getDistinctCourtNames();
            this._courtDropdown.selectedIndex = 1;
            this._courtDropdown.options[1].innerHTML = courtNames;
        }
    }

    _saveDialog() {
        // When FF Enabled and JMS Supplied Court is Unknown to Bonds, Prevent User From Proceeding
        if (this._courtInformationReadOnly && this._alertMessageIsForReadOnlyCourtError()) {
            showAlert(this._alert, this._readOnlyCourtInformationUnknownCourtSaveDialogErrorMessage);
            return;
        }
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: '/Application/UpdateCourt',
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }

    _gatherData() {
        const causeNum = this._causeNumberInput.value;
        const warrant = this._warrantNumberInput.value;
        const agencyCaseNumber = this._agencyCaseNumberInput.value;
        const extraditionState = this._extraditionStateDropDown.selectedIndex === 0 ?
            null :
            this._extraditionStateDropDown.options[this._extraditionStateDropDown.selectedIndex].text;
        let court = null;
        let courtDate = null;
        let courtTime = null;
        let alternateCourtDate = null;
        if (!this._hideCourtInformation) {
            court = this._courtDropdown.value;
            courtDate = this._courtDateInput.value;
            courtTime = (this._courtDateInput.value && this._courtTimeInput.value)
                ? window.DateFormatter.localToUtc(new Date(`${this._courtDateInput.value} ${this._courtTimeInput.value}`).toString())
                : null;
            alternateCourtDate = this._altCourtAppearanceInput.value;
        }
        let oocCourtName = null;
        let oocCourtAddress = null;
        let oocCourtCity = null;
        let oocCourtCounty = null;
        if (!this._oocCourtContainer.hasAttribute('hidden')) {
            oocCourtName = this._oocCourtNameInput.value;
            oocCourtAddress = this._oocCourtAddressInput.value;
            oocCourtCity = this._oocCourtCityInput.value;
            oocCourtCounty = this._oocCourtCountyInput.value;
        }
        return {
            selected: this._selectedCharges.map(element => element.BondApplicationDetailID),
            causeNum: causeNum,
            warrant: warrant,
            court: court === "Out of County" ? null : court,
            courtDate: courtDate,
            courtTime: courtTime,
            ooCountyCourtName: oocCourtName,
            ooCountyCourtAddress: oocCourtAddress,
            ooCountyCourtCity: oocCourtCity,
            ooCountyCourtCounty: oocCourtCounty,
            alternateCourtDate: alternateCourtDate,
            agencyCaseNumber: agencyCaseNumber,
            extraditionState: extraditionState,
            ignoreWarrantNumber: this._selectedCharges.some(x => x.IsTotalBond) && this._hasTotalBondFeatureFlag
        };
    }

    _getDistinctCourtNames() {
        const courtNames = this._selectedCharges.map(x => {
            if (x.CourtName !== null) {
                return x.CourtName;
            } else if (x.Court !== null) {
                return Array.from(this._courtDropdown.options).filter(y => y.value === x.Court.toString())[0].innerHTML;
            } else {
                return null;
            }
        });

        const distinct = Array.from(new Set(courtNames.filter(x => x !== null && x !== undefined && x.trim() !== "")));
        distinct.sort();
        return distinct.join(this._totalBondUiDelimiter);
    }

    _alertMessageIsForReadOnlyCourtError() {
        return this._alert.textContent === this._readOnlyCourtInformationUnknownCourtOpenDialogErrorMessage ||
            this._alert.textContent === this._readOnlyCourtInformationUnknownCourtSaveDialogErrorMessage;
    }
}

customElements.define('edit-arrest-info-dialog', EditArrestInfoDialog);