import template from './CompanyModal.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import './ModalComponent';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CompanyModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();

        this._closeModalButton = this.shadowRoot.getElementById("closeModal-button");
        this._actionModalButton = this.shadowRoot.getElementById("actionModal-button");
        this._modalRadioContent = this.shadowRoot.getElementById("radio-group");
        this._modal = this.shadowRoot.querySelector("modal-component");
        this._skeletonContainer = this.shadowRoot.getElementById("skeleton-container");
        this._tenantContainer = this.shadowRoot.getElementById("tenant-container");
        this._companyContainer = this.shadowRoot.getElementById("company-container");
        this._tenantDropdown = this.shadowRoot.getElementById("select-tenant");
        this._warningLabel = this.shadowRoot.getElementById("warning-label");
        this._selectedBondCompanyId = null;
        this._selectedTenantId = null;
        this._tenantGroups = null;

        this.closeModal = this.closeModal.bind(this);
        this.setCompany = this.setCompany.bind(this);
        this._selectRadioGroup = this._selectRadioGroup.bind(this);
        this.clearErrorMessages = this.clearErrorMessages.bind(this);
    }

    static get observedAttributes() {
        return ["bond-company-id"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;
        switch (name) {
            case "bond-company-id":
                this._selectedBondCompanyId = parseInt(newValue);
                break;
        }
    }

    connectedCallback() {
        this._closeModalButton.addEventListener("click", this.closeModal);
        this._actionModalButton.addEventListener("click", this.setCompany);
        this._tenantDropdown.addEventListener("change", this._selectRadioGroup);
    }

    disconnectedCallback() {
        this._closeModalButton.removeEventListener("click", this.closeModal);
        this._actionModalButton.removeEventListener("click", this.setCompany);
        this._tenantDropdown.removeEventListener("change", this._selectRadioGroup);
    }

    openModal(selectionRequired) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "/Account/GetBondCompaniesForModal", null, this.updateCompanyListInModal.bind(this));

        this._modal.openModal("Choosing a company is required", selectionRequired);

        if (selectionRequired) {
            this._closeModalButton.classList.add("disabled");
            this._closeModalButton.setAttribute("disabled", true);
        }
    }

    closeModal() {
        this._modal.closeModal();
    }

    _setErrorMessage(message) {
        this._warningLabel.textContent = message;
    }

    clearErrorMessages() {
        this._setErrorMessage("");
    }

    updateCompanyListInModal(message, isSuccess) {
        if (!isSuccess) {
            this._setErrorMessage(message);
            return;
        }
        const tenants = JSON.parse(message);
        this._tenantContainer.classList.toggle("d-none", this._generateTenantDropdown(tenants));
        this._generateRadioGroups(tenants);
        this._skeletonContainer.classList.add("d-none");
    }

    setCompany() {
        const selectedCompany = this.shadowRoot.querySelector('input[name="companyId"]:checked');

        if (!selectedCompany) {
            this._setErrorMessage("Choosing a company is required");
            return;
        }

        this._pageTools.toggleTriggers(this.shadowRoot, true);

        this.clearErrorMessages();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", `/Account/SetBondCompany/${selectedCompany.value}`, null, this.successUpdateState.bind(this));
    }

    successUpdateState(message, isSuccess) {
        if (isSuccess) {
            location.reload();
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._setErrorMessage(message);
    }

    _generateTenantDropdown(tenants) {
        if (tenants.length <= 1)
            return true;

        tenants.forEach((companies) => {
            const tenantId = (companies[0]) ? companies[0].TenantId : null;
            const tenantName = (companies[0].Tenant) ? companies[0].Tenant.TenantName : null;
            const tenantHasSelectedCompany =
                companies.filter(x => x.BondCompanyId === this._selectedBondCompanyId).length > 0;
            const option = document.createElement("option");
            option.value = tenantId;
            option.textContent = tenantName;
            if (tenantHasSelectedCompany) {
                option.selected = true;
                this._selectedTenantId = tenantId;
            }
            this._tenantDropdown.appendChild(option);
        });
        return false;
    }

    _generateRadioGroups(tenants) {
        let element = "";
        let idCounter = 1;
        tenants.forEach((companies) => {
            const tenantName = (companies[0].Tenant) ? companies[0].Tenant.TenantName : null;
            const tenantId = (companies[0]) ? companies[0].TenantId : null;
            element += `<div class="tenant-radio-group" tenantId=${tenantId}>`;
            element += `<span class="title">${tenantName}</span>`;
            companies.forEach(company => {
                const checked = (this._selectedBondCompanyId === company.BondCompanyId) ? "checked" : "";
                const id = `companyModalInput${idCounter++}`;
                element += `<div class="form-check">
<input class="form-check-input" ${checked} type="radio" name="companyId" value="${company.BondCompanyId}" id="${id}"/>
<label class="form-check-label" for=${id}>${company.CompanyName}</label>
</div>`;
            });
            element += "</div>";
        });

        this._modalRadioContent.innerHTML = element;

        this._tenantGroups = this._getAllTenantRadioGroups();
        const tenantFirstRadioGroup = this._tenantGroups[0];

        this._modalRadioContent.querySelectorAll(".form-check-input")
            .forEach(x => x.removeEventListener("click", this.clearErrorMessages));

        this._selectedTenantId = (!this._selectedTenantId) ? parseInt(tenantFirstRadioGroup.getAttribute("tenantId")) : this._selectedTenantId;
        this._modalRadioContent.querySelectorAll(".form-check-input")
            .forEach(x => x.addEventListener("click", this.clearErrorMessages));
        this._selectTenant();

        this._companyContainer.classList.remove("d-none");
    }

    _getAllTenantRadioGroups() {
        return this._modalRadioContent.querySelectorAll(".tenant-radio-group");
    }

    _selectRadioGroup() {
        this._selectedTenantId = parseInt(this._tenantDropdown.value);
        this._selectTenant();
    }

    _selectTenant() {
        this._tenantGroups.forEach((tenantRadioGroup, index) => {
            const groupTenantId = parseInt(tenantRadioGroup.getAttribute("tenantId"));
            tenantRadioGroup.classList.toggle("active", this._selectedTenantId === groupTenantId);
        });
    }
}
customElements.define("company-modal", CompanyModal);
