import { initializeHtmlElement } from "./../HTMLElementExtensions";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import "bootstrap-table/dist/bootstrap-table";
import { createColumn } from "../BootstrapTableExtensions";
import template from "./ManageTenantsPage.html";
import "./../ActionButton";

class ManageTenantsPage extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ["container"]);
        this._table = this.shadowRoot.getElementById("tenant-table");
        this._newButton = this.shadowRoot.getElementById("create-new-tenant-input");
        this._manageTenantModal = this.shadowRoot.querySelector("manage-tenant-modal");
        this._alert = this.shadowRoot.getElementById("alert");

        this._addNewDocumentButtonOnClick = this._addNewDocumentButtonOnClick.bind(this);
        this._displaySuccessMessage = this._displaySuccessMessage.bind(this);
    }

    set model(value) {
        this._model = value;
        this._table.destroyTable();
        this._loadTableData();

        this._table.shadowRoot.querySelectorAll('action-button[id^="edit-button"]')
            .forEach(x => x.addEventListener("click", this._editOnClick.bind(this)));
    }

    connectedCallback() {
        this._tableConfig = {
            tableColumns: this._compileColumns(),
            uniqueId: "TenantId"
        }

        this._newButton.addEventListener("click", this._addNewDocumentButtonOnClick);
        this._manageTenantModal.addEventListener("success", this._displaySuccessMessage);
    }

    disconnectedCallback() {
        this._newButton.removeEventListener("click", this._addNewDocumentButtonOnClick);
        this._manageTenantModal.removeEventListener("success", this._displaySuccessMessage);
    }

    _displaySuccessMessage(event) {
        this._showSuccessAlert(event.detail.message);
        this.model = event.detail.models;
    }

    _addNewDocumentButtonOnClick() {
        this._openCustomModal("Add Tenant", null, "/Admin/CreateTenant", false);
    }

    _editOnClick(e) {
        const tenantId = parseInt(e.target.id.replace("edit-button-", ""), 10);
        const model = this._model.find(x => x.TenantId === tenantId);
        if (model) {
            this._openCustomModal(`Edit Tenant - ${model.TenantName}`, model, "/Admin/UpdateTenant", true);
        }
    }

    _openCustomModal(title, model, endpoint, isEdit) {
        this._removeSuccessAlert();
        this._manageTenantModal.openModal(title, model, endpoint, isEdit);
    }

    _removeSuccessAlert() {
        this._alert.toggleAttribute("hidden", true);
        this._alert.classList.remove("text-success");
        this._alert.textContent = "";
    }

    _showSuccessAlert(message) {
        this._alert.toggleAttribute("hidden", false);
        this._alert.classList.add("text-success");
        this._alert.textContent = message;
    }

    _loadTableData() {
        this._table.initTableData({
            bootstrapTableConfig: this._tableConfig,
            tableData: this._model
        });
    }

    _compileColumns() {
        const columns = [
            createColumn("Tenant Name", "TenantName"),
            createColumn("Tenant ID", "TenantId"),
            createColumn("County GUID", "County"),
            createColumn("State Code", "StateCode"),
            createColumn("", "", undefined, this._editButtonFormatter)
        ];

        return columns;
    }

    _editButtonFormatter(value, row) {
        return `<div class='d-flex justify-content-center'><action-button id=edit-button-${row.TenantId} class='btn btn-primary'>Edit</action-button></div>`;
    }
}
customElements.define("manage-tenants-page", ManageTenantsPage);