import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";

class BondPaymentsGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="county-bond-fee-payment-list"></bootstrap-report-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
        this.shadowRoot.querySelector("bootstrap-report-grid").shadowRoot.querySelector('bootstrap-table').shadowRoot.querySelector('#export-pdf').toggleAttribute('hidden', true);
    }

    _creditedFormatter(value, row, index, field) {
        if (row.CountyPaymentCreditReceived)
            return "<input type='checkbox' disabled checked/>";
        else
            return "<input type='checkbox' disabled/>";
    }

    _compileColumns() {
        const payorColumnHeader = settings.featureFlags[FeatureFlag.ShowPaymentMethod] ? "Payor" : "Bond Company";
        this._tableColumns = [
            {
                title: payorColumnHeader,
                field: 'PayorName',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapReportGrid.payorFormatter.bind(this)
            },
            {
                title: 'Inmate',
                field: 'InmateFullName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking Number',
                field: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Total Bond Amount',
                field: 'TotalBondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Total Bond Fees',
                field: 'TotalCountyBondFees',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            }
        ];

        if (settings.featureFlags[FeatureFlag.ShowPaymentMethod]) {
            this._tableColumns.push(
                {
                    title: "Payment Method",
                    field: "PaymentMethod",
                    type: "Text",
                    escape: true,
                    formatter: this._bootstrapReportGrid.paymentMethodFormatter
                }
            );

            this._tableColumns.push(
                {
                    title: "Reference Number",
                    field: "ReferenceNumber",
                    type: "Text",
                    escape: true
                }
            );
        }

        this._tableColumns.push(
            {
                title: 'Payment Authorized Time',
                field: 'CountyPaymentAuthorizedDateTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Payment Capture Time',
                field: 'CountyPaymentCaptureDateTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Status',
                field: 'AppStatus',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapReportGrid.applicationStatusFormatter
            },
            {
                title: 'Cancel Reason',
                field: 'CancelReason',
                type: 'Text',
                escape: true
            },
            {
                title: 'Canceled By',
                field: 'CanceledBy',
                type: 'Text',
                escape: true
            },
            {
                title: 'Payment Canceled Time',
                field: 'CountyPaymentCanceledTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Remittance ID',
                field: 'RemittanceId',
                type: 'Number',
                escape: true
            },
            {
                title: 'Credited',
                field: 'CountyPaymentCreditReceived',
                type: 'Number',
                searchable: false,
                escape: false,
                formatter: this._creditedFormatter,
                printIgnore: true
            }
        );
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: 'BondApplicationID',
            tableColumns: this._tableColumns,
            print: true,
            export: true,
            pageSize: 20,
            mainTableHeight: 600,
            exportFilename: 'Bond-Fee-Payments',
            reportUrl: '/Reports/BondFeePaymentList_Read',
            restoreFilters: true,
            tableName: 'BondFeePaymentList_Read',
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }
}

customElements.define('bond-payments-grid', BondPaymentsGrid);