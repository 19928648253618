import "bootstrap-table/dist/bootstrap-table";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import bootstrap from "../scss/bootstrap-custom.scss";
import "./ActionButton";
import { createColumn, dollarFieldFormatter, fieldFormatter } from "./BootstrapTableExtensions";
import "./EditArrestInfoDialog";
import "./EditAttorneyInfoDialog";
import "./EditCosignerInfoDialog";
import { getArrestInfoStatus, getAuthorizedByInfo, getSpecialConditionsInfo, updateArrestInfo, updateAuthorizationInfo, updateSpecialConditionsInfo } from "./EditDialogExtensions";
import "./EditInstructionsDialog";
import "./EditPowersDialog";
import "./EditPRInfoDialog";
import "./EditPropertyInfoDialog";
import "./EditReceiptInfoDialog";
import "./EditSpecialConditionsDialog";
import bondWorkFlow from "./Enumerations/BondWorkflow.js";
import offenseStatus from "./Enumerations/OffenseStatus";
import receiptType from "./Enumerations/ReceiptType";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import "./InmateInformation";
import { isAttributeTrue } from "./JavaScriptFunctions";
import template from "./NewBondApplication.html";
import "./ViewPowersDialog";

class NewBondApplication extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ["container"]);

        this._pageTools = new PageTools();
        this._additionalPayorInformationRequired = null;
        this._hasCollectReceiptReferenceNumber = null;
        this._detailsObject = null;
        this._courtOptional = null;
        this._courtRequired = null;
        this._totalBondUiDelimiter = null;
        this._hasAgencyCaseNumber = null;
        this._extraditionInformationRequired = null;
        this._courtInformationReadOnly = null;
        this._titleElement = this.shadowRoot.getElementById("title");
        this._inmateInformation = this.shadowRoot.getElementById("inmate-information");
        this._editPropertyInfoDialog = this.shadowRoot.getElementById("edit-property-info-dialog");
        this._editPropertyInfoButton = this.shadowRoot.getElementById("edit-property-info-button");
        this._editAttorneyInfoDialog = this.shadowRoot.getElementById("edit-attorney-info-dialog");
        this._editAttorneyInfoButton = this.shadowRoot.getElementById("edit-attorney-info-button");
        this._editCosignerInfoButton = this.shadowRoot.getElementById("edit-cosigner-info-button");
        this._editCosignerInfoDialog = this.shadowRoot.getElementById("edit-cosigner-info-dialog");
        this._editArrestInfoDialog = this.shadowRoot.getElementById("edit-arrest-info-dialog");
        this._editArrestInfoButton = this.shadowRoot.getElementById("edit-arrest-info-button");
        this._editSpecialConditionsButton = this.shadowRoot.getElementById("edit-special-conditions-button");
        this._authorizationButton = this.shadowRoot.getElementById("edit-review-authorization-button");
        this._editReviewAuthorizationDialog = this.shadowRoot.getElementById("edit-review-authorization-dialog");
        this._editSpecialConditionsDialog = this.shadowRoot.getElementById("edit-special-conditions-dialog");
        this._editInstructionsButton = this.shadowRoot.getElementById("edit-instructions-button");
        this._editInstructionsDialog = this.shadowRoot.getElementById("edit-instructions-dialog");
        this._editPowersButton = this.shadowRoot.getElementById("edit-powers-button");
        this._editPowersDialog = this.shadowRoot.getElementById("edit-powers-dialog");
        this._editPRInfoDialog = this.shadowRoot.getElementById("edit-pr-info-dialog");
        this._editPRInfoButton = this.shadowRoot.getElementById("edit-pr-info-button");
        this._editReceiptInfoDialog = this.shadowRoot.getElementById("edit-receipt-info-dialog");
        this._editReceiptInfoButton = this.shadowRoot.getElementById("edit-receipt-info-button");
        this._viewPowersButton = this.shadowRoot.getElementById("view-powers-button");
        this._viewPowersDialog = this.shadowRoot.getElementById("view-powers-dialog");
        this._cancelButton = this.shadowRoot.getElementById("cancel-button");
        this._continueButton = this.shadowRoot.getElementById("submit-button");
        this._table = this.shadowRoot.getElementById("table");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");

        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
        this._continueButtonOnClick = this._continueButtonOnClick.bind(this);
        this._openEditPropertyInfoDialog = this._openEditPropertyInfoDialog.bind(this);
        this._openEditAttorneyInfoDialog = this._openEditAttorneyInfoDialog.bind(this);
        this._openEditCosignerInfoDialog = this._openEditCosignerInfoDialog.bind(this);
        this._openEditArrestInfoDialog = this._openEditArrestInfoDialog.bind(this);
        this._openSpecialConditionsDialog = this._openSpecialConditionsDialog.bind(this);
        this._openAuthorizationDialog = this._openAuthorizationDialog.bind(this);
        this._openEditInstructionsDialog = this._openEditInstructionsDialog.bind(this);
        this._openEditPowersDialog = this._openEditPowersDialog.bind(this);
        this._openEditPRInfoDialog = this._openEditPRInfoDialog.bind(this);
        this._openEditReceiptInfoDialog = this._openEditReceiptInfoDialog.bind(this);
        this._updatePropertyInfo = this._updatePropertyInfo.bind(this);
        this._updateAttorneyInfo = this._updateAttorneyInfo.bind(this);
        this._updateCosignerInfo = this._updateCosignerInfo.bind(this);
        this._updateArrestInfo = this._updateArrestInfo.bind(this);
        this._updateSpecialConditionsInfo = this._updateSpecialConditionsInfo.bind(this);
        this._updateInstructions = this._updateInstructions.bind(this);
        this._updatePRInfo = this._updatePRInfo.bind(this);
        this._updateElectronicPowers = this._updateElectronicPowers.bind(this);
        this._updateManualPowers = this._updateManualPowers.bind(this);
        this._updateReceiptInfo = this._updateReceiptInfo.bind(this);
        this._updateCreditCardInfo = this._updateCreditCardInfo.bind(this);
        this._cancelCreditCardAuthorization = this._cancelCreditCardAuthorization.bind(this);
        this._openViewPowersDialog = this._openViewPowersDialog.bind(this);
        this._enableEditButtons = this._enableEditButtons.bind(this);
        this._disableEditButtons = this._disableEditButtons.bind(this);
        this._checkDisableEditButtons = this._checkDisableEditButtons.bind(this);
        this._saveAuthorizationDialogOnClick = this._saveAuthorizationDialogOnClick.bind(this);
        this._getVisibilityStates = this._getVisibilityStates.bind(this);
    }

    static get observedAttributes() {
        return [
            "data-extradition-information-temp",
            "data-attorney-bar-number-expiration-required"
        ];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;

        if (name === "data-extradition-information-temp") {
            this._editArrestInfoDialog.setAttribute(name, newValue);
        } else if (name === "data-attorney-bar-number-expiration-required") {
            this._editAttorneyInfoDialog.setAttribute(name, newValue);
        }
    }

    get _type() {
        return this.getAttribute("type");
    }

    get _bondTypeDisplayName() {
        return this.getAttribute("display-name");
    }

    get _collectFeePaymentReferenceNumber() {
        return isAttributeTrue(this, "collect-fee-payment-reference-number");
    }

    get _getPayorSignature() {
        return isAttributeTrue(this, "payor-signature-required");
    }

    get _totalConvenienceFees() {
        return parseFloat(this.getAttribute("total-convenience-fee"));
    }

    get _hasSpecialConditions() {
        return isAttributeTrue(this, "has-special-conditions");
    }

    get _hasVerboseSpecialConditions() {
        return isAttributeTrue(this, "has-verbose-special-conditions");
    }

    get _userHasReviewPermission() {
        return isAttributeTrue(this, "user-has-review-permission");
    }

    get _isDemo() {
        return isAttributeTrue(this, "is-demo");
    }

    get _hideAdditionalInmateInformationInputs() {
        return isAttributeTrue(this, "hide-additional-inmate-information-inputs");
    }
    get _optionalParcelNumber() {
        return isAttributeTrue(this, "optional-parcel-number");
    }

    get _isCountyType() {
        const countyTypes = [bondWorkFlow.cash.string, bondWorkFlow.pr.string, bondWorkFlow.attorney.string, bondWorkFlow.property.string];
        return countyTypes.includes(this._type);
    }

    set model(value) {
        this._updateHtml(value);
        let combinedModel = null;
        this._editReceiptInfoDialog.isPayorInformationRequired = value.Bond.IsPayorInfoRequired;
        this._editReceiptInfoDialog.ebcaFee = value.Bond.Ebca;
        this._editReceiptInfoDialog.authorizedPaymentExists = value.ReceiptDetails?.some((x) => { return x.AuthorizedPaymentExists});
        this._extraditionInformationRequired =
            isAttributeTrue(this, "data-extradition-information-temp") &&
            value.CourtDetails.some(x => x.ExtraditionInformationRequired) &&
            !this._editArrestInfoDialog.hideCauseAndWarrantInformation;
        if (this._hasCollectReceiptReferenceNumber && this._isCountyType)
            this._editReceiptInfoButton.removeAttribute("hidden");
        switch (this._type) {
            case bondWorkFlow.companyAttorney.string:
                combinedModel = value.AttorneyDetails.map(attorneyDetails =>
                ({
                    BondApplicationDetailID: attorneyDetails.BondApplicationDetailID,
                    AttorneyDetails: attorneyDetails,
                    CourtDetails: value.CourtDetails.find(x => x.BondApplicationDetailID === attorneyDetails.BondApplicationDetailID),
                }));
                break;
            case bondWorkFlow.attorney.string:
            case bondWorkFlow.cash.string:
            case bondWorkFlow.pr.string:
            case bondWorkFlow.property.string:
                combinedModel = value.CountyDetails.map(countyDetails =>
                    ({
                        BondApplicationDetailID: countyDetails.BondApplicationDetailID,
                        CountyDetails: countyDetails,
                        CourtDetails: value.CourtDetails.find(x => x.BondApplicationDetailID === countyDetails.BondApplicationDetailID),
                        ReceiptDetails: value.ReceiptDetails.find(x => x.BondApplicationDetailID === countyDetails.BondApplicationDetailID)
                    })
                );
                this._isAuthorizationRequired = isAttributeTrue(this, "data-review-authorization-required-temp") && combinedModel.some(x => x.CountyDetails.IsReviewAuthorizationRequired);
                break;
            case bondWorkFlow.ownerSurety.string:
                combinedModel = value.OwnerDetails.map(ownerDetails =>
                    ({
                        BondApplicationDetailID: ownerDetails.BondApplicationDetailID,
                        OwnerDetails: ownerDetails,
                        CourtDetails: value.CourtDetails.find(x => x.BondApplicationDetailID === ownerDetails.BondApplicationDetailID)
                    })
                );
                break;
            case bondWorkFlow.electronicSurety.string:
            case bondWorkFlow.manualSurety.string:
                combinedModel = value.SuretyDetails.map(suretyDetails =>
                    ({
                        BondApplicationDetailID: suretyDetails.BondApplicationDetailID,
                        SuretyDetails: suretyDetails,
                        CourtDetails: value.CourtDetails.find(x => x.BondApplicationDetailID === suretyDetails.BondApplicationDetailID)
                    })
                );
                break;
        }
        this._loadTableData(combinedModel);
    }

    set courts(value) {
        this._editArrestInfoDialog.courts = value;
    }

    set totalBondUiDelimiter(value) {
        this._editArrestInfoDialog.totalBondUiDelimiter = value;
    }

    set collectReceiptReferenceNumber(value) {
        this._hasCollectReceiptReferenceNumber = value;
    }

    set additionalPayorInformationRequired(value) {
        this._additionalPayorInformationRequired = value;
    }

    set allowedCardTypes(value) {
        this._editReceiptInfoDialog.allowedCardTypes = value;
    }

    set stateSubdivision(value) {
        this._editReceiptInfoDialog.stateSubdivision = value;
    }

    set courtOptional(value) {
        this._courtOptional = value;
        this._editArrestInfoDialog.hasCourtRequired = false;
    }

    set courtRequired(value) {
        this._courtRequired = value;
        this._editArrestInfoDialog.hasCourtRequired = value;
    }

    set courtHasAgencyCaseNumber(value) {
        this._hasAgencyCaseNumber = value;
        this._editArrestInfoDialog.hasAgencyCaseNumber = value;
    }

    set courtInformationReadOnly(value) {
        this._courtInformationReadOnly = value;
        this._editArrestInfoDialog.courtInformationReadOnly = value;
    }

    connectedCallback() {
        this._editPRInfoDialog.prBondHasIssuingAuthorityFeatureFlag = isAttributeTrue(this, "pr-bond-has-issuing-authority-feature-flag");
        this._editPRInfoDialog.hideAdditionalInmateInformationInputs = this._hideAdditionalInmateInformationInputs;
        this._editPropertyInfoDialog.optionalParcelNumber = this._optionalParcelNumber;
        this._editSpecialConditionsDialog.hasVerboseSpecialConditions = this._hasVerboseSpecialConditions;
        this._editSpecialConditionsDialog.specialConditions = this._hasSpecialConditions;

        this._titleElement.textContent = `New ${this._bondTypeDisplayName} Bond Application`;
        this._editArrestInfoButton.removeAttribute("hidden");

        switch (this._type) {
            case bondWorkFlow.attorney.string:
                this._editAttorneyInfoButton.removeAttribute("hidden");
                this._showOrHideEditSpecialConditionsButton();
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = false;
                this._detailsObject = "CountyDetails";
                break;
            case bondWorkFlow.cash.string:
                this._editReceiptInfoButton.removeAttribute("hidden");
                this._showOrHideEditSpecialConditionsButton();
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = false;
                this._detailsObject = "CountyDetails";
                break;
            case bondWorkFlow.companyAttorney.string:
                this._editAttorneyInfoButton.removeAttribute("hidden");
                this._editInstructionsButton.removeAttribute("hidden");
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = true;
                this._detailsObject = "AttorneyDetails";
                break;
            case bondWorkFlow.pr.string:
                this._editPRInfoButton.removeAttribute("hidden");
                this._showOrHideEditSpecialConditionsButton();
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = false;
                this._detailsObject = "CountyDetails";
                break;
            case bondWorkFlow.ownerSurety.string:
                this._editCosignerInfoButton.removeAttribute("hidden");
                this._editInstructionsButton.removeAttribute("hidden");
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = true;
                this._detailsObject = "OwnerDetails";
                break;
            case bondWorkFlow.electronicSurety.string:
                this._viewPowersButton.removeAttribute("hidden");
                this._editCosignerInfoButton.removeAttribute("hidden");
                this._editInstructionsButton.removeAttribute("hidden");
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = true;
                this._detailsObject = "SuretyDetails";
                break;
            case bondWorkFlow.manualSurety.string:
                this._editPowersButton.removeAttribute("hidden");
                this._editCosignerInfoButton.removeAttribute("hidden");
                this._editInstructionsButton.removeAttribute("hidden");
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = true;
                this._detailsObject = "SuretyDetails";
                break;
            case bondWorkFlow.property.string:
                this._editPropertyInfoButton.removeAttribute("hidden");
                this._showOrHideEditSpecialConditionsButton();
                this._editArrestInfoDialog.hideCauseAndWarrantInformation = false;
                this._detailsObject = "CountyDetails";
                break;
        }
        this._editArrestInfoDialog.hasCauseNumberRequiredFeatureFlag = isAttributeTrue(this, "cause-number-required-feature-flag");
        this._editArrestInfoDialog.hasWarrantNumberRequiredFeatureFlag = isAttributeTrue(this, "warrant-number-required-feature-flag");
        this._editArrestInfoDialog.hasTotalBondsFeatureFlag = isAttributeTrue(this, "total-bonds-feature-flag");
        this._cancelButton.addEventListener("click", this._cancelButtonOnClick);
        this._continueButton.addEventListener("click", this._continueButtonOnClick);
        this._editAttorneyInfoButton.addEventListener("click", this._openEditAttorneyInfoDialog);
        this._editCosignerInfoButton.addEventListener("click", this._openEditCosignerInfoDialog);
        this._editArrestInfoButton.addEventListener("click", this._openEditArrestInfoDialog);
        this._editSpecialConditionsButton.addEventListener("click", this._openSpecialConditionsDialog);
        this._authorizationButton.addEventListener("click", this._openAuthorizationDialog);
        this._editReviewAuthorizationDialog.addEventListener("save", this._saveAuthorizationDialogOnClick)
        this._editInstructionsButton.addEventListener("click", this._openEditInstructionsDialog);
        this._editPowersButton.addEventListener("click", this._openEditPowersDialog);
        this._editPRInfoButton.addEventListener("click", this._openEditPRInfoDialog);
        this._editReceiptInfoButton.addEventListener("click", this._openEditReceiptInfoDialog);
        this._viewPowersButton.addEventListener("click", this._openViewPowersDialog);
        this._editPropertyInfoButton.addEventListener("click", this._openEditPropertyInfoDialog);
        this._editPropertyInfoDialog.addEventListener("save", this._updatePropertyInfo);
        this._editAttorneyInfoDialog.addEventListener("save", this._updateAttorneyInfo);
        this._editCosignerInfoDialog.addEventListener("save", this._updateCosignerInfo);
        this._editArrestInfoDialog.addEventListener("save", this._updateArrestInfo);
        this._editSpecialConditionsDialog.addEventListener("save", this._updateSpecialConditionsInfo);
        this._editInstructionsDialog.addEventListener("save", this._updateInstructions);
        this._editPowersDialog.addEventListener("save", this._updateManualPowers);
        this._editPRInfoDialog.addEventListener("save", this._updatePRInfo);
        this._editReceiptInfoDialog.addEventListener("save", this._updateReceiptInfo);
        this._editReceiptInfoDialog.addEventListener("credit-card-action", this._updateCreditCardInfo);
        this._editReceiptInfoDialog.addEventListener("cancel-existing-authorized-payment-event", this._cancelCreditCardAuthorization);
        this._viewPowersDialog.addEventListener("save", this._updateElectronicPowers);
        this._editPRInfoButton.textContent = `Edit ${this._bondTypeDisplayName} Information`;
        this._inmateInformation.userIsCounty = isAttributeTrue(this, "user-is-county");
    }

    disconnectedCallback() {
        this._cancelButton.removeEventListener("click", this._cancelButtonOnClick);
        this._continueButton.removeEventListener("click", this._continueButtonOnClick);
        this._editAttorneyInfoButton.removeEventListener("click", this._openEditAttorneyInfoDialog);
        this._editCosignerInfoButton.removeEventListener("click", this._openEditCosignerInfoDialog);
        this._editArrestInfoButton.removeEventListener("click", this._openEditArrestInfoDialog);
        this._editSpecialConditionsButton.removeEventListener("click", this._openSpecialConditionsDialog);
        this._editInstructionsButton.removeEventListener("click", this._openEditInstructionsDialog);
        this._editPowersButton.removeEventListener("click", this._openEditPowersDialog);
        this._editPRInfoButton.removeEventListener("click", this._openEditPRInfoDialog);
        this._editReceiptInfoButton.removeEventListener("click", this._openEditReceiptInfoDialog);
        this._viewPowersButton.removeEventListener("click", this._openViewPowersDialog);
        this._editPropertyInfoButton.removeEventListener("click", this._openEditPropertyInfoDialog);
        this._editPropertyInfoDialog.removeEventListener("save", this._updatePropertyInfo);
        this._editAttorneyInfoDialog.removeEventListener("save", this._updateAttorneyInfo);
        this._editCosignerInfoDialog.removeEventListener("save", this._updateCosignerInfo);
        this._editArrestInfoDialog.removeEventListener("save", this._updateArrestInfo);
        this._editSpecialConditionsDialog.removeEventListener("save", this._updateSpecialConditionsInfo);
        this._editInstructionsDialog.removeEventListener("save", this._updateInstructions);
        this._editPowersDialog.removeEventListener("save", this._updateManualPowers);
        this._editPRInfoDialog.removeEventListener("save", this._updatePRInfo);
        this._editReceiptInfoDialog.removeEventListener("save", this._updateReceiptInfo);
        this._editReceiptInfoDialog.removeEventListener("credit-card-action", this._updateCreditCardInfo);
        this._editReceiptInfoDialog.removeEventListener("cancel-existing-authorized-payment-event", this._cancelCreditCardAuthorization);
        this._viewPowersDialog.removeEventListener("save", this._updateElectronicPowers);
        this._authorizationButton.removeEventListener("click", this._openAuthorizationDialog);
        this._editReviewAuthorizationDialog.removeEventListener("save", this._saveAuthorizationDialogOnClick);
    }

    _cancelButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const location = this._pageTools.getLocation();
        location.href = `/Application/CancelApplication/${this._bondApplicationId}`;
    }

    _continueButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const location = this._pageTools.getLocation();
        location.href = `/Application/ReviewApplication/${this._bondApplicationId}`;
    }

    _loadTableData(model) {
        const isCosignerInformationProvided = cosignerData =>
            this._isOptionalInformationProvided({
                CosignerFn: cosignerData.CosignerFn,
                CosignerMi: cosignerData.CosignerMi,
                CosignerLn: cosignerData.CosignerLn,
                CosignerAddress: cosignerData.CosignerAddress,
                CosignerCity: cosignerData.CosignerCity,
                CosignerState: cosignerData.CosignerState,
                CosignerZip: cosignerData.CosignerZip,
                CosignerPhone: cosignerData.CosignerPhone,
                CosignerAmount: cosignerData.CosignerAmount
            });

        const table = $(this._table);
        const columns = this._compileColumns();

        model.forEach(item => {
            const arrestInfoStatusObject = {
                courtNameField: item.CourtDetails.CourtName,
                agencyCaseNumberField: item.CourtDetails.AgencyCaseNumber,
                causeWarrantField: item.CourtDetails.CauseNum || item.CourtDetails.WarrantNum,
                courtRequired: this._courtRequired,
                courtOptional: this._courtOptional,
                totalBondUiDelimiter: this._totalBondUiDelimiter,
                agencyCaseNumberRequired: this._hasAgencyCaseNumber,
                extraditionInfoRequired: this._extraditionInformationRequired,
                extraditionStateField: item.CourtDetails.ExtraditionState
            }

            const visibilityStateObject = this._getVisibilityStates();
            item.ArrestInfo = getArrestInfoStatus(arrestInfoStatusObject, visibilityStateObject);

            let infoObject = this._getInfoObject(item);

            switch (this._type) {
                case bondWorkFlow.attorney.string:
                    item.AttorneyInfo = infoObject.attorneyInfo;
                    break;
                case bondWorkFlow.companyAttorney.string:
                    item.Instructions = infoObject.attorneyInstructions;
                    item.AttorneyInfo = infoObject.attorneyInfo;
                    break;
                case bondWorkFlow.cash.string:
                    item.ReceiptInfo = infoObject.receiptInfo;
                    break;
                case bondWorkFlow.pr.string:
                    item.PRInfo = infoObject.prInfo;
                    break;
                case bondWorkFlow.property.string:
                    item.PropertyInfo = infoObject.propertyInfo;
                    break;
                case bondWorkFlow.ownerSurety.string:
                    item.CosignerInfo = isCosignerInformationProvided(item.OwnerDetails) ? offenseStatus.completed.string : offenseStatus.optional.string;
                    item.Instructions = infoObject.ownerInstructions;
                    break;
                case bondWorkFlow.electronicSurety.string:
                case bondWorkFlow.manualSurety.string:
                    item.PowersInfo = infoObject.powersInfo;
                    item.CosignerInfo = isCosignerInformationProvided(item.SuretyDetails) ? offenseStatus.completed.string : offenseStatus.optional.string;
                    item.Instructions = infoObject.powersInstructions;
                    break;
            }

            if (this._type !== bondWorkFlow.cash.string && this._isCountyType && this._hasCollectReceiptReferenceNumber)
                item.ReceiptInfo = infoObject.receiptInfo;

            if (this._isCountyType && (this._hasSpecialConditions || this._hasVerboseSpecialConditions))
                item.SpecialConditionsInfo = getSpecialConditionsInfo(item.CountyDetails.SpecialConditions, this._hasSpecialConditions, this._hasVerboseSpecialConditions);

            if (this._userHasReviewPermission && this._isAuthorizationRequired)
                item.AuthorizationInfo = getAuthorizedByInfo(item.CountyDetails.ReviewAuthorizedBy);
        });

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered table-hover",
            clickToSelect: true,
            uniqueId: "BondApplicationDetailID",
            onCheck: this._enableEditButtons,
            onCheckAll: this._enableEditButtons,
            onUncheck: this._checkDisableEditButtons,
            onUncheckAll: this._disableEditButtons
        });
        table.bootstrapTable("checkAll");
        this._updateButtonStates();
    }

    _getInfoObject(item) {
        let receiptInfoField = item.CountyDetails?.CashReceiptNumber;
        if (this._hasCollectReceiptReferenceNumber && this._isCountyType)
            receiptInfoField = item.ReceiptDetails?.CashReceiptNumber;

        const attorneyInfoField = this._getAttorneyInfoObject(item);

        const zeroSurchargeFees = item.ReceiptDetails?.CountyNonSurchargeFees === 0;
        const checkReceiptDetails = receiptInfoField ? offenseStatus.completed.string : offenseStatus.required.string;
        const receiptInfo = zeroSurchargeFees ? offenseStatus.notApplicable.string : checkReceiptDetails;

        return {
            propertyInfo: item.CountyDetails?.PropertyOwnerNames ? offenseStatus.completed.string : offenseStatus.required.string,
            attorneyInfo: attorneyInfoField,
            receiptInfo: this._hasCollectReceiptReferenceNumber ? receiptInfo : checkReceiptDetails,
            prInfo: this._hideAdditionalInmateInformationInputs || item.CountyDetails?.InmatePlaceOfBirth ? offenseStatus.completed.string : offenseStatus.required.string,
            ownerInstructions: item.OwnerDetails?.Instructions ? offenseStatus.completed.string : offenseStatus.optional.string,
            powersInstructions: item.SuretyDetails?.Instructions ? offenseStatus.completed.string : offenseStatus.optional.string,
            powersInfo: item.SuretyDetails?.POANumber ? offenseStatus.completed.string : offenseStatus.required.string,
            attorneyInstructions: item.AttorneyDetails?.Instructions ? offenseStatus.completed.string : offenseStatus.optional.string,
        };
    }

    _getAttorneyInfoObject(item) {
        const isCompanyAttorneyType = this._type === bondWorkFlow.companyAttorney.string;
        const isCompanyAttorney = isCompanyAttorneyType ? item.AttorneyDetails?.AttorneyName : item.CountyDetails?.AttorneyName;
        return isCompanyAttorney ? offenseStatus.completed.string : offenseStatus.required.string;
    }

    _compileColumns() {
        const columns = [
            {
                checkbox: true
            },
            createColumn("Degree", `${this._detailsObject}.OffenseDegree`),
            createColumn("Offense", `${this._detailsObject}.OffenseDesc`),
            createColumn("Bond Amount", `${this._detailsObject}.BondAmt`, undefined, dollarFieldFormatter)
        ];

        const hideCourtInformation = !this._courtOptional && !this._courtRequired;
        let arrestInfoColumnTitle = hideCourtInformation ? "Arrest Information" : "Arrest / Court Information";
        switch (this._type) {
            case bondWorkFlow.attorney.string:
            case bondWorkFlow.cash.string:
            case bondWorkFlow.pr.string:
            case bondWorkFlow.property.string:
                break;
            case bondWorkFlow.ownerSurety.string:
            case bondWorkFlow.electronicSurety.string:
            case bondWorkFlow.manualSurety.string:
            case bondWorkFlow.companyAttorney.string:
                arrestInfoColumnTitle = "Court Information";
                break;
        }

        this._pushColumns(columns, hideCourtInformation, arrestInfoColumnTitle);
        return columns;
    }

    _pushColumns(columns, hideCourtInformation, arrestInfoColumnTitle) {
        const propertyInfoColumn = createColumn("Property Information", "PropertyInfo", undefined, fieldFormatter);
        const attorneyInfoColumn = createColumn("Attorney Information", "AttorneyInfo", undefined, fieldFormatter);
        const arrestInfoColumn = createColumn(arrestInfoColumnTitle, "ArrestInfo", undefined, fieldFormatter);
        const specialConditionsColumn = createColumn("Special Conditions", "SpecialConditionsInfo", undefined, fieldFormatter);
        const prInfoColumn = createColumn(`${this._bondTypeDisplayName} Information`, "PRInfo", undefined, fieldFormatter);
        const paymentInfoColumn = createColumn("Payment Information", "ReceiptInfo", undefined, fieldFormatter);
        const cosignerInfoColumn = createColumn("Cosigner Information", "CosignerInfo", undefined, fieldFormatter);
        const instructionsColumn = createColumn("Instructions", "Instructions", undefined, fieldFormatter);
        const powersInfoColumn = createColumn("Powers", "PowersInfo", undefined, fieldFormatter);
        const authorizedByColumn = createColumn("Authorization", "AuthorizationInfo", false, fieldFormatter);

        const conditionallyPushArrestInfoColumn = () => {
            if (hideCourtInformation)
                this._editArrestInfoButton.toggleAttribute("hidden", true);
            else
                columns.push(arrestInfoColumn);
        }

        const conditionallyPushSpecialConditionsInfoColumn = () => {
            if (this._isCountyType && (this._hasSpecialConditions || this._hasVerboseSpecialConditions))
                columns.push(specialConditionsColumn);
        }

        const conditionallyPushAuthorizedByColumn = () => {
            if (this._userHasReviewPermission && this._isAuthorizationRequired)
                columns.push(authorizedByColumn);
        }

        switch (this._type) {
            case bondWorkFlow.attorney.string:
                columns.push(attorneyInfoColumn, arrestInfoColumn);
                break;
            case bondWorkFlow.companyAttorney.string:
                columns.push(attorneyInfoColumn);
                conditionallyPushArrestInfoColumn();
                columns.push(instructionsColumn);
                break;
            case bondWorkFlow.cash.string:
                columns.push(paymentInfoColumn, arrestInfoColumn);
                break;
            case bondWorkFlow.pr.string:
                columns.push(prInfoColumn, arrestInfoColumn);
                break;
            case bondWorkFlow.property.string:
                columns.push(propertyInfoColumn, arrestInfoColumn);
                break;
            case bondWorkFlow.ownerSurety.string:
                conditionallyPushArrestInfoColumn();
                columns.push(cosignerInfoColumn);
                columns.push(instructionsColumn);
                break;
            case bondWorkFlow.electronicSurety.string:
            case bondWorkFlow.manualSurety.string:
                columns.push(powersInfoColumn);
                conditionallyPushArrestInfoColumn();
                columns.push(cosignerInfoColumn);
                columns.push(instructionsColumn);
                break;
        }

        if (this._type !== bondWorkFlow.cash.string && this._isCountyType && this._hasCollectReceiptReferenceNumber)
            columns.splice(5, 0, paymentInfoColumn);

        conditionallyPushSpecialConditionsInfoColumn();
        conditionallyPushAuthorizedByColumn();
    }

    _updateHtml(model) {
        this._showOrHideAuthorizationButton(model.Bond.IsReviewAuthorizationRequired);
        this._bondApplicationId = model.Bond.BondApplicationID;
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.Bond.BondApplicationID);

        if((this._type === bondWorkFlow.cash.string || this._hasCollectReceiptReferenceNumber) && model.CountyDetails && model.CountyDetails.length > 0)
            this._editReceiptInfoDialog.setAttribute("data-additional-payor-information-required", this._additionalPayorInformationRequired);

        if (!this._courtOptional && !this._courtRequired) {
            this._editArrestInfoButton.textContent = "Edit Arrest Information";
            this._editArrestInfoDialog.hideCourtInformation = true;
        } else {
            this._editArrestInfoDialog.hideCourtInformation = false;
            switch (this._type) {
                case bondWorkFlow.attorney.string:
                case bondWorkFlow.cash.string:
                case bondWorkFlow.pr.string:
                case bondWorkFlow.property.string:
                    this._editArrestInfoButton.textContent = "Edit Arrest / Court Information";
                    break;
                case bondWorkFlow.ownerSurety.string:
                case bondWorkFlow.electronicSurety.string:
                case bondWorkFlow.manualSurety.string:
                case bondWorkFlow.companyAttorney.string:
                    this._editArrestInfoButton.textContent = "Edit Court Information";
                    break;
            }
        }
    }

    _openEditPropertyInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        const selectedChargesIds = selectedCharges.map(x => x.BondApplicationDetailID); 
        const allCharges = $(this._table).bootstrapTable("getData");
        const unselectedCharges = allCharges.filter(x => !selectedChargesIds.includes(x.BondApplicationDetailID));
        this._editPropertyInfoDialog.openModal(selectedCharges.map(x => x.CountyDetails), unselectedCharges.map(x => x.CountyDetails));
    }

    _openEditAttorneyInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editAttorneyInfoDialog.workflow = this._type;
        if (this._type == bondWorkFlow.companyAttorney.string) 
            this._editAttorneyInfoDialog.openModal(selectedCharges.map(x => x.AttorneyDetails));
        else
            this._editAttorneyInfoDialog.openModal(selectedCharges.map(x => x.CountyDetails));
    }

    _openEditCosignerInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editCosignerInfoDialog.openModal(selectedCharges.map(x => x[this._detailsObject]));
    }

    _openEditArrestInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editArrestInfoDialog.openModal(selectedCharges.map(x => x.CourtDetails));
    }

    _openSpecialConditionsDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editSpecialConditionsDialog.openModal(selectedCharges.map(x => x.CountyDetails), "Application");
    }

    _openAuthorizationDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editReviewAuthorizationDialog.openModal(selectedCharges.map(x => x.CountyDetails), "Application");
    }

    _openEditInstructionsDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editInstructionsDialog.openModal(selectedCharges.map(x => x[this._detailsObject]), isAttributeTrue(this, "user-is-company-admin"));
    }

    _openEditPowersDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editPowersDialog.openModal(selectedCharges.map(x => x.SuretyDetails));
    }

    _openEditPRInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        const prDialogDisplayName = {
            title: `${this._bondTypeDisplayName} Information`,
            button: `Save ${this._bondTypeDisplayName} Information`
        };
        this._editPRInfoDialog.openModal(selectedCharges.map(x => x.CountyDetails), prDialogDisplayName);
    }

    _openEditReceiptInfoDialog() {
        this._editReceiptInfoDialog.setAttribute("collect-fee-payment-reference-number", this._collectFeePaymentReferenceNumber);
        this._editReceiptInfoDialog.setAttribute("get-payor-signature", this._getPayorSignature);
        this._editReceiptInfoDialog.setAttribute("is-demo", this._isDemo);
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._editReceiptInfoDialog.openModal(selectedCharges.map(x => x.ReceiptDetails), this._totalConvenienceFees);
    }

    _openViewPowersDialog() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        this._viewPowersDialog.openModal(selectedCharges.map(x => x.SuretyDetails));
    }

    _updatePropertyInfo(event) {
        const data = event.detail;
        const $table = $(this._table);
        data.detailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.PropertyInfo = offenseStatus.completed.string;
            row.CountyDetails.PropertyOwnerNames = data.data.ownerNames;
            row.CountyDetails.ParcelNumber = data.data.parcelNumber;
            row.CountyDetails.AvailableCollateral = data.data.availableCollateral;
            row.CountyDetails.Address = data.data.propertyAddress;
            row.CountyDetails.City = data.data.propertyCity;
            row.CountyDetails.State = data.data.propertyState;
            row.CountyDetails.Zip = data.data.propertyZip;

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });
        this._updateButtonStates();
    }

    _updateAttorneyInfo(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.detailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.AttorneyInfo = offenseStatus.completed.string;
            if (this._type == bondWorkFlow.companyAttorney.string) {
                this._updateInmateAndEmployerForCompanyAttorneyRowWithData(row, data.model);
            } else {
                row.CountyDetails.AttorneyName = data.model.attorneyName;
                row.CountyDetails.BarNumber = data.model.barNumber;
                row.CountyDetails.BarNumberExpiration = data.model.barNumberExpiration;
                this._updateInmateAndEmployerRowWithData(row, data.model);
            }

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });
        this._updateButtonStates();
    }

    _updateCosignerInfo(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.selected.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.CosignerInfo = this._isOptionalInformationProvided(data.cosignerModel) ? offenseStatus.completed.string : offenseStatus.optional.string;
            row[this._detailsObject].CosignerFn = data.cosignerModel.FirstName;
            row[this._detailsObject].CosignerMi = data.cosignerModel.MiddleInitial;
            row[this._detailsObject].CosignerLn = data.cosignerModel.LastName;
            row[this._detailsObject].CosignerAddress = data.cosignerModel.Address;
            row[this._detailsObject].CosignerCity = data.cosignerModel.City;
            row[this._detailsObject].CosignerState = data.cosignerModel.State;
            row[this._detailsObject].CosignerZip = data.cosignerModel.Zip;
            row[this._detailsObject].CosignerPhone = data.cosignerModel.Phone;
            row[this._detailsObject].CosignerAmount = data.cosignerModel.Amount;

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _updateArrestInfo(event) {
        const visibilityStateObject = this._getVisibilityStates();
        const courtOptions = {
            required: this._courtRequired,
            optional: this._courtOptional,
            readOnly: this._courtInformationReadOnly
        };
        updateArrestInfo(
            this._table,
            event,
            courtOptions,
            this._hasAgencyCaseNumber,
            this._extraditionInformationRequired,
            visibilityStateObject
        );
        this._updateButtonStates();
    }

    _updateSpecialConditionsInfo(event) {
        updateSpecialConditionsInfo(this._table, "CountyDetails", event, this._hasSpecialConditions, this._hasVerboseSpecialConditions);
        this._updateButtonStates();
    }

    _updateInstructions(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.bondApplicationDetailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.Instructions = this._isOptionalInformationProvided(data, "bondApplicationDetailIds") ? offenseStatus.completed.string : offenseStatus.optional.string;
            //https://stackoverflow.com/a/34064434/7412948
            const doc = new DOMParser().parseFromString(data.instructions, "text/html");
            row[this._detailsObject].Instructions = doc.documentElement.textContent;

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _updatePRInfo(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.selected.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.PRInfo = offenseStatus.completed.string;
            row.CountyDetails.IssuingAuthority = data.issuingAuthority;
            this._updateInmateAndEmployerRowWithData(row, data);

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });
        this._updateButtonStates();
    }

    _updateCreditCardInfo(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.detailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            const receipt = data.data.receipt;
            const receiptType = data.data.receiptType;
            row.ReceiptDetails.CashReceiptNumber = receipt;
            row.ReceiptDetails.ReceiptType = receiptType;

            if (!receipt || !receiptType)
                row.ReceiptInfo = offenseStatus.required.string;
            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _cancelCreditCardAuthorization() {
        const $table = $(this._table);
        const creditCard = receiptType.creditCard.value;
        const tableData = $table.bootstrapTable("getData");
        tableData.forEach(row => {
            if (row.ReceiptDetails.ReceiptType === creditCard) {
                row.ReceiptDetails.CashReceiptNumber = "";
                row.ReceiptDetails.ReceiptType = "";
                row.ReceiptInfo = offenseStatus.required.string;
                $table.bootstrapTable("updateByUniqueId", {
                    id: row.BondApplicationDetailID,
                    row: row
                });
            }
        });

        this._updateButtonStates();
    }

    _updateReceiptInfo(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.detailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.ReceiptInfo = offenseStatus.completed.string;
            row.ReceiptDetails.CashReceiptNumber = data.data.receipt;
            row.ReceiptDetails.CashAmt = data.data.bondAmount;
            row.ReceiptDetails.PayorName = data.data.payorName;
            row.ReceiptDetails.PayorAddress = data.data.payorAddress;
            row.ReceiptDetails.PayorCity = data.data.payorCity;
            row.ReceiptDetails.PayorState = data.data.payorState;
            row.ReceiptDetails.PayorZip = data.data.payorZip;
            row.ReceiptDetails.PayorPhone = data.data.payorPhone;
            row.ReceiptDetails.PayorEmployer = data.data.payorEmployer;
            row.ReceiptDetails.PayorEmployerAddress = data.data.payorEmployerAddress;
            row.ReceiptDetails.PayorEmployerPhone = data.data.payorEmployerPhone;
            row.ReceiptDetails.ReceiptType = data.data.receiptType;
            row.ReceiptDetails.OtherReceiptTypeInfo = data.data.otherReceiptTypeInfo;
            row.ReceiptDetails.PayorSignature = data.data.payorSignature;

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _updateElectronicPowers(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.forEach(item => {
            const row = $table.bootstrapTable("getRowByUniqueId", item.BondApplicationDetailId);
            row.PowersInfo = item.PoaNumber ? offenseStatus.completed.string : offenseStatus.required.string;
            row.SuretyDetails.POANumber = item.PoaNumber;
            row.SuretyDetails.POALimit = item.PoaLimit;

            $table.bootstrapTable("updateByUniqueId", {
                id: item.BondApplicationDetailId,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _updateManualPowers(event) {
        const data = event.detail;

        const $table = $(this._table);
        data.bondApplicationDetailIds.forEach(id => {
            const row = $table.bootstrapTable("getRowByUniqueId", id);
            row.PowersInfo = data.poaNumber ? offenseStatus.completed.string : offenseStatus.required.string;
            row.SuretyDetails.POANumber = data.poaNumber;
            row.SuretyDetails.POALimit = data.poaLimit;

            $table.bootstrapTable("updateByUniqueId", {
                id: id,
                row: row
            });
        });

        this._updateButtonStates();
    }

    _enableEditButtons() {
        this.shadowRoot.querySelectorAll("#edit-button-container button").forEach(element => {
            element.toggleAttribute("disabled", false);
        });

        this._handleNotApplicablePaymentInfoSelections();
    }

    _disableEditButtons() {
        this.shadowRoot.querySelectorAll("#edit-button-container button").forEach(element => {
            element.toggleAttribute("disabled", true);
        });
    }

    _checkDisableEditButtons() {
        const $table = $(this._table);
        if ($table.bootstrapTable("getSelections").length === 0) {
            this._disableEditButtons();
        }

        this._handleNotApplicablePaymentInfoSelections();
    }

    _handleNotApplicablePaymentInfoSelections() {
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        if (this._hasCollectReceiptReferenceNumber && selectedCharges.every(item => item["ReceiptInfo"] === offenseStatus.notApplicable.string))
            this._editReceiptInfoButton.disabled = true;
    }

    _updateButtonStates() {
        const $table = $(this._table);
        const data = $table.bootstrapTable("getData", {
            includeHiddenRows: true,
            unfiltered: true,
            formatted: false
        });

        updateInfoButtonState(this._editPropertyInfoButton, "PropertyInfo");
        updateInfoButtonState(this._editAttorneyInfoButton, "AttorneyInfo");
        updateInfoButtonState(this._editArrestInfoButton, "ArrestInfo");
        updateInfoButtonState(this._editSpecialConditionsButton, "SpecialConditionsInfo");
        updateInfoButtonState(this._editPRInfoButton, "PRInfo");
        updateInfoButtonState(this._editReceiptInfoButton, "ReceiptInfo");
        updateInfoButtonState(this._editPowersButton, "PowersInfo");
        updateInfoButtonState(this._viewPowersButton, "PowersInfo");
        updateInfoButtonState(this._editCosignerInfoButton, "CosignerInfo");
        updateInfoButtonState(this._editInstructionsButton, "Instructions");
        updateInfoButtonState(this._authorizationButton, "AuthorizationInfo");

        const shouldBeDisabledButton = data.some(item =>
            item.AttorneyInfo === offenseStatus.required.string ||
            item.ArrestInfo === offenseStatus.required.string ||
            (this._hasSpecialConditions && item.SpecialConditionsInfo === offenseStatus.required.string) ||
            (this._isAuthorizationRequired && item.AuthorizationInfo === offenseStatus.required.string) ||
            item.PRInfo === offenseStatus.required.string ||
            item.ReceiptInfo === offenseStatus.required.string ||
            item.PowersInfo === offenseStatus.required.string ||
            item.PropertyInfo === offenseStatus.required.string
            );

        this._continueButton.toggleAttribute("disabled", shouldBeDisabledButton);


        if (this._type == bondWorkFlow.cash.string && this._hasCollectReceiptReferenceNumber) {
            this._alertMessage.makeMessageAnError();
            this._alertMessage.innerHTML = "The <em>CollectReceiptReferenceNumber</em> option is enabled but not allowed for a Cash Bond Type. Please contact <contact-link>eBONDS support</contact-link> to correct this.";
            this._pageTools.toggleTriggers(this.shadowRoot, true);
        }

        function updateInfoButtonState(button, propertyNameToCheck) {
            const shouldBeSolidButton = data.some(item => item[propertyNameToCheck] === offenseStatus.required.string);
            button.classList.toggle("btn-primary", shouldBeSolidButton);
            button.classList.toggle("btn-outline-primary", !shouldBeSolidButton);
        }
    }

    _updateInmateAndEmployerForCompanyAttorneyRowWithData(row, data) {
        row.AttorneyDetails.InmatePlaceOfBirth = data.inmatePlaceOfBirth;
        row.AttorneyDetails.NearestRelativeName = data.nearestRelativeName;
        row.AttorneyDetails.NearestRelativeAddress = data.nearestRelativeAddress;
        row.AttorneyDetails.NearestRelativeCity = data.nearestRelativeCity;
        row.AttorneyDetails.NearestRelativeState = data.nearestRelativeState;
        row.AttorneyDetails.NearestRelativeZip = data.nearestRelativeZip;
        row.AttorneyDetails.NearestRelativePhone = data.nearestRelativePhone;
        row.AttorneyDetails.Employer = data.employer;
        row.AttorneyDetails.EmployerAddress = data.employerAddress;
        row.AttorneyDetails.EmployerCity = data.employerCity;
        row.AttorneyDetails.EmployerState = data.employerState;
        row.AttorneyDetails.EmployerZip = data.employerZip;
        row.AttorneyDetails.EmployerPhone = data.employerPhone;
    }

    _updateInmateAndEmployerRowWithData(row, data) {
        row.CountyDetails.InmatePlaceOfBirth = data.inmatePlaceOfBirth;
        row.CountyDetails.NearestRelativeName = data.nearestRelativeName;
        row.CountyDetails.NearestRelativeAddress = data.nearestRelativeAddress;
        row.CountyDetails.NearestRelativeCity = data.nearestRelativeCity;
        row.CountyDetails.NearestRelativeState = data.nearestRelativeState;
        row.CountyDetails.NearestRelativeZip = data.nearestRelativeZip;
        row.CountyDetails.NearestRelativePhone = data.nearestRelativePhone;
        row.CountyDetails.Employer = data.employer;
        row.CountyDetails.EmployerAddress = data.employerAddress;
        row.CountyDetails.EmployerCity = data.employerCity;
        row.CountyDetails.EmployerState = data.employerState;
        row.CountyDetails.EmployerZip = data.employerZip;
        row.CountyDetails.EmployerPhone = data.employerPhone;
    }
    
    _isOptionalInformationProvided(data, ignoreProperty) {
        return Object.entries(data).some(([key, value]) => (
            key !== ignoreProperty &&
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value !== 0
        ));
    }

    _showOrHideEditSpecialConditionsButton() {
        if (this._hasSpecialConditions || this._hasVerboseSpecialConditions) {
            this._editSpecialConditionsButton.toggleAttribute("hidden", false);
            return;
        }
        this._editSpecialConditionsButton.toggleAttribute("hidden", true);
    }

    _showOrHideAuthorizationButton(isReviewAuthorizationRequired) {
        const canAuthorize = isAttributeTrue(this, "data-review-authorization-required-temp") && isReviewAuthorizationRequired && this._userHasReviewPermission;
        this._authorizationButton.toggleAttribute("hidden", !canAuthorize);
    }

    _saveAuthorizationDialogOnClick(event) {
        updateAuthorizationInfo(this._table, "CountyDetails", event);
        this._updateButtonStates();
    }

    _getVisibilityStates() {
        return {
            isWarrantInfoDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation,
            isCourtInfoDisplayed: !this._editArrestInfoDialog.hideCourtInformation,
            isAgencyCaseNumberDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation && !this._editArrestInfoDialog.hideAgencyCaseNumberInformation,
            isExtraditionStateDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation && this._extraditionInformationRequired
        };
    }
}

customElements.define("new-bond-application", NewBondApplication);