import template from './AmendBondDocumentModal.html';
import bootstrap from "../../scss/bootstrap-custom.scss";
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { nullThrow } from '../TypeScriptFunctions';
import { ModalComponent } from '../ModalComponent.js'
import { ActionButton } from "../ActionButton"
import { FileUploadComponent } from "../FileUploadComponent"
import { clearAlert, showAlert } from "../EditDialogExtensions";
import { FileStatusDetail } from "../../types/Library/Common/CustomEvents/CustomEvents";

export class AmendBondDocumentModal extends HTMLElement
{
    private _modal: ModalComponent;
    private _saveButton: ActionButton;
    private _previewButton: ActionButton;
    private _canceButton: ActionButton;
    private _fileUpload: FileUploadComponent;
    private _bondApplicationDetailId: number | undefined;
    private _routePath: string;
    private _alertElement: HTMLElement;
    private _pageTools: IPageTools;
    constructor()
    {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._pageTools = new PageTools;
        this._modal = <ModalComponent>nullThrow(this.shadowRoot?.getElementById("modal-component"));
        this._saveButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("save-button"));
        this._previewButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("preview-button"));
        this._canceButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("cancel-button"));
        this._fileUpload = <FileUploadComponent>nullThrow(this.shadowRoot?.getElementById("file-upload"));
        this._alertElement = nullThrow(this.shadowRoot?.getElementById("alert-element"));

        this._routePath = "/Reports/AmendBondDocument";

        this.closeModal = this.closeModal.bind(this);
        this._saveUploadDocuments = this._saveUploadDocuments.bind(this);
        this._uploadFilesCallback = this._uploadFilesCallback.bind(this);
        this._saveState = this._saveState.bind(this);

        this._saveButton.addEventListener('click',  () =>  this._saveUploadDocuments());
        this._canceButton.addEventListener('click', this.closeModal);
        this._fileUpload.addEventListener('fileStatusDetail', (event: Event) => this._saveState(event as CustomEvent<FileStatusDetail>));
        this._modal.addEventListener('modalClosed', (event: Event) => this._saveState(event as CustomEvent<FileStatusDetail>));
    }

    openModal(id: number) {
        this._fileUpload.removeAllFiles();
        this._bondApplicationDetailId = id;
        this._modal.openModal("Amend Bond Document", false);
        this._fileUpload.createListOfAcceptedFiles();
        clearAlert(this._alertElement);
        this._fileUpload.clearAlerts();
        this._fileUpload.hideSpinner(true);
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
        this._saveButton.toggleAttribute("disabled", true);
    }

    closeModal() {
        this._modal.closeModal();
        this._fileUpload.removeAllFiles();
    }

    _saveUploadDocuments() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        const filesList = this._fileUpload.saveFiles();
        const applicationDetailId = nullThrow(this._bondApplicationDetailId);
        const formData = new FormData();
        formData.append("Id", applicationDetailId.toString());
        filesList.forEach((file) => {
            formData.append("Files",  file);
        });

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST", 
        this._routePath,
        formData,
        this._uploadFilesCallback);
    }

    _uploadFilesCallback(response: string, success: boolean) {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        if(!success){
            showAlert(this._alertElement, "There was a problem uploading one or more of your documents.");
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            return;
        }
        this.closeModal();
    }
    
    _saveState(event: CustomEvent<FileStatusDetail>) {
        if (event.detail.flag) {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            this._saveButton.toggleAttribute("disabled", false);
        }
        else {
            this._pageTools.toggleTriggers(this.shadowRoot!, true);
            this._saveButton.toggleAttribute("disabled", true);
        }
        this._canceButton.toggleAttribute("disabled", false);
    }

}
customElements.define('amend-bond-document-modal', AmendBondDocumentModal);