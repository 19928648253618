import bootstrap from '../scss/bootstrap-custom.scss';
import kendoCommonBootstrap from '@progress/kendo-ui/css/web/kendo.common-bootstrap.min.css'
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import kendoBootstrap from '@progress/kendo-ui/css/web/kendo.bootstrap.min.css'
import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert } from './EditDialogExtensions';
import template from './EditInstructionsDialog.html';
import './ActionButton';

class EditInstructionsDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, kendoCommonBootstrap, kendoBootstrap, fontawesome]);

        this._pageTools = new PageTools();
        this._firstOptionTextContent = null;
        this._closeButton = this.shadowRoot.getElementById('instructions-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('instructions-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');
        this._inmateInstructionTemplateMessage = this.shadowRoot.getElementById("inmate-instruction-template-message");
        this._inmateInstructionTemplateSelector = this.shadowRoot.getElementById("inmate-instruction-template-selector");
        this._manageButton = this.shadowRoot.getElementById("instructions-dialog-manage-button");
        this._instructionsInput = this.shadowRoot.getElementById("instructions");
        this._instructionsSpinner = this.shadowRoot.getElementById("spinner-container");
        this._manageTooltip = this.shadowRoot.getElementById("manage-tooltip");

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._manageButtonOnClick = this._manageButtonOnClick.bind(this);
        this._getInmateInstructionTemplateNames = this._getInmateInstructionTemplateNames.bind(this);
        this._closeInmateInstructionTemplateSelector = this._closeInmateInstructionTemplateSelector.bind(this);
        this._templateSelectorChange = this._templateSelectorChange.bind(this);
        this._resetTemplateSelectorOptions = this._resetTemplateSelectorOptions.bind(this);

        this._inmateInstructionTemplates = null;
        this._templatesSelectorIsOpen = false;
        this._onGoingRequest = false;
        this._getTemplatesXhrWrapper = new XhrWrapper();
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
        this._inmateInstructionTemplateSelector.addEventListener('change', this._templateSelectorChange);
        this._inmateInstructionTemplateSelector.addEventListener('click', this._getInmateInstructionTemplateNames);
        this._inmateInstructionTemplateSelector.addEventListener('blur', this._closeInmateInstructionTemplateSelector);
        $(this._instructionsInput).kendoEditor();
        this.shadowRoot.querySelector('button[title="Insert image"]').remove();
        this._manageButton.addEventListener('click', this._manageButtonOnClick);
        $(this._manageTooltip).tooltip();
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
        this._manageButton.removeEventListener('click', this._manageButtonOnClick);
        this._inmateInstructionTemplateSelector.removeEventListener('change', this._templateSelectorChange);
        this._inmateInstructionTemplateSelector.removeEventListener('click', this._getInmateInstructionTemplateNames);
        this._inmateInstructionTemplateSelector.removeEventListener('blur', this._closeInmateInstructionTemplateSelector);
    }

    openModal(selectedCharges, userIsCompanyAdmin) {
        this._clearMessage();
        this._selectedCharges = selectedCharges;
        this._setInitialValues();
        this._modalComponent.openModal('Instructions', false);
        if (userIsCompanyAdmin) {
            this._manageButton.removeAttribute('hidden');
        }
    }

    _inmateInstructionTemplatesShowLoading() {
        this._firstOptionTextContent = this._inmateInstructionTemplateSelector.options[0].textContent;
        this._inmateInstructionTemplateSelector.options[0].textContent = 'Loading inmate instruction templates...';
    }

    _inmateInstructionTemplatesHideLoading() {
        this._inmateInstructionTemplateSelector.options[0].textContent = this._firstOptionTextContent;
    }

    _templateSelectorChange(e) {
        this._templatesSelectorIsOpen = false;
        this._inmateInstructionTemplateSelector.toggleAttribute("disabled", true);
        this._instructionsSpinner.toggleAttribute("hidden", false);
        const selectedTemplateName = e.target.options[e.target.selectedIndex].text;
        const index = this._inmateInstructionTemplates.find((x) => x.TemplateName === selectedTemplateName)["TemplateId"];

        if (selectedTemplateName !== "Select One") {
            $(this._instructionsInput).data("kendoEditor").value(this._inmateInstructionTemplates.find((x) => x.TemplateName === selectedTemplateName)["Instructions"]);
            const dummyIndex = this._inmateInstructionTemplates.length + 1;
            e.target.options[dummyIndex].text = selectedTemplateName;
            e.target.selectedIndex = dummyIndex;
        }
        this._getInmateInstructionTemplatesById(index);
    }

    _getInmateInstructionTemplatesById(templateId) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/Application/InmateInstructionTemplateById?templateId=${templateId}`,
            null,
            this._getInmateInstructionTemplateByIdCallback.bind(this));
    }

    _closeInmateInstructionTemplateSelector() {
        this._templatesSelectorIsOpen = false;
        this._getTemplatesXhrWrapper.abort();
        this._onGoingRequest = false;
        this._inmateInstructionTemplateSelector.options[0].textContent = 'Select One';
    }

    _getInmateInstructionTemplateNames() {
        if (this._templatesSelectorIsOpen) {
            this._closeInmateInstructionTemplateSelector();
            return;
        }

        this._templatesSelectorIsOpen = true;
        this._inmateInstructionTemplateSelector.selectedIndex = 0;
        this._inmateInstructionTemplatesShowLoading();
        this._inmateInstructionTemplates = [];
        this._onGoingRequest = true;
        this._setTemplateSelectorOptions();

        this._getTemplatesXhrWrapper.makeRequest("GET",
            '/Application/GetInmateInstructionTemplateNames',
            null,
            this._getInmateInstructionTemplatesCallback.bind(this));
    }

    _getInmateInstructionTemplateByIdCallback(response, isSuccess) {
        this._inmateInstructionTemplateSelector.toggleAttribute("disabled", false);
        if (!isSuccess) {
            this._instructionsSpinner.toggleAttribute("hidden", true);
            this._setMessage("Failed to retrieve inmate instruction template.", true);
            return;
        }
        const responseJson = JSON.parse(response);
        $(this._instructionsInput).data("kendoEditor").value(responseJson.Instructions);
        this._instructionsSpinner.toggleAttribute("hidden", true);
    }

    _getInmateInstructionTemplatesCallback(response, isSuccess) {
         this._onGoingRequest = false;

        if (!isSuccess) {
            this._inmateInstructionTemplatesHideLoading();
            this._inmateInstructionTemplates = [];
            this._setMessage("Failed to retrieve inmate instruction templates.", true);
            return;
        }
        this._inmateInstructionTemplates = JSON.parse(response);
        this._setTemplateSelectorOptions();
        this._inmateInstructionTemplatesHideLoading();
    }

    _setInitialValues() {
        const firstCharge = this._selectedCharges[0];
        let instructionsDifferent = false;

        this._selectedCharges.forEach(charge => {
            instructionsDifferent = instructionsDifferent || charge.Instructions != firstCharge.Instructions;
        });

        $(this._instructionsInput).data("kendoEditor").value(instructionsDifferent ? null : firstCharge.Instructions);

        if (instructionsDifferent) {
            showAlert(this._alert, 'There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _closeDialog() {
        this._clearMessage();
        this._resetTemplateSelectorOptions();
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: '/Application/ProcessInstructions',
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }

    _gatherData() {
        const editor = $(this._instructionsInput).data('kendoEditor');
        const instructions = editor.encodedValue();

        return {
            bondApplicationDetailIds: this._selectedCharges.map(element => element.BondApplicationDetailID),
            instructions: instructions,
        };
    }

    _setMessage(message, isError) {
        this._inmateInstructionTemplateMessage.classList.add(isError ? 'text-danger' : 'text-primary');
        this._inmateInstructionTemplateMessage.innerHTML = !isError ? message : `${message} Please try again, and if the problem persists, <contact-link>contact eBONDS™ Support</contact-link>.`;
    }

    _clearMessage() {
        this._inmateInstructionTemplateMessage.classList.remove("text-danger");
        this._inmateInstructionTemplateMessage.classList.remove("text-primary");
        this._inmateInstructionTemplateMessage.textContent = "";
    }

    _setTemplateSelectorOptions() {
        this._resetTemplateSelectorOptions();
        this._inmateInstructionTemplateSelector.toggleAttribute("disabled", false);
        this._inmateInstructionTemplates.forEach((template) => {
            const option = document.createElement("option");
            const optionText = document.createTextNode(template["TemplateName"]);
            option.appendChild(optionText);
            this._inmateInstructionTemplateSelector.appendChild(option);
        });

        const dummyOption = document.createElement("option");
        const dummyText = document.createTextNode("");
        dummyOption.toggleAttribute("hidden");
        dummyOption.appendChild(dummyText);
        this._inmateInstructionTemplateSelector.appendChild(dummyOption);

        if (this._inmateInstructionTemplates.length === 0 && !this._onGoingRequest) {
            this._setMessage("No inmate instruction templates exist. Contact your company administrator to have them create inmate instruction templates.", false);
            this._inmateInstructionTemplateSelector.toggleAttribute("disabled", true);
        }
    }

    _resetTemplateSelectorOptions() {
        while (this._inmateInstructionTemplateSelector.length > 1)
            this._inmateInstructionTemplateSelector.removeChild(this._inmateInstructionTemplateSelector[1]);
    }

    _manageButtonOnClick() {
        this._pageTools.openLinkInNewTab('/Admin/InmateInstructionTemplates');
    }
}
customElements.define('edit-instructions-dialog', EditInstructionsDialog);