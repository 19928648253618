import bootstrap from '../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import chargeStatus from '../Enumerations/ChargeStatus';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { showAlert } from '../EditDialogExtensions';
import { createColumn, dollarFieldFormatter, getRowChargeStatusCssClass } from '../BootstrapTableExtensions';
import template from './BookOutQueuePendingChargesDialog.html';
import 'bootstrap-table/dist/bootstrap-table';

class BookOutQueuePendingChargesDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome]);
        
        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('pending-charges-dialog-close-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._pendingChargesTable = this.shadowRoot.getElementById('pending-charges-table');

        this._closeDialog = this._closeDialog.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
    }

    openModal(pendingCharges, inmate, hasHold) {
        let message;
        let title = `Do Not Release Inmate ${inmate}`;
        if (pendingCharges.length > 0) {
            this._pendingChargesTable.toggleAttribute('hidden', false);
            this._loadTableData(pendingCharges);
            if (hasHold) {
                message = `The inmate ${inmate} has a hold and has not been bonded out on the following charges.`;
            } else {
                message = `The inmate ${inmate} has not been bonded out on the following charges.`;
            }
        } else {
            this._pendingChargesTable.toggleAttribute('hidden', true);
            message = `The inmate ${inmate} has a hold and should not be released.`;
        }
        showAlert(this._alert, message);
        this._modalComponent.openModal(title, false);
    }

    _loadTableData(model) {
        const pendingChargesTable = $(this._pendingChargesTable);
        const pendingChargesTableColumns = [];
        pendingChargesTableColumns.push(createColumn('Arrest Date', 'ArrestDate'));
        pendingChargesTableColumns.push(createColumn('Offense Code', 'OffenseCode'));
        pendingChargesTableColumns.push(createColumn('Degree', 'OffenseDegree'));
        pendingChargesTableColumns.push(createColumn('Offense', 'OffenseDesc'));
        pendingChargesTableColumns.push(createColumn('Bond Type', 'BondType'));
        pendingChargesTableColumns.push(createColumn('Bond Description', 'BondDesc'));
        pendingChargesTableColumns.push(createColumn('Bond Amount', 'BondAmt', undefined, dollarFieldFormatter));
        pendingChargesTableColumns.push(createColumn('Charge Status', 'ChargeStatus'));

        pendingChargesTable.bootstrapTable({
            columns: pendingChargesTableColumns,
            data: model,
            classes: 'table table-sm table-striped table-bordered',
            rowStyle: this._rowStyleFormatter.bind(this)
        });
    }

    _rowStyleFormatter(row) {
        const status = Object.values(chargeStatus).find(x => x.string === row.ChargeStatus).value;
        return getRowChargeStatusCssClass(status);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }
}
customElements.define('book-out-queue-pending-charges-dialog', BookOutQueuePendingChargesDialog);