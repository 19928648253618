import bootstrap from '../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { createColumn, uploadStatusFormatter } from '../BootstrapTableExtensions';
import template from './BookOutQueueDocumentFailureDialog.html';
import 'bootstrap-table/dist/bootstrap-table';

class BookOutQueueDocumentFailureDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('document-failure-close-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._uploadFailureTable = this.shadowRoot.getElementById('upload-failure-table');

        this._closeDialog = this._closeDialog.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
    }


    /**
     * @typedef {object} BookOutQueueDocumentFailureDialogModel
     * @property {int} BondNumber
     * @property {?string} OffenseDegree
     * @property {?string} OffenseDescription
     * @property {?boolean} PowersDocumentStored
     */

    /**
     * @param {BookOutQueueDocumentFailureDialogModel} model
     */
    openModal(model) {
        this._loadTableData(model);
        this._modalComponent.openModal(`Document Failures`, false);
    }

    _loadTableData(model) {
        const uploadFailureTable = $(this._uploadFailureTable);
        const uploadFailureTableColumns = [];
        const errorMessageText = this._createErrorMessage(model);
        this._setAlertMessage(this._alert, errorMessageText);
        uploadFailureTableColumns.push(createColumn('Bond Number', 'BondNumber'));
        uploadFailureTableColumns.push(createColumn('Offense Degree', 'OffenseDegree'));
        uploadFailureTableColumns.push(createColumn('Offense Description', 'OffenseDescription'));
        uploadFailureTableColumns.push(createColumn('Electronic Powers Document Retrieval', 'PowersDocumentStored', undefined, uploadStatusFormatter));

        uploadFailureTable.bootstrapTable({
            columns: uploadFailureTableColumns,
            data: model,
            classes: 'table table-sm table-striped table-bordered'
        });
    }

    _setAlertMessage(element, text) {
        element.innerHTML = text;
        element.classList.remove('d-none');
    }

    _createErrorMessage(model) {
        let errorMessage = '<ul>';
        if (model.some(x => x.PowersDocumentStored === false)) {
            errorMessage += '<li>There was an issue retrieving one or more electronic power documents. ' +
                'These documents are available by request through the bond agent\'s surety provider.</li>';
        }
        errorMessage += '</ul>';
        return errorMessage;
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }
}
customElements.define('book-out-queue-document-failure-dialog', BookOutQueueDocumentFailureDialog);