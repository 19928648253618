import * as signalR from '@microsoft/signalr';

class BondsSignalRClient {
    constructor() {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/bondsHub")
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect()
            .build();

        this._logout = this._logout.bind(this);

        connection.on("EndSession", this._logout);

        connection.start()
        .catch((err) => {
            return console.error(err.toString());
        });

        connection.onclose((err) => {
            console.error(err.toString());
        });
    }

    _logout() {
        const sessionLogoutInput = document.querySelector("input[name=isSessionLogout]");
        sessionLogoutInput.value = true;
        const logoutForm = document.getElementById('logoutForm');
        logoutForm.submit();
        logoutForm.disabled = true;
    }
}

window.BondsSignalRClient = BondsSignalRClient