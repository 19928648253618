import bootstrap from "../scss/bootstrap-custom.scss";
import "./CardLogo";
import template from "./CardModal.html";
import "./CreditCardFeeComponent";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import { debounce } from "./JavaScriptFunctions";

class CardModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap], ["payment-card-form"]);

        this._isNewCard = false;
        this._allowedCardTypes = null;
        this._restrictCardsFeatureEnabled = null;
        this._pageTools = new PageTools();
        this._modal = this.shadowRoot.getElementById("card-modal-component");
        this._modalComponent = this.shadowRoot.querySelector("modal-component");
        this._closeButton = this.shadowRoot.getElementById("closeModal-button");
        this._actionButton = this.shadowRoot.getElementById("card-action-button");
        this._allowedCardTypesContainer = this.shadowRoot.getElementById("allowed-card-types-container");
        this._allowedCardTypesCardLogoContainer = this.shadowRoot.getElementById("allowed-card-types-card-logo-container");
        this._cardIdElem = this.shadowRoot.getElementById("card-id");
        this._cardholderFirstNameElem = this.shadowRoot.getElementById("cardholder-first-name");
        this._cardholderLastNameElem = this.shadowRoot.getElementById("cardholder-last-name");
        this._cardNumberElem = this.shadowRoot.getElementById("card-number");
        this._expirationMonthElem = this.shadowRoot.getElementById("exp-month");
        this._expirationYearElem = this.shadowRoot.getElementById("exp-year");
        this._billingAddressElem = this.shadowRoot.getElementById("billing-address");
        this._zipcodeElem = this.shadowRoot.getElementById("zipcode");
        this._cardModalAlert = this.shadowRoot.getElementById("cardmodal-alert-view");
        this._formControlContainers = this.shadowRoot.querySelectorAll("#payment-card-form section div");
        this._cardModalAlertDismissButton = this.shadowRoot.querySelector("button[data-dismiss=alert]");
        this._form = this.shadowRoot.getElementById("modal-content");
        this._inputFields = this._form.querySelectorAll("input");
        this._creditCardFeeComponent = this.shadowRoot.getElementById("credit-card-fee-component");
        this._dollarHoldElement = this.shadowRoot.getElementById("verification-message-container");
        this._authorizedPaymentExistsDialog = this.shadowRoot.getElementById("existing-payment-dialog");
        this._existingPaymentMessage =
            "An existing authorized credit card payment exists for one or more selected charges. By pressing Cancel Previous Payment, the existing authorized payment will be canceled.";
        this._convenienceFeeError = this.shadowRoot.getElementById("convenience-fee-error");

        this._isOneTimePayment = false;
        this._checkAuthorizedPaymentExists = this._checkAuthorizedPaymentExists.bind(this);
        this._actionButtonOnClick = this._actionButtonOnClick.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._removeAlert = this._removeAlert.bind(this);
        this._getConvenienceFee = this._getConvenienceFee.bind(this);
        this._debouncedGetConvenienceFee = debounce(this._getConvenienceFee, 1000);
    }

    static get observedAttributes() {
        return ["model"];
    }

    set allowedCardTypes(value) {
        this._allowedCardTypes = value;
    }

    set restrictCardsFeatureEnabled(value) {
        this._restrictCardsFeatureEnabled = value;
    }

    set selectedCharges(value) {
        this._selectedCharges = value;
    }

    getTotalFees() {
        return this._creditCardFeeComponent.getTotalFees();
    }

    set authorizedPaymentExists(value) {
        this._authorizedPaymentExists = value;
    }

    set stateSubdivision(value) {
        this._creditCardFeeComponent.stateSubdivision = value;
    }

    attributeChangedCallback(name, oldModel, newModel) {
        const model = JSON.parse(newModel);
        this._cardIdElem.value = model.CardId;
        this._cardholderFirstNameElem.value = model.CardholderFirstName;
        this._cardholderLastNameElem.value = model.CardholderLastName;
        this._cardNumberElem.value = model.CardNumber;
        this._expirationMonthElem.value = model.ExpirationMonth;
        this._expirationYearElem.value = model.ExpirationYear;
        this._billingAddressElem.value = model.BillingAddress;
        this._zipcodeElem.value = model.Zipcode;
    }

    connectedCallback() {
        this._actionButton.addEventListener("click", this._checkAuthorizedPaymentExists);
        this._closeButton.addEventListener("click", this._closeModal);
        this._cardModalAlertDismissButton.addEventListener("click", this._removeAlert);
        this._authorizedPaymentExistsDialog.addEventListener("ok-confirmation", this._actionButtonOnClick);
    }

    disconnectedCallback() {
        this._actionButton.removeEventListener("click", this._actionButtonOnClick);
        this._closeButton.removeEventListener("click", this._closeModal);
        this._cardModalAlertDismissButton.removeEventListener("click", this._removeAlert);
        this._cardNumberElem.removeEventListener("input", this._debouncedGetConvenienceFee);
    }

    set model(value) {
        if (value) {
            if (typeof value === "object")
                value = JSON.stringify(value);
            this.setAttribute("model", value);
        }
        else
            this.removeAttribute("model");
    }

    get model() {
        return JSON.parse(this.getAttribute("model"));
    }

    openAddModal(company) {
        this._resetForm();
        if (this._restrictCardsFeatureEnabled && this._allowedCardTypes) {
            this._allowedCardTypes.forEach(x => {
                const cardLogo = document.createElement("card-logo");
                cardLogo.setAttribute("type", x);
                this._allowedCardTypesCardLogoContainer.appendChild(cardLogo);
            });
            this._allowedCardTypesContainer.toggleAttribute("hidden", false);
        }

        this._cardNumberElem.setAttribute("trigger", null);
        this._actionButton.textContent = "Verify & Add";
        this._isNewCard = true;
        this._cardType = company ? "Company" : "Personal";
        const modalTitle = `New ${company || "Personal"} Card`;
        this._openModal(modalTitle);
    }

    openEditModal(model, company) {
        this._resetForm();
        this.model = model;
        this._cardNumberElem.setAttribute("disabled", null);
        this._actionButton.textContent = "Verify & Save";
        this._isNewCard = false;
        this._cardType = model.CompanyCard ? "Company" : "Personal";
        const modalTitle = `Edit ${model.CompanyCard ? company : "Personal"} Card`;
        this._openModal(modalTitle);
    }

    openModalFromReceipt(cardTypes, countyFee, ebcaFee, detailIds) {
        this._detailIds = detailIds;
        this._dollarHoldElement.toggleAttribute("hidden", true);
        this._resetForm();
        this._creditCardFeeComponent.removeAttribute("hidden");
        this._cardNumberElem.addEventListener("input", this._debouncedGetConvenienceFee);

        if (this._allowedCardTypes)
            cardTypes = this._allowedCardTypes;

        if (this._restrictCardsFeatureEnabled && this._allowedCardTypes || cardTypes) {
            cardTypes.forEach(x => {
                const cardLogo = document.createElement("card-logo");
                cardLogo.setAttribute("type", x);
                this._allowedCardTypesCardLogoContainer.appendChild(cardLogo);
            });
            this._allowedCardTypesContainer.toggleAttribute("hidden", false);
        }
        document.createElement("card-logo");

        this._cardNumberElem.setAttribute("trigger", null);
        this._actionButton.textContent = "Authorize";
        this._isOneTimePayment = true;
        const modalTitle ="New Personal Card";

        this._creditCardFeeComponent.countyFee = countyFee;
        this._creditCardFeeComponent.ebcaFee = ebcaFee;
        this._creditCardFeeComponent.convenienceFee = 0.00;

        this._openModal(modalTitle);
        this._inputFields.forEach(input => {
            input.setAttribute("autocomplete", "off");
        });
    }

    _checkAuthorizedPaymentExists() {
        if (this._authorizedPaymentExists) {
            this._authorizedPaymentExistsDialog.open({
                message: this._existingPaymentMessage,
                response: { "action": "cancel" },
                actionButtonText: "Cancel Previous Payment",
                cancelButtonText: "Go Back",
                force: true
            });
            return;
        }
        this._actionButtonOnClick();
    }

    _actionButtonOnClick() {
        if (!this._form.checkValidity()) {
            this._form.reportValidity();
            return;
        }
        this._triggerState(true);

        if (this._isOneTimePayment) {
            this._setNewCardData();
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest("POST",
                "/Application/AuthorizeJailOriginatedPayment",
                {
                    detailIds: this._detailIds,
                    cardData: this._data
                },
                this._requestCallback.bind(this));
            return;
        }
        this._isNewCard ? this._setAddRequestProperties() : this._setEditRequestProperties();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeJsonRequest("POST",
            this._actionButtonOnClickUrl, JSON.stringify(this._data),
            this._requestCallback.bind(this));
    }

    _closeModal() {
        this._modal.closeModal();
    }

    _openModal(modalTitle) {
        this._modal.openModal(modalTitle, false);
    }

    _requestCallback(response, status) {
        this._triggerState(false);
        this._resetErrors();
        const result = this._pageTools.tryParseJson(response);
        if (!status) {
            if (result.ErrorMessage) {
                this._showAlert(result.ErrorMessage);
            } else {
                this._showAlert(
                    "There was an error processing your request." +
                    " Please try again, and if the problem persists, <contact-link>contact eBONDS™ Support</contact-link>.");
            }
            return;
        }

        if (this._isOneTimePayment) {
            this.dispatchEvent(new CustomEvent(
                "one-time-payment-captured",
                {detail: { receipt: response } }));
        } else {
            this.dispatchEvent(new CustomEvent(this._successEvent, { detail: { model: result } }));
        }
        this._closeModal();
    }

    _resetErrors() {
        this._formControlContainers.forEach(x => {
            x.querySelectorAll("span.errors").forEach(errorLabel => errorLabel.innerText = "");
        });
    }

    _resetForm() {
        this._removeAlert();
        this._resetErrors();
        this.shadowRoot.querySelectorAll(`input[type="text"]`).forEach(elem => elem.value = "");
        this._cardNumberElem.removeAttribute("disabled");
        this._cardNumberElem.removeAttribute("trigger");
        this._allowedCardTypesContainer.toggleAttribute("hidden", true);
        this._pageTools.removeAllChildNodes(this._allowedCardTypesCardLogoContainer);
    }

    _setAddRequestProperties() {
        this._successEvent = "add-card-success";
        this._actionButtonOnClickUrl = `/Payments/Create${this._cardType}Card`;
        this._setNewCardData();
    }

    _setNewCardData() {
        this._data = {
            cardholderFirstName: this._cardholderFirstNameElem.value,
            cardholderLastName: this._cardholderLastNameElem.value,
            cardNumber: this._cardNumberElem.value,
            billingAddress: this._billingAddressElem.value,
            expirationMonth: this._expirationMonthElem.value,
            expirationYear: this._expirationYearElem.value,
            zipCode: this._zipcodeElem.value
        };
    }

    _setEditRequestProperties() {
        this._successEvent = "edit-card-success";
        this._actionButtonOnClickUrl = `/Payments/Update${this._cardType}Card`;
        this._data = {
            cardId: this._cardIdElem.value,
            cardholderFirstName: this._cardholderFirstNameElem.value,
            cardholderLastName: this._cardholderLastNameElem.value,
            billingAddress: this._billingAddressElem.value,
            expirationMonth: this._expirationMonthElem.value,
            expirationYear: this._expirationYearElem.value,
            zipCode: this._zipcodeElem.value
        };
    }

    _triggerState(state) {
        this.shadowRoot.querySelectorAll("[trigger]").forEach(x => x.disabled = state);
    }

    _showAlert(message) {
        this._cardModalAlert.querySelector("span").innerHTML = message;
        this._cardModalAlert.classList.remove("d-none");
    }

    _removeAlert() {
        this._cardModalAlert.classList.add("d-none");
        this._cardModalAlert.querySelector("span").innerHTML = "";
    }

    _getConvenienceFee() {
        const minCardLen = 6;
        const cardNum = this._cardNumberElem.value;
        if (cardNum.length < minCardLen) {
            this._creditCardFeeComponent.convenienceFee = 0.00;
            this._convenienceFeeError.toggleAttribute("hidden", true);
            return;
        }
        if (this.submitXhrWrapper)
            this.submitXhrWrapper.abort();
        const event = new Event("beginFeeRequest");
        this.dispatchEvent(event);
        this.submitXhrWrapper = new XhrWrapper();
        const detailIds = this._selectedCharges.map(d => d.BondApplicationDetailID);
        const detailIdsQueryString = detailIds
            .reduce((accumulator, currentVal) => accumulator + `&detailIds=${currentVal}`, "")
            .substring(1); // Remove the leading &
        // https://stackoverflow.com/a/23592727
        const requestUrl = `/Payments/GetConvenienceFee?${detailIdsQueryString}`;
        this.submitXhrWrapper.makeJsonRequest("POST",
            requestUrl,
            JSON.stringify(cardNum),
            this._getConvFeeRequestCallback.bind(this));
    }

    _getConvFeeRequestCallback(response, status) {
        this._creditCardFeeComponent.convenienceFee = status ? response : 0.00;
        this._convenienceFeeError.toggleAttribute("hidden", status);
    }
}
customElements.define("card-modal", CardModal);