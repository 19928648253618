import bootstrap from '../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './SyncFailureDialog.html';
import '../ActionButton';

class SyncFailureDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._isBookoutPage = null;
        this._isReviewPage = null;

        this._jailId = null;
        this._buttonElementToRetry = null;
        this._pageTools = new PageTools();
        this._inmateFullNameSpan = this.shadowRoot.getElementById('inmate-full-name');
        this._inmateBookingNumberSpan = this.shadowRoot.getElementById('inmate-booking-number');
        this._lastSyncedTimeSpan = this.shadowRoot.getElementById('last-synced-time');
        this._cancelButton = this.shadowRoot.getElementById('sync-failure-dialog-cancel-button');
        this._continueButton = this.shadowRoot.getElementById('sync-failure-dialog-continue-button');
        this._continueButtonDescription = this.shadowRoot.getElementById('continue-button-description');
        this._retryButton = this.shadowRoot.getElementById('sync-failure-dialog-retry-button');
        this._odysseyErrorSpan = this.shadowRoot.getElementById('odyssey-error');
        this._tenantPhoneNumber = this.shadowRoot.getElementById('tenant-phone-number');
        this._contactUsSpan = this.shadowRoot.getElementById('contact-us-shortcut');

        this._modalComponent = this.shadowRoot.querySelector('modal-component');

        this.closeDialog = this.closeDialog.bind(this);
        this._continueButtonOnclick = this._continueButtonOnclick.bind(this);
        this._retryButtonOnclick = this._retryButtonOnclick.bind(this);
        this._openContactUsDialog = this._openContactUsDialog.bind(this);
    }

    set isBookoutPage(value) {
        this._isBookoutPage = value;
    }

    set isReviewPage(value) {
        this._isReviewPage = value;
    }

    connectedCallback() {
        this._cancelButton.addEventListener('click', this.closeDialog);
        this._continueButton.addEventListener('click', this._continueButtonOnclick);
        this._retryButton.addEventListener('click', this._retryButtonOnclick);
        this._contactUsSpan.addEventListener('click', this._openContactUsDialog);
    }

    disconnectedCallback() {
        this._cancelButton.removeEventListener('click', this.closeDialog);
        this._continueButton.removeEventListener('click', this._continueButtonOnclick);
        this._retryButton.removeEventListener('click', this._retryButtonOnclick);
        this._contactUsSpan.removeEventListener('click', this._openContactUsDialog);
    }

    openDialog(initObj) {
        this._jailId = initObj.jailId;
        this._inmateFullNameSpan.textContent = `${initObj.inmateLN}, ${initObj.inmateFN} ${initObj.inmateMI}`.trim();
        this._inmateBookingNumberSpan.textContent = initObj.bookingNumber;
        this._lastSyncedTimeSpan.textContent = initObj.lastSyncedTime;
        this._tenantPhoneNumber.textContent = this._pageTools.formatPhoneNumber(initObj.tenantPhoneNumber);
        this._buttonElementToRetry = initObj.buttonElementToRetry;
        const continueOnSyncFailure = initObj.continueOnSyncFailure;
        const hasSkipPermission = initObj.hasSkipPermission;
        const isOdysseyFailure = initObj.isOdysseyFailure;

        if (this._tenantPhoneNumber.textContent.length > 0) 
            this._tenantPhoneNumber.textContent = " at ".concat(this._tenantPhoneNumber.textContent);
        
        if (hasSkipPermission && continueOnSyncFailure) {
            this._continueButton.toggleAttribute('hidden', false);
            this._continueButtonDescription.toggleAttribute('hidden', false);
            if (this._isBookoutPage) {
                this._continueButtonDescription.innerText =
                    'Clicking the skip button will not load the latest booking information' +
                    ' and will complete the bookout process.';
            } else if (this._isReviewPage) {
                this._continueButtonDescription.innerText =
                    "Clicking the skip button will proceed with the inmate's last loaded booking information.";
            } else {
                this._continueButtonDescription.innerText = 'Clicking the skip button will not load the latest booking information and will begin the bond application creation process with the current booking information.';
            }
           
        } else {
            this._continueButton.toggleAttribute('hidden', true);
            this._continueButtonDescription.toggleAttribute('hidden', true);
        }
        this._odysseyErrorSpan.toggleAttribute('hidden', !(isOdysseyFailure && hasSkipPermission));
        this._modalComponent.openModal('Failed to Update Booking Information', false);
    }

    closeDialog() {
        this._modalComponent.closeModal();
        this._pageTools.toggleTriggers(this.shadowRoot, false);
    }

    _continueButtonOnclick() {
        const event = new CustomEvent('continue', { detail: this._jailId });
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this.dispatchEvent(event);
    }

    _retryButtonOnclick() {
        const event = new CustomEvent('retry', { detail: { buttonElement: this._buttonElementToRetry } });
        this.dispatchEvent(event);
        this.closeDialog();
    }

    _openContactUsDialog() {
        document.getElementById("contact-us-modal").openModal();
    }
}

customElements.define('sync-failure-dialog', SyncFailureDialog)