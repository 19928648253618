import template from './DemoMenu.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from './HTMLElementExtensions';
import './ActionButton';

class DemoMenu extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap, fontawesome]);

        this._menuButton = this.shadowRoot.getElementById("menu-button");
        this._flyoutMenu = this.shadowRoot.getElementById("flyout-menu");
        this._flyoutMenuCloseButton = this.shadowRoot.getElementById("flyout-menu-close-button");
        this._refreshReviewQueueButton = this.shadowRoot.getElementById("refresh-review-queue-button");
        this._refreshBookoutQueueButton = this.shadowRoot.getElementById("refresh-bookout-queue-button");
        this._cancelCompletedBondApplicationsButton = this.shadowRoot.getElementById("cancel-completed-bond-applications-button");
        this._cancelPendingBondsButton = this.shadowRoot.getElementById("cancel-pending-bonds-button");
        this._resetBondsButton = this.shadowRoot.getElementById("reset-bonds-button");
        this._alert = this.shadowRoot.getElementById("demo-alert");
        this._alertTimeout = 5000;
        this._endpoint = null;
        this._pageTools = new PageTools();

        this._openFlyoutMenu = this._openFlyoutMenu.bind(this);
        this._closeFlyoutMenu = this._closeFlyoutMenu.bind(this);
        this._requestRefreshReviewQueueData = this._requestRefreshReviewQueueData.bind(this);
        this._requestRefreshBookoutQueueData = this._requestRefreshBookoutQueueData.bind(this);
        this._requestCancelBondApplications = this._requestCancelBondApplications.bind(this);
        this._requestCancelPendingBonds = this._requestCancelPendingBonds.bind(this);
        this._requestResetBonds = this._requestResetBonds.bind(this);
    }
    get _isSalesTenant() {
        const attr = 'data-is-sales-tenant';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    connectedCallback() {
        this._menuButton.addEventListener('click', this._openFlyoutMenu);
        this._flyoutMenuCloseButton.addEventListener('click', this._closeFlyoutMenu);
        this._refreshReviewQueueButton.addEventListener('click', this._requestRefreshReviewQueueData);
        this._refreshBookoutQueueButton.addEventListener('click', this._requestRefreshBookoutQueueData);
        this._cancelCompletedBondApplicationsButton.addEventListener('click', this._requestCancelBondApplications);
        this._cancelPendingBondsButton.addEventListener('click', this._requestCancelPendingBonds);
        this._resetBondsButton.addEventListener('click', this._requestResetBonds);
    }

    disconnectedCallback() {
        this._menuButton.removeEventListener('click', this._openFlyoutMenu);
        this._flyoutMenuCloseButton.removeEventListener('click', this._closeFlyoutMenu);
        this._refreshReviewQueueButton.removeEventListener('click', this._requestRefreshReviewQueueData);
        this._refreshBookoutQueueButton.removeEventListener('click', this._requestRefreshBookoutQueueData);
        this._cancelCompletedBondApplicationsButton.removeEventListener('click', this._requestCancelBondApplications);
        this._cancelPendingBondsButton.removeEventListener('click', this._requestCancelPendingBonds);
        this._resetBondsButton.removeEventListener('click', this._requestResetBonds);
    }

    _openFlyoutMenu() {
        this._toggleFlyoutMenu(true);
    }

    _closeFlyoutMenu() {
        this._toggleFlyoutMenu(false);
    }

    _toggleFlyoutMenu(open) {
        this._toggleAlert("", "success", true);
        this._refreshReviewQueueButton.toggleAttribute("disabled", !this._isSalesTenant);
        this._refreshBookoutQueueButton.toggleAttribute("disabled", !this._isSalesTenant);
        if (!this._isSalesTenant) {
            this._refreshReviewQueueButton.removeAttribute("trigger");
            this._refreshBookoutQueueButton.removeAttribute("trigger");
        }
        this._flyoutMenu.classList.toggle("flyout-menu-visible", open);
        this._flyoutMenu.classList.toggle("flyout-menu-hidden", !open);
    }

    _requestRefreshReviewQueueData() {
        this._submitRequest("/Demo/RefreshReviewQueueData");
    }

    _requestRefreshBookoutQueueData() {
        this._submitRequest("/Demo/RefreshBookoutQueueData");
    }

    _requestCancelBondApplications() {
        this._submitRequest("/Demo/CancelCompletedBondApplications");
    }

    _requestCancelPendingBonds() {
        this._submitRequest("/Demo/CancelPendingBondApplications");
    }

    _requestResetBonds() {
        this._submitRequest("/Demo/ResetBondApplications");
    }

    _submitRequest(endpoint) {
        this._endpoint = endpoint;
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", this._endpoint, null,  this._refreshRequestCallback.bind(this));
    }

    _refreshRequestCallback(response, status) {
        let message = "";
        switch (this._endpoint) {
            case '/Demo/RefreshReviewQueueData':
                message = (!status) ? "Could not refresh applications. Please try again or contact support." : `Successfully refreshed Review Queue applications`;
                break;
            case '/Demo/RefreshBookoutQueueData':
                message = (!status) ? "Could not refresh applications. Please try again or contact support." : `Successfully refreshed Bookout Queue applications`;
                break;
            case '/Demo/CancelCompletedBondApplications':
                message = (!status) ? "Could not cancel completed applications. Please try again or contact support." : `Successfully cancelled completed applications`;
                break;
            case '/Demo/CancelPendingBondApplications':
                message = (!status) ? "Could not cancel pending applications. Please try again or contact support." : `Successfully cancelled pending applications`;
                break;
            case '/Demo/ResetBondApplications':
                message = (!status) ? "Could not reset demo applications. Please try again or contact support." : `Successfully reset demo applications`;
                break;
        }
       
        const alertState = (!status) ?  "danger" :  "success";
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._toggleAlert(message, alertState, false, this._alertTimeout);
    }

    _toggleAlert(message, state, isHidden, timer = null) {
        this._alert.querySelector('span').textContent = message;
        this._alert.classList.toggle("alert-success", state === "success");
        this._alert.classList.toggle("alert-danger", state === "danger");
        this._alert.classList.toggle("hidden", isHidden);
        if (!this._alert.classList.contains("hidden") && timer) {
            setTimeout(() => {
                this._toggleAlert("", "success", true);
            }, timer);
        }
    }
}
customElements.define('demo-menu', DemoMenu);