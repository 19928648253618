import bootstrap from "../scss/bootstrap-custom.scss";
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from "./FileUploadComponent.html";
import { nullThrow } from "./TypeScriptFunctions"
import { clearAlert, showAlert } from "./EditDialogExtensions";
import { FileStatusDetail } from "../types/Library/Common/CustomEvents/CustomEvents";
import './ActionButton';

export class FileUploadComponent extends HTMLElement
{
    _pageTools: IPageTools;
    _alert: HTMLElement;
    _clearItems: HTMLElement;
    _dropZone: HTMLElement;
    _dropZoneInput: HTMLElement;
    _fileContainer: HTMLElement;
    _pendingFiles: HTMLElement;
    _filesList: File[];
    _spinner: HTMLElement;
    _acceptedList: HTMLElement;
    _acceptedFileTypes: string[];
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap, fontawesome]);
        this._pageTools = new PageTools();
        this._alert = nullThrow(this.shadowRoot?.getElementById("component-alert-view"));
        this._clearItems = nullThrow(this.shadowRoot?.getElementById("clear-all"));
        this._dropZone = nullThrow(this.shadowRoot?.getElementById("drop-zone"));
        this._dropZoneInput = nullThrow(this.shadowRoot?.getElementById("dz-input"));
        this._fileContainer = nullThrow(this.shadowRoot?.getElementById("file-container"));
        this._pendingFiles = nullThrow(this.shadowRoot?.getElementById("pending-files"));
        this._spinner = nullThrow(this.shadowRoot?.getElementById("spinner"));
        this._acceptedList = nullThrow(this.shadowRoot?.getElementById("list-view"));

        this._filesList = [];
        this._acceptedFileTypes = ["application/pdf", "image/gif", "image/jpeg", "image/jpg", "image/png"];

        this._onDragOver = this._onDragOver.bind(this);
        this._onDragEnd = this._onDragEnd.bind(this);
        this._onDrop = this._onDrop.bind(this);
        this._onClick = this._onClick.bind(this);
        this.removeAllFiles = this.removeAllFiles.bind(this);
        this.saveFiles = this.saveFiles.bind(this);
        this.createListOfAcceptedFiles = this.createListOfAcceptedFiles.bind(this);
        this._filterFiles = this._filterFiles.bind(this);
    }

    connectedCallback() {
        this._clearItems.addEventListener("click", this.removeAllFiles);
        this._dropZone.addEventListener("click", this._onClick);
        this._dropZone.addEventListener("dragover", this._onDragOver);
        this._dropZone.addEventListener("dragleave", this._onDragEnd);
        this._dropZone.addEventListener("drop", this._onDrop);
    }

    disconnectedCallback() {
        this._clearItems.removeEventListener("click", this.removeAllFiles);
        this._dropZone.removeEventListener("click", this._onClick);
        this._dropZone.removeEventListener("dragover", this._onDragOver);
        this._dropZone.removeEventListener("dragend", this._onDragEnd);
        this._dropZone.removeEventListener("drop", this._onDrop);
    }

    _onDragOver(event: DragEvent) {
        event.preventDefault();
        this._dropZone.style.borderColor = "#007bff";
    }

    _onDragEnd(event: DragEvent) {
        event.preventDefault();
        this._dropZone.style.borderColor = "#808080";
    }

    _onDrop(event: DragEvent) {
        event.preventDefault();
        this._dropZone.style.borderColor = "#808080";
        if (!event.dataTransfer)
            return;

        const files = Array.from(event.dataTransfer.files);
        
        this.hideSpinner(false);
        files.forEach((element) => {
            this._filterFiles(element);
        })
    }

    createListOfAcceptedFiles() {
        this._dropZoneInput.setAttribute("accept", this._acceptedFileTypes.toString())
        const acceptedFilesMessage = this._acceptedFileTypes.join(", ").replace(/\w*\//g, "").toUpperCase().replace(/\s(?=\S*$)/, " and ");
        this._acceptedList.textContent = `The accepted file types are ${acceptedFilesMessage}.`;
    }

    _filterFiles(file: File) {
        this.hideSpinner(false);
        // https://youtu.be/HrK7RFNDTKA?si=ySJ2l1LDiw1aZ9ih&t=446
        const folderFilterPromise: Promise<void> = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onprogress = (event) => {
                if (event.loaded > 60) {
                    reader.abort();
                    resolve();
                }
            }
            reader.onload = () => { resolve(); }
            reader.onerror = () => { reject(); }
            reader.readAsArrayBuffer(file);
        });

        folderFilterPromise.then((_) => {
            if (!this._acceptedFileTypes.includes(file.type)) {
                const errorMsg = this._acceptedFileTypes.join(", ").replace(/\w*\//g, "").toUpperCase().replace(/\s(?=\S*$)/, " and ");
                const errorFileType = file.type.replace(/^.*?\//, '').toUpperCase();
                showAlert(this._alert, `Selected file type "${errorFileType}" is not accepted. The accepted file types are ${errorMsg}.`);
                this.hideSpinner(true);
                return;
            }

            this._filesList.push(file);
            this._displayFile(file);

            clearAlert(this._alert);
        });

        folderFilterPromise.catch((_) => {
            showAlert(this._alert,
                "This file type is not readable.");
            this.hideSpinner(true);
        });
    }

    _onClick() {
        const input = this._dropZoneInput;
        input.click();
        input.onchange = (event) => {
            let target = event.target as HTMLInputElement;
            let files = Array.from(target.files as FileList);
            files.forEach((file) => {
                this._filterFiles(file);
            });
        };
    }

    hideSpinner(state: boolean) {
        const pTags = this._dropZone.querySelectorAll("p");
        if (state) {
            this._spinner.setAttribute("hidden", "true");
            pTags.forEach(pTag => {
                pTag.removeAttribute("hidden");
            })
        }
        else {
            this._spinner.removeAttribute("hidden");
            pTags.forEach(pTag => {
                pTag.setAttribute("hidden", "true");
            })
        }
    }

    _displayFile(file: File) {
        this._fileContainer.removeAttribute("hidden");
        const maxLength = 80;
        const deleteItem = document.createElement("i");
        deleteItem.className += "fa fa-xmark ml-1 delete-item";
        deleteItem.style.position = "relative";
        deleteItem.style.top = "7px";

        const fileName = document.createElement("span");
        fileName.className += "m-1 pb-1 pl-2 pr-2 rounded-pill file-item";
        fileName.style.backgroundColor = "lightgrey";
        fileName.innerText = file.name;

        if (fileName.innerText.length > maxLength) {
            fileName.innerText = fileName.innerText.substring(0, 70) + "..." + fileName.innerText.substring(fileName.innerText.length - 7);
        }
        fileName.appendChild(deleteItem);
        
        this._pendingFiles.insertBefore(fileName, this._pendingFiles.firstChild);
        if (this._pendingFiles.children.length >= this._filesList.length) {
            this.hideSpinner(true);
            this._fileStatus(true);
        }
        if (fileName.offsetWidth > this._pendingFiles.offsetWidth) {
            this._pendingFiles.style.maxWidth = (fileName.offsetWidth + 25) + "px";
        }
        deleteItem.onclick = () => {
            this._removeFile(fileName, file);
        }; 
    }

    _removeFile(listItem: HTMLElement, file: File) {
        this._pendingFiles.removeChild(listItem);
        if (this._pendingFiles.children.length === 0) {
            this._fileContainer.setAttribute("hidden", "true");
            this._fileStatus(false);
        }

        let index = this._filesList.indexOf(file);
        if (index !== -1) {
            this._filesList.splice(index, 1);
        }
    }

    removeAllFiles() {
        this._pendingFiles.innerHTML = '';
        this._fileContainer.setAttribute("hidden", "true");
        this._filesList = [];
        clearAlert(this._alert);
        this._fileStatus(false);
    }

    _fileStatus(filesStatus: boolean) {
        const fileStatusEvent = new CustomEvent<FileStatusDetail>
            ('fileStatusDetail', {
                detail: {
                    flag: filesStatus
                }
            });
        this.dispatchEvent(fileStatusEvent);
        this.hideSpinner(true);
    }

    saveFiles() {
        this._fileStatus(false);
        return this._filesList;
    }

    clearAlerts() {
        clearAlert(this._alert);
    }
}
customElements.define('file-upload-component', FileUploadComponent);