import template from './ContactUsModal.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import './ModalComponent';
import { initializeHtmlElement } from './HTMLElementExtensions';

class ContactUsModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._modalComponent = this.shadowRoot.getElementById("contact-us-modal-component");
        this._closeButton = this.shadowRoot.getElementById("close-button");

        this._closeButtonClick = this._closeButtonClick.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener("click", this._closeButtonClick);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener("click", this._closeButtonClick);
    }

    openModal() {
        const event = { detail: { force: false, title: "Contact Us", customContent: true } }
        this._modalComponent._openModal(event);
    }

    _closeButtonClick() {
        this._modalComponent._closeModal();
    }
}
customElements.define("contact-us-modal", ContactUsModal);