import chargeStatus from "./Enumerations/ChargeStatus";

export function debounce(fn, delay) {
    let timeout;
    return function (abort) {
        clearTimeout(timeout);
        if (abort === true) {
            return;
        }
        const functionCall = fn.bind(this, arguments);
        timeout = setTimeout(functionCall, delay);
    };
}

// https://stackoverflow.com/a/34890276/7412948
// groupBy(['one', 'two', 'three'], 'length')
// => {"3": ["one", "two"], "5": ["three"]}
function groupBy(items, property) {
    return items.reduce(function (accumulator, item) {
        const key = item[property];
        if (!(key in accumulator)) {
            accumulator[key] = [];
        }
        accumulator[key].push(item);
        return accumulator;
    }, {});
};

/**
 * @typedef {Object} jmsBondTypeConfig
 * @prop {string} Name
 * @prop {boolean} CanBeAggregateBond
 * @prop {boolean} CanBeTotalBondParent
 * @prop {boolean} CanBeTotalBondChild
 * @prop {boolean} CanBeSimpleBond
 * @prop {boolean} CanShareGroupWithAnotherSimpleBond
 */

/**
 * @typedef {Object} partialCharge
 * @prop {string} WarrantNumber
 * @prop {string} ChargeStatus
 * @prop {number} BondAmt
 * @prop {string} JmsChargeId
 * @prop {?string} OffenseCode
 */

/**
 * 
 * @param {[partialCharge]} charges
 * @param {[jmsBondTypeConfig]} jmsBondTypeConfigs
 * @returns
 */
export function getTotalBondGrouping(charges, jmsBondTypeConfigs, bondAmountProperty) {
    // Remove charges that don't have a warrant number that is valid for grouping
    let aggregateBondGroups = [];
    let chargesWithValidWarrant = charges.filter(x => isValidWarrantNumber(x.WarrantNumber));

    // Citation Charges are grouped by CourtName regardless of WarrantNumber
    if (jmsBondTypeConfigs.some(x => x.CanBeAggregateBond)) {
        aggregateBondGroups = getCitationGroupsFromCharges();
        chargesWithValidWarrant = removeChargesThatBelongToGroup(aggregateBondGroups);
    }

    const validTotalBondCharges = chargesWithValidWarrant.filter(charge => isConfiguredAsTotalBondParentOrTotalBondChild(charge));
    let groups = Object.values(groupBy(validTotalBondCharges, "WarrantNumber"));
    // Remove any groups of valid simple bonds so they are displayed individually
    groups = groups.filter(group => !isSimpleBond(jmsBondTypeConfigs, group, bondAmountProperty));

    return groups.concat(aggregateBondGroups);

    function isValidWarrantNumber(warrantNumber) {
        return !!warrantNumber?.trim();
    }

    function isConfiguredAsTotalBondParentOrTotalBondChild(charge) {
        const config = getJmsBondTypeConfig(jmsBondTypeConfigs, charge);
        return config.CanBeTotalBondParent || config.CanBeTotalBondChild;
    }

    function getCitationGroupsFromCharges() {
        const citationCharges = charges.filter(charge => getJmsBondTypeConfig(jmsBondTypeConfigs, charge).CanBeAggregateBond);
        return Object.values(groupBy(citationCharges, "CourtName")).filter(x => x.length > 1);
    }

    function removeChargesThatBelongToGroup(chargeGroups) {
        const chargeIdsToRemove = [];
        chargeGroups.forEach(chargeGroup => {
            chargeGroup.forEach(charge => chargeIdsToRemove.push(charge.JmsChargeId));
        });
        return chargesWithValidWarrant.filter(charge => !chargeIdsToRemove.includes(charge.JmsChargeId));
    }
}

/**
 * @param {[{ChargeStatus: ChargeStatus, BondAmt: number, OffenseDegree: string, BondType: string}]} group
 * @param {number} tenantId
 * @param {[jmsBondTypeConfig]} jmsBondTypeConfigs
 * @param {string} bondAmountProperty
 * @returns {string} null if no error
 */
export function totalBondGroupErrors(group, tenantId, jmsBondTypeConfigs, bondAmountProperty) {
    const notAvailableError = "A selected Total Bond (offenses that share a common Warrant Number) includes an offense with a Status that is not Available.";
    const invalidBondAmountError = "A selected Total Bond (offenses that share a common Warrant Number) includes an offense with an unspecified bond amount. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.";
    const invalidConfigError = "A selected Total Bond (offenses that share a common Warrant Number) includes bond types that are not configured in such a way to allow for a total bond or a simple bond. If a Bond Type is not correct, click the Back button below, change the Bond Type in your jail management system, and click the Create Application button for this inmate.";;
    const exactlyOneNonZeroError = "A selected Total Bond (offenses that share a common Warrant Number) does not include exactly one offense with a non-zero Bond Amount. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.";
    const simpleBondWithZeroError = "A selected Total Bond (offenses that share a common Warrant Number) includes offenses with a zero Bond Amount and cannot be created as a group of simple bonds. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.";
    const fultonMixingOffensesError = "A selected Total Bond (offenses that share a common Warrant Number) includes both felony and misdemeanor offenses. Felony and misdemeanor offenses cannot be combined on a Total Bond. Check the Degree of these offenses. If a Degree is incorrect, click the Back button below, change the Degree in your jail management system, and click the Create Application button for this inmate.";
    const invalidAggregateBondError = "A selected group of charges are not properly configured aggregate bonds. If a Bond Type is not correct, click the Back button below, change the Bond Type in your jail management system, and click the Create Application button for this inmate.";

    if (!allChargesAreAvailable()) 
        return notAvailableError;

    if (!allChargesHaveValidBondAmount())
        return invalidBondAmountError;

    if (!bondTypesAreConfiguredToAllowTotalBond() && !bondTypesAreConfiguredToAllowSimpleBondInternal() && !bondTypesAreConfiguredToAllowAggregateBond()) 
        return invalidConfigError;

    if (!isSimpleBondInternal() && !isTotalBond() && !isAggregateBond()) {

        // We aren't a simple, total, or aggregate bond; now lets figure why its invalid
        if (bondTypesAreConfiguredToAllowAggregateBond() && !isAggregateBond())
            return invalidAggregateBondError;

        if (bondTypesAreConfiguredToAllowTotalBond() && !hasExactlyOneChargeWithBondAmount()) {
            return exactlyOneNonZeroError;
        }

        if (bondTypesAreConfiguredToAllowSimpleBondInternal() && !allChargesHaveANonZeroBondAmount()) {
            return simpleBondWithZeroError;
        }

        // A valid set of charges, but with the wrong configurations exists. Parent charge has child config, child charge has parent config.
        return invalidConfigError;
    }

    // Customer specific logic below here
    if (isFulton() && !allFultonChargesAreSameTypeOfOffenseDegree())
        return fultonMixingOffensesError;

    return null;

    function allChargesAreAvailable() {
        // Payment portal doesn't have a charge status so its undefined, which we assume is available
        return group.every(charge => charge.ChargeStatus === chargeStatus.available.value || charge.ChargeStatus === undefined);
    }

    function allChargesHaveValidBondAmount() {
        return group.every(charge => charge[bondAmountProperty] !== null);
    }

    function isFulton() {
        return tenantId === 4;
    }

    function allFultonChargesAreSameTypeOfOffenseDegree() {
        return group.every(charge => ["F", "SF"].includes(charge.OffenseDegree)) ||
            group.every(charge => ["M", "O"].includes(charge.OffenseDegree));
    }

    function isTotalBond() {
        // If allowed to be an aggregate bond, then we're not going to be a total bond. Ever. But we could be an invalid aggregate bond, so we only check the config instead of calling isAggregateBond
        const isPossibleAggregateBond = group.every(charge => getJmsBondTypeConfigInternal(charge).CanBeAggregateBond);
        const areTotalBondParentsValid = group.filter(charge => charge[bondAmountProperty] > 0 && getJmsBondTypeConfigInternal(charge).CanBeTotalBondParent).length === 1;
        const areTotalBondChildrenValid = group.filter(charge => charge[bondAmountProperty] === 0 && getJmsBondTypeConfigInternal(charge).CanBeTotalBondChild).length === group.length - 1;
        return !isPossibleAggregateBond && areTotalBondParentsValid && areTotalBondChildrenValid;
    }

    function isAggregateBond() {
        return isAggregateBondByCourt();
    }

    function isAggregateBondByCourt() {
        return group.every(charge =>
            charge[bondAmountProperty] >= 0 &&
            getJmsBondTypeConfig(jmsBondTypeConfigs, charge).CanBeAggregateBond &&
            charge.CourtName !== null
        );
    }

    function bondTypesAreConfiguredToAllowTotalBond() {
        return group.filter(charge => getJmsBondTypeConfigInternal(charge).CanBeTotalBondParent).length >= 1 &&
            group.filter(charge => getJmsBondTypeConfigInternal(charge).CanBeTotalBondChild).length >= 1;
    }

    function bondTypesAreConfiguredToAllowAggregateBond() {
        return group.every(charge => getJmsBondTypeConfigInternal(charge).CanBeAggregateBond);
    }

    function hasExactlyOneChargeWithBondAmount() {
        return group.filter(charge => charge[bondAmountProperty] > 0).length === 1;
    }

    function allChargesHaveANonZeroBondAmount() {
        return group.every(charge => charge[bondAmountProperty] > 0);
    }

    function isSimpleBondInternal() {
        return isSimpleBond(jmsBondTypeConfigs, group, bondAmountProperty);
    }

    function bondTypesAreConfiguredToAllowSimpleBondInternal() {
        return bondTypesAreConfiguredToAllowSimpleBond(jmsBondTypeConfigs, group);
    }

    function getJmsBondTypeConfigInternal(charge) {
        return getJmsBondTypeConfig(jmsBondTypeConfigs, charge);
    }
}

function isSimpleBond(configs, group, bondAmountProperty) {
    const hasBondAmount = group.every(charge => charge[bondAmountProperty] >= 0);
    const isConfiguredForSimpleBonds = bondTypesAreConfiguredToAllowSimpleBond(configs, group);
    return hasBondAmount && isConfiguredForSimpleBonds;
}

function bondTypesAreConfiguredToAllowSimpleBond(configs, group) {
    return group.every(charge => {
        const config = getJmsBondTypeConfig(configs, charge);
        return config.CanBeSimpleBond && (group.length === 1 || config.CanShareGroupWithAnotherSimpleBond);
    });
}

function getJmsBondTypeConfig(configs, charge) {
    // If nothing is configured, assume you can do everything
    if (configs.length === 0)
        return {
            CanBeTotalBondParent: true,
            CanBeTotalBondChild: true,
            CanBeSimpleBond: true,
            CanShareGroupWithAnotherSimpleBond: false,
            CanBeAggregateBond: false
        };
    return configs.find(config => config.Name.toLowerCase() === charge.BondType?.toLowerCase()) || {
        CanBeTotalBondParent: false,
        CanBeTotalBondChild: false,
        CanBeSimpleBond: true,
        CanShareGroupWithAnotherSimpleBond: true,
        CanBeAggregateBond: false
        };
}

export function openSyncFailureDialog(responseObj, buttonElement, syncFailureDialogElement, hasSkipPermission, continueOnSyncFailure, consoleErrorMessage) {
    if (responseObj) {
        const dialogInitializationObj = {
            hasSkipPermission: hasSkipPermission,
            continueOnSyncFailure: continueOnSyncFailure,
            inmateFN: responseObj.Inmate.InmateFN,
            inmateMI: responseObj.Inmate.InmateMI,
            inmateLN: responseObj.Inmate.InmateLN,
            bookingNumber: responseObj.Inmate.BookNumber,
            jailId: null, // This jailId property is only used for continue button when sync fails on the roster page
            buttonElementToRetry: buttonElement,
            lastSyncedTime: responseObj.LastSyncedTime,
            isOdysseyFailure: responseObj.IsOdysseyFailure,
            countyITPhoneNumber: responseObj.CountyItPhoneNumber
        }
        syncFailureDialogElement.openDialog(dialogInitializationObj);
    } else {
        console.error(consoleErrorMessage);
    }
}

export function isAttributeTrue(element, attributeName) {
    return element.hasAttribute(attributeName) &&
        element.getAttribute(attributeName).toLowerCase() === "true";
}

export function parseStringToNumberOrNull(stringValue) {
    return stringValue === undefined ||
        stringValue === null ||
        stringValue.trim() === "" || // checks value for both empty or whitespace
        isNaN(stringValue) ? null : Number(stringValue);
}

export function sortSelectElementOptions(selectElement) {
    const options = Array.from(selectElement.options);
    const selectOneOption = options.find(option => option.value === "");
    const otherOptions = options.filter(option => option.value !== "");

    otherOptions.sort((a, b) => a.text.localeCompare(b.text));

    if (selectOneOption)
        selectElement.add(selectOneOption);

    otherOptions.forEach(option => selectElement.add(option));
}

export function printPageBuilder(table) {
    let date = new Date;
    let companyAndTenant = settings.bondCompany === settings.tenant ? settings.tenant : settings.bondCompany + ' - ' + settings.tenant;

    return `
    <html>
        <head>
            <style type="text/css" media="print">
            @page {
              size: auto;
              margin: 25px 0 25px 0;
            }
            </style>
            <style type="text/css" media="all">
            table {
              border-collapse: collapse;
              font-size: 12px;
            }
            table, th, td {
              border: 1px solid grey;
            }
            th, td {
              text-align: center;
              vertical-align: middle;
            }
            p {
              font-weight: bold;
              margin-left:20px;
            }
            table {
              width:94%;
              margin-left:3%;
              margin-right:3%;
            }
            div.bs-table-print {
              text-align:center;
            }
            </style>
        </head>
        <title>Print Table</title>
        <body>
        <p>Printed on: ${date} - ${companyAndTenant}<p>
            <div class="bs-table-print">${table}</div>
        </body>
    </html>`
}