import template from './ProgressBar.html';
import { initializeHtmlElement } from './HTMLElementExtensions';

class ProgressBar extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template);

        this._progressBar = this.shadowRoot.getElementById('progress-container');
        this._progressLabel = this.shadowRoot.getElementById('display-label');
        this._meterLength = this.shadowRoot.getElementById('meter-length');
        this._progressLength = 0;
    }

    static get observedAttributes() {
        return ["striped", "animated", "progress", "status", "label"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;
        switch (name) {
            case 'progress':
                this._progressLength = parseInt(newValue);
                this._meterLength.setAttribute('style', `width:${newValue}%`);
                break;
            case 'status':
                this._progressBar.classList.remove('meter', 'success', 'warning', 'danger');
                this._progressBar.classList.add('meter', newValue);
                break;
            case 'animated':
                this._progressBar.classList.toggle('animate', newValue !== null);
                break;
            case 'label':
                this._progressLabel.classList.remove('hidden');
                this._progressLabel.textContent = newValue;
                break;
            case 'striped':
                this._progressBar.classList.toggle('noStripes', newValue === null);
                break;
        }
    }
}
customElements.define('progress-bar', ProgressBar);
