import template from "./CancelReasonModal.html";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import "./../ProgressBar";
import { initializeHtmlElement } from "./../HTMLElementExtensions";
import { debounce } from "./../JavaScriptFunctions";

class CancelReasonModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);
        this._pageTools = new PageTools();
        this._bondApplicationId = null;
        this._debounceTimeout = 50;
        this._remainingCount = 120;
        this._modalTitle = "Cancel Reason";
        this._cancelReasonTextArea = this.shadowRoot.getElementById("cancel-reason-textarea");
        this._cancelReasonErrorMessage = this.shadowRoot.getElementById("cancel-reason-error-message");
        this._closeModalButton = this.shadowRoot.getElementById("closeModal-button");
        this._modal = this.shadowRoot.querySelector("modal-component");
        this._progressBar = this.shadowRoot.querySelector("progress-bar");
        this._finishButton = this.shadowRoot.getElementById("finish-button");
        this._bondApplicationIdFormElement = this.shadowRoot.getElementById("bond-application-id");
        this._form = this.shadowRoot.getElementById("cancel-reason-form");
        this._resetModal();
        this._errorMessage = this.shadowRoot.getElementById("error-message");
        this._bondAgentWarning = this.shadowRoot.getElementById("visible-note");
        this._endPoint = null;
        this._redirectUrl = null;
        this._requestObject = null;

        this._submitForm = this._submitForm.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._calculateRemaining = debounce(this._calculateRemaining.bind(this), this._debounceTimeout);
    }

    set bondApplicationId(id) {
        this._bondApplicationId = id;
        this._bondApplicationIdFormElement.value = id;
    }

    set cancelReason(value) {
        this._cancelReasonTextArea.value = value;
        this._cancelReasonTextArea.dispatchEvent(new Event("input"));
    }

    connectedCallback() {
        this._finishButton.addEventListener("click", this._submitForm);
        this._closeModalButton.addEventListener("click", this._closeModal);
        this._cancelReasonTextArea.addEventListener("input", this._calculateRemaining);
    }
    disconnectedCallback() {
        this._finishButton.removeEventListener("click", this._submitForm);
        this._closeModalButton.removeEventListener("click", this.closeModal);
        this._cancelReasonTextArea.removeEventListener("input", this._calculateRemaining);
    }

    openModal(data) {
        this._modal.openModal(data.title, false);
        this._endPoint = data.endPoint;
        this._redirectUrl = data.redirectUrl;
        this._requestObject = data.requestObject;
        this._bondAgentWarning.toggleAttribute("hidden", !!data.hideBondAgentWarning); // !! is to ensure passing null/undefined gets converted to false
    }

    _closeModal() {
        this._modal.closeModal();
    }

    _calculateRemaining() {
        const wordCount = this._cancelReasonTextArea.value.length;
        this._validateTextarea(wordCount);
        const wordsLeft = this._remainingCount - wordCount;

        const progressNumber = (wordsLeft / this._remainingCount) * 100;
        this._progressBar.setAttribute("progress", progressNumber);
        this._progressBar.setAttribute("label", `${wordsLeft} characters remaining`);
    }

    _resetModal() {
        this._cancelReasonTextArea.value = "";
        this._cancelReasonErrorMessage.textContent = "";
    }

    _submitForm() {
        this._triggerState(true);
        const xhrWrapper = new XhrWrapper();
        const cancelReasonObject = {
            cancelReason: this._cancelReasonTextArea.value
        }
        const requestObject = Object.assign(this._requestObject, cancelReasonObject);
        xhrWrapper.makeRequest("POST", this._endPoint, requestObject, this._responseCallback.bind(this));
    }

    _responseCallback(response, isSuccess) {
        if (!isSuccess) {
            this._cancelReasonErrorMessage.textContent = response;
            this._triggerState(false);
            return;
        }
        this._pageTools.redirectToUrl(this._redirectUrl);
    }

    _triggerState(state) {
        this.shadowRoot.querySelectorAll("[trigger]").forEach(x => x.disabled = state);
    }

    _validateTextarea(wordCount) {
        if (wordCount >= 1) {
            this._finishButton.classList.remove("btn-disabled");
            this._finishButton.removeAttribute("disabled");
            this._progressBar.setAttribute("status", "success");
            this._cancelReasonErrorMessage.textContent = "";
        } else {
            this._finishButton.classList.add("btn-disabled");
            this._finishButton.toggleAttribute("disabled", true);
            this._progressBar.setAttribute("status", "default");
            this._cancelReasonErrorMessage.textContent = "Cancel reason cannot be empty.";
        }
    }
}
customElements.define("cancel-reason-modal", CancelReasonModal);
