import template from './DisplayCard.html';
import { initializeHtmlElement } from './HTMLElementExtensions';

class DisplayCard extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template);

        this._userBondCompany = null;
        this._ccType = this.shadowRoot.getElementById('cc-type').firstChild;
        this._ccNumber = this.shadowRoot.getElementById('cc-number');
        this._ccExp = this.shadowRoot.getElementById('cc-exp');
        this._ccName = this.shadowRoot.getElementById('cc-name');
        this._ccCompanyName = this.shadowRoot.getElementById('cc-company-name');
        this._ccLogo = this.shadowRoot.getElementById('cc-logo');
    }

    static get observedAttributes() {
        return ['company-name'];
    }

    attributeChangedCallback(name, oldAttrValue, newAttrValue) {
        if (oldAttrValue === newAttrValue) return;

        if (name === 'company-name')
            this._userBondCompany = newAttrValue;
    }

    set model(value) {
        this._model = value;
        this._update();
    }

    get model() {
        return this._model;
    }

    _update() {
        this._ccType.textContent = this._model.CompanyCard ? "Company Card" : "Personal Card";
        this._ccNumber.textContent = this._model.CardNumber;
        this._ccExp.textContent = this._model.ExpirationDate;
        this._ccName.textContent = this._model.CardHolderName;
        this._ccCompanyName.textContent = this._userBondCompany;
        this._ccCompanyName.toggleAttribute('hidden', this._model.CompanyId == null);
        this._ccLogo.setAttribute('type', this._model.CardType);
    }
}
customElements.define('display-card', DisplayCard);
