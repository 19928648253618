import bootstrap from './../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, ensureListHasSameValuesAndDisplay, clearAlert } from './EditDialogExtensions';
import template from './EditSpecialConditionsDialog.html';
import './ActionButton';

class EditSpecialConditionsDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._closeButton = this.shadowRoot.getElementById('special-conditions-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('special-conditions-dialog-save-button');
        this._specialConditionsDropDown = this.shadowRoot.getElementById('select-special-conditions');
        this._verboseSpecialConditionsContainer = this.shadowRoot.getElementById('verbose-special-conditions-container');
        this._verboseSpecialConditionsTextArea = this.shadowRoot.getElementById('verbose-special-conditions');
        this._verboseSpecialConditionsLabel = this.shadowRoot.getElementById('verbose-special-conditions-label');
        this._specialConditionsLabel = this.shadowRoot.getElementById('special-conditions-label');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._form = this.shadowRoot.querySelector('form');
        this._formGroup = this.shadowRoot.querySelector('.form-group');

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._isDifferent = this._isDifferent.bind(this);
        this._toggleVerboseSpecialConditions = this._toggleVerboseSpecialConditions.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
        this._specialConditionsDropDown.addEventListener('change', this._toggleVerboseSpecialConditions);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
        this._specialConditionsDropDown.removeEventListener('change', this._toggleVerboseSpecialConditions);
    }

    set hasVerboseSpecialConditions(value) {
        this._verboseSpecialConditionsFeatureFlag = value;
    }

    set specialConditions(value) {
        this._specialConditions = value;
    }

    openModal(selectedCharges, controllerPath) {
        this._controllerPath = controllerPath;
        this._selectedCharges = selectedCharges;
        this._setInitialValues();
        this._toggleVerboseSpecialConditions();
        this._modalComponent.openModal('Special Conditions', false);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _toggleVerboseSpecialConditions() {
        const dropdownValue = this._specialConditionsDropDown.value === "true";
        if (this._verboseSpecialConditionsFeatureFlag && this._specialConditions) {
            this._verboseSpecialConditionsContainer.classList.toggle("required", dropdownValue);
            this._verboseSpecialConditionsContainer.toggleAttribute("hidden", !dropdownValue);
            this._verboseSpecialConditionsTextArea.toggleAttribute("required", dropdownValue);
            if (!dropdownValue) {
                this._verboseSpecialConditionsTextArea.value = "";
            }
        } else if (this._verboseSpecialConditionsFeatureFlag && !this._specialConditions) {
            this._verboseSpecialConditionsContainer.classList.toggle("required", false);
            this._verboseSpecialConditionsContainer.toggleAttribute("hidden", false);
            this._verboseSpecialConditionsTextArea.toggleAttribute("required", false);
            this._specialConditionsDropDown.toggleAttribute("hidden", true);
            this._specialConditionsLabel.toggleAttribute("hidden", true);
        } else {
            this._verboseSpecialConditionsContainer.classList.toggle("required", false);
            this._verboseSpecialConditionsContainer.toggleAttribute("hidden", true);
            this._verboseSpecialConditionsTextArea.toggleAttribute("required", false);
        }
    }

    _isDifferent(firstItem, isDifferent) {
        if (isDifferent || firstItem.SpecialConditions === null) {
            this._specialConditionsDropDown.value = '';
        } else {
            this._specialConditionsDropDown.value = firstItem.SpecialConditions ? 'true' : 'false';
        }
    }

    _setInitialValues() {
        const properties = [{ property: 'SpecialConditions', element: this._specialConditionsDropDown, callback: this._isDifferent }];
        if (this._verboseSpecialConditionsFeatureFlag) {
            properties.push({ property: 'VerboseSpecialConditions', element: this._verboseSpecialConditionsTextArea, default: '' });
        }
        const differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, properties);
        if (differences.length > 0) {
            showAlert(this._alert,
                'There are conflicting special conditions with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _gatherData() {
        if (this._verboseSpecialConditionsFeatureFlag && !this._specialConditions) {
            this._specialConditionsDropDown.value = !!this._verboseSpecialConditionsTextArea.value;
        }
        return {
            selected: this._selectedCharges.map(x => x.BondApplicationDetailID),
            specialConditions: this._specialConditionsDropDown.value === 'true',
            verboseSpecialConditions: this._verboseSpecialConditionsTextArea.value
        }
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: `/${this._controllerPath}/UpdateSpecialConditions`,
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }
}
customElements.define('edit-special-conditions-dialog', EditSpecialConditionsDialog)