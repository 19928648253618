export default class ManageEmailsPage {
    constructor() {
        this._alert = document.getElementById('alert-view');
        this._addEmailButton = document.getElementById("add-email-button");
        this._emailInput = document.getElementById("email-input");
        this._confirmationModal = document.querySelector("confirmation-modal");
        this._pageTools = new PageTools();
        this._kendoGridContainer = document.getElementById("Grid");
        this._editTenantEmailDialog = document.getElementById('edit-tenant-email-dialog');

        this.addNewEmail = this.addNewEmail.bind(this);
        this._dialogSave = this._dialogSave.bind(this);
        this._refreshKendoGrid = this._refreshKendoGrid.bind(this);
        this._confirmationModal.addEventListener('ok-confirmation', this._removeEmailConfirmationCallback.bind(this));
        this._editTenantEmailDialog.addEventListener('refreshGrid', this._refreshKendoGrid);
        this._editTenantEmailDialog.addEventListener('save', this._dialogSave);
    }

    addNewEmail() {
        if (!this._emailInput.checkValidity()) {
            this._emailInput.reportValidity();
            return;
        }
        const xhrWrapper = new XhrWrapper();
        const data = { email: this._emailInput.value };
        xhrWrapper.makeRequest("POST", "/Admin/AddEmail", data, this._addNewEmailCallback.bind(this));
        this._pageTools.toggleTriggers(this._addEmailButton.parentElement, true);
    }

    openEditTenantEmailDialog(viewModel) {
        this._clearAlert();
        this._editTenantEmailDialog.openModal(viewModel);
    }

    showRemoveEmailConfirmationModal(tenantEmailId, email) {
        this._confirmationModal.open({
            message: `Remove email ${email}?`,
            response: tenantEmailId,
            actionButtonText: "Remove Email"
        });
    }

    _removeEmailConfirmationCallback(event) {
        const tenantEmailId = event.detail.response;
        const xhrWrapper = new XhrWrapper();
        const data = { tenantEmailId };
        xhrWrapper.makeRequest("POST", "/Admin/RemoveEmail", data, this._removeEmailCallback.bind(this));
        this._pageTools.toggleTriggers(this._kendoGridContainer, true);
    }

    _removeEmailCallback(response, status) {
        this._pageTools.toggleTriggers(this._kendoGridContainer, false);
        if (!status) {
            this._showAlert("Failed to remove email. Try again and if the problem persists contact support.", false);
            return;
        }
        this._showAlert("Successfully removed email.", true);
        this._refreshKendoGrid();
    }

    _addNewEmailCallback(response, status) {
        this._pageTools.toggleTriggers(this._addEmailButton.parentElement, false);
        if (!status) {
            this._showAlert("Failed to add email. Try again and if the problem persists contact support.", false);
            return;
        }
        this._emailInput.value = "";
        this._showAlert("Successfully added email.", true);
        this._refreshKendoGrid();
    }

    _dialogSave(e) {
        const email = e.detail.email;
        if (e.detail.isEdit) {
            this._showAlert(`Successfully edited ${email}`, true);
            return;
        }

        this._showAlert(`Successfully added ${email}`, true);
    }

    _showAlert(message, status) {
        this._alert.classList.toggle('text-success', status);
        this._alert.classList.toggle('text-danger', !status);
        this._alert.querySelector('span').textContent = message;
    }

    _clearAlert() {
        this._alert.querySelector('span').textContent = null;
    }

    _refreshKendoGrid() {
        const grid = $('#Grid').data('kendoGrid');
        grid.dataSource.read();
        grid.refresh();
    }
}
window.ManageEmailsPage = ManageEmailsPage;