import template from './SaveRestrictedIssuingAuthorityConfirmationDialog.html';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import bootstrap from '../../scss/bootstrap-custom.scss';

class SaveRestrictedIssuingAuthorityConfirmationDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._confirmButton = this.shadowRoot.querySelector('#confirm-button');
        this._cancelButton = this.shadowRoot.querySelector('#cancel-button');
        this._modal = this.shadowRoot.querySelector('modal-component');
        this._confirmationMessage = this.shadowRoot.querySelector('#confirmation-message');

        this._confirm = this._confirm.bind(this);
        this._cancel = this._cancel.bind(this);
    }

    connectedCallback() {
        this._confirmButton.addEventListener('click', this._confirm);
        this._cancelButton.addEventListener('click', this._cancel);
    }

    disconnectedCallback() {
        this._confirmButton.removeEventListener('click', this._confirm);
        this._cancelButton.removeEventListener('click', this._cancel);
    }

    openModal(value) {
        if (value.hasCharges) {
            this._confirmationMessage.textContent = value.title;
            this._modal.openModal("Confirm Creation", false);
        }
    }

    _confirm() {
        const event = new Event("confirm");
        this.dispatchEvent(event);
        this._modal.closeModal();
    }

    _cancel() {
        this._modal.closeModal();
    }
}
customElements.define('save-restrict-issuing-authority-dialog-confirmation-dialog', SaveRestrictedIssuingAuthorityConfirmationDialog);