export default class ManageUserPage {
    constructor() {
        this._usersViewModel = null;
        this._manageUserModal = document.querySelector('manage-user-modal');
        this._manageUserModal.addEventListener('show-alert-view', this._showAlertView.bind(this));
        this._manageUserModal.addEventListener('manage-user-update-grid', this._refreshKendoGrid.bind(this));
    }

    set users(value) {
        this._usersViewModel = value;
    }

    openCustomModal(userId) {
        this._manageUserModal.model = this._usersViewModel.find(x => x.UserId === userId);
        this._manageUserModal.openModal();
    }

    _showAlertView(event) {
        const alertView = document.getElementById('alert-view');
        const isSuccess = event.detail.isSuccess;
        alertView.classList.toggle('alert-danger', !isSuccess);
        alertView.classList.toggle('alert-success', isSuccess);
        alertView.querySelector('span').textContent = event.detail.message;
        alertView.classList.remove('hidden');
    }

    _refreshKendoGrid(event) {
        const user = event.detail;
        const grid = $('#grid').data('kendoGrid');
        grid.dataSource.read().then(() => {
            // The Auth0 API used is "eventually consistent" and we want to immediately reflect the results back to the user.
            const dataItem = grid.dataSource.get(user.UserId);
            if (!dataItem)
                return;
            dataItem.set('DisplayName', user.DisplayName);
            dataItem.set('IsActive', user.IsActive);
            dataItem.set('Role', user.Role.replace("County", "Jail"));
            dataItem.set('BondCompanyIds', user.BondCompanyIds);
            grid.saveRow();
            dataItem.dirty = dataItem.dirtyFields.Role = dataItem.dirtyFields.User = dataItem.dirtyFields.IsActive = false;
            grid.refresh();
        });
    }
}
window.ManageUserPage = ManageUserPage;