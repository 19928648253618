import bootstrap from '../scss/bootstrap-custom.scss';
import template from './ConfirmationModal.html';
import { initializeHtmlElement } from './HTMLElementExtensions';

class ConfirmationModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap], ["confirmation-modal-component"]);

        this._modal = this.shadowRoot.querySelector("modal-component");
        this._modalBody = this.shadowRoot.getElementById("modal-body");
        this._cancelButton = this.shadowRoot.getElementById("cancel-confirmation-modal-button");
        this._okButton = this.shadowRoot.getElementById("ok-confirmation-modal-button");
        this._formControlContainers = this.shadowRoot.querySelectorAll("#payment-card-form section div");
        this._pageTools = new PageTools();

        this._okButtonOnClick = this._okButtonOnClick.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
    }

    connectedCallback() {
        this._okButton.addEventListener("click", this._okButtonOnClick);
        this._cancelButton.addEventListener("click", this._cancelButtonOnClick);
    }

    disconnectedCallback() {
        this._okButton.removeEventListener("click", this._okButtonOnClick);
        this._cancelButton.removeEventListener("click", this._cancelButtonOnClick);
    }

    open({ message, response, modalBodyText = null, actionButtonText = "OK", cancelButtonText = "Cancel", force = false }) {
        this._message = message;
        this._response = response;
        this._okButton.textContent = actionButtonText;
        this._cancelButton.textContent = cancelButtonText;
        this._modalBody.textContent = modalBodyText;
        if (!this._modalBody.textContent) {
            this._modalBody.toggleAttribute("hidden", true);
        } 
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        
        this._modal.openModal(message, force);
    }

    close() {
        this._closeModal();
    }

    _okButtonOnClick() {
        const event = new CustomEvent("ok-confirmation", { cancelable: true, detail: { response: this._response } });
        const notCanceled = this.dispatchEvent(event);
        if (notCanceled)
            this._closeModal();
        else
            this._pageTools.toggleTriggers(this.shadowRoot, true);
    }

    _cancelButtonOnClick() {
        const cancelEvent = new CustomEvent("cancel-confirmation");
        this.dispatchEvent(cancelEvent);
        this._closeModal();
    }

    _closeModal() {
        this._modal.closeModal();
    }
}
customElements.define("confirmation-modal", ConfirmationModal);
