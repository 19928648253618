import template from './CustomCarousel.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import 'bootstrap';

class CustomCarousel extends HTMLElement {

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._indicators = this.shadowRoot.querySelector('.carousel-thumbnails');
        this._carouselInner = this.shadowRoot.querySelector('.carousel-inner');
        this._carousel = this.shadowRoot.querySelector('.carousel');
        this._right = this.shadowRoot.querySelector('.carousel-control-next');
        this._left = this.shadowRoot.querySelector('.carousel-control-prev');
        this._rightSelect = this._rightSelect.bind(this);
        this._leftSelect = this._leftSelect.bind(this);
    }

    set images(value) {
        this._carouselImages(value);
    }

    set thumbnails(value) {
        this._carouselThumbnails(value);
        $(this._carousel).carousel();
        this._setupEventListeners();
    }

    _setupEventListeners() {
        this._right.addEventListener('click', this._rightSelect);
        this._left.addEventListener('click', this._leftSelect);
        document.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowLeft') {
                this._leftSelect();
            }
            if (event.key === 'ArrowRight') {
                this._rightSelect();
            }
        });
    }

    _rightSelect() {
        $(this._carousel).carousel('next');
    }

    _leftSelect() {
        $(this._carousel).carousel('prev');
    }

    _selectThumbnail(index) {
        $(this._carousel).carousel(index);
    }

    _carouselThumbnails(imageList) {
        this._indicators.innerHTML = '';
        imageList.forEach((item, index) => {
            const newElement = document.createElement('img');
            if (index === 0) {
                newElement.classList.add('active');
            }
            newElement.src = item;
            newElement.alt = index.toString();
            newElement.setAttribute('data-target', '#custom-carousel');
            newElement.setAttribute('data-slide-to', index);
            newElement.className = "thumbnail";
            this._indicators.appendChild(newElement);
            newElement.addEventListener('click', this._selectThumbnail.bind(this, index));
        });
    }

    _carouselImages(imageList) {
        this._carouselInner.innerHTML = '';
        imageList.forEach((item, index) =>
        {
            const newElement = document.createElement('div');
            newElement.className = 'carousel-inner';
            const imgElement = document.createElement('img');
            newElement.classList.add('carousel-item');
            if (index === 0) {
                newElement.classList.add('active');
            }
            imgElement.src = item;
            imgElement.alt = index + item;
            imgElement.className = 'carousel-image';
            imgElement.classList = 'd-block';
            newElement.appendChild(imgElement);
            this._carouselInner.appendChild(newElement);
        });
    }
}
customElements.define('custom-carousel', CustomCarousel)