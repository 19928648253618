import template from './SmartSearch.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { debounce } from './JavaScriptFunctions';
import bootstrap from '../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css'

class SmartSearch extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['search-container']);
        this.submit = this.submit.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._hideOrShowClearButton = this._hideOrShowClearButton.bind(this);
        this._clearButtonOnClick = this._clearButtonOnClick.bind(this);
        this.debouncedSubmit = debounce(this.submit, 0);
        this._tooltip = this.shadowRoot.getElementById('title-tooltip');
        this._searchBar = this.shadowRoot.getElementById('search-bar');
        this._clearButton = this.shadowRoot.getElementById('clear-button');
        this._minimumFirstName = null;
        this._minimumLastName = null;
        this._allowDob = null;
        this._allowBooking = null;
        this._submissionInterval = null;
        this._submissionURI = null;
    }

    set toolTip(value) {
        if (!value) {
            this._tooltip.toggleAttribute('hidden', true);
            this._clearButton.style["right"] = "0em";
            this._searchBar.style["padding-left"] = "1.6em";
        } else {
            this._tooltip.toggleAttribute('hidden', false);
            this._clearButton.style["right"] = "";
            this._searchBar.style["padding-left"] = "";
            if (this._tooltip.hasAttribute('data-original-title')) {
                this._tooltip.setAttribute('data-original-title', value);
                return;
            }
            this._tooltip.title = value;
            $(this._tooltip).tooltip();
        }
    }

    set submissionInterval(value) {
        this._submissionInterval = value;
        this._searchBar.removeEventListener('input', this.debouncedSubmit);
        this.debouncedSubmit = debounce(this.submit, this._submissionInterval);
        this._searchBar.addEventListener('input', this.debouncedSubmit);
    }

    set submissionURI(value) {
        this._submissionURI = value;
    }

    set setSmartSearchPlaceHolder(value) {
        this._searchBar.placeholder = value;
    }

    set additionalQueryParameters(value) {
        this._additionalQueryParameters = value;
    }
        
    connectedCallback() {
        this._searchBar.addEventListener('input', this.debouncedSubmit);
        this._searchBar.addEventListener('input', this._hideOrShowClearButton);
        this._searchBar.addEventListener('keyup', this._onKeyUp);
        this._clearButton.addEventListener('click', this._clearButtonOnClick);
    }

    disconnectedCallback() {
        this._searchBar.removeEventListener('input', this.debouncedSubmit);
        this._searchBar.removeEventListener('input', this._hideOrShowClearButton);
        this._searchBar.removeEventListener('keyup', this._onKeyUp);
        this._clearButton.removeEventListener('click', this._clearButtonOnClick);
    }

    submit() {
        if (this.submitXhrWrapper) {
            this.submitXhrWrapper.abort();
        }
        const event = new Event('beginsearchrequest');
        this.dispatchEvent(event);
        this.submitXhrWrapper = new XhrWrapper();
        let query = "?query=" + encodeURIComponent(this._searchBar.value);
        if (this._additionalQueryParameters)
            query = this._additionalQueryParameters.reduce((value, x) => value + `&${x.name}=${encodeURIComponent(x.value)}`, query);
        this.submitXhrWrapper.makeRequest('GET',
            this._submissionURI + query,
            null,
            this._searchCallback.bind(this));
    }

    setSearchTerm(inmateName) {
        this._searchBar.value = inmateName;
    }

    setSmartSearchfontSize(fontSizeValue) {
        this._searchBar.style.fontSize = fontSizeValue;
    }

    _onKeyUp(event){
        if (event.key === "Enter") {
            this.debouncedSubmit(true);
            this.submit();
        }
    }

    _searchCallback(response, status) {
        const eventMessage = status ? 'success' : 'failure';
        const event = new CustomEvent(eventMessage, { detail: response });
        this.dispatchEvent(event);
    }

    _hideOrShowClearButton() {
        this._clearButton.toggleAttribute('hidden', !this._searchBar.value);
    }

    _clearButtonOnClick() {
        this._searchBar.value = '';
        this._searchBar.dispatchEvent(new Event('input'));
    }

    clear() {
        this._searchBar.value = '';
    }
}
customElements.define('smart-search', SmartSearch);