// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum FeatureFlag {
  UseFingerPrintScanner = 0,
  UseDemoFeatures = 1,
  BookoutCompleteShowsAllCharges = 2,
  PowersOfAttorneyReport = 3,
  PoaBanner = 4,
  PRBondStrictValidation = 5,
  ContinueOnSyncFailure = 6,
  CourtInformationHasAgencyCaseNumber = 7,
  RestrictCardsTemp = 8,
  BondCompaniesHaveFullRosterAccess = 9,
  AcceptsAllPaidPayments = 10,
  CountyUserHasAllPaidNavMenuItem = 11,
  HasMugshot = 12,
  HasPerOffenseFee = 13,
  BondTypeSurchargeScheduleTemp = 14,
  CourtInformationRequiredForCountyUsers = 15,
  CourtInformationOptionalForCountyUsers = 16,
  CourtInformationRequiredForBondUsers = 17,
  CourtInformationOptionalForBondUsers = 18,
  OffensesHaveSpecialConditions = 19,
  PrBondHasIssuingAuthority = 20,
  HaysConfirmNoHoldsTemp = 21,
  OutOfCountyBondTemplateTemp = 22,
  DetailedManageUsersTableTemp = 23,
  BondDocumentTemplatesAreAssignablePerOffense = 24,
  BondTypeEnforcementTemp = 25,
  ReceiptTemplateUploadTemp = 26,
  ExtraditionInformationTemp = 27,
  BondCompanyMugshotVerify = 28,
  SendEmailToBondCompanyOnApplicationSubmitted = 29,
  SendEmailToBondCompanyOnApplicationReviewed = 30,
  AllPaidDisablePartialPayments = 31,
  PreventBondCompaniesFromReceivingOrViewingBondDocuments = 32,
  CourtInformationCauseNumberRequired = 33,
  CourtInformationWarrantNumberRequired = 34,
  CaptureFeesAtEndOfReviewProcess = 35,
  AttorneyBarNumberExpirationRequired = 36,
  PreviewAllFeatureFlagTemp = 37,
  HasBookingSheet = 38,
  ReviewAuthorizationRequiredTemp = 39,
  FullApiInmateAccess = 40,
  RefreshOnCadence = 41,
  TenantWidePoaReportTemp = 42,
  EnforceBondApplicationLimitsTemp = 43,
  BondDocumentCertificationTemp = 44,
  ShortcutBookoutQueueProcessTemp = 45,
  RequireAuthorizationSignatureAtBookout = 46,
  BondCompaniesOnlyGetUnofficialBondDocuments = 47,
  ExpandUserRolesTemp = 48,
  ConfigurableTimeoutTemp = 49,
  SingleSessionSignOnTemp = 50,
  RestrictIssuingAuthority = 51,
  CollateralLimitEnforcement = 52,
  OffensesHaveVerboseSpecialConditions = 53,
  TotalBonds = 54,
  UserLevelPoaLicense = 55,
  HideMugshotsForJailAccounts = 56,
  HideMugshotsForCompanyAccounts = 57,
  HideMugshotsForPaymentPortal = 58,
  ShowPaymentMethod = 59,
  StoreCompaniesAssignedToUserInDatabaseTemp = 60,
  AllowPaymentPortalInmateReleaseNotification = 61,
  PaymentPortalPlcCheckTemp = 62,
  NotifyTenantOfDataProcessingIssuesTemp = 63,
  PaymentPortalRequiresPayorIdentifyingInformation = 64,
  ShowBookingReportOfficialUseOnlyText = 65,
  DataEntryErrorLogicTemp = 66,
  UpdateFeeCalculationTemp = 67,
  ShowBookingReportSpecialConditions = 68,
  BondDocumentsBookedOutReportShowsCourtNameTemp = 69,
  RequireAdditionalAuthorizationSignatureAtBookout = 70,
  CourtNameShowsForBondApplication = 71,
  CourtInformationReadOnly = 72,
  EnforceOwnerBondCompanyCollateralLimit = 73,
  HideAdditionalInmateInformationInputs = 74,
  OptionalParcelNumber = 75,
  AttorneyCompaniesTemp = 76,
  ExtendedAllPaidValidationTemp = 77,
  BondPaymentCaptureCourtTemp = 78,
  CollectInmateContactInformation = 79,
  CollectInmateContactEmailAddress = 80,
  CollectInmateContactEmailAddressRequired = 81,
  SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp = 82,
  AllowAmendingBondDocuments = 83,
  CancelAmendedBondDocumentsTemp = 84
}