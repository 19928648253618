import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class BondListGrid extends HTMLElement {

    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="bond-list"></bootstrap-report-grid>',
            [],
            []
        );
        this._uniqueId = 'BondApplicationID';
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
        this._tableColumns = [];
        this._subTableColumns = [];
    }

    _compileColumns() {
        this._tableColumns = [
            {
                title: 'Bond Company',
                field: 'PayorName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Created By',
                field: 'CreatedBy',
                type: 'Text',
                escape: true
            },
            {
                title: 'Inmate',
                field: 'InmateFullName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking Number',
                field: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Total Bond Amount',
                field: 'TotalBondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Total Bond Fees',
                field: 'TotalCountyBondFees',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Payment Authorized Time',
                field: 'CountyPaymentAuthorizedDateTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Payment Capture Time',
                field: 'CountyPaymentCaptureDateTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Status',
                field: 'AppStatus',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapReportGrid.applicationStatusFormatter.bind(this)
            },
            {
                title: 'Cancel Reason',
                field: 'CancelReason',
                type: 'Text',
                escape: true
            },
            {
                title: 'Canceled By',
                field: 'CanceledBy',
                type: 'Text',
                escape: true
            },
            {
                title: 'Remittance ID',
                field: 'RemittanceId',
                type: 'Number',
                escape: true
            }
        ];
    }

    _compileSubTableColumns() {
        this._subTableColumns = [
            {
                title: 'BondApplicationID',
                field: 'BondApplicationID',
                class: "d-none"
            },
            {
                title: 'Bond Number',
                field: 'BondApplicationDetailID'
            },
            {
                title: 'Bond Type',
                field: 'BondTypeDisplayName'
            },
            {
                title: 'Bond Amt',
                field: 'BondAmt',
                formatter: dollarFieldFormatter
            },
            {
                title: 'Bond Fee',
                field: 'CountyBondFee',
                formatter: dollarFieldFormatter
            },
            {
                title: 'Offense Degree Desc',
                field: 'OffenseDegreeDesc'
            },
            {
                title: 'Offense Desc',
                field: 'OffenseDesc'
            }
        ];

    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        this._compileColumns();
        this._compileSubTableColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
    }

    _createTableConfig() {

        this._tableConfig = {
            detailViewFormatter: this._detailViewFormatter.bind(this),
            detailView: true,
            uniqueId: this._uniqueId,
            tableColumns: this._tableColumns,
            pageSize: 20,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: 'Bond-Listing',
            reportUrl: '/Reports/List_Read',
            restoreFilters: true,
            tableName: "BondList",
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }

    _detailViewFormatter(index, row, element) {
        const subTableUrl = 'BondDetail_Read?bondappID=';
        const uniqueValue = row[`${this._uniqueId}`];
        let loader = `<div class='text-center spinner'><div class='spinner-border' role='status'><span class='sr-only'>Loading...</span></div></div>`;
        let subTable = `<div class='wrapper'>
                                    <div class="card">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" href="#bond-details-${uniqueValue}">Bond Details</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane booking-tab-content active" id="bond-details-${uniqueValue}">
                                                ${loader}
                                                <table class="booking-table" id="bootstrapSubTable-${uniqueValue}"></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>`;
        let tdElement = element[0];
        tdElement.innerHTML = subTable;
        // Append the bond-details information to the bond-details tab content
        const $table = element.find(`table.booking-table`);
        let subTableColumns = this._subTableColumns;
        let bondDetailsCallback = function (response, success) {
            if (success) {
                const jsonResponse = JSON.parse(response);
                $table.bootstrapTable({
                    columns: subTableColumns,
                    data: jsonResponse,
                    pagination: true,
                    pageSize: 5,
                    paginationHAlign: 'left',
                    paginationDetailHAlign: 'left',
                    // by default, pagesize dropdown doesn't work since it is added dynamically,
                    // so to fix this we need to bind it to dropdown everytime the table is reset
                    onResetView: function () {
                        element.find('.dropdown-toggle').dropdown();
                    },
                    classes: 'table table-sm table-striped table-bordered'
                });
                tdElement.querySelector('.booking-tab-content .spinner').classList.add('d-none');
            }
        };
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('GET',
            subTableUrl + row.BondApplicationID,
            null,
            bondDetailsCallback);
    }

}
customElements.define('bond-list-grid', BondListGrid);