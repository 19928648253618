import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './BondCompanyCollateralItem.html';

import './BannerItemCard';

class BondCompanyCollateralItem extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._remainingCollateralLimit = this.shadowRoot.getElementById('remaining-collateral-limit');
        this._pageTools = new PageTools();
    }

    set model(value) {
        this._remainingCollateralLimit.textContent = this._pageTools.formatNumberToDollarAmount(value.RemainingCollateralLimit);
    }
}
customElements.define('bond-company-collateral-item', BondCompanyCollateralItem);