import { nullThrow } from "../TypeScriptFunctions";
import { ButtonRenderer, ButtonRendererParams } from "./ButtonRenderer";
import type { CellRendererSelectorResult, ICellRendererParams, RowClassParams } from "ag-grid-enterprise";

function deleteRow<TData>(
    getDatasetReference: () => TData[],
    params: ICellRendererParams<TData>
) {
    const parentDataset = getDatasetReference();
    const index = parentDataset.indexOf(nullThrow(params.data));
    if (index !== -1)
        parentDataset.splice(index, 1);
    params.api.setGridOption("rowData", parentDataset);
}

function addRow<TData>(
    create: () => TData,
    getDatasetReference: () => TData[],
    params: ICellRendererParams<TData>
) {
    const parentDataset = getDatasetReference();
    parentDataset.push(nullThrow(params.data));
    params.api.setGridOption('pinnedTopRowData', [create()]);
    params.api.setGridOption('rowData', parentDataset);
}

export function basicAddDeleteButtonCellRendererSelector<TData>(
    params: ICellRendererParams<TData>,
    createEmptyData: () => TData,
    getDatasetReference: () => TData[],
    text: string
): CellRendererSelectorResult {
    const resultParams: ButtonRendererParams<TData> = { value: { buttons: [] } };
    const result: CellRendererSelectorResult = {
        component: ButtonRenderer,
        params: resultParams
    }
    if (params.node.isRowPinned()) {
        resultParams.value.buttons.push({
            onClick: (addRow<TData>).bind(undefined, createEmptyData, getDatasetReference),
            text: `Add ${text}`,
            color: "btn-success"
        });
    } else {
        resultParams.value.buttons.push({
            onClick: (deleteRow<TData>).bind(undefined, getDatasetReference),
            text: `Delete ${text}`,
            color: "btn-danger"
        });
    }
    return result;
}

export function getPinnedAddRowStyle<TData>(params: RowClassParams<TData>) {
    return params.node.rowPinned ? { 'font-weight': 'bold', 'font-style': 'italic', 'background-color': '#dbffe5' } : undefined;
}
