import template from './BookOutQueueHaysConfirmModal.html';
import bootstrap from '../../scss/bootstrap-custom.scss';
import '../ModalComponent';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class BookOutQueueHaysConfirmModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._modalComponent = this.shadowRoot.getElementById('hays-confirm-component');
        this._usernameComponent = this.shadowRoot.getElementById('username-container');
        this._continueButton = this.shadowRoot.getElementById('continue-button');
        this._confirmCheckbox = this.shadowRoot.getElementById('confirm-checkbox');
        this._usernameContainer = this.shadowRoot.getElementById('username-container');
        this._inmateContainer = this.shadowRoot.getElementById('inmate-container');

        this._confirmCheckBoxOnChange = this._confirmCheckBoxOnChange.bind(this);
        this._confirmButtonOnClick = this._confirmButtonOnClick.bind(this);
    }

    get _username() {
        return this.getAttribute('username');
    }

    get _inmate() {
        return this.getAttribute('inmate');
    }

    connectedCallback() {
        this._confirmCheckbox.addEventListener('change', this._confirmCheckBoxOnChange);
        this._continueButton.addEventListener('click', this._confirmButtonOnClick);

        this._usernameContainer.innerText = this._username;
        this._inmateContainer.innerText = this._inmate;
        this._modalComponent.openModal('Confirm Inmate Has No Holds', true);
    }

    disconnectedCallback() {
        this._confirmCheckbox.removeEventListener('change', this._confirmCheckBoxOnChange);
        this._continueButton.removeEventListener('click', this._confirmButtonOnClick);
    }

    _confirmCheckBoxOnChange(e) {
        this._continueButton.toggleAttribute('disabled', !e.target.checked);
    }

    _confirmButtonOnClick(e) {
        this._modalComponent.closeModal();
    }
}
customElements.define('book-out-queue-hays-confirm-modal', BookOutQueueHaysConfirmModal);