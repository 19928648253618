import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';
class BondDocumentsCanceledGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="bond-documents-canceled"></bootstrap-report-grid>',
            [],
            []
        );
        this._pageTools = new PageTools();
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
        this._bootstrapTable = this._bootstrapReportGrid.shadowRoot.querySelector('bootstrap-table');
        this._table = this._bootstrapTable.shadowRoot.querySelector('table');
        this._errorMessage = this._bootstrapReportGrid.shadowRoot.getElementById('error-message');
    }

    get _preventBondCompaniesFromViewingSignedBondDocuments() {
        const attribute = 'prevent-bond-companies-from-viewing-signed-bond-documents'
        return this.hasAttribute(attribute) &&
            this.getAttribute(attribute).toLowerCase() === 'true';
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
        this.shadowRoot.querySelector("bootstrap-report-grid").shadowRoot.querySelector('bootstrap-table').shadowRoot.querySelector('#export-pdf').toggleAttribute('hidden', true);
    }

    _compileColumns() {
        const operateEvents = {
            'click .check-for-document': this._checkForDocumentOnClick.bind(this)
        };

        this._tableColumns = [
            {
                title: 'Canceled Date',
                field: 'CanceledDate',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: 'Cancel Reason',
                field: 'CancelReason',
                type: 'Text',
                escape: true
            },
            {
                title: 'Canceled By',
                field: 'CanceledBy',
                type: 'Text',
                escape: true
            },
            {
                title: 'Status When Canceled',
                field: 'StatusWhenCanceled',
                type: 'Text',
                escape: true
            },
            {
                title: 'Power Status',
                field: 'POAStatus',
                type: 'Text',
                escape: true
            },
            {
                title: 'Inmate',
                field: 'InmateFullName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Application ID',
                field: 'BondApplicationID',
                type: 'Number',
                escape: true,
                class: 'd-none',
                printIgnore: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking',
                field: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Offense Degree',
                field: 'OffenseDegree',
                type: 'Text',
                escape: true
            },
            {
                title: 'Offense',
                field: 'OffenseDesc',
                type: 'Text',
                escape: false
            },
            {
                title: 'Bond Number',
                field: 'BondApplicationDetailID',
                type: 'Number',
                escape: true
            },
            {
                title: 'Bond Amt',
                field: 'BondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            }
        ];

        if (!this._preventBondCompaniesFromViewingSignedBondDocuments) {
            this._tableColumns.push({
                title: 'Bond Document',
                field: 'BondDocumentPlaceholder',
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._printPreviewFormatter,
                printIgnore: true
            });
        }

        this._tableColumns.push({
            title: 'Electronic Power Document',
            field: 'ElectronicPowerDocumentPlaceholder',
            type: 'SafeHTML',
            searchable: false,
            escape: false,
            formatter: this._electronicPowerDocumentFormatter,
            printIgnore: true,
            events: operateEvents
        });
    }

    _printPreviewFormatter(value, row, index, field) {
        const bondDocumentId = row.BondDocumentId;
        if (bondDocumentId === null)
            return "<button class='btn btn-primary' disabled>N/A</button>";
        return `<a class='btn btn-primary' href='Download/${bondDocumentId}' target='_blank'>Print Preview</a>`;
    }

    _electronicPowerDocumentFormatter(value, row, index, field) {
        const powerDocumentId = row.PowerDocumentId;
        const powerDocumentGuid = row.PowerDocumentGuid;
        if (powerDocumentId === null)
            return "<button class='btn btn-primary' disabled>N/A</button>";
        else if (powerDocumentGuid === null)
            return `<action-button 
    class='btn btn-primary check-for-document'
    title='Electronic Power Document was not available at the time of bond bookout. Click here to check current availability.'
    alternatebuttontext='Loading'
    powerdocumentid=${powerDocumentId}
    trigger>Check for Document
</action-button>
<a class='btn btn-primary' powerdocumentid=${powerDocumentId} rel='noopener' href ='' target='_blank' hidden>Print Preview</a>`;
        else
            return `<a class='btn btn-primary' href='DownloadPower/${powerDocumentGuid}' target='_blank'>Print Preview</a>`;
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: 'BondDocumentsCanceled',
            tableColumns: this._tableColumns,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: 'Bond-Documents-Canceled',
            reportUrl: '/Reports/BondDocumentsCanceled_Read',
            restoreFilters: true,
            tableName: 'BondDocumentCanceled',
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }

    _checkForDocumentOnClick(event) {
        this._pageTools.toggleTriggers(this._table, true);
        this._errorMessage.toggleAttribute('hidden', true);
        this._clearErrorRowStyle();
        const buttonElement = event.currentTarget;
        const powerDocumentId = Number(buttonElement.getAttribute('powerdocumentid'));
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'POST',
            '/Reports/CheckPowerDocument',
            { id: powerDocumentId },
            this._checkPowerDocumentCallback.bind(this, buttonElement));
    }

    _clearErrorRowStyle() {
        const rows = this._table.querySelectorAll('tr');
        rows.forEach(row => {
            row.classList.toggle('charge-status-hold-without-bond', false);
        });
    }

    _checkPowerDocumentCallback(buttonElement, response, isSuccess) {
        this._pageTools.toggleTriggers(this._table, false);
        const responseObj = this._pageTools.tryParseJson(response);
        if (!isSuccess || !responseObj) {
            this._displayCheckPowerDocumentFailure(buttonElement);
            return;
        }
        const anchorElement = buttonElement.nextElementSibling;
        anchorElement.href = `DownloadPower/${responseObj}`;
        buttonElement.toggleAttribute('hidden', true);
        anchorElement.toggleAttribute('hidden', false);
    }

    _displayCheckPowerDocumentFailure(buttonElement) {
        this._errorMessage.innerHTML =
            'There was a problem retrieving the Electronic Power Document you requested.' +
            ' Please try again, and if the problem persists, <u id="contact-support" onclick="contactUsModal.openModal()" role="button">contact eBONDS Support</u>.' +
            ' Click <u id="return-to" role="button">here</u> to return to the affected entry.';
        this._errorMessage.toggleAttribute('hidden', false);
        const returnToButton = this._errorMessage.querySelector('#return-to');
        returnToButton.addEventListener('click', () => {
            this._pageTools.scrollTo({
                top: buttonElement.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2), //scroll the element to the center of the screen
                behavior: 'smooth'
            }); //Element.scrollIntoView(scrollIntoViewOptions) is not supported on IOS :(
        });
        const parentRow = buttonElement.closest('tr');
        parentRow.classList.add('charge-status-hold-without-bond');
        this._pageTools.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

customElements.define('bond-documents-canceled-grid', BondDocumentsCanceledGrid);