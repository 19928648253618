import bootstrap from '../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './AvailablePowersComponent.html';
import { isAttributeTrue } from "./JavaScriptFunctions";

import './AvailablePowersItem';
import './ActionButton';
import './BondCompanyCollateralItem';

class AvailablePowersComponent extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['main-container']);
        this._mainContainer = this.shadowRoot.getElementById('main-container');
        this._bannerContainer = this.shadowRoot.getElementById('banner-container');
        this._gridContainer = this.shadowRoot.getElementById('grid-container');
        this._powersContainer = this.shadowRoot.getElementById('powers-container');
        this._error = this.shadowRoot.getElementById('error');
        this._noAvailablePowers = this.shadowRoot.getElementById('no-available-powers');
        this._refreshButton = this.shadowRoot.getElementById('refresh-powers');
        this._toggleContainer = this.shadowRoot.getElementById('toggle-container');
        this._reportLink = this.shadowRoot.getElementById('report-link');
        this._progressBar = this.shadowRoot.getElementById('progress-bar');

        this._collateralComponent = this.shadowRoot.querySelector('bond-company-collateral-item');
        this._autoRefreshInterval = 10000;

        this._refreshButtonOnClick = this._refreshButtonOnClick.bind(this);
        this._toggleContainerOnClick = this._toggleContainerOnClick.bind(this);
        this._getAvailablePowersListCallback = this._getAvailablePowersListCallback.bind(this);
        this._updateAvailablePowersList = this._updateAvailablePowersList.bind(this);
    }

    get _bondCompanyId() {
        const companyId = this.getAttribute('bondcompanyid');
        if (companyId)
            return Number(companyId);
        return null;
    }

    get _isBanner() {
        return this.hasAttribute('banner');
    }

    get _isReport() {
        return !this._isBanner;
    }

    get _userIdentifier() {
        return this.getAttribute('user');
    }

    get _isBannerEnabled() {
        const userSettingsString = localStorage.getItem(this._userIdentifier);
        if (userSettingsString === null)
            return true;
        const setting = JSON.parse(userSettingsString).isBannerEnabled;
        return setting === undefined || setting;
    }

    get _isCollateralLimit() {
        return isAttributeTrue(this, "is-collateral-limit");
    }

    connectedCallback() {
        if (this._isBanner) {
            if (this._isBannerEnabled) {
                this._mainContainer.classList.toggle('banner-show');
            } else {
                this._mainContainer.classList.toggle('banner-hide');
            }
            this._bannerContainer.classList.add('bg-light', 'container-banner');
            this._gridContainer.classList.add('grid-container', 'pb-4');
            this._error.classList.add('pb-4');
            this._noAvailablePowers.classList.add('pb-4');
            this.shadowRoot.querySelectorAll('[report-only]').forEach(x => x.toggleAttribute('hidden', true));
        }
        if (this._isReport) {
            this._powersContainer.classList.add('justify-content-center', 'flex-wrap');
            this.shadowRoot.querySelectorAll('[banner-only]').forEach(x => x.toggleAttribute('hidden', true));
        }
        if (this._isCollateralLimit) {
            this.shadowRoot.querySelector('available-powers-item').remove();
            this._collateralComponent.toggleAttribute('hidden', false);
            this._reportLink.toggleAttribute("hidden", true);
        } else {
            this._collateralComponent.remove();
            this.shadowRoot.querySelector('available-powers-item').toggleAttribute('hidden', false);
        }
        this._refreshButton.addEventListener('click', this._refreshButtonOnClick);
        this._refreshButton.click();
        this._toggleContainer.addEventListener('click', this._toggleContainerOnClick);
    }

    disconnectedCallback() {
        this._refreshButton.removeEventListener('click', this._refreshButtonOnClick);
        this._toggleContainer.removeEventListener('click', this._toggleContainerOnClick);
    }

    _updateAvailablePowersList() {
        this._setTimerToIndeterminate();
        let requestUrl = '/Reports/GetAvailablePowersList';
        
        if (this._isCollateralLimit) {
            requestUrl = `/Reports/GetOwnerBondCompanyAvailableCollateral`;
        } else if (this._bondCompanyId) {
            requestUrl = `/Reports/GetBondCompanyAvailablePowersList?bondCompanyId=${this._bondCompanyId}`;
        }

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('GET',
            requestUrl,
            null,
            this._getAvailablePowersListCallback);
    }

    _getAvailablePowersListCallback(powerInventoryResultListJson, success) {
        if (this._isBanner && this._isBannerEnabled) {
            this._startUpdateTimer();
        }
        if (!success) {
            this._error.toggleAttribute('hidden', false);
            this._gridContainer.toggleAttribute('hidden', true);
            this._refreshButton.toggleAttribute('disabled', false);
            return;
        }
        this._error.toggleAttribute('hidden', true);
        const model = JSON.parse(powerInventoryResultListJson);

        if (this._isCollateralLimit) {
            this._collateralComponent.model = model;
        } else {
            while (this._powersContainer.children.length > model.length) {
                this._powersContainer.removeChild(this._powersContainer.lastElementChild);
            }
            this._noAvailablePowers.toggleAttribute('hidden', model.length > 0);
            this._gridContainer.toggleAttribute('hidden', model.length === 0);

            model.sort((a, b) => {
                return b.LiabilityLimit - a.LiabilityLimit;
            }).forEach((x, index) => {
                let item;
                if (index < this._powersContainer.children.length) {
                    item = this._powersContainer.children.item(index);
                } else {
                    item = document.createElement('available-powers-item');
                    this._powersContainer.appendChild(item);
                }
                item.limit = x.LiabilityLimit;
                item.amount = x.PowerCount;
            });
        }
        this._refreshButton.toggleAttribute('disabled', false);
    }

    _refreshButtonOnClick() {
        this._refreshButton.toggleAttribute('disabled', true);
        this._updateAvailablePowersList();
    }

    _toggleContainerOnClick() {
        const shouldBeHidden = this._mainContainer.classList.contains('banner-show');

        this._mainContainer.classList.toggle('banner-show', !shouldBeHidden);
        this._mainContainer.classList.toggle('banner-hide', shouldBeHidden);

        const userSettings = JSON.parse(localStorage.getItem(this._userIdentifier)) || {};
        userSettings.isBannerEnabled = !shouldBeHidden;
        localStorage.setItem(this._userIdentifier, JSON.stringify(userSettings));

        if (shouldBeHidden) {
            this._stopUpdateTimer();
        } else {
            this._startUpdateTimer();
        }
    }

    _startUpdateTimer() {
        this._stopUpdateTimer();
        this._autoRefreshTimer = setTimeout(this._updateAvailablePowersList, this._autoRefreshInterval);
        this._toggleProgressBarClasses(true);
    }

    _stopUpdateTimer() {
        clearTimeout(this._autoRefreshTimer);
        this._progressBar.classList.remove('bg-success');
        this._progressBar.classList.remove('countdown');
        this._progressBar.classList.remove('indeterminate');
    }

    _setTimerToIndeterminate() {
        this._toggleProgressBarClasses(false);
    }

    _toggleProgressBarClasses(setToCountdownState) {
        this._progressBar.classList.toggle('bg-success', !setToCountdownState);
        this._progressBar.classList.toggle('countdown', setToCountdownState);
        this._progressBar.classList.toggle('indeterminate', !setToCountdownState);
    }
}
customElements.define('available-powers-component', AvailablePowersComponent);