export function generateSmartSearchTooltip(firstNameLength, lastNameLength, allowDob, allowBooking, allowSoNumber) {
    let message = '<b>Smart search criteria</b>' +
        `<br> First Name minimum length: ${firstNameLength}` +
        `<br> Last Name minimum length: ${lastNameLength}`;
    if (allowDob) {
        message += '<br> Date of Birth search enabled';
    }
    if (allowBooking) {
        message += '<br> Booking Number search enabled';
    }
    if (allowSoNumber) {
        message += '<br> SO Number search enabled';
    }

    return message;
}

export function generateSmartSearchPlaceholder(allowDob, allowBooking, allowSoNumber) {
    let message = "Search for inmate by ";
    const items = ["First Name", "Last Name"];
    if (allowDob) {
        items.push('DOB');
    }
    if (allowBooking) {
        items.push("Booking");
    }
    if (allowSoNumber) {
        items.push("SO Number")
    }
    items[items.length - 1] = 'or ' + items[items.length - 1];
    let separator = ' ';
    if (items.length > 2)
        separator = ', ';
    message += items.join(separator);

    return message;
}