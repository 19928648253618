import template from './MugshotCarousel.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css'
import './CustomCarousel';

class MugshotCarousel extends HTMLElement {

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome]);
        this._images = [];
        this._thumbnails = [];
        this._carouselSlot = document.createElement('div');
        this._mugshotCarouselTemplate = this.shadowRoot.getElementById('mugshot-carousel-template');
        this._mugshotCarousel = this._mugshotCarouselTemplate.content.getElementById('mugshot-carousel');
        this._closeButton = this._mugshotCarouselTemplate.content.getElementById('close-carousel');
        this._customCarousel = this._mugshotCarouselTemplate.content.querySelector('custom-carousel');
        this._mugshotThumbnail = this.shadowRoot.getElementById('mugshot-thumbnail');
        this._thumbnailContainer = this.shadowRoot.getElementById('thumbnail-container');
        this._thumbnailAndIconContainer = this.shadowRoot.getElementById('thumbnail-and-icon-container');
        this._thumbnailLoading = this.shadowRoot.getElementById('thumbnail-loading');
        this._noMugShotsAvailable = this.shadowRoot.getElementById('no-mugshots-available');
        this._mugshotIcon = this.shadowRoot.getElementById('mugshot-icon');
        this._toggleCloseCarousel = this._toggleCloseCarousel.bind(this);
        this._openCarousel = this._openCarousel.bind(this);
        this._onThumbnailLoad = this._onThumbnailLoad.bind(this);
        this._closeCarousel = this._closeCarousel.bind(this);
    }

    static get observedAttributes() {
        return ['mugshots', 'tenant-name'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;

        switch(name) {
            case 'mugshots':
                this.mugshots = JSON.parse(newValue);
                break;
            case 'tenant-name':
                this.tenantName = newValue;
                break;
        }
    }

    set tenantId(value) {
        this._tenantId = value
    }

    set mugshots(value) {
        const mugshots = value;
        const route = this._tenantId ? "PaymentPortal" : "Mugshot"
        if (!mugshots) { // Feature is disabled when null is sent
            this.classList.add('d-none');
            return;
        }

        if (mugshots.length === 0) {
            this._noMugShotsAvailable.toggleAttribute('hidden', false);
            this._thumbnailAndIconContainer.toggleAttribute('hidden', true);
            return;
        }
        this._noMugShotsAvailable.toggleAttribute('hidden', true);

        if (mugshots.length < 2) {
            this._mugshotIcon.toggleAttribute('hidden', true);
        }

        this._thumbnailLoading.toggleAttribute('hidden', false);

        mugshots.sort((a, b) => {
            if (a.Type.toLowerCase() === 'front')
                return -1;
            if (b.Type.toLowerCase() === 'front')
                return 1;
            return 0;
        });

        this._images = mugshots.map(x => this._addTenantIdToQueryIfAllpaid(`/${route}/GetMugshot?mugshotName=${x.ImageName}`));
        this._thumbnails = mugshots.map(x => this._addTenantIdToQueryIfAllpaid(`/${route}/GetMugshot?mugshotName=${x.ThumbnailName}`));
        this._mugshotThumbnail.src = this._thumbnails[0];
    }

    _addTenantIdToQueryIfAllpaid(str) {
        if (this._tenantId)
            return str + `&tenantId=${this._tenantId}`
        return str;
    }

    set tenantName(value) {
        this._noMugShotsAvailable.innerText = `No mugshots are available from ${value}`;
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._toggleCloseCarousel);
        this._mugshotIcon.addEventListener('click', this._openCarousel);
        this._thumbnailContainer.addEventListener('click', this._openCarousel);
        this._mugshotThumbnail.addEventListener('load', this._onThumbnailLoad);
        document.addEventListener('keydown', this._closeCarousel);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._toggleCloseCarousel);
        this._mugshotIcon.removeEventListener('click', this._openCarousel);
        this._thumbnailContainer.removeEventListener('click', this._openCarousel);
        this._mugshotThumbnail.removeEventListener('load', this._onThumbnailLoad);
        document.removeEventListener('keydown', this._closeCarousel);
    }

    _toggleCloseCarousel() {
        if (document.body.contains(this._carouselSlot)) {
            document.body.removeChild(this._carouselSlot);
            Array.from(this._carouselSlot.childNodes).forEach(x => {
                this._mugshotCarouselTemplate.content.appendChild(x);
            });
        }
    }

    _openCarousel() {
        this._carouselSlot.appendChild(this._mugshotCarouselTemplate.content);
        document.body.appendChild(this._carouselSlot);
        this._customCarousel.images = this._images;
        this._customCarousel.thumbnails = this._thumbnails;
    }

    _onThumbnailLoad() {
        this._thumbnailAndIconContainer.toggleAttribute('hidden', false);
        this._thumbnailLoading.toggleAttribute('hidden', true);
    }

    _closeCarousel(event) {
        if (event.key === "Escape") {
            this._toggleCloseCarousel();
        }
    }
}
customElements.define('mugshot-carousel', MugshotCarousel)