const bondWorkflow = {
    surety: { value: 0, string: "Surety" },
    cash: { value: 1, string: "Cash" },
    pr: { value: 2, string: "Personal Recognizance" },
    attorney: { value: 3, string: "Attorney" },
    electronicSurety: { value: 4, string: "Electronic Powers Surety"},
    manualSurety: { value: 5, string: "Manual Powers Surety"},
    ownerSurety: { value: 6, string: "Owner Surety" },
    property: { value: 7, string: "Property"},
    companyAttorney: { value: 8, string: "Company Attorney"}
};

export default bondWorkflow;