import { initializeHtmlElement } from "./../HTMLElementExtensions";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import "bootstrap-table/dist/bootstrap-table";
import { createColumn } from "../BootstrapTableExtensions";
import template from "./BondDocumentTemplates.html";
import "./../ActionButton";

class BondDocumentTemplates extends HTMLElement {
    constructor() {
        super();
        this._pageTools = new PageTools();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ["container"]);
        this._alert = this.shadowRoot.getElementById("alert");
        this._documentsTable = this.shadowRoot.getElementById("documents-table");
        this._newDocumentInput = this.shadowRoot.getElementById("new-document-input");
        this._addNewDocumentButton = this.shadowRoot.getElementById("add-new-document-button");
        this._createNewDocumentInput = this.shadowRoot.getElementById("create-new-document-input");
        this._uploadNewDocumentInput = this.shadowRoot.getElementById("upload-new-document-input");

        this._addNewDocumentButtonOnClick = this._addNewDocumentButtonOnClick.bind(this);
        this._addNewDocumentOnChange = this._addNewDocumentOnChange.bind(this);
        this._uploadDocumentTemplate = this._uploadDocumentTemplate.bind(this);
    }

    connectedCallback() {
        this._addNewDocumentButton.addEventListener("click", this._addNewDocumentButtonOnClick);
        this._createNewDocumentInput.addEventListener("change", this._addNewDocumentOnChange);
        this._uploadNewDocumentInput.addEventListener("change", this._uploadDocumentTemplate);
        this._initialize();
    }

    disconnectedCallback() {
        this._addNewDocumentButton.removeEventListener("click", this._addNewDocumentButtonOnClick);
        this._createNewDocumentInput.removeEventListener("change", this._addNewDocumentOnChange);
        this._uploadNewDocumentInput.removeEventListener("change", this._uploadDocumentTemplate);
    }

    _isOutOfCounty() {
        return this.hasAttribute("out-of-county");
    }

    _isReceipt() {
        return this.hasAttribute("receipt");
    }

    _isTotalBond() {
        return this.hasAttribute("total-bonds");
    }

    set model(value) {
        const $table = $(this._documentsTable);
        $table.bootstrapTable("load", value);
        $table.bootstrapTable("hideLoading");

        this._documentsTable.querySelectorAll("action-button[data-view]")
            .forEach(x => x.addEventListener("click", this._viewOnClick.bind(this)));
        this._documentsTable.querySelectorAll("action-button[data-upload]")
            .forEach(x => x.addEventListener("click", this._uploadOnClick.bind(this)));
        this._documentsTable.querySelectorAll("action-button[data-delete]")
            .forEach(x => x.addEventListener("click", this._deleteOnClick.bind(this)));
    }

    _getTableTypeName() {
        if (this._isReceipt())
            return "Receipt Template Name";

        if (this._isTotalBond())
            return "Total Bonds Template Name";

        return "Template Name";
    }

    _getEndPoint() {
        if (this._isReceipt())
            return "GetReceiptTemplate";

        return `GetBondDocumentTemplates?outOfCounty=${this._isOutOfCounty()}&totalBond=${this._isTotalBond()}`;
    }

    _initialize() {
        const tableType = this._getTableTypeName();
        const $table = $(this._documentsTable);
        const columns = [
            createColumn(tableType, "name", true),
            createColumn("", "bondDocumentTemplateId", false, this._viewButtonFormatter),
            createColumn("", "bondDocumentTemplateId", false, this._uploadButtonFormatter),
            createColumn("", "bondDocumentTemplateId", false, this._deleteButtonFormatter)
        ];

        // Set button columns to min-width which will fit content
        columns[1].width = 1;
        columns[2].width = 1;
        columns[3].width = 1;

        $table.bootstrapTable({
            columns: columns,
            classes: "table table-sm table-striped table-bordered",
            uniqueId: "bondDocumentTemplateId"
        });

        $table.bootstrapTable("showLoading");

        const endpoint = this._getEndPoint();

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/SysAdmin/${endpoint}`,
            null,
            this._initializeCallback.bind(this));
    }

    _initializeCallback(response, isSuccess) {
        if (!isSuccess) {
            this._showAlert(response, true);
            return;
        }

        this.model = JSON.parse(response);
    }

    _viewButtonFormatter(value) {
        return `<div><action-button class="btn btn-primary" data-view="${value}" trigger>View</action-button></div>`;
    }

    _uploadButtonFormatter(value) {
        return `<div><action-button class="btn btn-success" data-upload="${value}" trigger>Upload</action-button></div>`;
    }

    _deleteButtonFormatter(value) {
        return `<div><action-button class="btn btn-danger" data-delete="${value}" trigger>Delete</action-button></div>`;
    }

    _viewOnClick(e) {
        const bondDocumentTemplateId = e.target.getAttribute("data-view");
        const elem = document.createElement("a");
        elem.setAttribute("href", `/SysAdmin/BondDocumentTemplate/${bondDocumentTemplateId}`);
        elem.setAttribute("target", "_blank");
        elem.click();
    }

    _uploadOnClick(e) {
        this._uploadNewDocumentInput.setAttribute("templateId", e.target.getAttribute("data-upload"));
        this._uploadDocument();
    }

    _deleteOnClick(e) {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const bondDocumentTemplateId = e.target.getAttribute("data-delete");
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST",
            "/SysAdmin/DeleteBondDocumentTemplate",
            { bondDocumentTemplateId: bondDocumentTemplateId },
            this._deleteExistingTemplate.bind(this));
    }

    _addNewDocumentButtonOnClick() {
        this._createNewDocumentInput.click();
    }

    _addNewDocumentOnChange(e) {
        const documentName = this._newDocumentInput.value;
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._addNewDocumentButton.setSpinner(true);
        const input = e.target;
        const formData = new FormData();
        const inputFile = input.files[0];
        input.value = ""; // Clear the input selection to allow for same file to be selected again in case of error
        if (!inputFile) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            this._showAlert("No file attached", true);
            return;
        }
        formData.append("name", documentName);
        formData.append("templateFile", inputFile);
        formData.append("outOfCounty", this._isOutOfCounty());
        formData.append("isReceipt", this._isReceipt());
        formData.append("isTotalBond", this._isTotalBond());

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST",
            "/SysAdmin/BondDocumentTemplate",
            formData,
            this._addNewDocumentCallback.bind(this));
    }

    _uploadDocument() {
        this._removeAlert();
        this._uploadNewDocumentInput.click();
    }

    _uploadDocumentTemplate(event) {
        const templateId = this._uploadNewDocumentInput.getAttribute("templateId");
        const buttonElement = this.shadowRoot.querySelector(`[data-upload='${templateId}']`);
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        buttonElement.setSpinner(true);
        const input = event.target;
        const formData = new FormData();
        formData.append("templateFile", input.files[0]);
        formData.append("bondDocumentTemplateId", templateId);
        input.value = "";
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST",
            "/SysAdmin/UpdateBondDocumentTemplate",
            formData,
            this._uploadDocumentCallback.bind(this));
    }

    _addNewDocumentCallback(response, isSuccess) {
        this._responseCallback(response, isSuccess, "New Template Successfully Added");
        this._refreshBootstrap();
    }

    _uploadDocumentCallback(response, isSuccess) {
        this._responseCallback(response, isSuccess, "Template Successfully Updated");
    }

    _deleteExistingTemplate(response, isSuccess) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._responseCallback(response, isSuccess, "Template Successfully Removed");
        this._refreshBootstrap();
    }

    _responseCallback(response, isSuccess, message) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!isSuccess) {
            this._showAlert(response, true);
            return;
        }
        this._showAlert(message, false);
    }

    _showAlert(message, isError) {
        this._alert.classList.toggle("text-success", !isError);
        this._alert.classList.toggle("text-danger", isError);
        this._alert.toggleAttribute("hidden", false);
        this._alert.textContent = message;
    }

    _removeAlert() {
        this._alert.toggleAttribute("hidden", true);
        this._alert.textContent = "";
    }

    _refreshBootstrap() {
        this.dispatchEvent(new Event("templates-changed"));
        const $table = $(this._documentsTable);
        $table.bootstrapTable("destroy");
        this._initialize();
    }
}
customElements.define("bond-document-templates", BondDocumentTemplates);