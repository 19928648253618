import template from './PaymentGatewayManagement.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import paymentGateway from './Enumerations/PaymentGateway';

class PaymentGatewayManagement extends HTMLElement{
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], ['container']);

        this._model = null;
        this._submitButton = this.shadowRoot.getElementById('submit-button');
        this._paymentGatewayDropdown = this.shadowRoot.getElementById('payment-gateway-dropdown');
        this._usernameContainer = this.shadowRoot.getElementById('username-container');
        this._passwordContainer = this.shadowRoot.getElementById('password-container');
        this._applicationIdContainer = this.shadowRoot.getElementById('application-id-container');
        this._usernameLabel = this.shadowRoot.getElementById('username-label');
        this._passwordLabel = this.shadowRoot.getElementById('password-label');
        this._applicationIdLabel = this.shadowRoot.getElementById('application-id-label');
        this._usernameElement = this.shadowRoot.getElementById('input-username');
        this._passwordElement = this.shadowRoot.getElementById('input-password');
        this._applicationIdElement = this.shadowRoot.getElementById('input-application-id');
        this._alertContainer = this.shadowRoot.getElementById('alert-container');
        this._callbackTextContainer = this.shadowRoot.getElementById('show-message-data');
        this._allowAmexCheckbox = this.shadowRoot.getElementById('allow-amex-checkbox');
        this._allowDiscoverCheckbox = this.shadowRoot.getElementById('allow-discover-checkbox');
        this._allowMastercardCheckbox = this.shadowRoot.getElementById('allow-mastercard-checkbox');
        this._allowOtherCheckbox = this.shadowRoot.getElementById('allow-other-checkbox');
        this._allowVisaCheckbox = this.shadowRoot.getElementById('allow-visa-checkbox');
        this._form = this.shadowRoot.querySelector('form');
        this._pageTools = new PageTools();

        this._submitGateway = this._submitGateway.bind(this);
        this._paymentGatewayDropdownChange = this._paymentGatewayDropdownChange.bind(this);

        for (const [, x] of Object.entries(paymentGateway)) {
            this._paymentGatewayDropdown.options.add(new Option(x.string, x.value));
        }
    }

    set model(value) {
        this._model = value;
        this._setFieldsOnLoad();
    }

    connectedCallback() {
        this._submitButton.addEventListener('click', this._submitGateway);
        this._paymentGatewayDropdown.addEventListener('change', this._paymentGatewayDropdownChange);
    }

    disconnectedCallback() {
        this._submitButton.removeEventListener('click', this._submitGateway);
        this._paymentGatewayDropdown.removeEventListener('change', this._paymentGatewayDropdownChange);
    }

    _setFieldsOnLoad() {
        const applicationId = this._model.ApplicationId;
        const name = this._model.Name;
        const username = this._model.Username;
        if (name) {
            this._paymentGatewayDropdown.value = name;
            this._paymentGatewayDropdown.dispatchEvent(new Event('change'));
        }
        if (username) {
            this._usernameElement.value = username;
        }
        if (applicationId || applicationId === 0) {
            this._applicationIdElement.value = applicationId;
        }

        this._allowAmexCheckbox.checked = this._model.AllowAmex;
        this._allowDiscoverCheckbox.checked = this._model.AllowDiscover;
        this._allowMastercardCheckbox.checked = this._model.AllowMastercard;
        this._allowOtherCheckbox.checked = this._model.AllowOther;
        this._allowVisaCheckbox.checked = this._model.AllowVisa;
    }

    _paymentGatewayDropdownChange(e) {
        const paymentGatewayInformation = {
            [paymentGateway.demo.value]: {},
            [paymentGateway.velocity.value]: {
                Username: 'Username',
                Password: 'Password',
                ApplicationId: 'Application ID'
            },
            [paymentGateway.authorizeDotNet.value]: {
                Username: 'API Login ID',
                Password: 'Transaction Key'
            },
            [paymentGateway.certifiedPaymentsDotNet.value]: {
                Username: 'Client ID',
                Password: 'Client Key',
                ApplicationId: 'Bureau Code'
            },
            [paymentGateway.epx.value]: {
                Username: 'EPI ID',
                Password: 'EPI Key'
            }
        };

        const selectedPaymentGatewayInformation = paymentGatewayInformation[e.target.value] || {};

        this._usernameContainer.toggleAttribute('hidden', !selectedPaymentGatewayInformation.Username);
        this._passwordContainer.toggleAttribute('hidden', !selectedPaymentGatewayInformation.Password);
        this._applicationIdContainer.toggleAttribute('hidden', !selectedPaymentGatewayInformation.ApplicationId);

        this._usernameLabel.innerText = selectedPaymentGatewayInformation.Username;
        this._passwordLabel.innerText = selectedPaymentGatewayInformation.Password;
        this._applicationIdLabel.innerText = selectedPaymentGatewayInformation.ApplicationId;
    }

    _createPaymentGatewayObject() {
        return {
            name: this._paymentGatewayDropdown.value,
            username: this._usernameElement.value,
            password: this._passwordElement.value,
            applicationId: this._applicationIdElement.value,
            allowAmex: this._allowAmexCheckbox.checked,
            allowDiscover: this._allowDiscoverCheckbox.checked,
            allowMastercard: this._allowMastercardCheckbox.checked,
            allowOther: this._allowOtherCheckbox.checked,
            allowVisa: this._allowVisaCheckbox.checked
        };
    }

    _submitGateway() {
        this._alertContainer.toggleAttribute('hidden', true);
        this._alertContainer.classList.remove('alert-success');
        this._alertContainer.classList.remove('alert-danger');
        if (!this._form.checkValidity()) {
            this._form.reportValidity();
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const paymentGatewayDataObject = this._createPaymentGatewayObject();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST',
            '/Admin/UpsertPaymentGateway',
            paymentGatewayDataObject,
            this._submitCallback.bind(this));
    }

    _submitCallback(response, status) {
        this._alertContainer.toggleAttribute('hidden', false);
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (status) {
            this._alertContainer.classList.add('alert-success');
            this._callbackTextContainer.textContent = 'Successfully updated the payment gateway client settings.';
        } else {
            this._alertContainer.classList.add('alert-danger');
            this._callbackTextContainer.textContent = typeof (response) === 'string' ? response : 'There was an error updating the payment gateway client settings.';
        }
    }
}
customElements.define('payment-gateway-management', PaymentGatewayManagement);