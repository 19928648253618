import "../ActionButton";
import "../AlertMessage";
import "../Grid/BasicGrid";
import "./BondTypeManagementJmsBondTypeGrid";
import "./BondTypeManagementEbondsBondTypeGrid";
import "bootstrap";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import template from "./BondTypeManagement.html";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import type { ActionButton } from "../ActionButton"
import type { AlertMessage } from "../AlertMessage";
import type { BondTypeManagementJmsBondTypeGrid } from "./BondTypeManagementJmsBondTypeGrid";
import type { BondTypeManagementEbondsBondTypeGrid } from "./BondTypeManagementEbondsBondTypeGrid";
import type { BondTypeManagementViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/BondTypeManagementViewModel";

export class BondTypeManagement extends HTMLElement {
    private _pageTools: IPageTools = new PageTools();
    private _alertMessage: AlertMessage;
    private _jmsGrid: BondTypeManagementJmsBondTypeGrid;
    private _ebondsGrid: BondTypeManagementEbondsBondTypeGrid;
    private _data: BondTypeManagementViewModel = { JmsBondTypes: [], BondTypes: []};

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], []);
        this._alertMessage = <AlertMessage>nullThrow(this.shadowRoot?.getElementById("alert-message"));
        this._jmsGrid = <BondTypeManagementJmsBondTypeGrid>nullThrow(this.shadowRoot?.getElementById("jms-bond-type-grid"));
        this._ebondsGrid = <BondTypeManagementEbondsBondTypeGrid>nullThrow(this.shadowRoot?.getElementById("ebonds-bond-type-grid"));

        this._jmsGrid.addEventListener("alert", this.alertFired.bind(this));
    }

    connectedCallback() {
        const saveButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("save-button"));
        const cancelButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("cancel-button"));
        const titleTenant = nullThrow(this.shadowRoot?.getElementById("title-tenant"));
        const cardHeaders = nullThrow(this.shadowRoot?.querySelectorAll(".card-header"));
        cardHeaders.forEach(element => {
            element.addEventListener("click", () => {
                const collapsible = nullThrow(element.parentElement?.querySelector('.collapse'));
                $(collapsible).collapse('toggle');
                collapsible.previousElementSibling?.querySelectorAll('.fa').forEach(x => x.toggleAttribute('hidden'));
            });
        });
        saveButton.addEventListener("click", this.saveClicked.bind(this));
        cancelButton.addEventListener("click", this.cancelClicked.bind(this));
        titleTenant.textContent = settings.tenant;

        this.refreshData();
    }

    private refreshData() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        const callback = (response: string, status: boolean) => {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            this._ebondsGrid.gridApi?.setGridOption("loading", false);
            this._jmsGrid.gridApi?.setGridOption("loading", false);
            if (!status) {
                this.showAlert(response, true);
                return;
            }
            const data = JSON.parse(response) as BondTypeManagementViewModel
            this._data = data;
            this._ebondsGrid.data = data;
            this._jmsGrid.data = data.JmsBondTypes;
        }

        this.showAlert();
        this._ebondsGrid.gridApi?.setGridOption("loading", true);
        this._jmsGrid.gridApi?.setGridOption("loading", true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET", "BondTypes/Get", null, callback)
    }

    private saveData() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        const callback = (response: string, status: boolean) => {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            this._ebondsGrid.gridApi?.setGridOption("loading", false);
            this._jmsGrid.gridApi?.setGridOption("loading", false);
            if (!status) {
                this.showAlert(response, true);
                return;
            }
            this.showAlert("Saved");
        }

        this.showAlert();
        this._ebondsGrid.gridApi?.setGridOption("loading", true);
        this._jmsGrid.gridApi?.setGridOption("loading", true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "BondTypes/Update", { model: this._data }, callback)
    }

    private saveClicked() {
        this.saveData();
    }

    private cancelClicked() {
        this.refreshData();
    }

    private showAlert(message: string = "", error: boolean = false) {
        if (error) {
            this._alertMessage.makeMessageAnError();
        } else {
            this._alertMessage.makeMessageASuccess();
        }

        this._alertMessage.innerHTML = message;
    }

    private alertFired(e: CustomEvent<AlertParams>) {
        this.showAlert(e.detail.message, e.detail.error);
    }
}

customElements.define("bond-type-management", BondTypeManagement);