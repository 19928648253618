const jms = {
    odysseyOnPremises: { value: 0, string: 'Odyssey On-Premises' },
    odysseyCloud: { value: 1, string: 'Odyssey Cloud' },
    southernSoftwareOnPremises: { value: 2, string: 'Southern Software On-Premises' },
    kologikCloud: { value: 3, string: 'Kologik Cloud' },
    ssiCloud: { value: 4, string: 'SSI Cloud' },
    spillmanCloud: { value: 5, string: 'Spillman Cloud' },
    eforceCloud: { value: 6, string: 'EFORCE Cloud' },
    standardJmsCloud: { value: 7, string: 'eBONDS Standard Cloud' },
    cpsoCloud: {value: 8, string: 'CPSO Cloud' },
    centralSquareCloud: {value: 9, string: 'Central Square Cloud' },
    spillmanOnPremises: { value: 10, string: 'Spillman On-Premises' },
    threeSixtyFiveLabsCloud: { value: 11, string: '365Labs Cloud' }
};
export default jms;