import template from './CardLogo.html';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CardLogo extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template);
    }
    static get observedAttributes() {
        return ["type"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;

        if (name === 'type') {
            this.setLogo(newValue);
        }
    }

    setLogo(logoType) {
        const elems = this.shadowRoot.querySelectorAll('.cc-logo-container');

        elems.forEach(x => {
            x.classList.remove('active');
            if (x.getAttribute('type') === this.convertTypeToString(logoType))
                x.classList.add('active');
        });
    }
    convertTypeToString(logoType) {
        switch (logoType) {
        case '0':
            return 'Visa';
        case '1':
            return 'MasterCard';
        case '2':
            return 'Amex';
        case '3':
            return 'Discover';
        case '4':
            return 'Other';
        default:
            return logoType;
        }
    }
}

customElements.define('card-logo', CardLogo);