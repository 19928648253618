import template from './CardManagementItem.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import './CardLogo';
import './CardManagementFooter';
import './AuthPaymentCardFooter';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CardManagementItem extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();

        this._parentElem = this.shadowRoot.querySelector('#card-item');
        this._cardLogoElem = this.shadowRoot.querySelector('card-logo');
        this._groupOne = this.shadowRoot.querySelector('.cmi-group-one');
        this._headerContainer = this.shadowRoot.querySelector('#cmi-section-container');
        this._controlsElem = this.shadowRoot.querySelector('.cmi-group-three');
        this._expirationDate = this.shadowRoot.getElementById('expiration-date');
        this._expirationMessage = this.shadowRoot.getElementById('expires-message');
        this._cmiNumberElement = this.shadowRoot.getElementById('cmi-number');
        this._cmiNameElement = this.shadowRoot.getElementById('cmi-name');
        this._cmiBillingElement = this.shadowRoot.getElementById('cmi-billing');
        this._cmiZipElement = this.shadowRoot.getElementById('cmi-zip');

        this.toggleDetailSection = this.toggleDetailSection.bind(this);
        this.closeDetailSection = this.closeDetailSection.bind(this);
    }

    connectedCallback() {
        this.setup();
        this._headerContainer.addEventListener('click', this.toggleDetailSection);
    }

    disconnectedCallback() {
        this._headerContainer.removeEventListener('click', this.toggleDetailSection);
    }

    set model(value) {
        this._model = value;
        this._cmiNumberElement.innerText = value.CardNumber;
        if (!value.CardholderFirstName || !value.CardholderLastName)
            this._cmiNameElement.innerText = value.CardHolderName;
        else
            this._cmiNameElement.innerText = value.CardholderFirstName + ' ' + value.CardholderLastName;
        this._cmiBillingElement.innerText = value.BillingAddress;
        this._cmiZipElement.innerText = value.Zipcode;
        this._expirationDate.innerText = value.ExpirationDate;
        
        this.makeExpirationColored();
    }

    get model() {
        return this._model;
    }

    setup() {
        this.makeLogo();
        this.makeDefault();
        this.makeExpirationColored();
    }

    makeDefault() {
        const defaultedSpan = this.shadowRoot.querySelector('.cmi-group-three span');
        defaultedSpan.textContent = '';
        this._parentElem.classList.remove('defaulted');
        if (this.model.IsDefault) {
            if (!this._parentElem.classList.contains('defaulted')) {
                this._parentElem.classList.add('defaulted');
                defaultedSpan.textContent = 'Default';
            }
        }
    }

    makeExpirationColored() {
        const checkExpired = this._checkExpired(this.model.ExpirationDate);
        this._expirationDate.classList.remove("badge-none", "badge-warning", "badge-danger");
        this._expirationDate.classList.add(checkExpired.class);
        this._expirationMessage.textContent = checkExpired.message;

    }

    _checkExpired(date) {
        if (this._pageTools.checkExpired(date))
            return { class: 'badge-danger', message: 'Expired' };
        else if (this._pageTools.checkExpiringSoon(date))
            return { class: 'badge-warning', message: 'Expires soon' };
        return { class: 'badge-none', message: null }
    }

    makeLogo() {
        this._cardLogoElem.setAttribute('type', this.model.CardType);
    }

    closeDetailSection() {
        this._parentElem.setAttribute('status', 'closed');
    }

    toggleDetailSection() {
        if (this._parentElem.hasAttribute('status') && this._parentElem.getAttribute('status') === 'opened') {
            this._parentElem.setAttribute('status', 'closed');
            return;
        }
        this._parentElem.setAttribute('status', 'opened');
    }
}

customElements.define('card-management-item', CardManagementItem);