import "bootstrap-table/dist/bootstrap-table";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import bootstrap from "../scss/bootstrap-custom.scss";
import "./ActionButton";
import "./AlertMessage";
import {
    chargeStatusFieldFormatter, createColumn,
    dollarFieldFormatter, getRowChargeStatusCssClass
} from "./BootstrapTableExtensions";
import bondWorkflow from "./Enumerations/BondWorkflow";
import chargeStatus from "./Enumerations/ChargeStatus";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import "./InmateInformation";
import { getTotalBondGrouping, isAttributeTrue, totalBondGroupErrors } from "./JavaScriptFunctions";
import template from "./NewApplication.html";
import "./Roster/SyncFailureDialog";

class NewApplication extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ["container"]);

        this._pageTools = new PageTools();
        this._bondTypes = null;
        this._bookNumber = null;
        this._jailId = null;
        this._hasSkipPermission = null;
        this._bondApplicationLimit = null;
        this._bondTotal = null;
        this._bondCompany = null;
        this._totalBondConfigs = null;
        this._tenantId = null;

        this._tableHeaderInputSelector = ".bs-checkbox > .th-inner input";
        this._inmateInformation = this.shadowRoot.getElementById("inmate-information");
        this._table = this.shadowRoot.getElementById("table");
        this._backButton = this.shadowRoot.getElementById("back-button");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._syncFailureDialog = this.shadowRoot.getElementById("sync-failure-dialog");
        this._buttonContainer = this.shadowRoot.getElementById("button-container");

        this._disableCreateButtons = this._disableCreateButtons.bind(this);
        this._statusCheck = this._statusCheck.bind(this);
        this._checkAll = this._checkAll.bind(this);
        this._checkWarrantNumberGroup = this._checkWarrantNumberGroup.bind(this);

        this._backButtonOnclick = this._backButtonOnclick.bind(this);
        this._retryCreateApplication = this._retryCreateApplication.bind(this);
    }

    get _isCounty() {
        return isAttributeTrue(this, "isCounty");
    }

    get _bondTypeEnforcementFeatureFlag() {
        return isAttributeTrue(this, "bond-type-enforcement-feature-flag");
    }

    get _totalBondFeatureFlag() {
        return isAttributeTrue(this, "total-bond-feature-flag");
    }

    get _showCourtName() {
        return isAttributeTrue(this, "court-name-shows-for-bond-application-feature-flag");
    }

    set bondApplicationLimit(value) {
        this._bondApplicationLimit = value;
    }

    set bondCompany(value) {
        this._bondCompany = value;
    }

    set bondTypes(value) {
        this._bondTypes = value;
        const bondTypeValues = Object.values(bondWorkflow);
        const generatedButtons = value.map(x => {
            const bondTypeName = bondTypeValues.find(y => y.value === x.bondWorkflow).string;
            if (this._isCounty && !x.countyEnabled) {
                return null;
            }
            if (!this._isCounty && !x.suretyEnabled) {
                return null;
            }
            const element = document.createElement("action-button");
            element.id = `create-${bondTypeName.replace(/\s/g, "-").toLowerCase()}-application-button`;
            element.classList.add("btn",
                "btn-success",
                "col-12",
                "col-lg-2",
                "flex-fill",
                "flex-lg-grow-0",
                "mb-2",
                "ml-lg-4");
            if (x.bondWorkflow === bondWorkflow.pr.value)
                element.setAttribute("alternateButtonText", "Loading Inmate");
            element.setAttribute("data-bond-type-id", x.bondTypeId);
            element.toggleAttribute("trigger", true);
            element.textContent = `Create ${x.displayName} Bond Application`;
            element.addEventListener("click", this._createApplicationOnClick.bind(this, x.bondTypeId));
            return element;
        }).filter(x => x !== null);
        generatedButtons.sort((a, b) => { return this._pageTools.stringSort(a, b, "textContent") });
        generatedButtons.forEach(x => this._buttonContainer.appendChild(x));
    }

    set hasSkipPermission(value) {
        this._hasSkipPermission = value;
    }

    set model(value) {
        this._model = value;
        this._tenantId = value.TenantId;
        this._jailId = value.Inmate.JailID;
        this._totalBondConfigs = value.JmsBondTypeConfigurations;
        this._updateHtml(value);

        const combinedModel = value.Charges;
        this._loadTableData(combinedModel);
    }

    connectedCallback() {
        this._backButton.addEventListener("click", this._backButtonOnclick);
        this._syncFailureDialog.addEventListener("retry", this._retryCreateApplication);
        this._inmateInformation.userIsCounty = isAttributeTrue(this, "user-is-county");
    }

    disconnectedCallback() {
        this._backButton.removeEventListener("click", this._backButtonOnclick);
        this._syncFailureDialog.removeEventListener("retry", this._retryCreateApplication);
    }

    _updateHtml(model) {
        this._inmateInformation.loadInmateInformationBySoNumber(model.Inmate.SONumber);
        this._bookNumber = model.Inmate.BookNumber;
    }

    _loadTableData(model) {
        const table = $(this._table);
        const columns = [];
        columns.push({ checkbox: true });
        columns.push(createColumn("Arrest Date", "ArrestDate"));
        columns.push(createColumn("Offense Code", "OffenseCode"));
        columns.push(createColumn("Degree", "OffenseDegree"));
        if (this._totalBondFeatureFlag) {
            columns.push(createColumn("Warrant Number", "WarrantNumber"));
        }
        if (this._showCourtName)
            columns.push(createColumn("Court Name", "CourtName"));
        columns.push(createColumn("Offense Description", "OffenseDesc"));
        columns.push(createColumn("Bond Type", "BondType"));
        columns.push(createColumn("Bond Description", "BondDesc"));
        columns.push(createColumn("Bond Amount", "BondAmt", undefined, dollarFieldFormatter));
        columns.push(createColumn("Status", "ChargeStatus", undefined, chargeStatusFieldFormatter));

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered table-hover",
            clickToSelect: true,
            uniqueId: "JmsChargeId",
            onCheck: this._statusCheck,
            onCheckAll: this._checkAll,
            onUncheck: this._statusCheck,
            onUncheckAll: this._checkAll,
            rowStyle: this._rowStyleFormatter.bind(this)
        });

        this._setInitialApplicationState(table);
    }

    _setInitialApplicationState(table) {
        const tableData = table.bootstrapTable("getData");
        this._disableUnavailableRows(tableData, this._model.Inmate.HasDataEntryErrors);

        if (tableData.every(row => row.ChargeStatus !== chargeStatus.available.value)) {
            this._table.querySelector(this._tableHeaderInputSelector).disabled = true;
            this._statusCheck();
        } else {
            this._table.querySelector(this._tableHeaderInputSelector).disabled = false;
            if (this._bondTypeEnforcementFeatureFlag) {
                const createButtons = this.shadowRoot.querySelectorAll(".btn-success");
                if (createButtons.length > 1)
                    this._smartSelectAll(table);
                else
                    this._trySetCheckBoxesForSingleBondTypeButton(table, createButtons[0]);

                this._statusCheck();
            } else
                this._checkAllValid(table);
        }

        this._disableWarrantNumberChildren(tableData);
    }

    _disableUnavailableRows(tableData, disableAll) {
        tableData.forEach(row => {
            if (disableAll || row.ChargeStatus !== chargeStatus.available.value) {
                this._disableRowByChargeId(row, true);
            }
        });
    }

    _disableRowByChargeId(row, disable) {
        this._table.querySelector(`tr[data-uniqueid="${row.JmsChargeId}"] .bs-checkbox > label > input`).disabled = disable;
    }

    _disableWarrantNumberChildren(tableData) {
        if (!this._totalBondFeatureFlag)
            return;

        const groups = getTotalBondGrouping(tableData, this._totalBondConfigs, "BondAmt");
        groups.forEach(group => {
            const children = group.filter((i, index) => index !== 0);
            this._disableRowsByChargeId(children, true);
            this._visuallySeparateBondGrouping(group,tableData[0]);
        });
    }

    _visuallySeparateBondGrouping(group, firstCharge) {
        // Do not pad first charge in table
        if (group[0].JmsChargeId !== firstCharge.JmsChargeId)
            this._table.querySelector(`tr[data-uniqueid="${group[0].JmsChargeId}"]`).classList.add("bond-group-parent-charge");
        this._table.querySelector(`tr[data-uniqueid="${group[group.length - 1].JmsChargeId}"]`).classList.add("bond-group-last-charge");
    }

    _disableRowsByChargeId(array, disable) {
        if (array.length === 0)
            return;

        array.forEach(row => {
            this._disableRowByChargeId(row, disable);
        });
    }

    _checkWarrantNumberGroup(group, check) {
        const children = group.filter((i, index) => index !== 0);
        const chargeIds = children.map(row => row.JmsChargeId);
        chargeIds.forEach(chargeId => {
            const checkboxElement =
                this.shadowRoot.querySelector(`tr[data-uniqueid="${chargeId}"] .bs-checkbox > label > input`);
            if (checkboxElement.getAttribute("disabled") === null && checkboxElement.checked !== check)
                checkboxElement.click();
        });
    }

    _checkChildrenOffenses(row) {
        if (!this._totalBondFeatureFlag || !row?.JmsChargeId)
            return;

        const tableData = $(this._table).bootstrapTable("getData");
        const bondGroupings = getTotalBondGrouping(tableData, this._totalBondConfigs, "BondAmt");
        const group = bondGroupings.find(x => x[0].JmsChargeId === row.JmsChargeId);

        // This row has no children and is a simple bond.
        if (group === undefined)
            return;

        const check = row[0];
        const children = group.filter((i, index) => index !== 0);

        // Enable children offense checkboxes in the group, re-disable the checkboxes of members that have a Status that is not "Available"
        //      check or uncheck the checkboxes of remaining enabled members, then disable the again.
        this._disableRowsByChargeId(children, false);
        this._checkWarrantNumberGroup(group, check);
        this._disableRowsByChargeId(children, true);
    }

    _checkAllValid(table) {
        if (!this._totalBondFeatureFlag) {
            table.bootstrapTable("checkAll");
            return;
        }

        // Click valid total bonds rows
        const tableData = $(this._table).bootstrapTable("getData");
        const groupsByWarrantNumber = getTotalBondGrouping(tableData, this._totalBondConfigs, "BondAmt");

        const clickedChargeIds = [];
        for (const group of groupsByWarrantNumber) {
            const chargeId = group[0].JmsChargeId;
            group.forEach(x => clickedChargeIds.push(x.JmsChargeId));
            const checkboxElement =
                this.shadowRoot.querySelector(`tr[data-uniqueid="${chargeId}"] .bs-checkbox > label > input`);
            checkboxElement.click();
            const errorMessage = this._totalBondsErrors();
            if (errorMessage !== null) {
                checkboxElement.click();
            }
        }

        // Click non-total bonds rows
        const filteredTableData = tableData.filter(x => !clickedChargeIds.includes(x.JmsChargeId));
        filteredTableData.forEach(row => {
            const checkbox =
                this.shadowRoot.querySelector(`tr[data-uniqueid="${row.JmsChargeId}"] .bs-checkbox > label > input`);
            checkbox.click();
        });
    }

    _checkAll() {
        /*** this function exists because when the checkAll event is dispatched, it only checks/unchecks enabled rows, 
        we need to do the rest of the job for the disabled children's group (group by warrantNumber) ***/
        this._statusCheck();

        if (this._totalBondFeatureFlag) {
            const tableData = $(this._table).bootstrapTable("getData");
            const inputElements = this.shadowRoot.querySelectorAll(`tr .bs-checkbox > label > input`);
            const enabledInputs = Array.from(inputElements).filter(input => input.getAttribute("disabled") === null);

            enabledInputs.forEach(input => {
                const chargeId = input.parentElement.parentElement.parentElement.getAttribute("data-uniqueid");
                const rowData = tableData.find(row => row.JmsChargeId === chargeId);
                this._checkChildrenOffenses(rowData);
            });
        }
    }

    _statusCheck(row) {
        this._checkChildrenOffenses(row);
        this._alertMessage.textContent = null;
        let errorMessage = null;

        if ($(this._table).bootstrapTable("getSelections").length === 0 || this._model.Inmate.HasDataEntryErrors) {
            errorMessage = this._disableCreateButtons();
        }
        if (this._bondTypeEnforcementFeatureFlag && !errorMessage) {
            errorMessage = this._enforceBondTypeButtons();
        }
        if (isAttributeTrue(this, "enforce-bond-application-limit-feature-flag") &&
            this._bondApplicationLimit &&
            !errorMessage) {
            errorMessage = this._calculateBondTotal();
        }

        if (this._totalBondFeatureFlag && !errorMessage) {
            errorMessage = this._totalBondsErrors();
        }

        const createButtons = Array.from(this.shadowRoot.querySelectorAll(".btn-success"));
        if (errorMessage) {
            createButtons.forEach(element => { element.toggleAttribute("disabled", true) });
            this._alertMessage.innerHTML = errorMessage;
        }
        if (!errorMessage && !this._bondTypeEnforcementFeatureFlag) {
            createButtons.forEach(element => { element.toggleAttribute("disabled", false) });
        }
    }

    _totalBondsErrors() {
        if (!this._totalBondFeatureFlag)
            return null;
        const tableData = $(this._table).bootstrapTable("getData");
        const groupsByWarrantNumber = getTotalBondGrouping(tableData, this._totalBondConfigs, "BondAmt");
        for (const group of groupsByWarrantNumber) {
            const parentIsNotClicked = !group[0][0];
            if (parentIsNotClicked) {
                continue;
            }

            const errorMessage = totalBondGroupErrors(group, this._tenantId, this._totalBondConfigs, "BondAmt");
            if (errorMessage !== null)
                return errorMessage;
        }

        return null;
    }

    _enforceBondTypeButtons() {
        const jmsBondTypesOfCheckedOffenses = $(this._table)
            .bootstrapTable("getSelections")
            .map(row => row.BondType);
        const createButtons = Array.from(this.shadowRoot.querySelectorAll(".btn-success"));
        createButtons.forEach(button => {
            const buttonBondTypeId = Number(button.getAttribute("data-bond-type-id"));
            const allowedButtonJmsBondTypes = this._bondTypes.find(x => x.bondTypeId === buttonBondTypeId).allowedJmsBondTypes;
            button.toggleAttribute("disabled", !jmsBondTypesOfCheckedOffenses.every(x => allowedButtonJmsBondTypes.includes(x)));
        });

        if (createButtons.every(button => button.hasAttribute("disabled"))) {
            let message = "The selected Bond Types cannot be processed together on a single application. ";
            message += this._isCounty
                ? "Verify the Bond Type of the selected offenses. If a Bond Type is incorrect, click the Back button below, change the Bond Type in your jail management system, and click the Create Application button for this inmate."
                : "If a Bond Type is incorrect, please contact your jail administrator and request a correction to be made in the jail management system. Once the Bond Type has been corrected by the jail administrator, click the Refresh button on the Roster to verify the Bond Type.";
            return message;
        }
    }

    _calculateBondTotal() {
        const tableData = $(this._table).bootstrapTable("getSelections");
        let total = 0;

        total = tableData.reduce((a, b) =>
            a + b.BondAmt, 0
        );

        if (total > this._bondApplicationLimit) {
            return `The total bond amount of ${this._pageTools.formatNumberToDollarAmount(total)} in this application exceeds your bond application limit of ${this._pageTools.formatNumberToDollarAmount(this._bondApplicationLimit)}. If you have any questions on this limit, please contact the administrator for ${this._bondCompany}.`;
        }
    }

    _trySetCheckBoxesForSingleBondTypeButton(table, singleButton) {
        const singleButtonBondTypeId = Number(singleButton.getAttribute("data-bond-type-id"));
        const singleButtonBondType = this._bondTypes.find(x => x.bondTypeId === singleButtonBondTypeId);
        const tableData = table.bootstrapTable("getData").filter(x => x.ChargeStatus === chargeStatus.available.value);
        //if all the available rows are the same type than the available JMS Bond Type application button, then select them all
        if (tableData.every(row => singleButtonBondType.allowedJmsBondTypes.includes(row.BondType))) {
            this._checkAllValid(table);
            return;
        }
        //otherwise, select those rows that are the same than the JMS availabe button.
        const chargeIds = tableData
            .filter(row => singleButtonBondType.allowedJmsBondTypes.includes(row.BondType))
            .map(row => row.JmsChargeId);
        table.bootstrapTable("checkBy", { field: "JmsChargeId", values: chargeIds });
    }

    _rowStyleFormatter(row, index) {
        return getRowChargeStatusCssClass(row.ChargeStatus);
    }

    _smartSelectAll(table) {
        const createButtons = Array.from(this.shadowRoot.querySelectorAll(".btn-success"));
        const availableBondTypes = table.bootstrapTable("getData")
            .filter(x => x.ChargeStatus === chargeStatus.available.value)
            .map(x => x.BondType);

        if (availableBondTypes.every(x => x === availableBondTypes[0])) {
            const availableBondTypeButton = createButtons.some(button => {
                const buttonBondTypeId = Number(button.getAttribute("data-bond-type-id"));
                const allowedButtonJmsBondTypes = this._bondTypes.find(x => x.bondTypeId === buttonBondTypeId).allowedJmsBondTypes;
                return allowedButtonJmsBondTypes.includes(availableBondTypes[0]);
            });
            if (availableBondTypeButton)
                this._checkAllValid(table);
        }
    }

    _disableCreateButtons() {
        if (this._model.Inmate.HasDataEntryErrors) {
            return "There are unresolved data entry errors for this inmate and a bond application cannot be made at this time. Click the back button to return to the Roster.";
        }
        if (this._bondTypeEnforcementFeatureFlag) {
            if ($(this._table).bootstrapTable("getData").every(row => row.ChargeStatus !== chargeStatus.available.value))
                return "There are no charges available for bond applications for this inmate. Click the Back button to return to the Roster.";
            else
                return "Select one or more offenses to begin the bond application process.";
        }
        else
            return "Select one or more offenses to begin the bond application process.";
    }

    _createApplicationOnClick(id, e) {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._alertMessage.textContent = "";
        const selectedCharges = $(this._table).bootstrapTable("getSelections");
        const selected = selectedCharges.map(x => x.JmsChargeId);

        const data = {
            selected: selected,
            id: id,
            booknumber: this._bookNumber
        };

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/Application/ProcessApplicationData",
            data,
            this._createApplicationCallback.bind(this, e.currentTarget)
        );
    }

    _createApplicationCallback(createAppButton, response, isSuccess) {
        if (!isSuccess) {
            this._backButton.classList.remove("disabled");
            this._backButton.removeAttribute("disabled");
            if (this._bondTypeEnforcementFeatureFlag) {
                this._enforceBondTypeButtons();
            } else {
                this._pageTools.toggleTriggers(this.shadowRoot, false);
            }
            this._handleErrorMessage(createAppButton, response);
            return;
        }
        const responseObj = this._pageTools.tryParseJson(response);
        this._pageTools.redirectToUrl(`/Application/ProcessApplication/${responseObj.bondAppId}`);
    }

    _handleErrorMessage(createAppButton, response) {
        const errorMessageText =
            " Please try again and if the problem persists please <contact-link>contact eBONDS™ Support</contact-link>.";
        if (response) {
            const jsonObj = this._pageTools.tryParseJson(response);
            if (jsonObj) {
                const dialogInitializationObj = {
                    hasSkipPermission: this._hasSkipPermission,
                    continueOnSyncFailure: false, //No continue button on pr bond sync failure
                    inmateFN: jsonObj.Inmate.InmateFN,
                    inmateMI: jsonObj.Inmate.InmateMI,
                    inmateLN: jsonObj.Inmate.InmateLN,
                    bookingNumber: jsonObj.Inmate.BookNumber,
                    jailId: null, //JailId is only used for continue button when sync fails on the roster page
                    buttonElementToRetry: createAppButton,
                    lastSyncedTime: jsonObj.LastSyncedTime,
                    isOdysseyFailure: jsonObj.IsOdysseyFailure,
                    tenantPhoneNumber: jsonObj.TenantPhoneNumber
                };
                this._syncFailureDialog.openDialog(dialogInitializationObj);
                return;
            }
            if (this._shouldDisplayRawErrorMessage(response))
                this._alertMessage.textContent = response;
            else if (response.includes("Failed to create bond application."))
                this._doRefreshTableLogic();
            else
                this._alertMessage.innerHTML = "There was a problem creating a bond application." + errorMessageText;
        } else
            this._alertMessage.innerHTML = "There was a problem creating a bond application." + errorMessageText;
    }

    _shouldDisplayRawErrorMessage(response) {
        return response.includes(
            "The following information is required by the Texas Code of Criminal Procedure Section 17.04 to create a Personal Recognizance Bond Application:")
            || response.includes("is greater than your bond company's available collateral limit");
    }

    _doRefreshTableLogic() {
        const $table = $(this._table);
        $table.bootstrapTable("showLoading");

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "GET",
            `/Application/GetNewApplicationViewModel?jailId=${this._jailId}`,
            null,
            this._doRefreshTableLogicCallback.bind(this)
        );
    }

    _doRefreshTableLogicCallback(response, isSuccess) {
        const $table = $(this._table);
        const jsonObj = this._pageTools.tryParseJson(response);
        if (!isSuccess || !jsonObj) {
            $table.bootstrapTable("hideLoading");
            this._alertMessage.textContent =
                "One or more of the previously selected charges are no longer available, and the charge list was unable to be refreshed. Refresh the page and try again.";
            return;
        }
        const data = jsonObj.Charges;
        $table.bootstrapTable("load", data);
        this._setInitialApplicationState($table);
        $table.bootstrapTable("hideLoading");
        this._alertMessage.textContent = "One or more of the previously selected charges are no longer available.";
    }

    _retryCreateApplication(event) {
        event.detail.buttonElement.click();
    }

    _backButtonOnclick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl("/Roster/");
    }
}

customElements.define("new-application", NewApplication);