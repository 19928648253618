import bootstrap from '../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { showAlert, clearAlert } from './EditDialogExtensions';
import { createColumn, dollarFieldFormatter, dateTimeFormatterWithTz } from './BootstrapTableExtensions';
import template from './ViewPowersDialog.html';
import 'bootstrap-table/dist/bootstrap-table';
import $ from 'jquery';
import './ActionButton';

class ViewPowersDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('view-powers-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('view-powers-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._powersTable = this.shadowRoot.getElementById('powers-table');

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._generatePoaMessageTable = this._generatePoaMessageTable.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(selectedCharges) {
        this._selectedCharges = selectedCharges;
        this._setInitialValues();

        this._modalComponent.openModal('Powers of Attorney', false);
    }

    _setInitialValues() {
        const table = $(this._powersTable);
        table.bootstrapTable('destroy');
        const columns = [
            createColumn('Offense', 'OffenseDesc'),
            createColumn('Bond Amount', 'BondAmt', undefined, dollarFieldFormatter),
            createColumn('POA Number', 'POANumber'),
            createColumn('POA Limit', 'POALimit', undefined, dollarFieldFormatter)
        ];

        table.bootstrapTable({
            columns: columns,
            data: this._selectedCharges,
            classes: 'table table-sm table-striped table-bordered',
            detailView: true,
            detailFormatter: this._generatePoaMessageTable,
            uniqueId: 'BondApplicationDetailID',
        });

        table.bootstrapTable('expandAllRows');

        clearAlert(this._alert);
        this._saveButton.toggleAttribute('disabled', this._selectedCharges.every(x => x.POANumber));
    }

    _generatePoaMessageTable(index, row, element) {
        const columns = [
            createColumn('Date', 'POAMessageDate', undefined, dateTimeFormatterWithTz), //field needs to display timezone
            createColumn('Severity', 'POASeverity'),
            createColumn('Message', 'POAMessage')
        ];
        const $table = element.html('<table></table>').find('table');
        $table.bootstrapTable({
            columns: columns,
            classes: 'table table-sm table-striped table-bordered',
            rowStyle: rowStyleGenerator,
        });

        $table.bootstrapTable('showLoading');
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/Application/PowersMessages/${row.BondApplicationDetailID}`,
            null,
            powersMessagesCallback);

        function rowStyleGenerator(rowItem, itemIndex) {
            if ('error'.localeCompare(rowItem.POASeverity, 'en-US', { sensitivity: 'base' }) === 0) {
                return {
                    classes: 'text-danger font-weight-bold'
                }
            }
            return {};
        }

        function powersMessagesCallback(response, success) {
            if (success) {
                $table.bootstrapTable('append', JSON.parse(response));
            } else {
                console.error(response);
            }
            $table.bootstrapTable('hideLoading');
        }
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        const data = {
            bondApplicationDetailIds: this._selectedCharges.map(element => element.BondApplicationDetailID),
            bondApplicationId: this._selectedCharges[0].BondApplicationID,
        };

        this._pageTools.toggleTriggers(this.shadowRoot, true);
        clearAlert(this._alert);

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'POST',
            '/Application/RetrievePowers',
            data,
            this._retrievePowersCallback.bind(this)
        );
    }

    _retrievePowersCallback(response, success) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!success) {
            showAlert(this._alert, response ? response : 'Failed to retrieve powers.');
            return;
        }
        const data = this._pageTools.tryParseJson(response);

        const event = new CustomEvent('save', { detail: data });
        this.dispatchEvent(event);

        const $table = $(this._powersTable);

        data.forEach(item => {
            const row = $table.bootstrapTable('getRowByUniqueId', item.BondApplicationDetailId);
            row.POANumber = item.PoaNumber;
            row.POALimit = item.PoaLimit;
            row.POAStatus = item.PoaStatus;

            $table.bootstrapTable('updateByUniqueId', {
                id: item.BondApplicationDetailId,
                row: row
            });
        });

        if (data.every(item => item.PoaNumber)) {
            this._closeDialog();
        } else {
            $table.bootstrapTable('collapseAllRows');
            $table.bootstrapTable('expandAllRows');
        }
    }
}
customElements.define('view-powers-dialog', ViewPowersDialog);