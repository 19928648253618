// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum BondWorkflow {
  Surety = 0,
  Cash = 1,
  PR = 2,
  Attorney = 3,
  SuretyElectronic = 4,
  SuretyManual = 5,
  SuretyOwner = 6,
  Property = 7,
  CompanyAttorney = 8
}