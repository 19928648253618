import 'bootstrap-table/dist/bootstrap-table';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import { createColumn } from '../BootstrapTableExtensions';
import bootstrap from './../../scss/bootstrap-custom.scss';
import '../ActionButton';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './InmateInstructionTemplates.html';

class InmateInstructionTemplates extends HTMLElement {
    constructor() {
        super();
        this._pageTools = new PageTools();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ['container']);
        this._alert = this.shadowRoot.getElementById('alert');
        this._instructionsTable = this.shadowRoot.getElementById('inmate-instruction-templates-table');
        this._addNewTemplateButton = this.shadowRoot.getElementById('add-new-template-button');
        this._inmateInstructionTemplatesDialog = this.shadowRoot.getElementById('inmate-instruction-templates-dialog');
        this._confirmationModal = this.shadowRoot.getElementById("confirmation-modal");

        this._addNewTemplateOnClick = this._addNewTemplateOnClick.bind(this);
        this._refreshTable = this._refreshTable.bind(this);
        this._editTemplateSaveCallback = this._editTemplateSaveCallback.bind(this);
        this._addTemplateSaveCallback = this._addTemplateSaveCallback.bind(this);
        this._deleteOnClick = this._deleteOnClick.bind(this);
        this._model = null;
    }

    connectedCallback() {
        this._addNewTemplateButton.addEventListener('click', this._addNewTemplateOnClick);
        this._inmateInstructionTemplatesDialog.addEventListener('onModalEdit', this._editTemplateSaveCallback);
        this._inmateInstructionTemplatesDialog.addEventListener('onModalAdd', this._addTemplateSaveCallback);
        this._confirmationModal.addEventListener('ok-confirmation', this._deleteOnClick);
        this._initialize();
    }

    disconnectedCallback() {
        this._addNewTemplateButton.removeEventListener('click', this._addNewTemplateOnClick);
        this._inmateInstructionTemplatesDialog.removeEventListener('onModalEdit', this._editTemplateSaveCallback);
        this._inmateInstructionTemplatesDialog.removeEventListener('onModalAdd', this._addTemplateSaveCallback);
        this._confirmationModal.removeEventListener('ok-confirmation', this._deleteOnClick);
    }

    set model(value) {
        this._model = value;
        const $table = $(this._instructionsTable);
        $table.bootstrapTable('load', value);
        $table.bootstrapTable('hideLoading');

        this._instructionsTable.querySelectorAll('action-button[data-edit]')
            .forEach(x => x.addEventListener('click', this._editOnClick.bind(this)));
        this._instructionsTable.querySelectorAll('action-button[data-delete]')
            .forEach(x => x.addEventListener('click', this._showDeleteTemplateConfirmationModal.bind(this)));
    }

    _showDeleteTemplateConfirmationModal(e) {
        const clickedDeleteButton = e.target;
        const templateId = clickedDeleteButton.getAttribute('data-delete');
        const template = this._model.find(template => template.TemplateId == templateId);
        this._confirmationModal.open({
            message: `Delete ${template.TemplateName}?`,
            response: { 'templateId': templateId, 'deleteButton': clickedDeleteButton },
            cancelButtonText: "Delete"
        });
    }

    _initialize() {
        const $table = $(this._instructionsTable);
        const columns = [
            createColumn('Template Name', 'TemplateName', true),
            createColumn('', 'TemplateId', false, this._editButtonFormatter),
            createColumn('', 'TemplateId', false, this._deleteButtonFormatter)
        ];

        // Set button columns to min-width which will fit content
        columns[1].width = 1;
        columns[2].width = 1;

        $table.bootstrapTable({
            columns: columns,
            classes: 'table table-sm table-striped table-bordered',
            uniqueId: 'inmateInstructionTemplates'
        });

        $table.bootstrapTable('showLoading');
    }

    _editButtonFormatter(value, row, index) {
        return `<div><action-button class="btn btn-primary" data-edit="${value}" trigger>Edit</action-button></div>`;
    }

    _deleteButtonFormatter(value, row, index) {
        return `<div><action-button class="btn btn-danger" data-delete="${value}" trigger>Delete</action-button></div>`;
    }

    _editOnClick(e) {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const instructionTemplateId = parseInt(e.target.getAttribute('data-edit'));
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('GET',
            `/Admin/InmateInstructionTemplates/InmateInstructionTemplateById?templateId=${instructionTemplateId}`,
            null,
            this._editCallback.bind(this));
    }

    _editCallback(response, isSuccess) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const data = {
            detail: JSON.parse(response),
            endPoint: "/Admin/InmateInstructionTemplates/UpdateInmateInstructionTemplateById",
            operationType: 'edit',
            title: 'Edit inmate instruction template'
        };

        this._inmateInstructionTemplatesDialog.openModal(data);
    }

    _deleteOnClick(e) {
        const instructionTemplateId = parseInt(e.detail.response.templateId);
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const deleteButton = e.detail.response.deleteButton;
        deleteButton.setSpinner(true);
        const template = this._model.find(template => template.TemplateId == instructionTemplateId);
        this._templateName = template.TemplateName;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST',
            '/Admin/InmateInstructionTemplates/DeleteInmateInstructionTemplateById',
            { TemplateId: instructionTemplateId },
            this._deleteExistingTemplate.bind(this));
    }

    _addNewTemplateOnClick(e) {
        const data = {
            endPoint: "/Admin/InmateInstructionTemplates/CreateNewInmateInstructionTemplate",
            title: 'Add inmate instruction template',
            operationType: 'add'
        };
        this._inmateInstructionTemplatesDialog.openModal(data);
    }

    _deleteExistingTemplate(response, isSuccess) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!isSuccess) {
            this._showAlert("Failed to delete inmate instruction template.", true);
            return;
        }
        const $table = $(this._instructionsTable);
        $table.bootstrapTable('showLoading');
        this._showAlert(`Successfully deleted inmate instruction template: ${this._templateName}`, false);
        this._refreshTable();
    }

    _addTemplateSaveCallback(response) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const $table = $(this._instructionsTable);
        $table.bootstrapTable('showLoading');
        this._showAlert(`Successfully added inmate instruction template: ${response.detail.templateName}`, false);;
        this._refreshTable();
    }

    _editTemplateSaveCallback(response) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const $table = $(this._instructionsTable);
        $table.bootstrapTable('showLoading');
        this._showAlert(`Successfully edited template: ${response.detail.templateName}`, false);
        this._refreshTable();
    }

    _showAlert(message, isError) {
        this._alert.classList.toggle('text-success', !isError);
        this._alert.classList.toggle('text-danger', isError);
        this._alert.toggleAttribute('hidden', false);
        this._alert.textContent = message;
    }

    _refreshTable() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/Admin/InmateInstructionTemplates/Read_InmateInstructionsTemplate`,
            null,
            this._refreshtableCallback.bind(this));
    }

    _refreshtableCallback(response, isSuccess) {
        if (!isSuccess) {
            this._showAlert("Failed to retrieve inmate instruction templates");
            return;
        }

        this.model = JSON.parse(response);
    }
}

customElements.define('inmate-instruction-templates', InmateInstructionTemplates);