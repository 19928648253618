import bootstrap from '../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { showAlert } from '../EditDialogExtensions';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import {
    chargeStatusFieldFormatter, createColumn,
    dollarFieldFormatter, getRowChargeStatusCssClass
} from '../BootstrapTableExtensions';
import template from './ChargesUnavailableDialog.html';
import 'bootstrap-table/dist/bootstrap-table';

class ChargesUnavailableDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ['charges-unavailable-modal-component']);
        this._alertText = 'A bond application cannot be submitted for one or more of the charges included in this application. ' +
            'The current status of the charges contained in this application is shown below. ' +
            'Please cancel this bond application and create a new one with any remaining available charges.';

        this._bondApplicationId = null;
        this._type = null;
        this._pageTools = new PageTools();
        this._cancelApplicationButton = this.shadowRoot.getElementById('cancel-application-button');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._table = this.shadowRoot.getElementById('charge-table');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._cancelApplicationOnClick = this._cancelApplicationOnClick.bind(this);
    }

    set type(value) {
        this._type = value;
    }

    connectedCallback() {
        this._cancelApplicationButton.addEventListener('click', this._cancelApplicationOnClick);
    }

    disconnectedCallback() {
        this._cancelApplicationButton.removeEventListener('click', this._cancelApplicationOnClick);
    }

    open(bondAppId) {
        this._bondApplicationId = bondAppId;
        this._buildTable();
        showAlert(this._alert, this._alertText);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'GET',
            `/Application/GetChargesWithStatus?bondApplicationId=${this._bondApplicationId}`,
            null,
            this._getNewApplicationViewModelCallBack.bind(this)
        );

        this._modalComponent.openModal('Unable to Submit Bond Application', true);
    }

    _getNewApplicationViewModelCallBack(response, isSuccess) {
        const table = $(this._table);
        if (!isSuccess) {
            table.bootstrapTable('hideLoading');
            showAlert(this._alert, this._alertText + ' ERROR: Failed to get list of unavailable charges.' );
            return;
        }

        const jsonObj = this._pageTools.tryParseJson(response);
        table.bootstrapTable('load', jsonObj);
        table.bootstrapTable('hideLoading');
    }

    _buildTable() {
        const table = $(this._table);
        const columns = [];
        columns.push(createColumn('Arrest Date', 'ArrestDate'));
        columns.push(createColumn('Offense Code', 'OffenseCode'));
        columns.push(createColumn('Degree', 'OffenseDegree'));
        columns.push(createColumn('Offense', 'OffenseDesc'));
        columns.push(createColumn('Bond Type', 'BondType'));
        columns.push(createColumn('Bond Description', 'BondDesc'));
        columns.push(createColumn('Bond Amount', 'BondAmt', undefined, dollarFieldFormatter));
        columns.push(createColumn('Status', 'ChargeStatus', undefined, chargeStatusFieldFormatter ));

        table.bootstrapTable({
            columns: columns,
            data: [],
            classes: 'table table-sm table-striped table-bordered',
            uniqueId: 'JmsChargeId',
            rowStyle: this._rowStyleFormatter
        });
        table.bootstrapTable('showLoading');
    }

    _rowStyleFormatter(row, index) {
        return getRowChargeStatusCssClass(row.ChargeStatus);
    }

    _cancelApplicationOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Application/CancelApplication/${this._bondApplicationId}`);
    }
}
customElements.define('charges-unavailable-dialog', ChargesUnavailableDialog);