import bootstrap from '../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './UserBondCompaniesConfiguration.html';
import { createColumn } from '../BootstrapTableExtensions';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css'

class UserBondCompaniesConfiguration extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome]);
        this._model = null;
        this._table = this.shadowRoot.getElementById('table');
        this._errorSpan = this.shadowRoot.getElementById('error-text');
        this._userBondCompanyIds = null;
        this._editorBondCompanyIds = null;
        this._editorIsCounty = null;
        this._tableConfig = null;

        this._enforceLimitOnClick = this._enforceLimitOnClick.bind(this);
        this._updateRowState = this._updateRowState.bind(this);
        this._updateMoneyInputOnBlur = this._updateMoneyInputOnBlur.bind(this);
        this._updateLicenseInputOnBlur = this._updateLicenseInputOnBlur.bind(this);
        this._licenseFormatter = this._licenseFormatter.bind(this);
    }

    get _enforceBondApplicationLimitsTempFeatureFlag() {
        const attr = 'data-enforce-bond-application-limits-temp';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    get _userLevelPoaLicenseFeatureFlag() {
        const attr = 'data-user-level-poa-license';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    set editorIsCounty(value) {
        this._editorIsCounty = value;
    }

    set userBondCompanyIds(value) {
        this._userBondCompanyIds = value;
    }

    set editorBondCompanies(value) {
        this._editorBondCompanyIds = value;
    }

    set model(value) {
        this._model = value;
        this._loadTableData();
    }

    connectedCallback() {
        this._tableConfig = {
            tableColumns: this._compileColumns(),
            classes: 'table table-sm table-striped table-bordered table-hover',
            uniqueId: 'BondCompanyId',
            mainTableHeight: 250,
            onCheck: this._updateRowState.bind(this, true),
            onCheckAll: this._onAllRowsCheckChanged.bind(this, true),
            onUncheck: this._updateRowState.bind(this, false),
            onUncheckAll: this._onAllRowsCheckChanged.bind(this, false),
            checkboxHeader: true,
            toolbar: false,
            columnFilters: false,
            tableAlertMessage: false,
            virtualScroll: false
        }
    }

    getTableData() {
        return this._model;
    }

    reportValidity() {
        const moneyInputs = Array.from(this._table.shadowRoot.querySelectorAll('money-input'));
        return moneyInputs.every(input => input.reportValidity());
    }

    _setTableState() {
        const rows = this._table.shadowRoot.querySelectorAll('tbody>tr');
        Array.from(rows).forEach(x => {
            const companyCheckbox = x.querySelector('input[type="checkbox"]');
            const companyId = parseInt(x.getAttribute('data-uniqueid'));
            if (this._editorBondCompanyIds.includes(companyId)) {
                x.toggleAttribute("disabled", false);
                companyCheckbox.toggleAttribute("disabled", false);
                return;
            }
            if (this._userBondCompanyIds.includes(companyId)) {
                if (this._enforceBondApplicationLimitsTempFeatureFlag) {
                    const limitCheckBox = x.querySelector(`#enforce-limit-${companyId}`);
                    const moneyInput = x.querySelector('money-input');
                    limitCheckBox.toggleAttribute("disabled", true);
                    moneyInput.toggleAttribute("disabled", true);
                    moneyInput.toggleAttribute("required", false);
                    moneyInput.toggleAttribute("not-allowed-cursor", true);
                    x.addEventListener('click', () => { this.dispatchEvent(new Event("showDisabledCompanyErrorSpan")) });
                    x.classList.add('user-company-configuration-not-allowed-cursor');
                }
                if (this._userLevelPoaLicenseFeatureFlag) {
                    const licenseInput = x.querySelector(`#license-${companyId}`);
                    licenseInput.toggleAttribute("disabled", true);
                }
                x.toggleAttribute("disabled", true);
                companyCheckbox.toggleAttribute("disabled", true);
                return;
            }
            x.toggleAttribute("hidden", true);
            companyCheckbox.toggleAttribute("disabled", true);
        });

        if (this._enforceBondApplicationLimitsTempFeatureFlag)
            this._setAsteriskVisibility();
    }

    _loadTableData() {
        this._table.destroyTable();
        this._table.initTableData({
            bootstrapTableConfig: this._tableConfig,
            tableData: this._model
        });

        if (this._enforceBondApplicationLimitsTempFeatureFlag) {
            const inputs = this._table.shadowRoot.querySelectorAll('[id*="enforce-limit-"]');
            const moneyInputs = this._table.shadowRoot.querySelectorAll('money-input');
            inputs.forEach((input) => {
                input.addEventListener('change', this._enforceLimitOnClick);
            });

            moneyInputs.forEach((input) => {
                input.addEventListener('blur', this._updateMoneyInputOnBlur);
            });

            this._setAsteriskVisibility();

            const tooltipTitle = 'By enabling the "Enforce Limit" checkbox, users are restricted from creating a bond application that surpasses the specified limit.';
            const tooltip = this._table.shadowRoot.querySelector('#title-tooltip');
            tooltip.title = tooltipTitle;
            $(tooltip).tooltip();
        }

        if (this._userLevelPoaLicenseFeatureFlag) {
            const licenseInputs = this._table.shadowRoot.querySelectorAll('[id^="license-"]');
            licenseInputs.forEach((input) => {
                input.addEventListener('blur', this._updateLicenseInputOnBlur);
            });
        }

        if (!this._editorIsCounty)
            this._setTableState(); 
    }

    _updateMoneyInputOnBlur(e) {
        const input = e.currentTarget;
        const bondCompanyId = input.id.replace('limit-value-', '');
        const limit = input.value;
        this._updateModel('Limit', bondCompanyId, limit);
    }

    _updateLicenseInputOnBlur(e) {
        const input = e.currentTarget;
        const bondCompanyId = input.id.replace('license-', '');
        const license = input.value;
        this._updateModel('License', bondCompanyId, license);
    }

    _enforceLimitOnClick(e) {
        const input = e.currentTarget;
        const bondCompanyId = input.id.replace('enforce-limit-', '');
        const moneyElement = this._table.shadowRoot.querySelector(`#limit-value-${bondCompanyId}`);
        moneyElement.toggleAttribute('disabled', !input.checked);
        moneyElement.toggleAttribute('required', input.checked);

        this._updateModel('EnforceLimitSelected', bondCompanyId, input.checked);
        this._setAsteriskVisibility();
    }

    _updateRowState(checked, row) {
        this._updateModel('CompanySelected', row.BondCompanyId, checked);
        this._checkCompaniesSelectedCount();

        if (this._enforceBondApplicationLimitsTempFeatureFlag) {
            const enforcedCheckbox = this._table.shadowRoot.querySelector(`#enforce-limit-${row.BondCompanyId}`);
            const limit = this._table.shadowRoot.querySelector(`#limit-value-${row.BondCompanyId}`);
            const editorHasCompany = this._editorBondCompanyIds.includes(row.BondCompanyId);
            enforcedCheckbox.toggleAttribute('disabled', !checked || (!this._editorIsCounty && !editorHasCompany));

            this._setAsteriskVisibility();
            limit.toggleAttribute('disabled', !checked || !enforcedCheckbox.checked || enforcedCheckbox.disabled);
            limit.toggleAttribute('required', checked && enforcedCheckbox.checked);
        }

        if (this._userLevelPoaLicenseFeatureFlag) {
            const license = this._table.shadowRoot.querySelector(`#license-${row.BondCompanyId}`);
            license.toggleAttribute('disabled', !checked);
        }
    }

    _updateModel(property, bondCompanyId, newValue) {
        this._model.find(row => row.BondCompanyId == bondCompanyId)[property] = newValue;
    }

    _checkCompaniesSelectedCount() {
        const selectedCompaniesCount = this._table.shadowRoot.querySelectorAll("tbody > tr.selected:not([disabled])").length;
        const event = new CustomEvent('companiesSelectedCount', { detail: { 'selectedCompaniesCount': selectedCompaniesCount } });
        this.dispatchEvent(event);
    }

    _setAsteriskVisibility() {
        const asterisk = this._table.shadowRoot.querySelector('#table').querySelector('#asterisk');

        if (!this._editorIsCounty) {
            let anyRowRequired = false;
            this._model.forEach(dataRow => {
                const editorHasCompany = this._editorBondCompanyIds.includes(dataRow.BondCompanyId);
                if (editorHasCompany  && dataRow.EnforceLimitSelected && dataRow.CompanySelected) {
                    anyRowRequired = true;
                }
            });
            asterisk.toggleAttribute('hidden', !anyRowRequired);
        }
        else {
            const anyRowRequired = this._model.some(dataRow => dataRow.EnforceLimitSelected && dataRow.CompanySelected);
            asterisk.toggleAttribute('hidden', !anyRowRequired);
        }
    }

    _onAllRowsCheckChanged(checked, rowsAfter, rowsBefore) {
        if (checked) {
            rowsAfter.forEach(row => {
                this._updateRowState(checked, row);
            });
            return;
        }
        rowsBefore.forEach(row => {
            this._updateRowState(checked, row);
        });
    }

    _limitInputFormatter(value, row) {
        const valueAttr = value == null ? "" : ` value="${value}"`;
        const disabledAttr = row.EnforceLimitSelected && row.CompanySelected ? "" : "disabled";
        const requiredAttr = row.EnforceLimitSelected && row.CompanySelected ? "required" : "";
        return `<money-input${valueAttr} ${disabledAttr} id=limit-value-${row.BondCompanyId} ${requiredAttr} min='0.01' max='100000000000' placeholder="e.g. $1,000.00"></money-input>`;
    }

    _formatEnforcedCheckbox(value, row) {
        const checkedAttr = value ? ' checked': '';
        const disabledAttr = !row.CompanySelected ? ' disabled': '';
        return `<span class="d-flex justify-content-center">` +
                    `<input id="enforce-limit-${row.BondCompanyId}" type='checkbox'${checkedAttr} ${disabledAttr} class="input-style"/>` +
               `</span>`;
    }

    _licenseFormatter(value, row) {
        const valueAttr = value == null ? "" : `value="${value}"`;
        const disabledAttr = !row.CompanySelected ? " disabled" : "";
        return `<span class="d-flex justify-content-center">` +
                    `<input id="license-${row.BondCompanyId}" type='text' ${valueAttr} ${disabledAttr} placeholder="e.g. B1332" class="form-control"/>` +
                "</span>";
    }

    _compileColumns() {
        const columns = [
            {
                checkbox: true,
                formatter: this._formatRowCheckbox.bind(this)
            },
            createColumn('Company', 'BondCompanyName')
        ];


        if (this._enforceBondApplicationLimitsTempFeatureFlag) {
            const asterisk = '<span id="asterisk" class="text-danger">*</>';
            const tooltip = '<span id="title-tooltip" class="fa fa-info-circle mr-1 ml-1"></span>';
            columns.push(createColumn(`Enforce Limit ${tooltip}`, 'EnforceLimitSelected', undefined, this._formatEnforcedCheckbox.bind(this)));
            columns.push(createColumn(`Limit ${asterisk}`, 'Limit', undefined, this._limitInputFormatter.bind(this)));
        }

        if (this._userLevelPoaLicenseFeatureFlag) {
            columns.push(createColumn("License", "License", undefined, this._licenseFormatter));
        }

        return columns;
    }

    _formatRowCheckbox(value, row) {
        return {
            checked: row.CompanySelected
        }
    }
}
customElements.define('user-bond-companies-configuration', UserBondCompaniesConfiguration);
