import template from './CardsComponent.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import './CardModal';
import './ConfirmationModal';
import './CardManagementItem';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CardsComponent extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap], ['cards-component-section']);

        this._addCompanyCardButton = this.shadowRoot.getElementById('add-company-card');
        this._addPersonalCardButton = this.shadowRoot.getElementById('add-personal-card');
        this.companyCardContainer = this.shadowRoot.getElementById('company-card-container');
        this.personalCardContainer = this.shadowRoot.getElementById('personal-card-container');
        this._companyCardHeader = this.shadowRoot.getElementById('company-card-header');

        this._addNewCompanyCard = this._addNewCompanyCard.bind(this);
        this._addNewPersonalCard = this._addNewPersonalCard.bind(this);
        this._addCardSuccess = this._addCardSuccess.bind(this);
        this._editCardSuccess = this._editCardSuccess.bind(this);
        this._editCard = this._editCard.bind(this);
        this._openConfirmationModal = this._openConfirmationModal.bind(this);
        this._removeCard = this._removeCard.bind(this);
    }

    static get observedAttributes() {
        return ["cmi-footer-name", "cmi-footer-slot", 'company-name', 'can-manage-company-cards'];
    }

    get restrictCardsFeatureEnabled() {
        return this.getAttribute('restrict-cards-feature') === 'true';
    }

    attributeChangedCallback(name, oldAttrValue, newAttrValue) {
        if (oldAttrValue === newAttrValue) return;

        switch (name) {
            case 'cmi-footer-name':
                this._cmiFooterName = newAttrValue;
                break;
            case 'cmi-footer-slot':
                this._cmiFooterSlot = newAttrValue;
                break;
            case 'company-name':
                this._userBondCompany = newAttrValue;
                break;
            case 'can-manage-company-cards':
                this._canManageCompanyCards = newAttrValue.toLowerCase() === 'true';
                break;
        }
    }

    connectedCallback() {
        this._cardModal = document.createElement('card-modal');
        this._cardModal.restrictCardsFeatureEnabled = this.restrictCardsFeatureEnabled;
        this._confirmationModal = document.createElement('confirmation-modal');
        this.shadowRoot.appendChild(this._cardModal);
        this.shadowRoot.appendChild(this._confirmationModal);

        this._companyCardHeader.textContent = `${this._userBondCompany} Cards`;
        this._addCompanyCardButton.style.visibility = this._canManageCompanyCards ? '' : 'hidden';

        this._addCompanyCardButton.addEventListener('click', this._addNewCompanyCard);
        this._addPersonalCardButton.addEventListener('click', this._addNewPersonalCard);
        this._confirmationModal.addEventListener('ok-confirmation', this._removeCard);
        this._cardModal.addEventListener('add-card-success', this._addCardSuccess);
        this._cardModal.addEventListener('edit-card-success', this._editCardSuccess);
    }

    disconnectedCallback() {
        this._addCompanyCardButton.removeEventListener('click', this._addNewCompanyCard);
        this._addPersonalCardButton.removeEventListener('click', this._addNewPersonalCard);
        this._confirmationModal.removeEventListener('ok-confirmation', this._removeCard);
        this._cardModal.removeEventListener('add-card-success', this._addCardSuccess);
        this._cardModal.removeEventListener('edit-card-success', this._editCardSuccess);
    }

    set model(value) {
        this._model = value;
        this._cardModal.allowedCardTypes = this._model.AllowedCardTypes;
        this._initialSetup();
    }

    get model() {
        return this._model;
    }

    triggerState(state) {
        [... this.companyCardContainer.querySelectorAll('card-management-item'), ... this.personalCardContainer.querySelectorAll('card-management-item')]
            .forEach(item => {
                item.querySelector(this._cmiFooterName).shadowRoot.querySelectorAll('[trigger]').forEach(x => x.disabled = state);
            });

        this._addCompanyCardButton.disabled = state;
        this._addPersonalCardButton.disabled = state;
    }

    _addCardSuccess(event) {
        const model = event.detail.model;
        const message = `Successfully added a new ${model.CompanyCard ? "company" : "personal"} card`;
        const container = model.CompanyCard ? this.companyCardContainer : this.personalCardContainer;
        container.appendChild(this._createCardManagementItemElement(model));
        this._showAlert(message);
    }

    _editCardSuccess(event) {
        const model = event.detail.model;
        const message = `Successfully saved changes for the ${model.CompanyCard ? "company" : "personal"} card`;
        const container = model.CompanyCard ? this.companyCardContainer : this.personalCardContainer;
        container.querySelectorAll('card-management-item').forEach(item => {
            if (item.model.CardId === model.CardId) {
                item.model = model;
                item.querySelector(this._cmiFooterName).model = model;
            }
        });
        if (model.IsDefault)
            this.dispatchEvent(new CustomEvent('default-card-selected', { detail: { model } }));
        this._showAlert(message);
    }

    _editCard(event) {
        this._clearAlert();
        this._cardModal.openEditModal(event.detail.model, this._userBondCompany);
    }

    _openConfirmationModal(event) {
        this._clearAlert();
        this._confirmationModal.open({
            message: "Remove this card?",
            response: event.detail.model
        });
    }

    _addNewCompanyCard() {
        this._clearAlert();
        this._cardModal.openAddModal(this._userBondCompany);
    }

    _addNewPersonalCard() {
        this._clearAlert();
        this._cardModal.openAddModal();
    }

    _removeCard(event) {
        this.triggerState(true);
        const model = event.detail.response;
        const requestUrl = model.CompanyCard
            ? '/Payments/RemoveCompanyCard'
            : '/Payments/RemovePersonalCard';

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST', requestUrl,
            { id: model.CardId },
            this._requestCallback.bind(this, model));
    }

    _requestCallback(model, response, status) {
        this.triggerState(false);
        if (!status) {
            const message = typeof (response) === 'string' ? response : "Unable to remove card. Please contact your administrator if this issue persists.";
            this._showAlert(message, true);
            return;
        }

        this._showAlert("Card has been removed.");
        const container = model.CompanyCard ? this.companyCardContainer : this.personalCardContainer;
        container.querySelectorAll('card-management-item').forEach(item => {
            if (item.model.CardId === model.CardId) {
                container.removeChild(item);
            }
        });

        if (model.IsDefault)
            this.dispatchEvent(new CustomEvent('clear-default-card'));
    }

    _initialSetup() {
        this.model.CompanyCards.forEach(companyCardModel => this._createCardItems(companyCardModel, this.companyCardContainer, true));
        this.model.PersonalCards.forEach(personalCardModel => this._createCardItems(personalCardModel, this.personalCardContainer, false));
    }

    _createCardItems(cardModel, container, isCompanyCard) {
        container.insertBefore(this._createCardManagementItemElement(cardModel, isCompanyCard), container.querySelector('hr'));
    }

    _createCardManagementItemElement(model, isCompanyCard) {
        const cardItem = document.createElement('card-management-item');
        cardItem.model = model;
        const cardItemFooter = document.createElement(this._cmiFooterName);
        cardItemFooter.setAttribute('slot', this._cmiFooterSlot);
        if (isCompanyCard) {
            cardItemFooter.editButton.style.visibility = this._canManageCompanyCards ? 'visible' : 'hidden';
            cardItemFooter.removeButton.style.visibility = this._canManageCompanyCards ? 'visible' : 'hidden';
        }
        cardItemFooter.addEventListener("edit-card-onclick", this._editCard);
        cardItemFooter.addEventListener("remove-card-onclick", this._openConfirmationModal);
        cardItem.cardFooter = cardItemFooter;
        cardItem.appendChild(cardItemFooter);
        return cardItem;
    }

    _showAlert(message, danger = false) {
        this.dispatchEvent(new CustomEvent("show-alert", { detail: { message, danger } }));
    }

    _clearAlert() {
        this.dispatchEvent(new CustomEvent("clear-alert"));
    }
}
customElements.define('cards-component', CardsComponent);