import bootstrap from '../../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './ChargeStatusLegend.html';
import $ from 'jquery';
import 'bootstrap';

class ChargeStatusLegend extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['legend-container']);

        this._legendContainer = this.shadowRoot.getElementById('legend-container');
        this._toggleButton = this.shadowRoot.getElementById('toggle-button');
        this._notBondableTooltip = this.shadowRoot.getElementById('not-bondable-tooltip');

        this._dataErrorLegend = this.shadowRoot.getElementById('data-entry-error-legend');
        this._dataErrorTooltip = this.shadowRoot.getElementById('data-error-tooltip');

        this._toggleLegend = this._toggleLegend.bind(this);
    }

    get _userIdentifier() {
        return this.getAttribute('user');
    }

    get _restrictIssuingAuthorityFeatureFlag() {
        return this.hasAttribute('restrict-issuing-authority-feature-flag') &&
            this.getAttribute('restrict-issuing-authority-feature-flag').toLowerCase() === 'true';
    }

    get _dataEntryErrorLogicTempFeatureFlag() {
        return this.hasAttribute('data-entry-error-logic-temp-feature-flag') &&
            this.getAttribute('data-entry-error-logic-temp-feature-flag').toLowerCase() === 'true';
    }

    get _tenantStateSubdivision() {
        return this.getAttribute('tenant-state-subdivision');
    }

    get _isRosterLegendEnabled() {
        const userSettingsString = localStorage.getItem(this._userIdentifier);
        if (userSettingsString === null) {
            return true;
        }
        const settings = JSON.parse(userSettingsString);
        const setting = settings.isRosterLegendEnabled;
        return setting === undefined || setting;
    }

    connectedCallback() {
        if (!this._isRosterLegendEnabled) {
            this._toggleLegend();
        }

        let notBondableTipTitle = this._notBondableTooltip.getAttribute('title');
        if (this._restrictIssuingAuthorityFeatureFlag) {
            notBondableTipTitle = notBondableTipTitle + ', the issuing authority is restricted, or the bond amount exceeds your maximum single bond amount';
        }
        notBondableTipTitle = notBondableTipTitle + '.';
        this._notBondableTooltip.title = notBondableTipTitle;

        if (!this._dataEntryErrorLogicTempFeatureFlag) {
            this._dataErrorLegend.remove();
        } else {
            let dataErrorTipTitle = this._dataErrorTooltip.getAttribute('title');
            dataErrorTipTitle = dataErrorTipTitle.replace('[STATE_SUBDIVISION]', this._tenantStateSubdivision);
            this._dataErrorTooltip.title = dataErrorTipTitle;
        }

        $('[data-toggle="tooltip"]', this.shadowRoot).tooltip();

        this._toggleButton.addEventListener('click', this._toggleLegend);
    }

    disconnectedCallback() {
        this._toggleButton.removeEventListener('click', this._toggleLegend);
    }

    _toggleLegend() {
        const shouldBeHidden = this._legendContainer.classList.toggle('hide');
        const userSettings = JSON.parse(localStorage.getItem(this._userIdentifier)) || {};
        userSettings.isRosterLegendEnabled = !shouldBeHidden;
        localStorage.setItem(this._userIdentifier, JSON.stringify(userSettings));
    }
}

customElements.define('charge-status-legend', ChargeStatusLegend)