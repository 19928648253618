import kendoBootstrap from '@progress/kendo-ui/css/web/kendo.bootstrap.min.css';
import kendoCommonBootstrap from '@progress/kendo-ui/css/web/kendo.common-bootstrap.min.css';
import bootstrap from './../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './InmateInstructionTemplatesDialog.html';

class InmateInstructionTemplatesDialog extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap, kendoCommonBootstrap, kendoBootstrap]);
        this._pageTools = new PageTools();
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._closeModalButton = this.shadowRoot.getElementById('closeModal-button');
        this._modal = this.shadowRoot.querySelector('modal-component');
        this._saveButton = this.shadowRoot.getElementById('save-button');
        this._templateNameInput = this.shadowRoot.getElementById('template-name');
        this._templateInstructions = this.shadowRoot.getElementById('instructions');
        this._errorMessage = this.shadowRoot.getElementById('error-message');

        this._endPoint = null;
        this._requestObject = null;

        this._submitForm = this._submitForm.bind(this);
        this._closeModal = this._closeModal.bind(this);
    }

    connectedCallback() {
        this._saveButton.addEventListener('click', this._submitForm);
        this._closeModalButton.addEventListener('click', this._closeModal);
        $(this._templateInstructions).kendoEditor({
            pasteCleanup: {
                custom: function (html) {
                    const regex = /src="(data:image\/[^;]+;base64[^"]+)"/g;
                    const match = regex.exec(html);
                    if (match) {
                        return "";
                    }
                    return html;
                }
            }
        });
        const onLoadCallback = () => {
            const editor = $(this._templateInstructions).data("kendoEditor");
            $(editor.body).on("drop", function (e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
        };
        document.addEventListener("DOMContentLoaded", onLoadCallback, { once: true });
        this.shadowRoot.querySelector('button[title="Insert image"]').remove();
    }

    disconnectedCallback() {
        this._saveButton.removeEventListener('click', this._submitForm);
        this._closeModalButton.removeEventListener('click', this.closeModal);
    }

    openModal(data) {
        this._errorMessage.textContent = "";
        this._endPoint = data.endPoint;
        this._title = data.title;
        this._operationType = data.operationType;
        this._templateId = data.detail?.TemplateId ? data.detail?.TemplateId : "";
        this._modalComponent.openModal(this._title, false);
        $(this._templateInstructions).data("kendoEditor").value(data.detail?.Instructions ? data.detail.Instructions : "");
        this._templateNameInput.value = data.detail?.TemplateName ? data.detail?.TemplateName : "";
    }

    _closeModal() {
        this._errorMessage.textContent = "";
        this._modalComponent.closeModal();
    }

    _submitForm() {
        this._errorMessage.textContent = "";
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();
        if (!this._templateNameInput.value) {
            this._errorMessage.textContent = "A Template Name Is Required";
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            return;
        }
        const reqObject =
        {
            TemplateId: this._templateId,
            TemplateName: this._templateNameInput.value,
            Instructions: $(this._templateInstructions).data("kendoEditor").encodedValue()
        };
        xhrWrapper.makeRequest('POST', this._endPoint, reqObject, this._responseCallback.bind(this));
    }

    _responseCallback(response, isSuccess) {
        if (!isSuccess) {
            if (response === "Duplicate Inmate Instruction Template") {
                this._errorMessage.textContent = `A template with name ${this._templateNameInput.value} exists.`;
            } else if (response && response.includes("Shorten the instructions")) {
                this._errorMessage.textContent = response;
            } else {
                this._errorMessage.innerHTML = 'There was an error saving these changes, please try again in few minutes. If the problem persists, contact <contact-link>eBONDS support</contact-link>.';
            }
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._modalComponent.closeModal();
        this.dispatchEvent(new CustomEvent(this._operationType === 'add' ? 'onModalAdd' : 'onModalEdit', { detail: { 'templateName': this._templateNameInput.value } }));
    }
}
customElements.define('inmate-instruction-templates-dialog', InmateInstructionTemplatesDialog);