import template from './BootstrapTableFilter.html';
import bootstrap from './../../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class BootstrapTableFilter extends HTMLElement
{
    constructor()
    {
        super();
        this._fieldName = this.getAttribute('fieldName');
        this._fieldType = (this.getAttribute('fieldType') || 'Text').toLowerCase();
        initializeHtmlElement(
            this,
            template.replace(/FILTER_BUTTON_ID/g, `${this._fieldName}-filter-button`),
            [bootstrap, fontawesome],
            ['bootstrap-table-filter-container']
        );
        const filterByConditionTemplate = this.shadowRoot.getElementById(
            `${this._fieldType}-filter-conditions`
        );
        this.shadowRoot.getElementById('filter-by-condition').appendChild(filterByConditionTemplate.content.cloneNode(true));

        this.searchText = {};
        this.searchTextClear = this.shadowRoot.getElementById('search-text-clear');
        this.searchTextClear.style.display = 'none';
        this.filterByValuesChanged = false;
        this.filterByConditionChanged = false;
        this.filter = {};
        this.uniqueValuesCount = 0;
    }

    connectedCallback()
    {
        // Listen to uniquevalues event and generate the checkboxes based on the returned value
        this.shadowRoot.addEventListener('uniquevalues', e => this._generateCheckBox(
            (e.detail != undefined && e.detail.values != undefined) ? e.detail.values : ''
        ));
        this.shadowRoot.addEventListener('filterchanged', e =>
        {
            const filterValue = (e.detail != undefined && e.detail.values != undefined) ? e.detail.values : {};
            this.filter = filterValue;
        });

        // Close other open dropdowns and generate checkbox values on clicking the filter icon
        $('.dropdown-toggle-button', this.shadowRoot).dropdown();
        $('#bootstrap-table-filter-container', this.shadowRoot).on('show.bs.dropdown', () => this._dropdownShown());

        // Filter the checkboxes based on search criteria
        this.shadowRoot.getElementById('filter-search-input').addEventListener('keyup', (e) => {
            if (e.target.value !== null && e.target.value !== '')
                this.filterByValuesChanged = true;
            this._filterCheckBox(e.target.value);
        });

        // Clear the filter text box
        this.searchTextClear.addEventListener('click', (e) =>
        {
            this.shadowRoot.querySelector('#filter-search-input').value = '';
            e.target.style.display = 'none';
            this.shadowRoot.querySelector('#filter-search-input').dispatchEvent(new Event('keyup'));
        });

        // Enable accordion - to choose between filterByCondition or filterByValues
        $('.collapse', this.shadowRoot).collapse();
        this.shadowRoot.querySelectorAll('a[data-toggle=collapse]').forEach(item =>
        {
            item.addEventListener('click', function (e)
            {
                e.target.closest('.custom-card').querySelector('[id^="filter-by-"]').classList.toggle('show');
                e.target.closest('.custom-card').querySelector('em').classList.toggle('fa-caret-down');
                e.target.closest('.custom-card').querySelector('em').classList.toggle('fa-caret-right');
            });
        });

        // Filter by condition change event
        this.shadowRoot.querySelector('.field-condition-type').addEventListener('change', this._filterByConditionDropdownChanged.bind(this));
        this.shadowRoot.querySelector('.first-textfield input').addEventListener('change', () => this.filterByConditionChanged = true);
        const secondTextField = this.shadowRoot.querySelector('.second-textfield input');

        if (secondTextField)
            secondTextField.addEventListener('change', () => this.filterByConditionChanged = true);

        // Make filter Apply/Cancel buttons actionable
        this.shadowRoot.getElementById('filter-action-apply').addEventListener('click', () => this._applyFilterClick());
        this.shadowRoot.getElementById('filter-action-cancel').addEventListener('click', () => this._closeDropdown());

        // toggle checkboxes upon clicking
        this.shadowRoot.querySelector('#filter-by-values .form-group').addEventListener('click', (e) => this._setCheckboxValue(e), true);

        // Make select all and clear actionable in Filter by values
        this.shadowRoot.getElementById('filter-value-select-all').addEventListener('click', () => this._setAllCheckboxValue(true));
        this.shadowRoot.getElementById('filter-value-clear').addEventListener('click', () => this._setAllCheckboxValue(false));
        this.shadowRoot.querySelector('.dropdown-menu').addEventListener('click', (e) => e.stopPropagation());
    }

    _filterByConditionDropdownChanged(e) {
        // Hide first textField if 'none'
        const firstTextField = e.target.closest('#filter-by-condition').querySelector('.first-textfield input');
        const hideFirstTextField = e.target.value === 'none';
        firstTextField.classList.toggle('d-none', hideFirstTextField);
        if (hideFirstTextField) {
            firstTextField.value = '';
        }      

        //Hide second textField if not 'between'
        const secondTextField = e.target.closest('#filter-by-condition').querySelector('.second-textfield');
        if (secondTextField) {
            const hideSecondTextField = e.target.value !== 'between';
            secondTextField.classList.toggle('d-none', hideSecondTextField);
            if (hideSecondTextField) {
                secondTextField.value = '';
            }
        }

        this.filterByConditionChanged = true;
    }

    // Close all dropdown and generate checkbox on dropdown show event
    _dropdownShown()
    {
        this._closeDropdown();
        // Emit getuniquevalues event with fieldname and fieldtype
        const getUniqueValues = new CustomEvent('getuniquevalues', {
            bubbles: true,
            cancelable: false,
            composed: true,
            detail: {
                'fieldName': this._fieldName,
                'fieldType': this._fieldType
            }
        });
        this.shadowRoot.dispatchEvent(getUniqueValues);
        this._restoreFilterByConditionSettings();
        this._showFilterByConditionOrByValuesSectionsBasedOnCurrentFilters();
    }

    _restoreFilterByConditionSettings() {
        const filteredConditionType = this.filter.byCondition?.type;
        const filteredConditionValue = this.filter.byCondition?.value;
        const filteredConditionValue2 = this.filter.byCondition?.value2;

        const conditionTypeDropdown = this.shadowRoot.querySelector('.field-condition-type');
        const firstInput = this.shadowRoot.querySelector('.first-textfield input');
        if (filteredConditionType) {
            conditionTypeDropdown.value = filteredConditionType;
            if (filteredConditionValue)
                firstInput.value = filteredConditionValue;
            if (filteredConditionType === 'between' && filteredConditionValue2) {
                // This event creates and displays the second input when condition type is between
                conditionTypeDropdown.dispatchEvent(new Event('change'));
                this.shadowRoot.querySelector('.second-textfield input').value = filteredConditionValue2;
            }
            return;
        }

        conditionTypeDropdown.value = 'none';
        firstInput.value = null;
    }

    _showFilterByConditionOrByValuesSectionsBasedOnCurrentFilters() {
        const byCondition = this._getByCondition();
        const byValue = this._getByValue();

        if (byCondition['type'] !== 'none') {
            const filterByConditionSection = this.shadowRoot.querySelector('#filter-by-condition');
            const filterByConditionSectionCaretIcon = filterByConditionSection.parentElement.querySelector('em');

            filterByConditionSection.classList.toggle('show', true);
            filterByConditionSectionCaretIcon.classList.toggle('fa-caret-down', true);
            filterByConditionSectionCaretIcon.classList.toggle('fa-caret-right', false);
        }

        if (this.uniqueValuesCount !== byValue['values'].length) {
            const filterByValueSection = this.shadowRoot.querySelector('#filter-by-values');
            const filterByValueSectionCaretIcon = filterByValueSection.parentElement.querySelector('em');

            filterByValueSection.classList.toggle('show', true);
            filterByValueSectionCaretIcon.classList.toggle('fa-caret-down', true);
            filterByValueSectionCaretIcon.classList.toggle('fa-caret-right', false);
        }
    }

    // Dispatch the closedropdown event
    _closeDropdown()
    {
        this.shadowRoot.querySelector('#filter-search-input').value = '';
        const closeDropdown = new CustomEvent('closedropdown', {
            bubbles: true,
            cancelable: false,
            composed: true
        });
        this.shadowRoot.dispatchEvent(closeDropdown);
    }

    /**
     * Toggle the checkbox value and mark filter has changed, only then the filter by value will be considered
     * @param {Event} e
     */
    _setCheckboxValue(e) {
        this.filterByValuesChanged = true;
        if (e.target.closest('.form-check') != null)
        {
            let checkbox = e.target.closest('.form-check').querySelector('input');
            if (e.target.nodeName !== 'INPUT')
            {
                checkbox.checked = !checkbox.checked;
                e.preventDefault();
            }
        }
    }

    /**
     * Set the all checkbox value based Select All/Clear button
     * @param {Boolean} value
     */
    _setAllCheckboxValue(value) {
        this.shadowRoot.querySelectorAll('#filter-by-values .form-group input').forEach(item => {
            this.filterByValuesChanged = true;
            item.checked = value;
        });
    }

    _getByValue() {
        let byValue = {};
        byValue['type'] = 'values';
        let values = [];
        this.shadowRoot.querySelectorAll('#filter-by-values .form-group input:checked').forEach(c => {
            if (!c.parentElement.matches('.d-none')) {
                values.push(
                    this._fieldType === 'number' ?
                        parseFloat(c.getAttribute('value'), 10) :
                        c.getAttribute('value')
                );
            }
        });
        byValue['values'] = values;
        // Set clear to be true when none of the checkboxes are checked
        byValue['clear'] = values.length === 0 ? true : false;
        return byValue;
    }

    _getByCondition() {
        let byCondition = {};
        byCondition['type'] = this.shadowRoot.querySelector('.field-condition-type').value;
        byCondition['value'] = this.shadowRoot.querySelector('.first-textfield input').value;
        if (byCondition['type'] === 'between') {
            byCondition['value2'] = this.shadowRoot.querySelector('.second-textfield input').value;
        }
        return byCondition;
    }

    /**
     * Apply the filters and filter by value will be applied only if any of checkbox value has changed
     */
    _applyFilterClick() {
        const filter = {};
        let byConditionApplied = false;
        let byValueApplied = false;
        let allFilterByValueItemsSelected = false;
        let filterByConditionCleared = false;
        filter['dataType'] = this._fieldType; // number | text | date
        if (this.filterByConditionChanged) {
            const byCondition = this._getByCondition();
            this.filterByConditionChanged = false;
            if (byCondition['type'] !== 'none') {
                filter['byCondition'] = byCondition;
                byConditionApplied = true;
            }
            else
                filterByConditionCleared = true;
        }
        if (this.filterByValuesChanged) {
            const byValue = this._getByValue();
            this.filterByValuesChanged = false;
            // Consider filter to be applied only when not all checkboxes are checked
            if (this.uniqueValuesCount !== byValue['values'].length) {
                filter['byValue'] = byValue;
                byValueApplied = true;
            }
            else
                allFilterByValueItemsSelected = true;
        }

        const filterActiveClasses = ['text-primary', 'filter-active'];
        const filterIconClasses = Array.from(this.shadowRoot.querySelector('#bootstrap-table-filter-container > a').classList);
        const isFilterActive = filterActiveClasses.every(x => filterIconClasses.includes(x));

        filter['isVisible'] = byValueApplied || byConditionApplied || (isFilterActive && !allFilterByValueItemsSelected);

        if (byValueApplied || byConditionApplied || allFilterByValueItemsSelected || filterByConditionCleared) {
            this.filter = filter;
            // Dispatch applyfilter event along with the data in detail
            // Filter icon should be visible when either of the filters are applied
            const applyFilter = new CustomEvent('applyfilter',
                {
                    bubbles: true,
                    cancelable: false,
                    composed: true,
                    detail: {
                        'fieldName': this._fieldName,
                        'filter': filter,
                        'isApplyFilter': byConditionApplied || byValueApplied
                    }
                });
            this.shadowRoot.dispatchEvent(applyFilter);
        }
        this._closeDropdown();
    }

    // Generate checkboxes under the filter
    _generateCheckBox(values)
    {
        if (values !== '')
        {
            let filterByValues = this.shadowRoot.querySelector('#filter-by-values .form-group');
            let filterValueCheckboxTemplate = this.shadowRoot.getElementById(
                'filter-value-checkbox-template'
            );
            filterByValues.innerHTML = '';
            let filterValues = [];
            let filterCleared = false;
            if (this.filter['byValue'] !== undefined) {
                filterValues = this.filter['byValue']['values'];
                filterCleared = this.filter['byValue']['clear'];
            }

            values.forEach(value => {
                const $filterValue = filterValueCheckboxTemplate.content.cloneNode(true);
                const randomId = makeId(10);
                let originalValue = value.original;
                const formattedValue = value.formatted;
                let input = $filterValue.querySelector('input.form-check-input');
                input.setAttribute('id', randomId);
                input.checked = true;
                // set checkbox state based on filter values
                if (this.filter['dataType']?.toLowerCase() === 'number')
                    originalValue = value.original === '' ? Number(undefined) : Number(originalValue);
                if (filterValues.length > 0 && !filterValues.includes(originalValue) || filterCleared)
                    input.checked = false;
                input.value = originalValue;
                let label = $filterValue.querySelector('label.form-check-label');
                label.setAttribute('for', randomId);
                label.innerHTML = formattedValue;
                filterByValues.appendChild($filterValue);
            });
            this.uniqueValuesCount = values.length;
        }
    }

    // Hide/Show the checkboxes based on the search text
    _filterCheckBox(searchText = '') {
        if (searchText !== '')
            this.searchTextClear.style.display = 'block';
        else
            this.searchTextClear.style.display = 'none';

        const filterByValues = this.shadowRoot.querySelector('#filter-by-values .form-group');
        filterByValues.querySelectorAll('div.form-check').forEach(div =>
        {
            const inputCheckbox = div.querySelector('input.form-check-input');
            if (inputCheckbox.value.toLowerCase().includes(searchText.toLocaleLowerCase()))
            {
                div.classList.remove('d-none');
            }
            else
            {
                div.classList.add('d-none');
            }
        });
    }
}

// Create a random string
function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

customElements.define('bootstrap-table-filter', BootstrapTableFilter);