import template from './AllPaidBanner.html';
import bootstrap from '../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class AllPaidBanner extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap], ['container']);
    }
}

customElements.define('all-paid-banner', AllPaidBanner);