import template from './CardManagementFooter.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';

class CardManagementFooter extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this.pageTools = new PageTools();
        
        this.editButton = this.shadowRoot.querySelector('#cmi-button-edit');
        this.removeButton = this.shadowRoot.querySelector('#cmi-button-remove');
        this._defaultButton = this.shadowRoot.getElementById('cmi-button-default');
        this._defaultButtonRemove = this.shadowRoot.getElementById('cmi-remove-default');

        this._editButtonClick = this._editButtonClick.bind(this);
        this._removeButtonClick = this._removeButtonClick.bind(this);
        this._defaultButtonClick = this._defaultButtonClick.bind(this);
        this._defaultRemoveButtonClick = this._defaultRemoveButtonClick.bind(this);
    }
    
    connectedCallback() {
        this.editButton.addEventListener('click', this._editButtonClick);
        this.removeButton.addEventListener('click', this._removeButtonClick);
        this._defaultButton.addEventListener('click', this._defaultButtonClick);
        this._defaultButtonRemove.addEventListener('click', this._defaultRemoveButtonClick);
        this.model = (this.parentElement || this.parentNode).model;
        this._validateDefaultButtons();
    }

    disconnectedCallback() {
        this.editButton.removeEventListener('click', this._editButtonClick);
        this.removeButton.removeEventListener('click', this._removeButtonClick);
        this._defaultButton.removeEventListener('click', this._defaultButtonClick);
        this._defaultButtonRemove.removeEventListener('click', this._defaultRemoveButtonClick);
    }

    set model(value) {
        this._model = value;
        this._validateDefaultButtons();
    }

    get model() {
        return this._model;
    }

    _fireCustomEventWithModel(eventName) {
        // Bubbles: true, Composed: true allows for things to listen on the card component which owns this footer rather that every individual footer.
        // https://pm.dartus.fr/blog/a-complete-guide-on-shadow-dom-and-event-propagation/
        this.dispatchEvent(new CustomEvent(eventName, { bubbles: true, composed: true, detail: { model: this.model } }));
    }

    _editButtonClick() {
        this._fireCustomEventWithModel("edit-card-onclick");
    }

    _removeButtonClick() {
        this._fireCustomEventWithModel("remove-card-onclick");
    }

    _defaultButtonClick() {
        this._fireCustomEventWithModel("default-card-onclick");
    }

    _defaultRemoveButtonClick() {
        this._fireCustomEventWithModel("remove-default-card-onclick");
    }

    _toggleDefaultButtonState(isDefault) {
        this._defaultButtonRemove.classList.toggle('hidden', !isDefault);
        this._defaultButton.classList.toggle('hidden', isDefault);
    }

    _validateDefaultButtons() {
        if (this.model){
            const cardExpiredState = this.pageTools.checkExpired(this.model.ExpirationDate);
            const isDefault = this.model.IsDefault;
    
            this._toggleDefaultButtonState(isDefault);
            this._defaultButton.toggleAttribute('disabled', cardExpiredState);
        }
    }
}

customElements.define('card-management-footer', CardManagementFooter);