import { initializeHtmlElement } from '../HTMLElementExtensions';
import { createColumn } from '../BootstrapTableExtensions';
import bootstrap from './../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import template from './ManageRestrictedIssuingAuthoritiesPage.html';
import './RestrictIssuingAuthorityDialog';
import '../ActionButton';

class ManageRestrictedIssuingAuthoritiesPage extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable], ['container']);
        this._pageTools = new PageTools();
        this._restrictedIssuingAuthoritiesTable = this.shadowRoot.getElementById('restricted-issuing-authorities-table');
        this._newButton = this.shadowRoot.getElementById('create-new-restricted-issuing-authority-input');
        this.restrictIssuingAuthorityDialog = this.shadowRoot.querySelector('restrict-issuing-authority-dialog');
        this._alert = this.shadowRoot.getElementById('alert');
        this._confirmationModal = this.shadowRoot.getElementById("confirmation-modal");

        this._addNewRestrictedIssuingAuthorityOnClick = this._addNewRestrictedIssuingAuthorityOnClick.bind(this);
        this._addRestrictedIssuingAuthoritySaveCallback = this._addRestrictedIssuingAuthoritySaveCallback.bind(this);
        this._editRestrictedIssuingAuthoritySaveCallback = this._editRestrictedIssuingAuthoritySaveCallback.bind(this);
        this._editOnClick = this._editOnClick.bind(this);
        this._deleteOnClick = this._deleteOnClick.bind(this);
    }

    set model(value) {
        this._model = value;
        this._restrictedIssuingAuthoritiesTable.destroyTable();
        this._loadTableData();

        this._restrictedIssuingAuthoritiesTable.shadowRoot.querySelectorAll('action-button[id="edit-button"]')
            .forEach(x => x.addEventListener('click', this._editOnClick.bind(this)));

        this._restrictedIssuingAuthoritiesTable.shadowRoot.querySelectorAll('action-button[id="delete-button"]')
            .forEach(x => x.addEventListener('click', this._showDeleteConfirmationModal.bind(this)));
    }

    connectedCallback() {
        this._tableConfig = {
            tableColumns: this._compileColumns(),
            uniqueId: 'RestrictedIssuingAuthorityId'
        }

        this._newButton.addEventListener('click', this._addNewRestrictedIssuingAuthorityOnClick);
        this.restrictIssuingAuthorityDialog.addEventListener('onModalAdd', this._addRestrictedIssuingAuthoritySaveCallback);
        this.restrictIssuingAuthorityDialog.addEventListener('onModalEdit', this._editRestrictedIssuingAuthoritySaveCallback);
        this._confirmationModal.addEventListener('ok-confirmation', this._deleteOnClick);
    }

    disconnectedCallback() {
        this._newButton.removeEventListener('click', this._addNewRestrictedIssuingAuthorityOnClick);
        this.restrictIssuingAuthorityDialog.removeEventListener('onModalAdd', this._addRestrictedIssuingAuthoritySaveCallback);
        this.restrictIssuingAuthorityDialog.removeEventListener('onModalEdit', this._editRestrictedIssuingAuthoritySaveCallback);
        this._confirmationModal.removeEventListener('ok-confirmation', this._deleteOnClick);
    }

    _addNewRestrictedIssuingAuthorityOnClick(e) {
        const data = {
            endPoint: "/Admin/RestrictedIssuingAuthority/AddNewRestrictedIssuingAuthority",
            title: 'Add Restricted Issuing Authority',
            authUsers: this._model.AuthUsers,
            riaUsers: null,
            operationType: "add"
        };
        this.restrictIssuingAuthorityDialog.openModal(data);
    }

    _addRestrictedIssuingAuthoritySaveCallback(response) {
        this._toggleTriggers(true);
        this._restrictedIssuingAuthoritiesTable.showLoading();
        this._showAlert(`Successfully Added Restricted Issuing Authority: ${response.detail.Name}`, false);;
        this._refreshTable();
    }

    _editOnClick(e) {
        const button = e.target;
        const restrictedIssuingAuthorityId = parseInt(button.getAttribute('data-edit'));

        const data = {
            endPoint: "/Admin/RestrictedIssuingAuthority/UpdateRestrictedIssuingAuthority",
            title: 'Edit Restricted Issuing Authority',
            authUsers: this._model.AuthUsers,
            riaUsers: this._model.RestrictedIssuingAuthority.find(x => x.RestrictedIssuingAuthorityId == restrictedIssuingAuthorityId),
            operationType: "edit"
        };

        this.restrictIssuingAuthorityDialog.openModal(data);
    }

    _editRestrictedIssuingAuthoritySaveCallback(response) {
        this._toggleTriggers(true);
        this._restrictedIssuingAuthoritiesTable.showLoading();
        this._showAlert(`Successfully Edited Restricted Issuing Authority: ${response.detail.Name}`, false);
        this._refreshTable();
    }

    _showDeleteConfirmationModal(e) {
        const clickedDeleteButton = e.target;
        const restrictedIssuingAuthorityId = parseInt(clickedDeleteButton.getAttribute('data-delete'));
        const restrictedIssuingAuthority = this._model.RestrictedIssuingAuthority.find(ria => ria.RestrictedIssuingAuthorityId == restrictedIssuingAuthorityId);
        this._confirmationModal.open({
            message: `Delete ${restrictedIssuingAuthority.Name}?`,
            response: {
                'restrictedIssuingAuthorityId': restrictedIssuingAuthorityId,
                'deleteButton': clickedDeleteButton
            },
            actionButtonText: "Delete"
        });
    }

    _deleteOnClick(e){
        const restrictedIssuingAuthorityId = parseInt(e.detail.response.restrictedIssuingAuthorityId);
        this._toggleTriggers(true);
        const deleteButton = e.detail.response.deleteButton;
        deleteButton.setSpinner(true);
        const restrictedIssuingAuthority = this._model.RestrictedIssuingAuthority.find(ria => ria.RestrictedIssuingAuthorityId == restrictedIssuingAuthorityId);
        this._restrictedIssuingAuthorityName = restrictedIssuingAuthority.Name;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST',
            '/Admin/RestrictedIssuingAuthority/DeleteRestrictedIssuingAuthority',
            { RestrictedIssuingAuthorityId: restrictedIssuingAuthorityId },
            this._deleteExistingRestrictedIssuingAuthority.bind(this));
    }

    _deleteExistingRestrictedIssuingAuthority(response, isSuccess) {
        if (!isSuccess) {
            this._toggleTriggers(false);
            this._showAlert("Failed to Delete Restricted Issuing Authority.", true);
            return;
        }
        this._restrictedIssuingAuthoritiesTable.showLoading();
        this._showAlert(`Successfully deleted Restricted Issuing Authority: ${this._restrictedIssuingAuthorityName}`, false);
        this._refreshTable();
    }

    _showAlert(message, isError) {
        this._alert.classList.toggle('text-success', !isError);
        this._alert.classList.toggle('text-danger', isError);
        this._alert.toggleAttribute('hidden', false);
        this._alert.textContent = message;
    }

    _loadTableData() {
        this._restrictedIssuingAuthoritiesTable.initTableData({
            bootstrapTableConfig: this._tableConfig,
            tableData: this._model.RestrictedIssuingAuthority
        });
    }

    _compileColumns() {
        const columns = [
            createColumn('Restricted Issuing Authority', 'Name'),
            createColumn('Authorized Users', 'AuthorizedUsers'),
            createColumn('', '', undefined, this._editButtonFormatter),
            createColumn('', '', undefined, this._deleteButtonFormatter)
        ];

        return columns;
    }

    _editButtonFormatter(value, row) {
        return `<div class='d-flex justify-content-center'><action-button id=edit-button data-edit=${row.RestrictedIssuingAuthorityId} class='btn btn-primary' trigger>Edit</action-button></div>`;
    }

    _deleteButtonFormatter(value, row) {
        return `<div class='d-flex justify-content-center'><action-button id=delete-button data-delete=${row.RestrictedIssuingAuthorityId} class='btn btn-danger' trigger>Delete</action-button></div>`;
    }

    _refreshTable() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/Admin/RestrictedIssuingAuthority/Read_RestrictedIssuingAuthorities`,
            null,
            this._refreshTableCallback.bind(this));
    }

    _refreshTableCallback(response, isSuccess) {
        this._toggleTriggers(false);
        if (!isSuccess) {
            this._showAlert("Failed to retrieve restricted issuing authorities");
            return;
        }

        this.model = JSON.parse(response);
    }

    _toggleTriggers(state) {
        this._pageTools.toggleTriggers(this.shadowRoot, state);
        this._pageTools.toggleTriggers(this._restrictedIssuingAuthoritiesTable.shadowRoot, state);
    }
}
customElements.define('manage-restricted-issuing-authorities-page', ManageRestrictedIssuingAuthoritiesPage);