import template from './ContactLink.html'
import { initializeHtmlElement } from './HTMLElementExtensions';

class ContactLink extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template);
    }

}
customElements.define("contact-link", ContactLink);