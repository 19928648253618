import bootstrap from "../../scss/bootstrap-custom.scss";
import jmsEnum from "../Enumerations/Jms.js";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import template from "./ManageTenantModal.html";
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";

class ManageTenantModal extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._form = this.shadowRoot.getElementById("modal-content");
        this._alert = this.shadowRoot.getElementById("alert-modal");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._modal = this.shadowRoot.querySelector("modal-component");
        this._statusMessage = this.shadowRoot.getElementById("status-message");
        this._tenantName = this.shadowRoot.getElementById("modal-content-name");
        this._tenantIsActiveToggleSwitch = this.shadowRoot.getElementById("is-active-toggle-switch");
        this._tenantPhoneNumber = this.shadowRoot.getElementById("phone-number");
        this._saveButton = this.shadowRoot.getElementById("user-action-button");
        this._closeButton = this.shadowRoot.getElementById("closeModal-button");

        this._smartSearchFirstName = this.shadowRoot.getElementById("smart-search-minimum-first-name-length");
        this._smartSearchLastName = this.shadowRoot.getElementById("smart-search-minimum-last-name-length");
        this._smartSearchAllowDob = this.shadowRoot.getElementById("smart-search-allow-dob");
        this._smartSearchAllowBookingNumber = this.shadowRoot.getElementById("smart-search-allow-booking-number");
        this._smartSearchAllowSoNumber = this.shadowRoot.getElementById("smart-search-allow-sonumber");
        this._allpaidContainer = this.shadowRoot.getElementById("allpaid-container");
        this._allpaidPlcCodeTitle = this.shadowRoot.getElementById("plc-code-title");
        this._allpaidPlcCodeInput = this.shadowRoot.getElementById("allpaid-smart-search-plc-code");
        this._payAmount1Name = this.shadowRoot.getElementById("pay_amount1_name");
        this._payAmount1Title = this.shadowRoot.getElementById("pay_amount1_name_title");
        this._payAmount2Name = this.shadowRoot.getElementById("pay_amount2_name");
        this._payAmount2Title = this.shadowRoot.getElementById("pay_amount2_name_title");
        this._payAmount3Name = this.shadowRoot.getElementById("pay_amount3_name");
        this._payAmount3Title = this.shadowRoot.getElementById("pay_amount3_name_title");
        this._payAmount4Name = this.shadowRoot.getElementById("pay_amount4_name");
        this._payAmount4Title = this.shadowRoot.getElementById("pay_amount4_name_title");
        this._allpaidMinimumFirstNameInput = this.shadowRoot.getElementById("allpaid-smart-search-minimum-first-name-length");
        this._allpaidMinimumLastNameInput = this.shadowRoot.getElementById("allpaid-smart-search-minimum-last-name-length");
        this._allpaidGenesisFee = this.shadowRoot.getElementById("allpaid-genesis-fee");
        this._maximumPaymentAmount = this.shadowRoot.getElementById("maximum-payment-amount");
        this._apiSmartSearchFirstName = this.shadowRoot.getElementById("api-smart-search-minimum-first-name-length");
        this._apiSmartSearchLastName = this.shadowRoot.getElementById("api-smart-search-minimum-last-name-length");
        this._apiSmartSearchAllowDob = this.shadowRoot.getElementById("api-smart-search-allow-dob");
        this._apiSmartSearchAllowBookingNumber = this.shadowRoot.getElementById("api-smart-search-allow-booking-number");
        this._apiSmartSearchAllowSoNumber = this.shadowRoot.getElementById("api-smart-search-allow-so-number");
        this._allpaidAllowDob = this.shadowRoot.getElementById("allpaid-smart-search-allow-dob");
        this._allpaidAllowBookingNumber = this.shadowRoot.getElementById("allpaid-smart-search-allow-booking-number");
        this._allpaidAllowSoNumber = this.shadowRoot.getElementById("allpaid-smart-search-allow-sonumber");
        this._timezoneContainer = this.shadowRoot.getElementById("timezone-container");
        this._stateCode = this.shadowRoot.getElementById("select-state-code");
        this._stateSubdivision = this.shadowRoot.getElementById("state-subdivision-dropdown");
        this._selectTimezone = this.shadowRoot.getElementById("select-timezone");
        this._selectJms = this.shadowRoot.getElementById("select-jms");
        this._isTimezoneOnDocument = this.shadowRoot.getElementById("timezone-checkbox");
        this._countyTimeoutInput = this.shadowRoot.getElementById("county-timeout");
        this._bondCompanyTimeoutInput = this.shadowRoot.getElementById("bond-company-timeout");
        this._timeoutContainer = this.shadowRoot.getElementById("timeout-container");
        this._totalBondsContainer = this.shadowRoot.getElementById("total-bonds-container");
        this._totalBondsUiDelimiterInput = this.shadowRoot.getElementById("total-bonds-ui-delimiter");
        this._totalBondsBondDocumentDelimiterInput = this.shadowRoot.getElementById("total-bonds-bond-document-delimiter");
        this._attorneyLegaleseTextArea = this.shadowRoot.getElementById("attorney-bond-company-legalese");
        this._bondCoMinWorth = this.shadowRoot.getElementById("bond-company-min-worth");

        this._successMessage = null;
        this._endpoint = null;

        this._save = this._save.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this._makeAllPaidPlcCodeRequired = this._makeAllPaidPlcCodeRequired.bind(this);
        this._defaultToCorrectSubdivision = this._defaultToCorrectSubdivision.bind(this);
    }

    _createJmsTypeDropdownOptions() {
        const sortedJmsOptions = Object.values(jmsEnum).sort((a, b) => a.string.localeCompare(b.string));

        for (const item of sortedJmsOptions) {
            const option = document.createElement("option");
            option.value = item.value;
            option.text = item.string;
            this._selectJms.appendChild(option);
        }
    }

    connectedCallback() {
        this._saveButton.addEventListener("click", this._save);
        this._closeButton.addEventListener("click", this.closeModal);
        this._allpaidMinimumFirstNameInput.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidMinimumLastNameInput.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidGenesisFee.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._maximumPaymentAmount.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowDob.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowBookingNumber.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowSoNumber.addEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._stateCode.addEventListener("change", this._defaultToCorrectSubdivision.bind(this));
        this._createJmsTypeDropdownOptions();
    }

    disconnectedCallback() {
        this._saveButton.removeEventListener("click", this._save);
        this._closeButton.removeEventListener("click", this.closeModal);
        this._allpaidMinimumFirstNameInput.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidMinimumLastNameInput.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidGenesisFee.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._maximumPaymentAmount.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowDob.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowBookingNumber.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
        this._allpaidAllowSoNumber.removeEventListener("input", this._makeAllPaidPlcCodeRequired);
    }

    closeModal() {
        this._modal.closeModal();
    }

    openModal(title, model, endpoint, isEdit) {
        this._allpaidPlcCodeTitle.classList.toggle("required", false);
        this._allpaidPlcCodeInput.toggleAttribute("required", false);
        this._allpaidContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.AcceptsAllPaidPayments]);
        this._timeoutContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.ConfigurableTimeoutTemp]);
        this._totalBondsContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.TotalBonds]);

        this.resetModal(isEdit);
        if (model && settings.featureFlags[FeatureFlag.AcceptsAllPaidPayments])
            this.validateAllPaidDefaults(model);
        this._model = model ||
        {
            TenantName: "",
            IsActive: true,
            Timezone: "",
            IsTimezoneOnDocument: false,
            SmartSearchMinimumFirstNameLength: 0,
            SmartSearchMinimumLastNameLength: 0,
            SmartSearchAllowDobSearch: false,
            SmartSearchAllowBookingNumberSearch: false,
            SmartSearchAllowSoNumberSearch: false,
            ApiSmartSearchMinimumFirstNameLength: 0,
            ApiSmartSearchMinimumLastNameLength: 0,
            ApiSmartSearchAllowDobSearch: false,
            ApiSmartSearchAllowBookingNumberSearch: false,
            ApiSmartSearchAllowSoNumberSearch: false,
            PlcCode: "",
            PayAmount1Name: "",
            PayAmount2Name: "",
            PayAmount3Name: "",
            PayAmount4Name: "",
            PaymentPortalSmartSearchMinimumFirstNameLength: 0,
            PaymentPortalSmartSearchMinimumLastNameLength: 0,
            GenesisFee: 0,
            MaximumPaymentAmount: 50000,
            PaymentPortalSmartSearchAllowDobSearch: false,
            PaymentPortalSmartSearchAllowBookingNumberSearch: false,
            PaymentPortalSmartSearchAllowSoNumberSearch: false,
            CountyUserConfigurableTimeout: 30,
            BondCompanyUserConfigurableTimeout: 10,
            StateCode: "",
            StateSubdivision: "",
            Jms: "",
            TenantPhoneNumber: "",
            TotalBondsUiDelimiter: "",
            TotalBondsBondDocumentDelimiter: "",
            AttorneyLegalese: "",
            CollateralAmount: null,
        };

        if (!isEdit) {
            this._selectTimezone.value = "";
            this._selectTimezone.options[0].value = "";
            this._isTimezoneOnDocument.checked = false;
            this._selectJms.value = "";
        } else {
            this._selectTimezone.value = this._model.Timezone;
            this._isTimezoneOnDocument.checked = this._model.IsTimezoneOnDocument;
            this._selectJms.value = this._model.Jms;
        }

        this._successMessage = isEdit ? `Successfully edited ${this._model.TenantName}` : "Successfully created a new tenant!";
        this._endpoint = endpoint;

        this._tenantName.value = this._model.TenantName;
        this._tenantIsActiveToggleSwitch.checked = this._model.IsActive;
        this._bondCoMinWorth.value = this._model.CollateralAmount;
        this._tenantPhoneNumber.value = this._model.TenantPhoneNumber;
        this._smartSearchFirstName.value = this._model.SmartSearchMinimumFirstNameLength;
        this._smartSearchLastName.value = this._model.SmartSearchMinimumLastNameLength;
        this._smartSearchAllowDob.checked = this._model.SmartSearchAllowDobSearch;
        this._smartSearchAllowBookingNumber.checked = this._model.SmartSearchAllowBookingNumberSearch;
        this._smartSearchAllowSoNumber.checked = this._model.SmartSearchAllowSoNumberSearch;
        this._apiSmartSearchFirstName.value = this._model.ApiSmartSearchMinimumFirstNameLength ?? 0;
        this._apiSmartSearchLastName.value = this._model.ApiSmartSearchMinimumLastNameLength ?? 0;
        this._apiSmartSearchLastName.value = this._model.ApiSmartSearchMinimumLastNameLength ?? 0;
        this._apiSmartSearchAllowDob.checked = this._model.ApiSmartSearchAllowDobSearch;
        this._apiSmartSearchAllowBookingNumber.checked = this._model.ApiSmartSearchAllowBookingNumberSearch;
        this._apiSmartSearchAllowSoNumber.checked = this._model.ApiSmartSearchAllowSoNumberSearch;
        this._stateCode.value = this._model.StateCode;
        this._stateSubdivision.value = this._model.StateSubdivision;
        this._attorneyLegaleseTextArea.value = this._model.AttorneyLegalese;
        if (settings.featureFlags[FeatureFlag.AcceptsAllPaidPayments]) {
            this._allpaidPlcCodeTitle.classList.toggle("required", true)
            this._allpaidPlcCodeInput.toggleAttribute("required", true);
            this._allpaidPlcCodeInput.value = this._model.PlcCode;
            this._extendedValidationRequired();
            this._payAmount1Name.value = this._model.PayAmount1Name;
            this._payAmount2Name.value = this._model.PayAmount2Name;
            this._payAmount3Name.value = this._model.PayAmount3Name;
            this._payAmount4Name.value = this._model.PayAmount4Name;
            this._allpaidMinimumFirstNameInput.value = this._model.PaymentPortalSmartSearchMinimumFirstNameLength;
            this._allpaidMinimumLastNameInput.value = this._model.PaymentPortalSmartSearchMinimumLastNameLength;
            this._allpaidGenesisFee.value = this._model.GenesisFee;
            this._maximumPaymentAmount.value = this._model.MaximumPaymentAmount;
            this._allpaidAllowDob.checked = this._model.PaymentPortalSmartSearchAllowDobSearch;
            this._allpaidAllowBookingNumber.checked = this._model.PaymentPortalSmartSearchAllowBookingNumberSearch;
            this._allpaidAllowSoNumber.checked = this._model.PaymentPortalSmartSearchAllowSoNumberSearch;
        }
        if (settings.featureFlags[FeatureFlag.ConfigurableTimeoutTemp]) {
            this._countyTimeoutInput.value = this._model.CountyUserConfigurableTimeout;
            this._bondCompanyTimeoutInput.value = this._model.BondCompanyUserConfigurableTimeout;
        }
        if (settings.featureFlags[FeatureFlag.TotalBonds]) {
            this._totalBondsUiDelimiterInput.value = this._model.TotalBondsUiDelimiter;
            this._totalBondsBondDocumentDelimiterInput.value = this._model.TotalBondsBondDocumentDelimiter;
        }
        this._modal.openModal(title, false);
    }

    _extendedValidationRequired() {
        if (settings.featureFlags[FeatureFlag.ExtendedAllPaidValidationTemp]) {
            this._payAmount1Title.classList.toggle("required", true);
            this._payAmount1Title.toggleAttribute("hidden", false);
            this._payAmount1Name.toggleAttribute("required", true);
            this._payAmount2Title.classList.toggle("required", true);
            this._payAmount2Title.toggleAttribute("hidden", false);
            this._payAmount2Name.toggleAttribute("required", true);
            this._payAmount3Title.toggleAttribute("hidden", false);
            this._payAmount4Title.toggleAttribute("hidden", false);
        }       
    }

    _save() {
        if (!this._form.checkValidity()) {
            this._form.reportValidity();
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        
        this._model.IsActive = this._tenantIsActiveToggleSwitch.checked;
        this._model.TenantPhoneNumber = this._tenantPhoneNumber.value.trim();
        this._model.TenantName = this._tenantName.value.trim();
        this._model.CollateralAmount = this._bondCoMinWorth.value ? this._bondCoMinWorth.value : null;
        this._model.SmartSearchMinimumFirstNameLength = parseInt(this._smartSearchFirstName.value);
        this._model.SmartSearchMinimumLastNameLength = parseInt(this._smartSearchLastName.value);
        this._model.SmartSearchAllowDobSearch = this._smartSearchAllowDob.checked;
        this._model.SmartSearchAllowBookingNumberSearch = this._smartSearchAllowBookingNumber.checked;
        this._model.SmartSearchAllowSoNumberSearch = this._smartSearchAllowSoNumber.checked;
        this._model.ApiSmartSearchMinimumFirstNameLength = parseInt(this._apiSmartSearchFirstName.value);
        this._model.ApiSmartSearchMinimumLastNameLength = parseInt(this._apiSmartSearchLastName.value);
        this._model.ApiSmartSearchAllowDobSearch = this._apiSmartSearchAllowDob.checked;
        this._model.ApiSmartSearchAllowBookingNumberSearch = this._apiSmartSearchAllowBookingNumber.checked;
        this._model.ApiSmartSearchAllowSoNumberSearch = this._apiSmartSearchAllowSoNumber.checked;
        this._model.StateCode = this._stateCode.value;
        this._model.StateSubdivision = parseInt(this._stateSubdivision.value);
        this._model.Jms = Number(this._selectJms.value);
        this._model.AttorneyLegalese = this._attorneyLegaleseTextArea.value;
        if (settings.featureFlags[FeatureFlag.AcceptsAllPaidPayments]) {
            this._model.PlcCode = this._allpaidPlcCodeInput.value.replace(/\s/g, '');
            this._model.PayAmount1Name = this._payAmount1Name.value.trim();
            this._model.PayAmount2Name = this._payAmount2Name.value.trim();
            this._model.PayAmount3Name = this._payAmount3Name.value.trim();
            this._model.PayAmount4Name = this._payAmount4Name.value.trim();
            this._model.PaymentPortalSmartSearchMinimumFirstNameLength = parseInt(this._allpaidMinimumFirstNameInput.value);
            this._model.PaymentPortalSmartSearchMinimumLastNameLength = parseInt(this._allpaidMinimumLastNameInput.value);
            this._model.GenesisFee = this._allpaidGenesisFee.value;
            this._model.MaximumPaymentAmount = this._maximumPaymentAmount.value;
            this._model.PaymentPortalSmartSearchAllowDobSearch = this._allpaidAllowDob.checked;
            this._model.PaymentPortalSmartSearchAllowBookingNumberSearch = this._allpaidAllowBookingNumber.checked;
            this._model.PaymentPortalSmartSearchAllowSoNumberSearch = this._allpaidAllowSoNumber.checked;
        }
        this._model.CountyUserConfigurableTimeout = parseInt(this._countyTimeoutInput.value);
        this._model.BondCompanyUserConfigurableTimeout = parseInt(this._bondCompanyTimeoutInput.value);

        this._model.TotalBondsUiDelimiter = this._totalBondsUiDelimiterInput.value;
        this._model.TotalBondsBondDocumentDelimiter = this._totalBondsBondDocumentDelimiterInput.value;

        this._model.Timezone = this._selectTimezone.value;
        this._model.IsTimezoneOnDocument = this._isTimezoneOnDocument.checked;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeJsonRequest("POST", this._endpoint, JSON.stringify(this._model), this._saveCallback.bind(this));
    }

    _saveCallback(response, isSuccess) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!isSuccess) {
            this._toggleViewAlert(response, false);
            return;
        }

        this.dispatchEvent(
            new CustomEvent("success",
                {
                    detail: {
                        message: this._successMessage,
                        models: JSON.parse(response)
                    }
                })
        );
        this._modal.closeModal();
    }

    // replace disabled state to !isEdit when ready to use;
    resetModal(isEdit) {
        this._tenantIsActiveToggleSwitch.disabled = true;
        this._tenantIsActiveToggleSwitch.checked = true;
        this._tenantName.value = "";
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._toggleViewAlert("", true);
    }

    validateAllPaidDefaults(model) {
        if (!model.PaymentPortalSmartSearchAllowBookingNumberSearch)
            model.PaymentPortalSmartSearchAllowBookingNumberSearch = false;
        if (!model.PaymentPortalSmartSearchAllowSoNumberSearch)
            model.PaymentPortalSmartSearchAllowSoNumberSearch = false;
        if (!model.PaymentPortalSmartSearchAllowDobSearch)
            model.PaymentPortalSmartSearchAllowDobSearch = false;
        if (!model.PaymentPortalSmartSearchMinimumFirstNameLength)
            model.PaymentPortalSmartSearchMinimumFirstNameLength = 0;
        if (!model.PaymentPortalSmartSearchMinimumLastNameLength)
            model.PaymentPortalSmartSearchMinimumLastNameLength = 0;
        if (!model.GenesisFee)
            model.GenesisFee = 0;
        if (!model.MaximumPaymentAmount)
            model.MaximumPaymentAmount = 50000;
    }

    _toggleViewAlert(message, state) {
        this._alertMessage.textContent = message ? message : "";
        this._alert.classList.toggle("hidden", state);
    }

    _makeAllPaidPlcCodeRequired() {
        this._allpaidPlcCodeTitle.classList.toggle("required", true);
        this._allpaidPlcCodeInput.toggleAttribute("required", true);
    }

    _defaultToCorrectSubdivision(event) {
        const selectedState = event.detail.state;
        switch (selectedState) {
            case "AK":
                this._stateSubdivision.value = 2;
                break;
            case "LA":
                this._stateSubdivision.value = 1;
                break;
            default:
                this._stateSubdivision.value = 0;
        }
    }
}
customElements.define("manage-tenant-modal", ManageTenantModal);
