import bootstrap from '../scss/bootstrap-custom.scss';
import template from './BannerItemCard.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import watermark from '../images/poa-report-icon.svg';

class BannerItemCard extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], ['card']);
        this._cardBackgroundImage = this.shadowRoot.getElementById('card-background-image');
        this._cardBackgroundImage.style.backgroundImage = `url(${watermark})`;
    }
}

customElements.define('banner-item-card', BannerItemCard);