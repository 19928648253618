import { dollarFieldFormatter, dateTimeFormatterWithTz } from '../BootstrapTableExtensions.js';
import bootstrap from './../../scss/bootstrap-custom.scss';
import template from './BootstrapReportGrid.html';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import applicationStatus from '../Enumerations/ApplicationStatus.js';
import receiptType from '../Enumerations/ReceiptType.js';
import { parseStringToNumberOrNull } from '../JavaScriptFunctions.js';
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";

class BootstrapReportGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            template,
            [bootstrap],
            ['bootstrap-report-wrapper']
        );
        this._tableConfig = {};
        this.dollarFieldFormatter = dollarFieldFormatter;
        this.dateTimeFormatterWithTz = dateTimeFormatterWithTz;
        this._table = null;
        this._bootstrapTable = this.shadowRoot.querySelector('bootstrap-table');
        if (this._bootstrapTable)
            this._table = this._bootstrapTable.shadowRoot.getElementById('table');

        this._generateReportButton = this.shadowRoot.getElementById('GetReport');
        this._errorMessage = this.shadowRoot.getElementById('error-message');
        this._generateReportButton.addEventListener('click', this._generateReportClick.bind(this));
    }

    setReportDate(dateFrom, dateTo) {
        this.shadowRoot.getElementById('date-from').value = dateFrom;
        this.shadowRoot.getElementById('date-to').value = dateTo;
    }

    getReportDate() {
        const datefrom = this.shadowRoot.getElementById('date-from').value;
        const dateto = this.shadowRoot.getElementById('date-to').value;

        const datefromUTC = window.DateFormatter.localToUtc(datefrom);
        const datetoUTC = window.DateFormatter.localToUtc(dateto);

        return {
            datefrom: datefromUTC,
            dateto: datetoUTC
        };
    }

    initBootstrapReportGrid(tableConfig) {
        if (tableConfig != null)
            this._tableConfig = tableConfig;
        if (this._tableConfig.reportUrl !== undefined)
            this._reportUrl = this._tableConfig.reportUrl;

        // Initialize the bootstrap table component
        this._toggleButtonState(true);
        this._bootstrapTable.initTableData({
            bootstrapTableConfig: this._tableConfig
        });

        this._fetchReportData(this._initCallback);
    }

    _fetchReportData(callback) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST',
            this._reportUrl,
            this.getReportDate(),
            callback.bind(this));
    }

    applicationStatusFormatter(value) {
        if (isNaN(value))
            return applicationStatus[`${value}`] !== undefined ?
                applicationStatus[`${value}`].string : value;
        else
            return value;
    }

    payorFormatter(rowValue, rowData) {
        if (settings.featureFlags[FeatureFlag.ShowPaymentMethod] &&
            rowData.PaymentMethod &&
            typeof rowData.PaymentMethod === "string" &&
            rowData.PaymentMethod.localeCompare(
                receiptType.commissary.string, undefined, { sensitivity: "base" }) === 0
        )
            return rowData.InmateFullName;
        return rowValue;
    }

    paymentMethodFormatter(value, data) {
        const number = parseStringToNumberOrNull(value);
        const formattedOtherPaymentMethod = `${receiptType.other.string}: ${data.OtherReceiptTypeInfo}`;
        if (number && number === receiptType.other.value) {
            return formattedOtherPaymentMethod;
        } else if (number || number === 0) {
            return Object.values(receiptType).find(x => x.value === number).string;
        } else {
            const paymentMethod = receiptType[`${value}`];
            if (paymentMethod?.value === receiptType.other.value)
                return formattedOtherPaymentMethod;
            return paymentMethod ? paymentMethod.string : value;
        }
    }

    _initCallback(response, success) {
        let tableData = [];
        if (success) {
            const responseJson = JSON.parse(response);
            tableData = responseJson.Data !== undefined ? responseJson.Data : responseJson;
        }
        this._bootstrapTable.tableFullData = tableData;
        this._bootstrapTable.refreshTableData();
        this._toggleButtonState(false);
    }

    _refreshCallback(response, success) {
        if (success) {
            const responseJson = JSON.parse(response);
            this._bootstrapTable.tableFullData = responseJson.Data !== undefined ? responseJson.Data : responseJson;
            this._bootstrapTable.refreshTableData();
        } else {
            this._errorMessage.innerHTML =
                'There was a problem generating the report. Please try again, and if the problem persists, contact eBONDS™ Support.';
            this._errorMessage.toggleAttribute('hidden', false);
        }
        this._toggleButtonState(false);
    }

    _generateReportClick() {
        this._errorMessage.toggleAttribute('hidden', true);
        this._toggleButtonState(true);
        this._fetchReportData(this._refreshCallback);
    }

    dateFormatter(value) {
        return window.DateFormatter.getTenantFormattedDate(value);
    }

    _toggleButtonState(value) {
        this._generateReportButton.disabled = value;
        this._generateReportButton.setSpinner(value);
    }
}

customElements.define('bootstrap-report-grid', BootstrapReportGrid);