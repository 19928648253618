import bootstrap from './../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import { initializeHtmlElement } from './../HTMLElementExtensions';
import { createColumn, dollarFieldFormatter, generateAndShowDetailTable, dateTimeFormatterWithTz } from '../BootstrapTableExtensions';
import template from './CancelBondIndex.html';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import './../InmateInformation';
import './../ActionButton';
import '../AlertMessage';
import paymentFeeDestination from '../Enumerations/PaymentFeeDestination';

class CancelBondIndex extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ['container']);

        this._pageTools = new PageTools();
        this._tooltip = this.shadowRoot.getElementById('title-tooltip');
        this._searchContainer = this.shadowRoot.getElementById('search-container');
        this._bookingNumberInput = this.shadowRoot.getElementById('booking-number');
        this._searchButton = this.shadowRoot.getElementById('search-button');
        this._buttonText = this.shadowRoot.getElementById('button-text');
        this._inmateInformation = this.shadowRoot.getElementById('inmate-information');
        this._table = this.shadowRoot.getElementById('table');
        this._alertMessage = this.shadowRoot.getElementById('alert-message');

        this._searchButtonOnclick = this._searchButtonOnclick.bind(this);
        this._searchRequestCallback = this._searchRequestCallback.bind(this);
        this._inputOnKeyUp = this._inputOnKeyUp.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
    }

    get _userIsCounty() {
        return this.hasAttribute('user-is-county') &&
            this.getAttribute('user-is-county').toLowerCase() === 'true';
    }

    connectedCallback() {
        this._loadTableData([]);
        $(this._tooltip).tooltip({ boundary: 'window', placement: 'auto' });
        this._bookingNumberInput.addEventListener('keyup', this._inputOnKeyUp);
        this._searchButton.addEventListener('click', this._searchButtonOnclick);
        this._inmateInformation.userIsCounty = this._userIsCounty;
    }

    disconnectedCallback() {
        this._bookingNumberInput.removeEventListener('keyup', this._inputOnKeyUp);
        this._searchButton.removeEventListener('click', this._searchButtonOnclick);
    }

    _inputOnKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this._searchButton.click();
        }
    }

    _loadTableData(model) {
        const table = $(this._table);
        const columns = [];
        columns.push(createColumn('Bond Company', 'BondCompanyName'));
        columns.push(createColumn('Bond Type', 'BondType'));
        columns.push(createColumn('Posted Date', 'PostedDateTime', undefined, dateTimeFormatterWithTz));
        columns.push(createColumn('Remittance ID', 'RemittanceID'));
        columns.push(createColumn('Payment Authorized Date', 'CountyPayment.AuthorizedDateTime', undefined, dateTimeFormatterWithTz));
        columns.push(createColumn('Payment Captured Date', 'CountyPayment.CaptureDateTime', undefined, dateTimeFormatterWithTz));
        columns.push(createColumn('', 'BondApplicationID', false, this._cancelButtonFormatter));

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: 'table table-sm table-striped table-bordered mt-2 d-none',
            detailView: true,
            detailFormatter: this._detailFormatter,
            uniqueId: 'BondApplicationID'
        });
    }

    _cancelButtonFormatter(value, row, index) {
        return `<div><action-button class="btn btn-success" bondappid="${value}" trigger>Cancel</action-button></div>`;
    }

    _cancelButtonSetup() {
        const cancelButtons = this.shadowRoot.querySelectorAll('action-button[bondappid]');
        Array.from(cancelButtons).forEach(button => button.addEventListener('click', this._cancelButtonOnClick));
    }

    _cancelButtonOnClick(event) {
        const bondAppId = event.target.getAttribute('bondappid');
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Cancel/FinishCancel/${bondAppId}`);
    }

    _detailFormatter(index, row, element) {
        let columns = [];
        columns.push(createColumn('Bond Number', 'BondApplicationDetailID'));
        columns.push(createColumn('Degree', 'OffenseDegree'));
        columns.push(createColumn('Offense', 'OffenseDesc'));
        columns.push(createColumn('Bond Amount', 'BondAmt', undefined, dollarFieldFormatter));
        columns.push(createColumn('Bond Fee', 'CountyBondFee', undefined, dollarFieldFormatter));
        columns.push(createColumn('POA Number', 'POANumber'));
        columns.push(createColumn('POA Status', 'POAStatus'));
        const detailRequestUrl = `/Cancel/GetCancelDetails/${row.BondApplicationID}`;
        generateAndShowDetailTable(columns, detailRequestUrl, element);
    }

    _searchButtonOnclick() {
        this._clearErrorMessage();
        const searchString = this._bookingNumberInput.value;
        if (searchString) {
            this._searchButton.disabled = true;
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest('POST',
                '/Cancel/GetPostedBondsByBookingNumber',
                { bookingNumber: searchString },
                this._searchRequestCallback
            );
        }
    }

    _searchRequestCallback(response, isSuccess) {
        this._searchButton.disabled = false;
        if (!isSuccess) {
            this._showErrorMessage('Failed to retrieve bonds. If this error persists contact your administrator.');
            return;
        }
        const model = this._pageTools.tryParseJson(response);
        model.forEach(x => x.CountyPayment = x.Payments.find(y => y.PaymentFeeDestination === paymentFeeDestination.county.value));
        if (model.length > 0) {
            $(this._table).bootstrapTable('load', model);
            this._updateHtml(model[0]);
            return;
        }
        this._showErrorMessage(`The search for booking number '${this._bookingNumberInput.value}' returned no results. Enter another booking number and try again.`);
    }

    _showErrorMessage(message) {
        this._inmateInformation.toggleAttribute('hidden', true);
        this._table.classList.add('d-none');
        this._alertMessage.textContent = message;
        this._alertMessage.toggleAttribute('hidden', false);
    }

    _clearErrorMessage() {
        this._alertMessage.textContent = '';
        this._alertMessage.toggleAttribute('hidden', true);
    }

    _updateHtml(model) {
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.BondApplicationID);
        this._cancelButtonSetup();
        this._clearErrorMessage();
        this._inmateInformation.toggleAttribute('hidden', false);
        this._table.classList.remove('d-none');
    }
}
customElements.define('cancel-bond-index', CancelBondIndex);