import { dollarFieldFormatter } from "../BootstrapTableExtensions.js";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import feeCategory from "../Enumerations/FeeCategory.js"
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";

class BondPaymentsCapturedGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="bond-payments-captured"></bootstrap-report-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector("bootstrap-report-grid");

        this._pageTools = new PageTools();
        this._customFeeFieldFormatter = this._customFeeFieldFormatter.bind(this);
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, "days").format("YYYY-MM-DDT00:00"), moment().format("YYYY-MM-DDT23:59"));
        this.shadowRoot.querySelector("bootstrap-report-grid").shadowRoot.querySelector('bootstrap-table').shadowRoot.querySelector('#export-pdf').toggleAttribute('hidden', true);
    }

    set fees(value) {
        this._fees = value;
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
    }

    _creditedFormatter(value, row, index, field) {
        if (row.CountyPaymentCreditReceived !== undefined && row.CountyPaymentCreditReceived)
            return "<input type='checkbox' disabled checked/>";
        else
            return "<input type='checkbox' disabled/>";
    }

    _compileColumns() {
        const payorColumnHeader = (settings.featureFlags[FeatureFlag.ShowPaymentMethod]) ? "Payor" : "Bond Company";
        this._tableColumns = [
            {
                title: payorColumnHeader,
                field: "PayorName",
                type: "Text",
                escape: true,
                formatter: this._bootstrapReportGrid.payorFormatter.bind(this)
            },
            {
                title: "Inmate",
                field: "InmateFullName",
                type: "Text",
                escape: true
            },
            {
                title: "Booking Number",
                field: "BookNumber",
                type: "Text",
                escape: true
            },
            {
                title: "SO Number",
                field: "SONumber",
                type: "Text",
                escape: true
            }
        ];

        if (settings.featureFlags[FeatureFlag.BondPaymentCaptureCourtTemp]) {
            this._tableColumns.push({
                title: "Court Name",
                field: "CourtName",
                type: "Text",
                escape: true,
            });
        }

        this._tableColumns.push({
            title: "Total Bond Amount",
            field: "TotalBondAmt",
            type: "Number",
            escape: true,
            formatter: dollarFieldFormatter
        });

        this._tableColumns.push({
                title: "Total Bond Fees",
                field: "TotalCountyBondFees",
                type: "Number",
                escape: true,
                formatter: dollarFieldFormatter
        });
          
        if (settings.featureFlags[FeatureFlag.ShowPaymentMethod]) {
            this._tableColumns.push(
                {
                    title: "Payment Method",
                    field: "PaymentMethod",
                    type: "Text",
                    escape: true,
                    formatter: this._bootstrapReportGrid.paymentMethodFormatter
                }
            );

            this._tableColumns.push(
                {
                    title: "Reference Number",
                    field: "ReferenceNumber",
                    type: "Text",
                    escape: true
                }
            );
        }

        this._tableColumns.push(
            {
                title: "Payment Authorized Time",
                field: "CountyPaymentAuthorizedDateTime",
                type: "Date",
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: "Payment Capture Time",
                field: "CountyPaymentCaptureDateTime",
                type: "Date",
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            },
            {
                title: "Status",
                field: "AppStatus",
                type: "Text",
                escape: true,
                formatter: this._bootstrapReportGrid.applicationStatusFormatter
            },
            {
                title: "Cancel Reason",
                field: "CancelReason",
                type: "Text",
                escape: true
            },
            {
                title: "Canceled By",
                field: "CanceledBy",
                type: "Text",
                escape: true
            },
            {
                title: "Remittance ID",
                field: "RemittanceId",
                type: "Number",
                escape: true
            },
            {
                title: "Credited",
                field: "CountyPaymentCreditReceived",
                type: "Number",
                searchable: false,
                escape: false,
                formatter: this._creditedFormatter,
                printIgnore: true
            }
        );

        const suretyEnumerationValue = feeCategory.suretyFee.value;
        const customFees = this._fees.filter(x => x.FeeCategory !== suretyEnumerationValue);
        const suretyFees = this._fees.filter(x => x.FeeCategory === suretyEnumerationValue);
        const showAdditionalColumns =
            (suretyFees.length > 0 && customFees.length >= 1) || (suretyFees.length === 0 && customFees.length >= 2);

        if (showAdditionalColumns) {
            this._addAdditionalDynamicColumns(customFees, suretyFees, suretyEnumerationValue);
        }
    }

    _addAdditionalDynamicColumns(customFees, suretyFees, suretyEnumerationValue) {
        this._fees = customFees;
        if ((settings.featureFlags[FeatureFlag.HasPerOffenseFee]) && suretyFees.length > 0) {
            this._fees.push({
                "Amount": null,
                "FeeCategory": suretyEnumerationValue,
                "Name": "SuretyFeesSum",
                "IsSurcharge": false
            });
            //https://stackoverflow.com/a/1129270
            this._fees.sort((a, b) => a.Name.localeCompare(b.Name));
        }

        const distinctFeeColumnNames = [];
        for (const fee of this._fees) {
            if (!distinctFeeColumnNames.includes(fee.Name)) {
                this._tableColumns.push({
                    title: fee.Name === "SuretyFeesSum" ? "Surety Fee" : fee.Name,
                    field: fee.Name,
                    type: "Number",
                    escape: true,
                    formatter: this._customFeeFieldFormatter
                });
                distinctFeeColumnNames.push(fee.Name);
            }
        }
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: "BondDocumentsPosted",
            tableColumns: this._tableColumns,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: "Bond-Fee-Payments-Captured",
            reportUrl: "/Reports/Payment_Read",
            restoreFilters: true,
            tableName: "Payment",
            tableClasses: "table-striped",
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }

    _customFeeFieldFormatter(_, rowData, rowIndex, rowField) {
        let modelAmountsSum;

        if (rowField === "SuretyFeesSum")
            modelAmountsSum = rowData.SuretyFeesSum;
        else {
            const models = rowData.CustomFees.filter(model => model.Name === rowField);
            const modelAmounts = models.map(model => model.Amount);
            modelAmountsSum = modelAmounts.reduce((a, b) => a + b, 0);
        }

        // This line is needed to persist the calculated data for the row column to table's row data.
        // It is also needed in order for these custom column to not appear collapsed or scrunched up in the xlsx export.
        rowData[rowField] = modelAmountsSum;

        const amount = this._pageTools.formatNumberToDollarAmount(modelAmountsSum);
        return `<div>${amount}</div>`;
    }
}

customElements.define("bond-payments-captured-grid", BondPaymentsCapturedGrid);