import bootstrap from '../../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import template from './ApplicationRefreshLegend.html';
import $ from 'jquery';
import 'bootstrap';

class ApplicationRefreshLegend extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['refresh-legend-container']);

        this._legendContainer = this.shadowRoot.getElementById('refresh-legend-container');
        this._toggleButton = this.shadowRoot.getElementById('toggle-button');

        this._toggleLegend = this._toggleLegend.bind(this);
    }

    get _userIdentifier() {
        return this.getAttribute('user');
    }

    get _isRefreshLegendEnabled() {
        const userSettingsString = localStorage.getItem(this._userIdentifier);
        if (userSettingsString === null) {
            return true;
        }
        const settings = JSON.parse(userSettingsString);
        const setting = settings.isRefreshLegendEnabled;
        return setting === undefined || setting;
    }

    connectedCallback() {
        if (!this._isRefreshLegendEnabled) {
            this._toggleLegend();
        }

        $('[data-toggle="tooltip"]', this.shadowRoot).tooltip();

        this._toggleButton.addEventListener('click', this._toggleLegend);
    }

    disconnectedCallback() {
        this._toggleButton.removeEventListener('click', this._toggleLegend);
    }

    _toggleLegend() {
        const shouldBeHidden = this._legendContainer.classList.toggle('hide');
        const userSettings = JSON.parse(localStorage.getItem(this._userIdentifier)) || {};
        userSettings.isRefreshLegendEnabled = !shouldBeHidden;
        localStorage.setItem(this._userIdentifier, JSON.stringify(userSettings));
    }
}

customElements.define('application-refresh-legend', ApplicationRefreshLegend)