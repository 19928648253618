import bootstrap from "../scss/bootstrap-custom.scss";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import { saveDialog, showAlert, clearAlert } from "./EditDialogExtensions";
import template from "./EditPowersDialog.html";
import "./ActionButton";

class EditPowersDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById("edit-powers-dialog-close-button");
        this._saveButton = this.shadowRoot.getElementById("edit-powers-dialog-save-button");
        this._alert = this.shadowRoot.getElementById("modal-alert-view");
        this._modalComponent = this.shadowRoot.querySelector("modal-component");
        this._form = this.shadowRoot.querySelector("form");

        this._powerNumberInput = this.shadowRoot.getElementById("power-number");
        this._powerLimitInput = this.shadowRoot.getElementById("power-limit");

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
    }

    connectedCallback() {
        this._closeButton.addEventListener("click", this._closeDialog);
        this._saveButton.addEventListener("click", this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener("click", this._closeDialog);
        this._saveButton.removeEventListener("click", this._saveDialog);
    }

    openModal(selectedCharges) {
        this._selectedCharges = selectedCharges;
        this._setInitialValues();

        this._modalComponent.openModal("Powers of Attorney", false);
    }

    _setInitialValues() {
        const firstCharge = this._selectedCharges[0];
        let powerNumberDifferent = false;
        let powerLimitDifferent = false;

        this._selectedCharges.forEach(charge => {
            powerNumberDifferent = powerNumberDifferent || charge.POANumber != firstCharge.POANumber;
            powerLimitDifferent = powerLimitDifferent || charge.POALimit != firstCharge.POALimit;
        });

        this._powerNumberInput.value = powerNumberDifferent ? null : firstCharge.POANumber;
        this._powerLimitInput.value = powerLimitDifferent ? null : firstCharge.POALimit;

        if (powerNumberDifferent || powerLimitDifferent) {
            showAlert(this._alert, "There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.");
        } else {
            clearAlert(this._alert);
        }
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: "/Application/ProcessManualPowers",
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: false,
            customSaveCallback: null
        });
    }

    _gatherData() {
        const powerNumber = this._powerNumberInput.value;
        const powerLimit = this._powerLimitInput.value;

        return {
            bondApplicationDetailIds: this._selectedCharges.map(element => element.BondApplicationDetailID),
            bondApplicationId: this._selectedCharges[0].BondApplicationID,
            poaNumber: powerNumber,
            poaLimit: powerLimit,
        };
    }
}
customElements.define("edit-powers-dialog", EditPowersDialog);