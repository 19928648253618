import { ColDef, ColDefField, ISelectCellEditorParams, SelectCellEditor, EditableCallback } from "ag-grid-enterprise";

export function CreatePercentageColumn<TData>(
    field: ColDefField<TData, number | null> & keyof TData,
): ColDef<TData, number | null> {
    return {
        field: field,
        cellDataType: "number",
        valueGetter: (params) => { // Server expects 1.0 == 100%
            if (params.data === undefined || params.data === null)
                return null;
            const value = <number>params.data[field];
            return value * 100;
        },
        valueSetter: (params) => {
            if (params.newValue === undefined || params.newValue === null) {
                (<number | null>params.data[field]) = null;
            } else {
                (<number | null>params.data[field]) = params.newValue / 100;
            }
            return true;
        },
        valueFormatter: (params) => {
            const num = Number(params.value);
            if (isNaN(num))
                return "";
            return (num / 100).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 });
        }
    }
};

type StandardEnum<T> = {
    [id: string]: T | string;
    [n: number]: string;
}

export function CreateEnumColumn<TData, TValue extends (string | number)>(
    field: ColDefField<TData, TValue> & keyof TData,
    type: StandardEnum<TValue>,
    editable?: boolean | EditableCallback<TData, TValue>,
): ColDef<TData, TValue> {
    return {
        field: field,
        cellEditor: SelectCellEditor,
        cellEditorParams: <ISelectCellEditorParams>{ values: Object.keys(type).map(x => Number(x)).filter(x => !isNaN(x)) },
        editable: editable,
        valueFormatter: (data) => {
            return `${data.value !== null && data.value !== undefined ? type[data.value] : "Invalid"}`;
        }               
    }
}