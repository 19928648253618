import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert, ensureListHasSameValuesAndDisplay, handleDropdownDifferencesCallback } from './EditDialogExtensions';
import template from './EditPRInfoDialog.html';
import './StateDropdown';
import './ActionButton';

class EditPRInfoDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('pr-info-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('pr-info-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');
        this._informationTitle = this.shadowRoot.getElementById('pr-information-title');
        this._prInfoContainer = this.shadowRoot.getElementById('pr-information-container');
        this._issuingAuthorityInput = this.shadowRoot.getElementById("issuing-authority");
        this._additionalInmateInfoContainer = this.shadowRoot.getElementById("additional-inmate-info-container")
        this._inmatePlaceOfBirthInput = this.shadowRoot.getElementById("inmate-place-of-birth");
        this._inmateRelativeCheckbox = this.shadowRoot.getElementById('inmate-relative-checkbox');
        this._inmateRelativeContainer = this.shadowRoot.getElementById('inmate-relative-container');
        this._inmateRelativeNameInput = this.shadowRoot.getElementById("inmate-relative-name");
        this._inmateRelativeAddressInput = this.shadowRoot.getElementById("inmate-relative-address");
        this._inmateRelativeCityInput = this.shadowRoot.getElementById("inmate-relative-city");
        this._inmateRelativeStateInput = this.shadowRoot.getElementById("inmate-relative-state");
        this._inmateRelativeZipInput = this.shadowRoot.getElementById("inmate-relative-zip");
        this._inmateRelativePhoneInput = this.shadowRoot.getElementById("inmate-relative-phone");
        this._inmateEmployerCheckbox = this.shadowRoot.getElementById('inmate-employer-checkbox');
        this._inmateEmployerContainer = this.shadowRoot.getElementById('inmate-employer-container');
        this._inmateEmployerNameInput = this.shadowRoot.getElementById("inmate-employer-name");
        this._inmateEmployerAddressInput = this.shadowRoot.getElementById("inmate-employer-address");
        this._inmateEmployerCityInput = this.shadowRoot.getElementById("inmate-employer-city");
        this._inmateEmployerStateInput = this.shadowRoot.getElementById("inmate-employer-state");
        this._inmateEmployerZipInput = this.shadowRoot.getElementById("inmate-employer-zip");
        this._inmateEmployerPhoneInput = this.shadowRoot.getElementById("inmate-employer-phone");

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._inmateRelativeCheckboxOnChange = this._inmateRelativeCheckboxOnChange.bind(this);
        this._inmateEmployerCheckboxOnChange = this._inmateEmployerCheckboxOnChange.bind(this);
    }

    set prBondHasIssuingAuthorityFeatureFlag(value) {
        this._informationTitle.toggleAttribute('hidden', !value);
        this._prInfoContainer.toggleAttribute('hidden', !value);
        this._issuingAuthorityInput.toggleAttribute('required', value);
    }

    set hideAdditionalInmateInformationInputs(value) {
        this._optionalContainerToggle(this._additionalInmateInfoContainer, !value);
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
        this._inmateRelativeCheckbox.addEventListener('change', this._inmateRelativeCheckboxOnChange);
        this._inmateEmployerCheckbox.addEventListener('change', this._inmateEmployerCheckboxOnChange);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
        this._inmateRelativeCheckbox.removeEventListener('change', this._inmateRelativeCheckboxOnChange);
        this._inmateEmployerCheckbox.removeEventListener('change', this._inmateEmployerCheckboxOnChange);
    }

    openModal(selectedCharges, bondDisplayName) {
        this._selectedCharges = selectedCharges;
        this._setInitialValues();

        this._informationTitle.textContent = `${bondDisplayName.title}`;
        this._saveButton.textContent = `${bondDisplayName.button}`;
        this._modalComponent.openModal(`${bondDisplayName.title}`, false);
    }

    _inmateRelativeNameDifferenceCallback(item, different) {
        this._optionalInmateInformationSectionsCallbackHelper(
            item.NearestRelativeName,
            different,
            this._inmateRelativeNameInput,
            this._inmateRelativeCheckbox
        );
    }

    _inmateEmployerNameDifferenceCallback(item, different) {
        this._optionalInmateInformationSectionsCallbackHelper(
            item.Employer,
            different,
            this._inmateEmployerNameInput,
            this._inmateEmployerCheckbox
        );
    }

    _optionalInmateInformationSectionsCallbackHelper(value, different, input, checkbox) {
        if (this._additionalInmateInfoContainer.hasAttribute("hidden"))
            return;

        const isUnknown = value === 'Unknown';
        const shouldBeUnchecked = isUnknown && !different; // If all are set to 'Unknown'
        if (shouldBeUnchecked === checkbox.checked)
            checkbox.click();

        input.value = (different || isUnknown) ? null : value;
    }

    _setInitialValues() {
        const elements = [
            { property: 'IssuingAuthority', element: this._issuingAuthorityInput },
            { property: 'InmatePlaceOfBirth', element: this._inmatePlaceOfBirthInput },
            { property: 'NearestRelativeName', callback: this._inmateRelativeNameDifferenceCallback.bind(this)},
            { property: 'NearestRelativeAddress', element: this._inmateRelativeAddressInput },
            { property: 'NearestRelativeCity', element: this._inmateRelativeCityInput },
            { property: 'NearestRelativeState', element: this._inmateRelativeStateInput, callback: handleDropdownDifferencesCallback },
            { property: 'NearestRelativeZip', element: this._inmateRelativeZipInput },
            { property: 'NearestRelativePhone', element: this._inmateRelativePhoneInput },
            { property: 'Employer', callback: this._inmateEmployerNameDifferenceCallback.bind(this) },
            { property: 'EmployerAddress', element: this._inmateEmployerAddressInput },
            { property: 'EmployerCity', element: this._inmateEmployerCityInput },
            { property: 'EmployerState', element: this._inmateEmployerStateInput, callback: handleDropdownDifferencesCallback },
            { property: 'EmployerZip', element: this._inmateEmployerZipInput },
            { property: 'EmployerPhone', element: this._inmateEmployerPhoneInput }
        ];

        const differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, elements);

        if (differences.length > 0) {
            showAlert(this._alert, 'There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: '/Application/UpdatePR',
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }

    _gatherData() {
        let model = {
            selected: this._selectedCharges.map(element => element.BondApplicationDetailID),
            issuingAuthority: this._issuingAuthorityInput.value,
            inmatePlaceOfBirth: this._inmatePlaceOfBirthInput.value,
            nearestRelativeName: 'Unknown',
            nearestRelativeAddress: null,
            nearestRelativeCity: null,
            nearestRelativeState: null,
            nearestRelativeZip: null,
            nearestRelativePhone: null,
            employer: 'Unknown',
            employerAddress: null,
            employerCity: null,
            employerState: null,
            employerZip: null,
            employerPhone: null
        };

        if (this._inmateRelativeCheckbox.checked) {
            model.nearestRelativeName = this._inmateRelativeNameInput.value;
            model.nearestRelativeAddress = this._inmateRelativeAddressInput.value;
            model.nearestRelativeCity = this._inmateRelativeCityInput.value;
            model.nearestRelativeState = this._inmateRelativeStateInput.value;
            model.nearestRelativeZip = this._inmateRelativeZipInput.value;
            model.nearestRelativePhone = this._inmateRelativePhoneInput.value;
        }

        if (this._inmateEmployerCheckbox.checked) {
            model.employer = this._inmateEmployerNameInput.value;
            model.employerAddress = this._inmateEmployerAddressInput.value;
            model.employerCity = this._inmateEmployerCityInput.value;
            model.employerState = this._inmateEmployerStateInput.value;
            model.employerZip = this._inmateEmployerZipInput.value;
            model.employerPhone = this._inmateEmployerPhoneInput.value;
        }

        return model;
    }

    _inmateRelativeCheckboxOnChange(e) {
        this._optionalContainerToggle(this._inmateRelativeContainer, e.target.checked);
    }

    _inmateEmployerCheckboxOnChange(e) {
        this._optionalContainerToggle(this._inmateEmployerContainer, e.target.checked);
    }

    _optionalContainerToggle(container, show) {
        container.querySelectorAll('[required-when-shown]').forEach(x => {
            x.toggleAttribute('required', show);
        });
        container.toggleAttribute('hidden', !show);
    }
}
customElements.define('edit-pr-info-dialog', EditPRInfoDialog);