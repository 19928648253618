import { dollarFieldFormatter, dateTimeFormatterWithTz } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import bondWorkflow from '../Enumerations/BondWorkflow.js';
import 'lodash';

class BootstrapApplicationGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            `<bootstrap-table id="bootstrap-app-grid" class="mt-3 mb-3 mb-lg-5"></bootstrap-table>`,
            [],
            ['table']
        );
        this._tableConfig = {};
        this.dollarFieldFormatter = dollarFieldFormatter;
        this.dateTimeFormatterWithTz = dateTimeFormatterWithTz;
        this._table = null;
        this._bootstrapTable = this.shadowRoot.querySelector('bootstrap-table');
        if (this._bootstrapTable) {
            this._table = this._bootstrapTable.shadowRoot.getElementById('table');
            this._table.addEventListener('dataappend', this._updateTableUI.bind(this));
        }
        this._changedRows = [];
        this._removedRows = [];
        this._createdRows = [];
        this._uniqueId = null;
        this._refreshInterval = 3; // in minutes
        this._existingData = {};
        this._newData = {};
        this._httpMethod = null;
        this._url = null;
        this._parameter = null;
    }

    connectedCallback() {
        // nothing to do
    }

    disconnectedCallback() {
        // nothing to do
    }

    /**
     *
     * @param {Object} tableConfig
     *
     * Initialize the bootstrap application grid
     */
    initBootstrapApplicationGrid(tableConfig) {
        if (tableConfig != null)
            this._tableConfig = tableConfig;
        if (this._tableConfig.uniqueId != null)
            this._uniqueId = this._tableConfig.uniqueId;
        if (this._tableConfig.parameter != null)
            this._parameter = this._tableConfig.parameter;
        if (this._tableConfig.url !== undefined && this._tableConfig.httpMethod !== undefined) {
            this._url = this._tableConfig.url;
            this._httpMethod = this._tableConfig.httpMethod;
            this._fetchData(this._initCallback);
        }
    }

    _fetchData(callback) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(this._httpMethod,
            this._url,
            this._parameter,
            callback.bind(this));
    }

    /**
     *
     * @param {String} value
     * @param {Object} row
     *
     * Return InmateMI if present else return the value
     */
    nameFormatter(value, row) {
        return (row.InmateMI !== undefined && row.InmateMI !== null) ? value + ' ' + row.InmateMI : value;
    }

    /**
     *
     * @param {String} value
     *
     * Return the respective bondWorkflow value if present else return the value as it is
     */
    bondTypeFormatter(value) {
        if (isNaN(value))
            return bondWorkflow[`${value}`] !== undefined ? bondWorkflow[`${value}`].string : value;
        else
            return value;
    }

    _initCallback(response, success) {
        if (success) {
            const responseJson = JSON.parse(response);
            const tableData = responseJson.Data !== undefined ? responseJson.Data : responseJson;
            // Initialize the bootstrap table component
            this._bootstrapTable.initTableData({
                bootstrapTableConfig: this._tableConfig,
                tableData: tableData
            });
            this._existingData = tableData;
        }
    }

    // Call the respective functions to generate the diff values and update the table
    triggerRefresh() {
        setTimeout(function () {
            this._fetchData(this._refreshCallback);
        }.bind(this), (this._refreshInterval * 60000));
    }

    /**
     *
     * @param {String} response
     * @param {String} success
     *
     * Callback to generate diff values and refresh the table
     */
    _refreshCallback(response, success) {
        if (success) {
            this._newData = JSON.parse(response);
            this._generateDiffValues();
            this._updateRefreshedData();
            this._updateTableUI();
            // swap the new data as existing data
            this._existingData = this._newData;
            this.triggerRefresh();
        }
    }

    // Update the table with data and scrollTo the existing position in the table
    _updateRefreshedData() {
        let unionData = [];
        let newTableData = [];
        // generate the table data by combining new and existing data, and sort them based on the uniqueId
        unionData = _.unionBy(this._newData, this._existingData, this._uniqueId);
        newTableData = _.sortBy(unionData, this._uniqueId);
        // update the table based on the existing page number and refresh
        this._bootstrapTable.tableFullData = newTableData;
        const scrollTopValue = this._bootstrapTable.shadowRoot.querySelector('.fixed-table-body').scrollTop;
        const pageNo = this._bootstrapTable.pageNo;
        this._bootstrapTable.refreshTableData(pageNo);
        // scroll to the existing position in the table
        $(this._table).bootstrapTable('scrollTo', scrollTopValue);
    }

    /**
     *
     * @param {Object} base
     * @param {Object} values
     *
     * Return the uniqueId to identify the created and removed rows
     */
    _getKeysDifferenceBy(base, values) {
        const diff = _.differenceBy(base, values, this._uniqueId);
        return diff.map(a => a[this._uniqueId]);
    }

    // Return the changed, removed and created rows
    _generateDiffValues() {
        let changed = {};
        // get the newly added row
        const createdKeys = this._getKeysDifferenceBy(this._newData, this._existingData);
        // get the removed row
        const removedKeys = this._getKeysDifferenceBy(this._existingData, this._newData);
        // get rows that are common between new and existing data
        const commonRows = _.intersectionBy(this._existingData, this._newData, this._uniqueId);
        // get the updated field keys based on the uniqueId of the row
        commonRows.forEach(function (oldObj) {
            const newObj = this._newData.find(o => o[this._uniqueId] === oldObj[this._uniqueId]);
            const uniqueIdValue = oldObj[this._uniqueId];
            const objDiff = Object.keys(this._objDifference(oldObj, newObj));
            if (objDiff.length > 0)
                changed[uniqueIdValue] = objDiff;
        }.bind(this));

        this._changedRows = changed;
        this._removedRows = removedKeys;
        this._createdRows = createdKeys;
    }

    // Highlight the table rows with respective color to identify the updates
    _updateTableUI() {
        const addedClass = 'refresh-new-row';
        const removedClass = 'bg-secondary';
        this._table.querySelectorAll('tbody tr').forEach((row) => {
            const rowUniqueId = parseInt(row.getAttribute('data-uniqueid'));
            // Highlight the newly created row
            if (this._createdRows.includes(rowUniqueId))
                row.classList.add(addedClass);
            // Highlight the removed row and disable the buttons
            if (this._removedRows.includes(rowUniqueId)) {
                row.classList.add(removedClass);
                row.querySelectorAll('.btn').forEach((button) => {
                    button.disabled = true;
                });
            }
            // Highlight the updated fields in the row
            if (this._changedRows.hasOwnProperty(rowUniqueId)) {
                this._changedRows[rowUniqueId].forEach((field) => {
                    if (row.querySelector('td.' + field) !== undefined && row.querySelector('td.' + field) !== null)
                        row.querySelector('td.' + field).classList.add(addedClass);
                });
            }
        });
    }

    /**
     *
     * @param {Object} object
     * @param {Object} base
     *
     * Return the difference between the two objects
     */
    _objDifference(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    dateFormatter(value) {
        return window.DateFormatter.getTenantFormattedDate(value);
    }
}

customElements.define('bootstrap-application-grid', BootstrapApplicationGrid);