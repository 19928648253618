import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { saveDialog, showAlert, clearAlert, ensureListHasSameValuesAndDisplay, handleDropdownDifferencesCallback } from './EditDialogExtensions';
import template from './EditAttorneyInfoDialog.html';
import './StateDropdown';
import './ActionButton';
import bondWorkFlow from "./Enumerations/BondWorkflow.js";

class EditAttorneyInfoDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);

        this._pageTools = new PageTools();
        this._closeButton = this.shadowRoot.getElementById('attorney-info-dialog-close-button');
        this._saveButton = this.shadowRoot.getElementById('attorney-info-dialog-save-button');
        this._alert = this.shadowRoot.getElementById('modal-alert-view');
        this._modalComponent = this.shadowRoot.querySelector('modal-component');
        this._form = this.shadowRoot.querySelector('form');

        this._attorneyNameInput = this.shadowRoot.getElementById("attorney-name");
        this._attorneyBarNumberInput = this.shadowRoot.getElementById("attorney-bar-number");
        this._attorneyBarNumberExpirationInput = this.shadowRoot.getElementById("attorney-bar-number-expiration");
        this._inmatePlaceOfBirthInput = this.shadowRoot.getElementById("inmate-place-of-birth");
        this._inmateRelativeNameInput = this.shadowRoot.getElementById("inmate-relative-name");
        this._inmateRelativeAddressInput = this.shadowRoot.getElementById("inmate-relative-address");
        this._inmateRelativeCityInput = this.shadowRoot.getElementById("inmate-relative-city");
        this._inmateRelativeStateInput = this.shadowRoot.getElementById("inmate-relative-state");
        this._inmateRelativeZipInput = this.shadowRoot.getElementById("inmate-relative-zip");
        this._inmateRelativePhoneInput = this.shadowRoot.getElementById("inmate-relative-phone");
        this._inmateEmployerNameInput = this.shadowRoot.getElementById("inmate-employer-name");
        this._inmateEmployerAddressInput = this.shadowRoot.getElementById("inmate-employer-address");
        this._inmateEmployerCityInput = this.shadowRoot.getElementById("inmate-employer-city");
        this._inmateEmployerStateInput = this.shadowRoot.getElementById("inmate-employer-state");
        this._inmateEmployerZipInput = this.shadowRoot.getElementById("inmate-employer-zip");
        this._inmateEmployerPhoneInput = this.shadowRoot.getElementById("inmate-employer-phone");
        this._workFlow = null;

        this._closeDialog = this._closeDialog.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
    }

    set workflow(value) {
        this._workFlow = value;
    }

    get _hasAttorneyBarNumberExpirationRequiredFeatureFlag() {
        const attr = 'data-attorney-bar-number-expiration-required';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    connectedCallback() {
        this._closeButton.addEventListener('click', this._closeDialog);
        this._saveButton.addEventListener('click', this._saveDialog);
    }

    disconnectedCallback() {
        this._closeButton.removeEventListener('click', this._closeDialog);
        this._saveButton.removeEventListener('click', this._saveDialog);
    }

    openModal(selectedCharges) {
        this._selectedCharges = selectedCharges;
        this._setInitialValues();

        this._bondApplicationId = this._selectedCharges[0].BondApplicationID;
        const barNumberExpirationRequired = this._hasAttorneyBarNumberExpirationRequiredFeatureFlag;
        const attorneyBarNumberExpirationContainer = this._attorneyBarNumberExpirationInput.parentElement;
        this._attorneyBarNumberExpirationInput.toggleAttribute('required', barNumberExpirationRequired)

        attorneyBarNumberExpirationContainer.classList.toggle('required', barNumberExpirationRequired);
        attorneyBarNumberExpirationContainer.toggleAttribute('hidden', !barNumberExpirationRequired);

        if (this._workFlow === bondWorkFlow.companyAttorney.string) {
            this._attorneyNameInput.toggleAttribute('disabled', true);
            this._attorneyBarNumberInput.toggleAttribute('disabled', true);
            this._attorneyBarNumberExpirationInput.toggleAttribute('disabled', true);
        }
        
        this._modalComponent.openModal('Attorney Information', false);
    }

    _barNumberExpirationDateDifferenceCallback(item, different) {
        if (different) {
            this._attorneyBarNumberExpirationInput.value = null;
        } else {
            this._attorneyBarNumberExpirationInput.value = window.DateFormatter.convertToDateCourtDateTime(item.BarNumberExpiration);
        }
    }

    _setInitialValues() {
        const differences = ensureListHasSameValuesAndDisplay(this._selectedCharges, [
            { property: 'AttorneyName', element: this._attorneyNameInput },
            { property: 'BarNumber', element: this._attorneyBarNumberInput },
            { property: 'BarNumberExpiration', callback: this._barNumberExpirationDateDifferenceCallback.bind(this) },
            { property: 'InmatePlaceOfBirth', element: this._inmatePlaceOfBirthInput },
            { property: 'NearestRelativeName', element: this._inmateRelativeNameInput },
            { property: 'NearestRelativeAddress', element: this._inmateRelativeAddressInput },
            { property: 'NearestRelativeCity', element: this._inmateRelativeCityInput },
            { property: 'NearestRelativeState', element: this._inmateRelativeStateInput, callback: handleDropdownDifferencesCallback },
            { property: 'NearestRelativeZip', element: this._inmateRelativeZipInput },
            { property: 'NearestRelativePhone', element: this._inmateRelativePhoneInput },
            { property: 'Employer', element: this._inmateEmployerNameInput },
            { property: 'EmployerAddress', element: this._inmateEmployerAddressInput },
            { property: 'EmployerCity', element: this._inmateEmployerCityInput },
            { property: 'EmployerState', element: this._inmateEmployerStateInput, callback: handleDropdownDifferencesCallback },
            { property: 'EmployerZip', element: this._inmateEmployerZipInput },
            { property: 'EmployerPhone', element: this._inmateEmployerPhoneInput },
        ]);

        if (differences.length > 0) {
            showAlert(this._alert, 'There is conflicting information with one or more charges. Saved changes will be applied to all selected charges.');
        } else {
            clearAlert(this._alert);
        }
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }

    _saveDialog() {
        saveDialog({
            dialogElement: this,
            modalComponent: this._modalComponent,
            form: this._form,
            alertElement: this._alert,
            url: `/Application/UpdateAttorney/${this._bondApplicationId}`,
            data: this._gatherData(),
            pageTools: this._pageTools,
            useGenericErrorMessage: true,
            customSaveCallback: null
        });
    }

    _gatherData() {
        const model = { // BondApplicationAttorneyInformationViewModel
            attorneyName: this._attorneyNameInput.value,
            barNumber: this._attorneyBarNumberInput.value,
            inmatePlaceOfBirth: this._inmatePlaceOfBirthInput.value,
            nearestRelativeName: this._inmateRelativeNameInput.value,
            nearestRelativeAddress: this._inmateRelativeAddressInput.value,
            nearestRelativeCity: this._inmateRelativeCityInput.value,
            nearestRelativeState: this._inmateRelativeStateInput.value,
            nearestRelativeZip: this._inmateRelativeZipInput.value,
            nearestRelativePhone: this._inmateRelativePhoneInput.value,
            employer: this._inmateEmployerNameInput.value,
            employerAddress: this._inmateEmployerAddressInput.value,
            employerCity: this._inmateEmployerCityInput.value,
            employerState: this._inmateEmployerStateInput.value,
            employerZip: this._inmateEmployerZipInput.value,
            employerPhone: this._inmateEmployerPhoneInput.value
        };

        if (this._hasAttorneyBarNumberExpirationRequiredFeatureFlag) {
            model.barNumberExpiration = this._attorneyBarNumberExpirationInput.value;
        }

        return {
            detailIds: this._selectedCharges.map(element => element.BondApplicationDetailID),
            model
        };
    }    
}
customElements.define('edit-attorney-info-dialog', EditAttorneyInfoDialog);