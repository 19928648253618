import bootstrap from "./../../scss/bootstrap-custom.scss";
import template from "./BondTypeManagementEbondsBondTypeGrid.html";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import { SelectCellEditor } from "ag-grid-enterprise";
import { BasicGrid } from "../Grid/BasicGrid";
import { CreateEnumColumn, CreatePercentageColumn } from "../Grid/CustomDataColumns";
import type { CellEditorSelectorResult, GridApi, GridOptions, ICellEditorParams, IRichCellEditorParams, ICellRendererParams, IDetailCellRendererParams, ColDef, CellRendererSelectorResult, ValueFormatterParams, ISelectCellEditorParams } from "ag-grid-enterprise";
import type { BondTypeViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/BondTypeViewModel";
import type { BondTypeManagementViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/BondTypeManagementViewModel";
import type { JmsBondTypeViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/JmsBondTypeViewModel";
import type { BondTypeFeeViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/BondTypeFeeViewModel";
import { BondTypeUserType } from "../../types/Website/Bonds/Areas/SysAdmin/Enumerations/BondTypeUserType";
import { BondWorkflow } from "../../types/Entity/Bonds/ReferenceData/BondWorkflow";
import { FeeCategory } from "../../types/Entity/Bonds/ReferenceData/FeeCategory";
import { FilterBy } from "../../types/Entity/Bonds/ReferenceData/FilterBy";
import { GroupBy } from "../../types/Entity/Bonds/ReferenceData/GroupBy";
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";
import { basicAddDeleteButtonCellRendererSelector, getPinnedAddRowStyle } from "../Grid/BasicGridFunctions";
import { PaymentPortalFeeDestination } from "../../types/Website/Bonds/Areas/SysAdmin/Enumerations/PaymentPortalFeeDestination";

export class BondTypeManagementEbondsBondTypeGrid extends HTMLElement {
    public gridApi: GridApi<BondTypeViewModel> | undefined;
    private _jmsData: JmsBondTypeViewModel[] = [];
    private _ebondsData: BondTypeViewModel[] = [];

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], []);
    }
    public set data(value: BondTypeManagementViewModel) {
        this._jmsData = value.JmsBondTypes;
        this._ebondsData = value.BondTypes;
        this.gridApi?.setGridOption("rowData", this._ebondsData);
    }

    connectedCallback() {
        const detailFeeColumnDefs = this.createDetailColumnDefinitions();
        const columnDefs = this.createColumnDefinitions();
        const grid = <BasicGrid>nullThrow(this.shadowRoot?.getElementById("grid"));
        const options: GridOptions<BondTypeViewModel> = {
            popupParent: document.querySelector("body"),
            masterDetail: true,
            embedFullWidthRows: true,
            detailRowAutoHeight: true,
            keepDetailRows: true,
            pinnedTopRowData: [this.createEmptyEbondsBondType()],
            suppressNoRowsOverlay: true,
            stopEditingWhenCellsLoseFocus: true,
            singleClickEdit: true,
            getRowStyle: getPinnedAddRowStyle<BondTypeViewModel>,
            detailCellRendererParams: (params: ICellRendererParams) => <IDetailCellRendererParams<BondTypeViewModel, BondTypeFeeViewModel>>{
                detailGridOptions: <GridOptions<BondTypeFeeViewModel>>{
                    popupParent: document.querySelector("body"),
                    context: params,
                    pinnedTopRowData: [this.createEmptyFee()],
                    suppressNoRowsOverlay: true,
                    getRowStyle: getPinnedAddRowStyle<BondTypeFeeViewModel>,
                    stopEditingWhenCellsLoseFocus: true,
                    defaultColDef: {
                        editable: true,
                        singleClickEdit: true
                    },
                    columnDefs: detailFeeColumnDefs,
                    
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.Fees);
                }
            },
            defaultColDef: {
                filter: "agTextColumnFilter",
                floatingFilter: true,
                editable: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            columnDefs: columnDefs
        };
        
        this.gridApi = grid.createGrid(options);
    }

    private createColumnDefinitions(): ColDef<BondTypeViewModel>[] {
        return [
            {
                headerName: "eBONDS Bond Type",
                width: 220,
                field: "Name",
                sort: "asc",
                pinned: "left",
                cellRenderer: "agGroupCellRenderer"
            },
            CreateEnumColumn("UserType", BondTypeUserType),
            Object.assign(
                <ColDef<BondTypeViewModel>>{
                    cellEditorSelector: (params: ICellEditorParams<BondTypeViewModel>) => {
                        const selection: CellEditorSelectorResult = {
                            component: SelectCellEditor,
                            params: {}
                        }
                        switch (params.data.UserType) {
                            case BondTypeUserType.Jail:
                                selection.params.values = [BondWorkflow.Attorney, BondWorkflow.Cash, BondWorkflow.PR, BondWorkflow.Property];
                                break;
                            case BondTypeUserType.Company:
                                selection.params.values = [BondWorkflow.SuretyElectronic, BondWorkflow.SuretyManual, BondWorkflow.SuretyOwner];
                                if (settings.featureFlags[FeatureFlag.AttorneyCompaniesTemp]) {
                                    selection.params.values.unshift(BondWorkflow.CompanyAttorney)
                                }
                                break;
                            case BondTypeUserType.PaymentPortal:
                                selection.params.values = [BondWorkflow.Cash];
                                break;
                        }
                        return selection;
                    }
                },
                CreateEnumColumn("Workflow", BondWorkflow)
            ),
            {
                headerName: "Allowed JMS Bond Types",
                field: "JmsBondTypes",
                valueFormatter: (params: ValueFormatterParams<BondTypeViewModel>) => {
                    if (params.value?.length === 0) {
                        return "Select one or more";
                    }

                    return params.value;
                },
                cellEditorSelector: (params: ICellEditorParams<BondTypeViewModel>) => {
                    return <CellEditorSelectorResult>{
                        component: "agRichSelectCellEditor",
                        params: <IRichCellEditorParams>{
                            values: this._jmsData.map(x => x.Name).sort((a, b) => a.localeCompare(b)),
                            multiSelect: true,
                            highlightMatch: true
                        }
                    }
                }
            },
            { field: "IsTotalBond", cellDataType: "boolean", editable: settings.featureFlags[FeatureFlag.TotalBonds] },
            CreatePercentageColumn<BondTypeViewModel>("PercentSubtotal"),
            { field: "IsPayorInformationRequired", cellDataType: "boolean" },
            { field: "AdditionalPayorInformationRequired", cellDataType: "boolean" },
            { field: "ExtraditionInformationRequired", cellDataType: "boolean" },
            { field: "HasReceipt", cellDataType: "boolean" },
            { field: "ReviewAuthorizationRequired", cellDataType: "boolean" },
            {
                field: "CollectReceiptReferenceNumber",
                editable: (params) =>
                    params.data?.Workflow === BondWorkflow.Attorney ||
                    params.data?.Workflow === BondWorkflow.PR ||
                    params.data?.Workflow === BondWorkflow.Property,
                cellDataType: 'boolean'
            },
            { field: "CollectPayorSignature", cellDataType: "boolean" },
            {
                flex: 1,
                minWidth: 185,
                filter: false,
                floatingFilter: false,
                cellRendererSelector: (params: ICellRendererParams<BondTypeViewModel>): CellRendererSelectorResult => {
                    return basicAddDeleteButtonCellRendererSelector(params, this.createEmptyEbondsBondType, this.getDatasetReference.bind(this), "eBONDS Bond Type")
                },
                cellStyle: { 'font-style': 'normal' }
            }
        ];
    }

    private createDetailColumnDefinitions(): ColDef<BondTypeFeeViewModel>[] {
        const feeCategoryColumn: ColDef<BondTypeFeeViewModel, FeeCategory> = CreateEnumColumn("FeeCategory", FeeCategory);
        feeCategoryColumn.cellEditorParams.values = (<ISelectCellEditorParams>feeCategoryColumn.cellEditorParams).values.filter(x => x !== FeeCategory.SuretyFee);
        const detailFeeColumnDefs: ColDef<BondTypeFeeViewModel>[] = [
            { field: "Name", cellDataType: "text" },
            feeCategoryColumn,
            { field: "Amount", cellDataType: "number" },
            CreatePercentageColumn("PercentAmount"),
            { field: "MaxAmount", cellDataType: "number" },
            { field: "AfterSubtotal", cellDataType: "boolean" },
            { field: "IsSurcharge", cellDataType: "boolean" },
            { field: "NonRefundable", cellDataType: "boolean" },
            CreateEnumColumn("FilterBy", FilterBy),
            { field: "FilterByValue", cellDataType: "text" },
            CreateEnumColumn("GroupBy", GroupBy),
            CreateEnumColumn("PaymentPortalFeeDestination", PaymentPortalFeeDestination, (params) => params.context.data.UserType === BondTypeUserType.PaymentPortal),
            {
                flex: 1,
                minWidth: 70,
                filter: false,
                floatingFilter: false,
                cellRendererSelector: (params: ICellRendererParams<BondTypeFeeViewModel>): CellRendererSelectorResult => {
                    return basicAddDeleteButtonCellRendererSelector(params, this.createEmptyFee, () => params.context.data.Fees, "Fee")
                },
                cellStyle: { 'font-style': 'normal' }
            }
        ];

        return detailFeeColumnDefs;
    }

    private getDatasetReference() {
        return this._ebondsData;
    }

    private createEmptyFee(): BondTypeFeeViewModel {
        return <BondTypeFeeViewModel>{
            Name: "New Fee...",
            FeeCategory: FeeCategory.Custom1,
            Amount: 0,
            MaxAmount: null,
            AfterSubtotal: true,
            IsSurcharge: false,
            NonRefundable: false,
            FilterBy: FilterBy.None,
            FilterByValue: null,
            GroupBy: GroupBy.None,
            PaymentPortalFeeDestination: PaymentPortalFeeDestination.None
        };
    }

    private createEmptyEbondsBondType(): BondTypeViewModel {
        return <BondTypeViewModel>{
            BondTypeId: null,
            Name: "New eBONDS Bond Type...",
            UserType: BondTypeUserType.Disabled,
            Workflow: BondWorkflow.Cash,
            PercentSubtotal: 1,
            IsPayorInformationRequired: false,
            AdditionalPayorInformationRequired: false,
            HasReceipt: false,
            ExtraditionInformationRequired: false,
            ReviewAuthorizationRequired: false,
            IsTotalBond: false,
            CollectReceiptReferenceNumber: false,
            CollectPayorSignature: false,
            Fees: [],
            JmsBondTypes: []
        };
    }
}

customElements.define("bond-type-management-ebonds-bond-type-grid", BondTypeManagementEbondsBondTypeGrid);