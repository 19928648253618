import bootstrap from './../../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import { initializeHtmlElement } from './../../HTMLElementExtensions';
import { createColumn, dateTimeFormatterWithTz, dollarFieldFormatter } from './../../BootstrapTableExtensions';
import template from './BookingSheetTable.html';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { isAttributeTrue } from '../../JavaScriptFunctions';

class BookingSheetTable extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ['container']);
        this._table = this.shadowRoot.getElementById('table');
    }

    get _showBookingReportSpecialConditions() {
        return isAttributeTrue(this, "show-booking-sheet-special-conditions");
    }

    set charges(value) {
        const columns = [];
        columns.push(createColumn('Arrested', 'ArrestDateTime', undefined, dateTimeFormatterWithTz));
        columns.push(createColumn('Hold Reason', 'HoldReason'));
        columns.push(createColumn('Offense', 'OffenseDescription'));
        columns.push(createColumn('Degree', 'OffenseDegree'));
        columns.push(createColumn('Code', 'OffenseCode'));
        columns.push(createColumn('Court Description', 'CourtDescription'));
        columns.push(createColumn('Bond Amount', 'BondAmount', undefined, dollarFieldFormatter));
        columns.push(createColumn('Bond Type', 'BondType'));
        columns.push(createColumn('Disposition', 'Disposition'));
        if (this._showBookingReportSpecialConditions)
        {
            columns.push(createColumn('Special Conditions', 'SpecialConditions'));
        }

        $(this._table).bootstrapTable({
            columns: columns,
            data: value,
            classes: 'table table-sm table-striped table-bordered text-wrap',
            uniqueId: 'ArrestDateTime'
        });
    }
}

customElements.define('booking-sheet-table', BookingSheetTable);